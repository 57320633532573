export const getBlockedWordsText = blockedWords => {
    let blockedWordsText = '';
    if (Array.isArray(blockedWords) && blockedWords.length > 0) {
        for (let i = 0; i < blockedWords.length; i++) {
            blockedWordsText = blockedWordsText.length ? blockedWordsText + ', ' + blockedWords[i] : blockedWords[i];
        }
        return blockedWordsText
    } else {
        return blockedWords
    }
}