import React, {useState} from "react"
import "../css/careers.css"
import upArrow from "../../../public/images/up_arrow(blue).svg";
import downArrow from "../../../public/images/down_arrow(blue).svg";
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {isMobileOnly} from "react-device-detect";
import {getDateTimeWithFormat} from "../../shared/utils/get-date-time-with-format";
import {DATE_FORMATS} from "../../admin/constants";

const JobCard = (props) => {

    const [showMore, setShowMore] = useState(false)

    const handleApplyClick = () => {
        window.open(props.jobDetails?.formLink, "_blank")
    }

    return (
        <div className={"job-card"}>
            <div className={"job-header"}>
                <div className={"job-image"}>
                    <img style={{width: "35px"}} src={props.jobDetails.image} alt={"job icon"}/>
                </div>
                <div className={"job-title"}>
                    <h2>{props.jobDetails.jobTitle}</h2>
                    <span>{props.jobDetails.location}</span>
                    <p>Posted on: {getDateTimeWithFormat(props.jobDetails.published, DATE_FORMATS.DD_MM_YYYY_TIME)}</p>
                    {!isMobileOnly ? <>
                        <div className={"skills"}>
                            <img src={"../../../images/experience_icon.svg"} alt={"experience"}/>
                            <p>Experience - {props.jobDetails.experience}</p>
                        </div>
                        <div className={"skills"}>
                            <img src={"../../../images/skills_icon.svg"} alt={"skills"}/>
                            <p style={{display: "flex"}}>Skills- <textarea readOnly={true} style={{
                                outline: "0",
                                width: "100%",
                                resize: "none",
                                border: "none",
                                lineHeight: "20px",
                                fontFamily: "Nunito Sans, Sans-serif, serif",
                                fontSize: "16px",
                                height: (props.jobDetails.skills?.split(/\r?\n|\r|\n/g)?.length * 20) + "px"
                            }} value={props.jobDetails.skills}/></p>
                        </div>
                    </> : null}
                </div>
                {!isMobileOnly ? <MaterialButton handleClick={handleApplyClick}
                                                 data={{
                                                     title: "Apply",
                                                     buttonType: ButtonTypes.CONTAINED,
                                                     endImageFileName: '/apply_icon.svg',
                                                     bg_color: '#1976D2',
                                                     fontSize: '18px',
                                                     textTransform: 'uppercase',
                                                     customButtonContainerStyle: {
                                                         marginTop: '25px',
                                                         borderRadius: '24px',
                                                         padding: '15px 35px',
                                                         width: '150px',
                                                         height: '44px'
                                                     }
                                                 }}/> : null}
            </div>
            {showMore && isMobileOnly ? <>
                <div className={"skills"}>
                    <img src={"../../../images/experience_icon.svg"} alt={"experience"}/>
                    <p>Experience - {props.jobDetails.experience}</p>
                </div>
                <div className={"skills"}>
                    <img src={"../../../images/skills_icon.svg"} alt={"skills"}/>
                    <p>Skills- <textarea readOnly={true} style={{
                        outline: "0",
                        width: "100%",
                        resize: "none",
                        border: "none",
                        lineHeight: "20px",
                        fontFamily: "Nunito Sans, Sans-serif, serif",
                        fontSize: "16px",
                        height: (props.jobDetails.skills?.split(/\r?\n|\r|\n/g)?.length * 20) + "px"
                    }} value={props.jobDetails.skills}/></p>
                </div>
            </> : null}
            {showMore ? <>
                <div className={"job-description"}>
                    <h3>Job Description</h3>
                    <textarea readOnly={true} style={{
                        outline: "0",
                        width: "100%",
                        resize: "none",
                        border: "none",
                        lineHeight: "20px",
                        fontFamily: "Nunito Sans, Sans-serif, serif",
                        fontSize: "16px",
                        height: (props.jobDetails.jobDescription?.split(/\r?\n|\r|\n/g)?.length * 20) + "px"
                    }} value={props.jobDetails.jobDescription}/>
                </div>
                <div className={"job-description"}>
                    <h3>Roles & Reponsibilities</h3>
                    <textarea readOnly={true} style={{
                        outline: "0",
                        width: "100%",
                        resize: "none",
                        border: "none",
                        lineHeight: "20px",
                        fontFamily: "Nunito Sans, Sans-serif, serif",
                        fontSize: "16px",
                        height: (props.jobDetails.responsibilities?.split(/\r?\n|\r|\n/g)?.length * 20) + "px"
                    }} value={props.jobDetails.responsibilities}/>
                </div>
            </> : null}
            {!isMobileOnly ? <div className={"show-more"} onClick={() => setShowMore(!showMore)}>
                <span>{showMore ? "Show less" : "Show more"}</span>
                <img src={showMore ? upArrow : downArrow} alt={"show more"}/>
            </div> : <div style={{display: "flex", justifyContent: "space-between"}}>
                <MaterialButton handleClick={handleApplyClick}
                                data={{
                                    title: "Apply",
                                    buttonType: ButtonTypes.CONTAINED,
                                    endImageFileName: '/apply_icon.svg',
                                    bg_color: '#1976D2',
                                    fontSize: '18px',
                                    customButtonContainerStyle: {
                                        borderRadius: '24px',
                                        padding: '15px 35px',
                                        width: '100px',
                                        height: '40px'
                                    }
                                }}/>
                <div className={"show-more"} onClick={() => setShowMore(!showMore)}>
                    <p>{showMore ? "Show less" : "Show more"}</p>
                    <img src={showMore ? upArrow : downArrow} alt={"show more"}/>
                </div>
            </div>}
        </div>
    )
}

export default JobCard
