const initialState = {
    isForms: true,
    currentList: null,
    storageLevel: null,
    selectAll: undefined,
    currentForqData: null,
    selectedFilesArray: [],
    currentResponsesData: null,
    showStorageForqPopUp: false,
    showStorageDeletePopUp: false,
    showStorageManagementPopUp: false
}

export const STORAGE_LEVEL = {
    FORQ: 1,
    QUESTION: 2,
    FILE: 3
}

export const STORAGE_MANAGEMENT_ACTION_TYPE = {
    SET_IS_FORMS: "SET_IS_FORMS",
    SET_SELECT_ALL: "SET_SELECT_ALL",
    SET_CURRENT_LIST: "SET_CURRENT_LIST",
    SET_STORAGE_LEVEL: "SET_STORAGE_LEVEL",
    SET_CURRENT_FORQ_DATA: "SET_CURRENT_FORQ_DATA",
    SET_SELECTED_FILE_ARRAY: "SET_SELECTED_FILE_ARRAY",
    SET_CURRENT_RESPONSES_DATA: "SET_CURRENT_RESPONSES_DATA",
    SET_SHOW_STORAGE_FORQ_POP_UP: "SET_SHOW_STORAGE_FORQ_POP_UP",
    SET_SHOW_STORAGE_DELETE_POP_UP: "SET_SHOW_STORAGE_DELETE_POP_UP",
    SET_SHOW_STORAGE_MANAGEMENT_POP_UP: "SET_SHOW_STORAGE_MANAGEMENT_POP_UP",
}

const storageManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_IS_FORMS):
            return {
                ...state,
                isForms: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL):
            return {
                ...state,
                selectAll: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST):
            return {
                ...state,
                currentList: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL):
            return {
                ...state,
                storageLevel: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_FORQ_POP_UP):
            return {
                ...state,
                showStorageForqPopUp: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_FORQ_DATA):
            return {
                ...state,
                currentForqData: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_RESPONSES_DATA):
            return {
                ...state,
                currentResponsesData: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY):
            return {
                ...state,
                selectedFilesArray: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_DELETE_POP_UP):
            return {
                ...state,
                showStorageDeletePopUp: action.payload
            }
        case (STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_MANAGEMENT_POP_UP):
            return {
                ...state,
                showStorageManagementPopUp: action.payload
            }
        default:
            return state
    }
}

export default storageManagementReducer
