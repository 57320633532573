import React, {useEffect, useRef, useState} from 'react'
import PropTypes from "prop-types";
import style from '../css/add-collaboration-popup.module.css'
import ActionButtonContainer from "../../shared/containers/ActionButtonContainer";
import closeIcon from '../../../public/images/close_black.svg'
import MaterialTextFieldOutlinedContainer from "../../admin/containers/MaterialTextFieldOutlinedContainer";
import {isValidEmailTextPattern, validateCollaborator} from "../utils/validator";
import infoIcon from '../../../public/images/info.svg'
import {MDCSelect} from "@material/select/index";
import {useDispatch, useSelector} from "react-redux";
import {COLLABORATOR_ACTION_TYPE} from "../../app/utils/constants";
import {addFormCollaborator} from "../utils/sash-v2-api";
import {addQuizCollaborator} from "../utils/quiz-api-services";
import {isMobileOnly} from "react-device-detect";
import {COLLABORATOR_ROLES} from "../../app/builder/enums/collaboratorsRoles";
import Loader from "./Loader";
import RolesPopUp from "./RolesPopUp";
import {translationKeys} from "../../localizations/translationKeys-localization";
import MobileCollabRolesScreen from "../../app/dashboard/components/MobileCollabRolesScreen";
import {registerGAEvent} from "../utils/analytics";
import EmailAutoCompleteSuggestion from "./EmailAutoCompleteSuggestion";
import {EMAIL_ACTION_TYPE} from "../../app/reducers/collabAutocompleteReducer";
import {t} from "i18next";
import {BUILDER_CREATION_SOURCE} from "../utils/constants";
import MaterialSelectOptionsContainer from "./MaterialSelectOptionsContainer";

const COLLABORATOR_ROLES_TEXT = {
    ADMINISTRATOR: t(translationKeys.admin),
    EDITOR: t(translationKeys.editor),
    VIEWER: t(translationKeys.viewer)
}

const AddCollaboratorPopup = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const [showRolesPopUp, setShowRolesPopUp] = useState(false)
    const [role, setRole] = useState(props.owner ? COLLABORATOR_ROLES_TEXT.ADMINISTRATOR : COLLABORATOR_ROLES_TEXT.EDITOR)
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const formId = useSelector(state => state.appReducer.formData?._id)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const searchMatches = useSelector(state => state.collabAutocompleteReducer?.matches)
    const collaboratorsList = useSelector(state => state.collaboratorsData?.collaborators)
    const suggestions = useSelector(state => state.collabAutocompleteReducer?.suggestions)
    const selectedValue = useSelector(state => state.collabAutocompleteReducer?.selectedValue)
    const builderCreationSource = useSelector(state => state.appReducer.builderCreationSource)
    const suggestionClicked = useSelector(state => state.collabAutocompleteReducer?.suggestionClicked)
    const dispatch = useDispatch()
    const [wordInput, setWordInput] = useState('')
    const [errorText, setErrorText] = useState(t(translationKeys.enter_users_email_address))
    const infoText = role === COLLABORATOR_ROLES_TEXT.VIEWER ? isQuiz ? t(translationKeys.viewers_can_read_quiz_and_view_answers) : t(translationKeys.viewers_can_read_form_responses) : role === COLLABORATOR_ROLES_TEXT.EDITOR ?
        isQuiz ? t(translationKeys.editors_can_edit_quiz_view_answers) : t(translationKeys.editors_edit_form_view_responses) : isQuiz ? t(translationKeys.admin_can_edit_quiz_add_collaborator) : t(translationKeys.admin_edit_form_add_collaborator)

    useEffect(() => {
        dispatch({type: EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS})
        dispatch({type: EMAIL_ACTION_TYPE.SUGGESTION_CLICKED, payload: false})
    }, [])

    useEffect(() => {
        if (suggestionClicked) {
            setWordInput(selectedValue)
            setErrorText('')

            dispatch({type: EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS})
            dispatch({type: EMAIL_ACTION_TYPE.SET_SELECTED_EMAIL_VALUE, payload: ''})
            dispatch({type: EMAIL_ACTION_TYPE.SUGGESTION_CLICKED, payload: false})
        }
    }, [selectedValue])

    const checkForNewEmail = () => {
        const newEmail = []
        if (collaboratorsList) {
            collaboratorsList.map((newmail) => {
                if (suggestions.find((suggestion) => suggestion.toLowerCase() === newmail)) {
                    newEmail.push(newmail.user_id)
                }
            })
        }
        if (!suggestions.find((suggestion) => suggestion.toLowerCase() === wordInput)) {
            newEmail.push(wordInput)
        }
        if (newEmail.length > 0) {
            dispatch({type: EMAIL_ACTION_TYPE.STORE_NEW_EMAIL_IN_LOCAL_STORAGE, payload: newEmail})
        }
    }

    const handleSaveButtonClick = () => {
        if (errorText === '') {
            checkForNewEmail()
            setIsLoading(true)
            if (isValidEmailTextPattern(wordInput)) {
                if (formId && !isQuiz && builderCreationSource !== BUILDER_CREATION_SOURCE.DUPLICATE) {
                    const requestBody = JSON.stringify({
                        "form_id": formId,
                        "user_id": wordInput,
                        "role": role === COLLABORATOR_ROLES_TEXT.VIEWER ? COLLABORATOR_ROLES.VIEWER : role === COLLABORATOR_ROLES_TEXT.EDITOR ? COLLABORATOR_ROLES.EDITOR : COLLABORATOR_ROLES.ADMINISTRATOR
                    })
                    addFormCollaborator(requestBody, (res) => {
                        if (res.result) {
                            registerGAEvent('Form-Builder', 'Settings', 'collaborator-added')
                            dispatch({
                                type: COLLABORATOR_ACTION_TYPE.ADD_COLLABORATOR,
                                payload: {user_id: wordInput, role: role, collaboratorId: res.result}
                            })
                            props.setShowPopUp(false)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                } else if (formId && isQuiz && builderCreationSource !== BUILDER_CREATION_SOURCE.DUPLICATE) {
                    const requestBody = JSON.stringify({
                        "quiz_id": formId,
                        "user_id": wordInput,
                        "role": role === COLLABORATOR_ROLES_TEXT.VIEWER ? COLLABORATOR_ROLES.VIEWER : role === COLLABORATOR_ROLES_TEXT.EDITOR ? COLLABORATOR_ROLES.EDITOR : COLLABORATOR_ROLES.ADMINISTRATOR
                    })
                    addQuizCollaborator(requestBody, (res) => {
                        if (res.result) {
                            registerGAEvent('Quiz-Builder', 'Settings', 'collaborator-added')
                            dispatch({
                                type: COLLABORATOR_ACTION_TYPE.ADD_COLLABORATOR,
                                payload: {user_id: wordInput, role: role, collaboratorId: res.result}
                            })
                            props.setShowPopUp(false)
                        }
                    }, (err) => {
                        console.log(err)
                    })
                } else {
                    dispatch({
                        type: COLLABORATOR_ACTION_TYPE.ADD_COLLABORATOR,
                        payload: {user_id: wordInput, role: role}
                    })
                    props.setShowPopUp(false)
                }
            }
        }
    }
    const handleWordChange = value => {
        setWordInput(value.toLowerCase())

        if (value.length === 0) {
            setErrorText(t(translationKeys.enter_member_id))
        } else if (!isValidEmailTextPattern(value.toLowerCase())) {
            setErrorText(t(translationKeys.invalid_member_id))
        }

        if (value.length > 2) {
            dispatch({type: EMAIL_ACTION_TYPE.SEARCH, payload: value})
        } else {
            dispatch({type: EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS})
        }
    }
    const checkInList = (id) => {
        let isMember = false

        if (id === props.userId) {
            isMember = true
        }

        if (collaboratorsList?.length) {
            for (let collaborator of collaboratorsList) {
                if (collaborator && !validateCollaborator(props.userId, id, collaborator.user_id)) {
                    isMember = true
                    return true
                }
            }
        }
        return isMember
    }
    const isValidEmail = value => {
        if (isValidEmailTextPattern(value.toLowerCase()) && !checkInList(value.toLowerCase())) {
            setErrorText('')
            return true
        } else if (isValidEmailTextPattern(value.toLowerCase()) && checkInList(value.toLowerCase())) {
            setErrorText(t(translationKeys.already_member_added))
            return false
        } else {
            return false
        }
    }
    const handleSelection = (value) => {
        if (isQuiz) {
            registerGAEvent('Quiz-Builder', 'Settings', `collab-role-${value}`)
        } else {
            registerGAEvent('Form-Builder', 'Settings', `collab-role-${value}`)
        }
        setRole(value)
    }
    const handleClosePopUp = () => {
        dispatch({type: EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS})
        props.setShowPopUp(false)
    }
    const handleEmailBlur = () => {
        if (suggestionClicked) {
            dispatch({type: EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS})
        }
    }

    return (
        <>
            {showRolesPopUp && isMobileOnly ?
                <MobileCollabRolesScreen setShowRolesPopUp={setShowRolesPopUp}/> :

                <div className={style.card_container}>

                    {/*card title*/}
                    <div className={style.card_title}>
                        <span>{props.cardTitle}</span>
                    </div>

                    <div id={'add-collaborator'} style={{display: 'flex', height: isMobileOnly ? '40px' : null}}>
                        <div style={{display: 'block', width: '100%'}}>
                            {
                                <MaterialTextFieldOutlinedContainer placeHolder={t(translationKeys.email)} onChange={handleWordChange}
                                                                    validationFunction={suggestionClicked ? null : isValidEmail}
                                                                    onBlur={handleEmailBlur}
                                                                    autoFocus={true}
                                    // defaultValue={wordInputRef.current ? wordInputRef.current : '' }
                                                                    defaultValue={wordInput}
                                    // value={selectedValue}
                                                                    customStyleObject={{
                                                                        height: isMobileOnly ? '40px' : null,
                                                                        '--mdc-theme-primary': '#9e9e9e',
                                                                        '--mdc-theme-secondary': '#1976D2'
                                                                    }}
                                                                    errorText={errorText}
                                />
                            }
                            {
                                (() => {

                                    if (searchMatches?.length > 0) {
                                        return (
                                            <div
                                                style={{
                                                    zIndex: 2,
                                                }}
                                            >
                                                <EmailAutoCompleteSuggestion/>
                                            </div>
                                        )
                                    }
                                })()
                            }
                        </div>
                        <DropDown handleSelection={handleSelection} role={role} owner={props.owner}/>
                    </div>
                    <span style={{
                        margin: isMobileOnly ? null : '0 auto',
                        padding: isMobileOnly ? '7px' : '10px',
                        color: '#004BA0',
                        borderRadius: '5px',
                        textAlign: 'center',
                        whiteSpace: selectedLanguage === 'te' ? "nowrap" : null,
                        fontSize: isMobileOnly ? '12px' : null,
                        background: 'rgba(25, 118, 210, 0.1)'
                    }}><img src={infoIcon} alt={'infoIcon'}
                            style={{verticalAlign: 'bottom', width: isMobileOnly ? '15px' : null}}/> {infoText}<br/>
                        <span style={{textDecoration: 'underline', cursor: 'pointer'}}
                              onClick={() => {
                                  registerGAEvent('Builder', 'Settings', 'clicked-learn-roles-rights');
                                  setShowRolesPopUp(true)
                              }}>{t(translationKeys.learn_more)}</span></span>
                    {showRolesPopUp && !isMobileOnly ?
                        <RolesPopUp setShowRolePopUp={setShowRolesPopUp}/> : null}

                    {/*buttons container*/}
                    <div className={style.button_container}>
                        {isLoading ? <Loader height={'100%'} width={'100%'}/> :
                            <ActionButtonContainer handleClick={handleSaveButtonClick}
                                                   buttonText={t(translationKeys.add)}
                                                   height={isMobileOnly ? '35px' : '50px'}
                                                   fontSize={isMobileOnly ? '15px' : '20px'}
                                                   buttonWidth={'auto'}/>}
                    </div>

                    {/*close button*/}
                    <img className={style.close_image} alt={'close'} src={closeIcon} onClick={handleClosePopUp}/>
                </div>}
        </>
    );
}

const DropDown = (props) => {

    const mdcSelectMenuRef = useRef(null);
    useEffect(() => {
        const select = new MDCSelect(mdcSelectMenuRef.current);
        if (props.initialValueToSelect !== undefined) {
            select.value = props.initialValueToSelect
        }
    }, [])

    const selectorStyle = {
        '--mdc-theme-primary': '#212121',
        fontSize: '14px',
        width: '100%'
    }

    const selectTextStyle = {
        fontSize: isMobileOnly ? '14px' : '18px'
    }

    const selectValues = props.owner ? [COLLABORATOR_ROLES_TEXT.ADMINISTRATOR, COLLABORATOR_ROLES_TEXT.EDITOR, COLLABORATOR_ROLES_TEXT.VIEWER] : [COLLABORATOR_ROLES_TEXT.EDITOR, COLLABORATOR_ROLES_TEXT.VIEWER]

    return (
        <div className={'mdc-select mdc-select--outlined'}
             style={{color: '#212121', fontSize: '21px', background: 'transparent'}}
             ref={mdcSelectMenuRef}>
            <div className="mdc-select__anchor"
                 aria-labelledby="outlined-select-label"
                 style={{
                     width: selectorStyle.width ? selectorStyle.width : null,
                     background: '#F5F5F5',
                     marginLeft: '10px',
                     borderRadius: '5px',
                     height: isMobileOnly ? '40px' : null,
                     marginRight: '5px'
                 }}>
                <span className="mdc-select__selected-text-container">
                    <span className="mdc-select__selected-text" style={selectTextStyle}/>
                </span>
                <span className="mdc-select__dropdown-icon" style={{margin: isMobileOnly ? '0' : null}}>
                   <svg
                       className="mdc-select__dropdown-icon-graphic"
                       viewBox="7 10 10 5" focusable="false">
                     <polygon
                         className="mdc-select__dropdown-icon-inactive"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 10 12 15 17 10">
                     </polygon>
                     <polygon
                         className="mdc-select__dropdown-icon-active"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 15 12 10 17 15">
                     </polygon>
                   </svg>
                </span>
            </div>
            <MaterialSelectOptionsContainer selectValues={selectValues}
                                            selectTextStyle={selectTextStyle}
                                            handleSelection={props.handleSelection}
                                            initialValueToSelect={props.role}/>
        </div>
    )
}

export default AddCollaboratorPopup

AddCollaboratorPopup.propTypes = {
    cardTitle: PropTypes.string.isRequired,
}

AddCollaboratorPopup.defaultProps = {
    cardTitle: 'Title'
}