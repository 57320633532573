import React from 'react';
import '../css/material-fab.css'
import '../../shared/css/material-icons.css'

export default function MaterialFloatingActionButton(props) {
    return (
        <div id={'material-floating-action-button'} className="mdc-touch-target-wrapper">
            <button id={'mdc-fab'} className={'mdc-fab'}
                    style={props.buttonSize ? {
                        width: props.buttonSize,
                        height: props.buttonSize,
                        backgroundColor: props.backgroundColor ? props.backgroundColor : null
                    } : {backgroundColor: props.backgroundColor ? props.backgroundColor : null}}
                    aria-label="Favorite"
                    onClick={e => props.handleClick(e)}>
                <div className="mdc-fab__ripple"/>
                <span className="mdc-fab__icon material-icons"
                      style={{
                          fontSize: props.iconSize ? props.iconSize : null,
                          color: props.iconColor ? props.iconColor : null
                      }}>{props.iconImage ? <img src={props.iconImage}/> : props.iconName}</span>
            </button>
        </div>
    )
}