import React from "react";

const MaterialSelectOptionsContainer = props => {
    const lineStyle = {
        width: '100%'
    }
    return (
        <div className="mdc-select__menu mdc-menu mdc-menu-surface mdc-menu-surface--fullwidth">
            <ul className="mdc-list" role="listbox" aria-label="listbox">
                {
                    props.selectValues.map((value, index) => {
                        const selectedClass = (value === props.initialValueToSelect) ? 'mdc-list-item mdc-list-item--selected' : 'mdc-list-item'
                        const optionContainerStyle = {
                            display: 'flex',
                            width: '100%',
                            borderBottom: (index + 1) === props.selectValues.length ? null : '1px solid #e0e0e0'
                        }
                        const handleSelection = () => {
                            props.handleSelection(value)
                        };
                        return (
                            <div key={index} style={optionContainerStyle}
                                 onClick={handleSelection}>
                                <li className={selectedClass}
                                    aria-selected="false"
                                    data-value={value}
                                    style={lineStyle}
                                    role="option">
                                    <span className="mdc-list-item__ripple"/>
                                    <span className="mdc-list-item__text"  style={props.selectTextStyle}>
                                        {value}</span>
                                </li>
                            </div>
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default MaterialSelectOptionsContainer
