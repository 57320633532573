import {ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL} from "../../shared/utils/constants";

const initialState = {
    preview: false,
    formScreen: null,
    imageViewerAttachment: null,
    fillerSummary: false,
    actionToTakeBasedOnUserStorageLevel: ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL.PENDING
}

export const SCREENS_ACTION_TYPE = {
    SET_PREVIEW: 'SET_PREVIEW',
    SET_FORM_SCREEN: 'SET_FORM_SCREEN',
    SET_IMAGE_VIEWER: 'SET_IMAGE_VIEWER',
    SET_FILLER_SUMMARY: 'SET_FILLER_SUMMARY',
    SET_ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL: 'SET_ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL'
}

const screensReducer = (state = initialState, action) => {
    switch (action.type) {
        case (SCREENS_ACTION_TYPE.SET_ACTION_TO_TAKE_BASED_ON_USER_STORAGE_LEVEL):
            return {
                ...state,
                actionToTakeBasedOnUserStorageLevel: action.payload
            }
        case (SCREENS_ACTION_TYPE.SET_PREVIEW):
            return {
                ...state,
                preview: action.payload
            }
        case (SCREENS_ACTION_TYPE.SET_FORM_SCREEN):
            return {
                ...state,
                formScreen: action.payload
            }
        case (SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER):
            return {
                ...state,
                imageViewerAttachment: action.payload
            }
        case (SCREENS_ACTION_TYPE.SET_FILLER_SUMMARY):
            return {
                ...state,
                fillerSummary: action.payload
            }
        default:
            return state
    }
}

export default screensReducer