import React, {useEffect, useState} from 'react'
import CategorizedTemplatesContainer from "./CategorizedTemplatesContainer";
import {isMobileOnly} from "react-device-detect";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {useDispatch} from "react-redux";
import {templateTypes} from "./DashBoardContainer";

import {useSelector} from "react-redux";
import {t} from "i18next";
import {TEMPLATE_ACTION_TYPE} from "../../reducers/templateReducer";
import SearchContainer from "../../../admin/containers/SearchContainer";

const categories = []
const categoriesQuiz = []

const TemplateFormsContainer = (props) => {
    const [searchText, setSearchText] = useState("")
    const isShowingForms = useSelector(state => state.templateReducer.isShowingForms)
    const currentQuizTemplate = useSelector(state => state.getCurrentQuizTemplateReducer.quizTemplate)
    const currentFormTemplate = useSelector(state => state.getCurrentFormTemplateReducer.formTemplate)
    const dispatch = useDispatch()

    let currentTemplate = isShowingForms ? currentFormTemplate : currentQuizTemplate
    if (isShowingForms) {
        let categoriesCount = []
        let categoryIndex
        for (let i = 0; i < currentTemplate.length; i++) {
            categoryIndex = categories.indexOf(currentTemplate[i].category)
            if (categoryIndex === -1) {
                categories.push(currentTemplate[i].category)
                categoriesCount.push(1)
            } else {
                categoriesCount[categoryIndex] = categoriesCount[categoryIndex] + 1
            }
        }
    } else {
        let categoriesCountQuiz = []
        let categoryIndex
        for (let i = 0; i < currentTemplate.length; i++) {
            categoryIndex = categoriesQuiz.indexOf(currentTemplate[i].category)
            if (categoryIndex === -1) {
                categoriesQuiz.push(currentTemplate[i].category)
                categoriesCountQuiz.push(1)
            } else {
                categoriesCountQuiz[categoryIndex] = categoriesCountQuiz[categoryIndex] + 1
            }
        }
    }

    const getFormsByCategory = (categoryTitle) => {
        let categorizedForms = [];
        let currentTemplate = isShowingForms ? currentFormTemplate : currentQuizTemplate
        dispatch({type: TEMPLATE_ACTION_TYPE.SET_SHOW_ALL_FORMS_OF_THIS_CATEGORY, payload: null})
        for (let i = 0; i < currentTemplate.length; i++) {
            if (currentTemplate[i].category === categoryTitle) {
                categorizedForms.push(currentTemplate[i]);
            }
        }
        return categorizedForms;
    }

    const handleTextChange = (text) => {
        setSearchText(text)
    }

    const handleKeyDown = () => {

    }

    return (
        <div className="template-forms-container cards-container-width" style={{width: props.homePage ? "100%" : null}}>
            <div id={'dashboard-content-container'} className='dashboard-content-container'
                 style={{
                     display: 'flex',
                     flexFlow: 'column',
                     alignItems: 'center',
                     width: props.homePage ? "100%" : null,
                     paddingBottom: isMobileOnly ? '10px' : null
                 }}>
                <TemplateButtonsContainer/>
                {props.homePage ? <div style={{width: "100%", margin: "20px", textAlign: "-webkit-center"}}>
                    <SearchContainer handleTextChange={handleTextChange}
                                     width={"40%"}
                                     height={"40px"}
                                     handleKeyDown={handleKeyDown}/>
                </div> : null}
                {isShowingForms ? categories.map((categoryTitle) => {
                    return <CategorizedTemplatesContainer formList={searchText ? getFormsByCategory(categoryTitle).filter((template) => template?.welcome_screen?.title?.toLowerCase()?.includes(searchText?.toLowerCase())) : getFormsByCategory(categoryTitle)}
                                                          key={categoryTitle}
                                                          isFormTemplates={true}
                                                          homePage={props.homePage}
                                                          category={categoryTitle}
                                                          previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}/>
                }) : categoriesQuiz.map((categoryTitle, index) => {
                    return <CategorizedTemplatesContainer formList={searchText ? getFormsByCategory(categoryTitle).filter((template) => template?.welcome_screen?.title?.toLowerCase()?.includes(searchText?.toLowerCase())) : getFormsByCategory(categoryTitle)}
                                                          key={index}
                                                          isFormTemplates={false}
                                                          homePage={props.homePage}
                                                          category={categoryTitle}
                                                          previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}/>

                })}
            </div>
        </div>)
}

export default TemplateFormsContainer

export const TemplateButtonsContainer = (props) => {
    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    const templateType = useSelector(state => state.appDashboardReducer.templateType)
    const dispatch = useDispatch()

    useEffect(() => {
        if (!templateType) {
            setTemplatesData(templateTypes.FORM)
        }
    }, [])

    const setTemplatesData = (type) => {
        if (type !== templateType) {
            if (type === templateTypes.QUIZ) {
                dispatch({type: TEMPLATE_ACTION_TYPE.SET_IS_SHOWING_FORMS, payload: false})
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.QUIZ})
            } else if (type === templateTypes.FORM) {
                dispatch({type: TEMPLATE_ACTION_TYPE.SET_IS_SHOWING_FORMS, payload: true})
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.FORM})
            }
            dispatch({type: TEMPLATE_ACTION_TYPE.SET_SHOW_ALL_FORMS_OF_THIS_CATEGORY, payload: 'none'})
        }
    }

    return (isMobileOnly && !props.homePage ?
        <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly', backgroundColor: '#1976d2'}}>
            <div
                className={templateType === templateTypes.FORM ? 'template-button-mobile active-template-button-mobile' : 'template-button-mobile '}
                onClick={() => {
                    registerGAEvent('Dashboard', 'Templates', 'forms-view');
                    setTemplatesData(templateTypes.FORM);
                }}>
                {t(translationKeys.forms)}
            </div>
            <div
                className={templateType === templateTypes.FORM ? 'template-button-mobile ' : 'template-button-mobile  active-template-button-mobile'}
                onClick={() => {
                    registerGAEvent('Dashboard', 'Templates', 'quizzes-view');
                    setTemplatesData(templateTypes.QUIZ);
                }}>
                {t(translationKeys.quizzes)}
            </div>
        </div> : <div style={{width: '100%', display: 'flex', justifyContent: 'center'}}>
            <div
                className={(selected === 'fa' || selected === 'ar') ? templateType === templateTypes.FORM ? 'template-button form-template-button-fa active-template-button' : 'template-button form-template-button-fa' : templateType === templateTypes.FORM ? 'template-button form-template-button active-template-button' : 'template-button form-template-button'}
                onClick={() => {
                    registerGAEvent('Dashboard', 'Templates', 'forms-view');
                    setTemplatesData(templateTypes.FORM);
                }}>
                {t(translationKeys.forms)}
            </div>
            <div
                className={(selected === 'fa' || selected === 'ar') ? templateType === templateTypes.QUIZ ? 'template-button quiz-template-button-fa active-template-button' : 'template-button quiz-template-button-fa' : templateType === templateTypes.QUIZ ? 'template-button quiz-template-button active-template-button' : 'template-button quiz-template-button'}
                onClick={() => {
                    registerGAEvent('Dashboard', 'Templates', 'quizzes-view');
                    setTemplatesData(templateTypes.QUIZ);
                }}>
                {t(translationKeys.quizzes)}
            </div>
        </div>)
}
