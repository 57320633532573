export function isValidEmailTextPattern(emailText) {
    const check = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return check.test(emailText);
}

export function isValidPasswordPattern(passwordText) {
    return /.{8,}/.test(passwordText)
        && /\d/.test(passwordText)
        && /[A-Z]/.test(passwordText)
        && /[@$!%*?^&#]/.test(passwordText)
}

export function validateCollaborator(ownerEmailAddress, newCollaboratorEmailAddress, existingCollaborators) {
    if (isValidEmailTextPattern(newCollaboratorEmailAddress)) {
        if (ownerEmailAddress === newCollaboratorEmailAddress) {
            return false
        } else return newCollaboratorEmailAddress !== existingCollaborators;
    } else return false
}

export function detectHyperlinkText(str) {
    const checkURL = /\bhttps?:\/\/\S+/gi;
    const checkNumber = /(?:[-+() ]*\d){10,13}/g;
    const checkEmail = /\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}\b/g;
    const URLS = str.match(checkURL);
    const emails = str.match(checkEmail);
    const numbers = str.match(checkNumber);
    if (emails) {
        emails.forEach(email => {
            const emailHyper = `<a href="mailto:${email}">${email}</a>`;
            str = str.replace(email, emailHyper);
        });
    }
    if (numbers) {
        numbers.forEach(number => {
            const numberHyper = `<a href="tel:${number}">${number}</a>`;
            str = str.replace(number, numberHyper);
        });
    }
    if (URLS) {
        URLS.forEach(url => {
            const URLHyper = `<a href="${url}" target="_blank">${url}</a>`;
            str = str.replace(url, URLHyper);
        });
    }
    return str;
}
