import React from 'react';
import "../css/selected-file-component.css";
import closeIcon from '../../../public/images/close_black.svg'
import PropTypes from "prop-types";

const SelectedFileComponent = (props) => {
    return (
        <div className={'card-parent'}>
            <div className={'card'}>
                <span className={'text'}>{props.text}</span>
                <span className={'size'}>({props.size})</span>
                {props.showClose ? <span onClick={props.clickHandler}><img className={'image'} src={closeIcon}
                                                                           alt={'close'}/></span> : null}
            </div>
            <div style={{display: "none"}}>
                Placeholder
            </div>
        </div>
    )
}
export default SelectedFileComponent

SelectedFileComponent.prototype = {
    text: PropTypes.string.isRequired,
    size: PropTypes.string.isRequired,
    clickHandler: PropTypes.func,
    showClose: PropTypes.bool.isRequired
}
SelectedFileComponent.defaultProps = {
    text: 'default',
    size: '129kb',
    showClose: false
}