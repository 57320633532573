import React from "react";
import '../css/data-unavailable-component.css'
import {isMobileOnly} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {useDispatch} from "react-redux";
import {templateTypes} from "./DashBoardContainer";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {TEMPLATE_ACTION_TYPE} from "../../reducers/templateReducer";


function DataUnavailableComponent(props) {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    return (
        <div id={'forms-container'} className="forms-container" style={isMobileOnly ? {
            marginBottom: '55px'
            /*width: props.data === 'No Shared Data Available!' ? '100%' : null,
            display: props.data === 'No Shared Data Available!' ? 'flex' : null*/
        } : null}>
            <div id={'dashboard-content-container'} className='dashboard-content-container no-cards'>
                <img src='images/no_form_notepad.svg' alt='no-data'/>
                <h3>{props.data}</h3>
                {props.data === t(translationKeys.no_form_available) ?
                    <div className='template-button-container'
                         onClick={() => {
                             registerGAEvent('Dashboard', 'Templates', 'view');
                             dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.FORM})
                             dispatch({type: TEMPLATE_ACTION_TYPE.SET_IS_SHOWING_FORMS, payload: true})
                             dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_TEMPLATES})
                         }}>
                        <img className='no-data-template-icon' alt='template'
                             src='images/template_blue.png'/>
                        <span className='template-button-text'>{t(translationKeys.use_templates)}</span>
                    </div>
                    : props.data === t(translationKeys.no_quiz_available) ?
                        <div id={'template-button-container'} className='template-button-container'
                             onClick={() => {
                                 registerGAEvent('Dashboard', 'Templates', 'view');
                                 dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.QUIZ})
                                 dispatch({type: TEMPLATE_ACTION_TYPE.SET_IS_SHOWING_FORMS, payload: false})
                                 dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_TEMPLATES})
                             }}>
                            <img className='no-data-template-icon' alt='template'
                                 src='images/template_blue.png'/>
                            <span className='template-button-text'>{t(translationKeys.use_templates)}</span>
                        </div> : null}
            </div>
        </div>
    )
}

export default DataUnavailableComponent
