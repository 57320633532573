import React from 'react'
import styles from "../../css/question-attachment.module.css";
import {ATTACHMENT_TYPE} from "../../../shared/utils/constants";
import {isMobileOnly} from "react-device-detect";
import closeWhite from "../../../../public/images/close_white.svg";
import {SCREENS_ACTION_TYPE} from "../../reducers/screensReducer";
import {useDispatch, useSelector} from "react-redux";

const ImageViewerComponent = (props) => {
    const attachment = useSelector(state => state.screensReducer?.imageViewerAttachment)
    const dispatch = useDispatch()

    const popUpStyle = {
        backgroundColor: "#212121",
        height: '100%',
        width: '100%',
        zIndex: '12',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    }
    const backButtonBackgroundStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        margin: isMobileOnly ? "10px" : "30px",
        position: "absolute",
        top: "10px",
        zIndex: 1,
        right: "10px",
        borderRadius: '50%',
        backgroundColor: "#595959",
        cursor: 'pointer'
    }
    const handleImageError = (element) => {
        element.src = "/images/default_image.svg"
    }

    window.onhashchange = (() => {
        if (window.location.hash !== "#image-viewer") {
            dispatch({type: SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER, payload: null})
        }
    })

    return (
        <div style={popUpStyle}>
            <div style={backButtonBackgroundStyle}
                 onClick={(e) => {
                     if (!!props.close) {
                         props.close()
                     } else {
                         e.stopPropagation()
                         window.history.back()
                         dispatch({type: SCREENS_ACTION_TYPE.SET_IMAGE_VIEWER, payload: null})
                     }
                 }
                 }>
                <img alt='back-button' style={{width: "30px"}} src={closeWhite}/>
            </div>
            <div className={styles.question_attachment}
                 style={{
                     display: (attachment?.file_type
                         === ATTACHMENT_TYPE.WEBSITE.key) ? 'none' : 'flex',
                     height: isMobileOnly ? null : '80%'
                 }}>
                {props.video ?
                    <iframe width={isMobileOnly ? "100%" : "80%"} height={isMobileOnly ? "300" : "100%"} src={props.attachment}
                            title={"YouTube video player"} frameBorder={"0"}
                            allow={"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
                            referrerPolicy={"strict-origin-when-cross-origin"} allowFullScreen></iframe> :
                    <img src={props.attachment ? props.attachment : attachment?.image_url}
                         alt={'image_Url'}
                         onError={e => handleImageError(e.target)}
                         style={{
                             display: (attachment?.file_type
                                 === ATTACHMENT_TYPE.WEBSITE.key) ? 'none' : 'flex',
                             width: isMobileOnly ? '100%' : null,
                             objectFit: 'contain'
                         }}/>
                }
            </div>
        </div>
    )
}

export default ImageViewerComponent
