import React, {useRef, useState} from 'react'
import ChooseImageContainer from "./ChooseImageContainer";
import ImagePreviewContainer from "./ImagePreviewContainer";
import {isMobileOnly} from "react-device-detect";
import {UPLOAD_TYPE} from "../../../shared/utils/constants";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {translationsEnglish} from "../../../localizations/en-localization";
import {useSelector} from "react-redux";
import Loader from "../../../shared/components/Loader";

const UploadImageContainer = (props) => {
    const {t} = useTranslation()
    const [showPreviewComponent, setShowPreviewComponent] = useState(false);
    const [chooseImageComponent, setChooseImageComponent] = useState(true);
    const [showFileUploadMaxSizeError, setShowFileUploadMaxSizeError] = useState(false);
    const [showInvalidFileTypeError, setShowInvalidFileTypeError] = useState(false);
    const [imageToPreview, setImageToPreview] = useState('');
    const [imageFile, setImageFile] = useState(props.imageFile);
    const [loading, setLoading] = useState(false);
    const isFileUpload = props.isFileUpload;
    const [isDragging, setIsDragging] = useState(props.isDragging);

    const fileInput = useRef(null);
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)

    const [isCancelContainerVisible, setIsCancelContainerVisible] = useState(true);

    const hideCancelButton = () => {
        setIsCancelContainerVisible(false);
    };

    const openLocalStorage = () => {
        fileInput.current.click();
    }

    const closePopUp = () => {
        if (props.fileUpload) {
            props.shouldFileBrowserBeVisible(false);
            if (props.cancelAction) {
                props.cancelAction()
            }
        } else {
            props.openUploadPopUp(false);
        }
    }

    const cloneFile = async (file) => {
        let blob = new Blob([await file.arrayBuffer()], {
            type: file.type
        });

        const buffer = await blob.arrayBuffer();
        return new File([buffer], blob.name, {type: blob.type})
    }

    const previewImage = (imageFile) => {
        if (isFileUpload) {
            setLoading(true)
            const promise = cloneFile(imageFile)
            promise.then((blob) => {
                function blobToFile(blob, fileName){
                    return new File([blob], fileName, {
                        type: imageFile.type,
                    })
                }
                const file = blobToFile(blob, imageFile.name)
                if (isValidFileTypeAndSize(file, props.maxFileUploadSize)) {
                    props.shouldFileBrowserBeVisible(false)
                    props.previewCompleted(file, imageFile.size)
                }
                setLoading(false)
            }, (err) => {
                console.log(err)
                setLoading(false)
            })
        } else if (isValidImage(imageFile, props.maxFileUploadSize)) {
            setShowPreviewComponent(true);
            setChooseImageComponent(false);
            // setImageToPreview(URL.createObjectURL(imageFile));
            setImageToPreview(imageFile)
            setImageFile(imageFile);
        }
    }

    const isValidFileTypeAndSize = (image, maxFileUploadSize) => {
        // Check the File Type
        if (!isFileTypeValid(['image/jpeg', 'image/jpg', 'image/svg+xml', 'audio/mpeg', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/vnd.ms-powerpoint', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'image/png', 'video/mp4', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'application/pdf', 'application/x-zip-compressed', 'text/plain'], image.type)) {
            return false
        }

        // Check the File size
        // '4e6' = 4MB
        const maxSizeInBytes = maxFileUploadSize ? maxFileUploadSize : 4e6;

        if ((image.size / 1.024) > maxSizeInBytes) {
            setShowInvalidFileTypeError(false);
            setShowFileUploadMaxSizeError(true);
            return false;
        }
        return true;
    }

    const isFileTypeValid = (validFileTypes, fileType) => {
        if (validFileTypes.indexOf(fileType) === -1) {
            setShowFileUploadMaxSizeError(false);
            setShowInvalidFileTypeError(true);
            return false
        }
        return true
    }

    const isValidImage = (image, maxFileUploadSize) => {
        if (!isFileTypeValid(['image/jpeg', 'image/png', 'image/jpg'], image.type)) {
            return false
        }

        // checking the image size
        // '4e6' = 4MB
        const maxSizeInBytes = maxFileUploadSize ? maxFileUploadSize : 4e6;

        if ((image.size / 1.024) > maxSizeInBytes) {
            setShowInvalidFileTypeError(false);
            setShowFileUploadMaxSizeError(true);
            return false;
        }
        return true;
    }
    //to avoid the new tab opening for file dropped outside the target.
    window.addEventListener("dragleave", function (e) {
        if (e.target !== fileInput) {
            e.preventDefault();
        }
    }, false);
    window.addEventListener("dragover", function (e) {
        if (e.target !== fileInput) {
            e.preventDefault();
        }
    }, false);
    window.addEventListener("drop", function (e) {
        if (e.target !== fileInput) {
            e.preventDefault();
        }
    }, false);

    return (
        <div id={'image-upload-container'} className="image-upload-container" ref={fileInput.current}
             style={{borderColor: isDragging ? '#1976D2' : null, width: isMobileOnly && !props.fileUpload ? "100%" : null, height: isMobileOnly && !props.fileUpload ? "100%" : null}}
             onDragOver={e => {
                 e.preventDefault();
                 e.stopPropagation();
                 setIsDragging(true);
             }}
             onDragLeave={e => {
                 e.preventDefault();
                 e.stopPropagation();
                 setIsDragging(false);
             }}
             onDrop={e => {
                 e.preventDefault();
                 e.stopPropagation();
                 setIsDragging(false);
                 previewImage(e.dataTransfer.files[0])
             }}>
            <div className="drop-image-container">
                <input id="choose-file-input" type="file"
                       accept={isFileUpload ? ".jpg, .png, .jpeg, .svg, .doc, .docx, .mp3, .mp4, .ppt, .pptx, .mov, .xls, .xlsx, .pdf, .zip, .txt, .csv" : ".jpg, .png, .jpeg, .jfif"}
                       style={{display: 'none'}}
                       ref={fileInput}
                       onChange={e => {
                           setShowFileUploadMaxSizeError(false)
                           setShowInvalidFileTypeError(false)
                           previewImage(e.target.files[0])
                       }}/>
                {props.fileUpload ?
                    <ChooseImageContainer
                        isFiller={props.isFiller}
                        show={chooseImageComponent}
                        openLocalStorage={openLocalStorage}
                        uploadType={isFileUpload ? UPLOAD_TYPE.FILE : UPLOAD_TYPE.IMAGE}
                        showFileUploadMaxSizeError={showFileUploadMaxSizeError}
                        showInvalidFileTypeError={showInvalidFileTypeError}
                    /> :
                    <ChooseImageContainer show={chooseImageComponent} openLocalStorage={openLocalStorage}
                                          isFiller={props.isFiller}
                                          showFileUploadMaxSizeError={showFileUploadMaxSizeError}
                                          showInvalidFileTypeError={showInvalidFileTypeError}/>}
                {props.fileUpload ?
                    <ImagePreviewContainer
                        {...props}
                        showFileUploadMaxSizeError={showFileUploadMaxSizeError}
                        showInvalidFileTypeError={showInvalidFileTypeError}
                        setShowInvalidFileTypeError={setShowInvalidFileTypeError}
                        setShowFileUploadMaxSizeError={setShowFileUploadMaxSizeError}
                        show={showPreviewComponent} imageToPreview={imageToPreview}
                        openLocalStorage={openLocalStorage} closePopUp={closePopUp}
                        imageFile={imageFile}
                        hideCancelButton ={hideCancelButton}
                    /> :
                    <ImagePreviewContainer showFileUploadMaxSizeError={showFileUploadMaxSizeError}
                                           showInvalidFileTypeError={showInvalidFileTypeError}
                                           setShowInvalidFileTypeError={setShowInvalidFileTypeError}
                                           setShowFileUploadMaxSizeError={setShowFileUploadMaxSizeError}
                                           show={showPreviewComponent} imageToPreview={imageToPreview}
                                           openLocalStorage={openLocalStorage} closePopUp={closePopUp}
                                           imageFile={imageFile} updateThemesList={props.updateThemesList}
                                           careersIcon={props.careersIcon}
                                           uploadType={props.uploadType}
                                           hideCancelButton ={hideCancelButton}
                    />
                }
                { isCancelContainerVisible &&
                    <div className='upload-cancel-container' style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {marginRight: 0} : null}>
                        <button id="choose-image-button" className="upload-cancel-button"
                                onClick={() => {
                                    closePopUp()
                                    isMobileOnly && props.showBottomBarWhenImagesContainerClosed ? props.showBottomBarWhenImagesContainerClosed() : null;
                                }}>{props.isFiller ? translationsEnglish.cancel : t(translationKeys.cancel)}
                        </button>
                    </div>
                }

                {loading ? <Loader height={'100%'} width={"100%"}/> : null}
            </div>
        </div>
    )
}
export default UploadImageContainer