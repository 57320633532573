import React from 'react'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

export default function CreateNewBanner(props) {
    const {t} = useTranslation()
    return (
        <div id={'create-new-popup-container'} className='create-new-popup-container'>
            <div id={'create-new-card-form'} className='create-new-card' onClick={props.handleFormsCardClick}>
                <img style={{width: '48px', height: '48px'}} alt='form' src='images/summary_dark_blue.svg'/>
                <span id={'form-title-mobile'} className='create-new-banner-item-title'>{t(translationKeys.forms)}</span>
                <span id={'form-title-mobile-description'} className='create-new-desc'>{props.formsDesc}</span>
            </div>
            <div id={'create-new-card-quiz'} className='create-new-card' onClick={props.handleQuizzesCardClick}>
                <img style={{width: '48px', height: '48px'}} alt='form' src='images/quiz_dark_blue.svg'/>
                <span id={'quiz-title-mobile'} className='create-new-banner-item-title'>{t(translationKeys.quizzes)}</span>
                <span id={'quiz-title-mobile-description'} className='create-new-desc'>{props.quizzesDesc}</span>
            </div>
        </div>
    )
}
