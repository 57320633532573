import hallowCircle from "../../../../public/images/icons/hallow_circle.svg";
import doneBlue from '../../../../public/images/done_blue.svg'
import React, {useState} from "react";
import "../../builder/css/localization.css"
import i18next, {t} from "i18next";
import {initReactI18next} from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import close from "../../../../public/images/close.png"
import {resources} from "../../../shared/utils/constants";
import {useSelector} from "react-redux";
import {registerGAEvent} from "../../../shared/utils/analytics";

const fallbackLng = ["en"];

i18next.use(Backend).use(LanguageDetector).use(initReactI18next)
    .init({
        resources, fallbackLng, detection: {
            checkWhitelist: true
        }, interpolation: {
            escapeValue: false
        }
    });


function LanguageSelectionPopup(props) {

    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    const [selectedLanguage, setSelectedLanguage] = useState(selected)


    const popUpStyleShow = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '11',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    }

    const handleClick = (selectedLanguage) => {
        setSelectedLanguage(selectedLanguage)
    }

    return (<div style={popUpStyleShow}>
        <div className={'localization-header-width'} ref={props.localizationContainerRef}>
            <div className={'localization-header'}>
                <div className={'localization-header-text'}>
                    {t(translationKeys.select_language)}
                </div>
                <img onClick={() => {
                    registerGAEvent('Languages', 'pop-up', 'close');
                    props.setLocalizationShow(false)
                }} className={'localization-close-img'}
                     src={close} alt={close}/>
            </div>
            <div className={'localization-body'}>
                <div className="localization-each-option-mobile" style={{paddingTop: '10px'}}
                     onClick={() => handleClick('en')}>{'English'} ( {'Default'} ) {selectedLanguage.startsWith('en') ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>} </div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('fr')}>{'French'} ( {'français'} ) {selectedLanguage === 'fr' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}
                </div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('hi')}>{'Hindi'} ( {'हिन्दी'} ) {selectedLanguage === 'hi' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('pa')}>{'Punjabi'} ( {'ਪੰਜਾਬੀ'} ) {selectedLanguage === 'pa' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('in')}>{'Indonesia'} ( {'Bahasa Indonesia'} ) {selectedLanguage === 'in' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('ml')}>{'Malayalam'} ( {'മലയാളം'} ) {selectedLanguage === 'ml' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('mr')}>{'Marathi'} ( {'मराठी'} ) {selectedLanguage === 'mr' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('fa')}>{'Persian'} ( {'فارسی  '} ) {selectedLanguage === 'fa' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('pt')}>{'Portuguese'} ( {'Português'} ) {selectedLanguage === 'pt' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('es')}>{'Spanish'} ( {'Spanish'} ) {selectedLanguage === 'es' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('ta')}>{'Tamil'} ( {'தமிழ்'} ) {selectedLanguage === 'ta' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('te')}>{'Telugu'} ( {'తెలుగు'} ) {selectedLanguage === 'te' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className="localization-each-option-mobile"
                     onClick={() => handleClick('ar')}>{'Arabic'} ( {'عربي'} ) {selectedLanguage === 'ar' ?
                    <img className={'localization-icon-selected'} src={doneBlue}
                         alt={'select-option'}/> : <img src={hallowCircle}
                                                        alt={'select-option'}/>}</div>
                <hr className={'localization-popup-bottom-line'}/>
                <div className={'localization-button'}>
                    <div onClick={() => {
                        registerGAEvent('Languages', 'language-changed', selectedLanguage);
                        i18next.changeLanguage(selectedLanguage)
                        props.setLocalizationShow(false)
                        window.location.reload()
                    }} className={'localization-button-style'}>
                        {t(translationKeys.apply)}
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default LanguageSelectionPopup