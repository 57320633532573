export const translationsMalayalam = {
    all: 'എല്ലാം',
    ago: "മുമ്പ്",
    now: "ഇപ്പോൾ",
    start: "ആരംഭിക്കുക",
    account_blocked: "അക്കൗണ്ട് തടഞ്ഞിരിക്കുന്നു",
    account_blocked_alert: "ഞങ്ങളുടെ നിബന്ധനകളും വ്യവസ്ഥകളും ലംഘിച്ചതിന് ഈ അക്കൗണ്ട് തടഞ്ഞിരിക്കുന്നു.",
    sign_out: "സൈൻ ഔട്ട്",
    short_answer: "ഹ്രസ്വ ഉത്തരം",
    long_answer: "വിശദമായ ഉത്തരം",
    email: "ഇമെയിൽ",
    number: "നമ്പർ",
    faq_videos: "പതിവുചോദ്യങ്ങൾ വീഡിയോകൾ",
    file: "ഫയൽ",
    files: "ഫയലുകൾ",
    file_upload: "ഫയൽ അപ്ലോഡ്",
    question_error: "ചോദ്യം ചേർക്കാൻ കഴിയില്ല",
    storage: "സംഭരണം",
    form: "ഫോം",
    quiz: "ക്വിസ്",
    fup_desc: "ഫയലുകൾ അപ്‌ലോഡ് ചെയ്യാൻ നിങ്ങളുടെ ഫോമുകളിലേക്കും ക്വിസുകളിലേക്കും പ്രതികരിക്കുന്നവരെ നിങ്ങൾക്ക് ഇപ്പോൾ അനുവദിക്കാം!\n\nആരംഭിക്കുന്നതിന് ഫയൽ അപ്‌ലോഡ് ചോദ്യ തരം തിരഞ്ഞെടുക്കുക.",
    new_fup_feature_title: "പുതിയ ഫയൽ അപ്‌ലോഡ് ഫീച്ചർ!",
    multiple_choice: "മൾട്ടിപ്പിൾ ചോയ്സ്",
    date_text: "തീയതി",
    time_text: "സമയം",
    uploading: "അപ്‌ലോഡുചെയ്യുന്നു…",
    add_question: "ചോദ്യം ചേർക്കുക",
    add_question_alert: "ആവശ്യമായ എല്ലാ ഇനങ്ങളും ഉപയോഗിച്ച് ഒരു ചോദ്യമെങ്കിലും ദയവായി ചേർക്കുക.",
    add_question_alert_quiz: "ക്വിസ് സമർപ്പിക്കുന്നതിന് മുമ്പ് ദയവായി ഒരു ചോദ്യമെങ്കിലും ചേർക്കുക",
    ok: "ഓക്കേ",
    title: "ടൈറ്റിൽ",
    title_required: "ടൈറ്റിൽ ആവശ്യമാണ്",
    form_name_alert: "ഫോം പബ്ലിഷ് ചെയ്യുന്നതിന് മുമ്പ് ദയവായി പേര് നൽകുക.",
    quiz_name_alert: "ക്വിസ് പ്രസിദ്ധീകരിക്കുന്നതിന് മുമ്പ് ദയവായി പേര് നൽകുക",
    no_connection: "കണക്ഷനില്ല",
    check_connection: "ദയവായി നിങ്ങളുടെ നെറ്റ്‌വർക്ക് കണക്ഷൻ പരിശോധിക്കുക.",
    finish_incomplete_question: "ഫോമിലെ അപൂർണ്ണമായ ചോദ്യങ്ങൾ ദയവായി പൂർത്തിയാക്കുക",
    draft: "ഡ്രാഫ്റ്റ്",
    save_to_draft_alert: "നിങ്ങളുടെ മാറ്റങ്ങൾ നഷ്‌ടപ്പെടാതിരിക്കാൻ ഈ ഫോം ഡ്രാഫ്റ്റായി സംരക്ഷിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    save_to_draft_quiz_alert: "നിങ്ങളുടെ മാറ്റങ്ങൾ നഷ്‌ടപ്പെടാതിരിക്കാൻ ഈ ക്വിസ് ഒരു ഡ്രാഫ്റ്റായി സംരക്ഷിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    yes: "യെസ്",
    no: "നോ",
    cancel: "കാൻസൽ",
    live_preview: "തത്സമയ പ്രിവ്യൂ",
    themes: "തീമുകൾ",
    tap_to_set_theme: "ഫോമിനായി തീം സജ്ജീകരിക്കുന്നതിന് ഇവിടെ ടാപ്പുചെയ്യുക.",
    select_image: "ചിത്രം തിരഞ്ഞെടുക്കുക",
    created: "സൃഷ്ടിച്ചു",
    form_created_successfully: "ഫോം വിജയകരമായി സൃഷ്ടിച്ചു",
    warning: "മുന്നറിയിപ്പ്",
    form_edit_alert: "ഈ ഫോം ഇതിനകം പ്രസിദ്ധീകരിച്ചു പ്രതികരണങ്ങൾ ശേഖരിച്ചിരിക്കാം. ഫോം എഡിറ്റുചെയ്യുന്നത് ഇതിനകം ശേഖരിച്ച പ്രതികരണങ്ങളെ ബാധിച്ചേക്കാം.",
    quiz_edit_alert: 'ഈ ക്വിസ് ഇതിനകം സമർപ്പിക്കുകയും ഉത്തരങ്ങൾ ശേഖരിക്കുകയും ചെയ്തു. ക്വിസ് എഡിറ്റ് ചെയ്യുന്നത് മുമ്പത്തെ ഉത്തരങ്ങളെ ബാധിച്ചേക്കാം',
    edit: "എഡിറ്റുചെയ്യുക",
    download_as: "ഡൗൺലോഡ് ആസ്",
    delete_form: "ഡിലീറ്റ് ഫോം",
    delete_form_alert: "നിങ്ങൾക്ക് ഈ ഫോം ഇല്ലാതാക്കാൻ ആഗ്രഹമുണ്ടോ? ഈ പ്രവർത്തനം പഴയപടിയാക്കാൻ കഴിയില്ല.",
    delete_quizzes_alert: "ഈ ക്വിസുകൾ ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ? ഈ പ്രവർത്തനം പഴയപടിയാക്കാൻ കഴിയില്ല.",
    delete_forms_alert: "നിങ്ങൾക്ക് ഈ ഫോമുകൾ ഇല്ലാതാക്കാൻ ആഗ്രഹമുണ്ടോ? ഈ പ്രവർത്തനം പഴയപടിയാക്കാൻ കഴിയില്ല.",
    delete_quiz_alert: "ഈ ക്വിസ് ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ? ഈ പ്രവർത്തനം പഴയപടിയാക്കാൻ കഴിയില്ല.",
    delete_answers_alert: "ഈ ഉത്തരങ്ങൾ ഇല്ലാതാക്കണോ?",
    delete_answer_alert: "ഈ ഉത്തരം ഇല്ലാതാക്കണോ?",
    delete: "ഡിലീറ്റ്",
    delete_all: "എല്ലാം ഇല്ലാതാക്കുക",
    section: "സെക്ഷൻ",
    worst: "ഏറ്റവും മോശം",
    server_error_alert: "സെർവർ കണ്ടെത്താനായില്ല. കുറച്ച് സമയത്തിന് ശേഷം വീണ്ടും ശ്രമിക്കുക !!",
    signed_in_as: "സൈൻഡ് ഇൻ ആസ്",
    signing_out: "സൈനിങ് ഔട്ട്…",
    create_form: "ഫോം സൃഷ്ടിക്കുക",
    create_blank_form: "ശൂന്യമായ ഫോം സൃഷ്‌ടിക്കുക",
    create_blank_quiz: "ശൂന്യമായ ക്വിസ് സൃഷ്‌ടിക്കുക",
    close: "ക്ലോസ്",
    response_collection_exit_alert: "പ്രതികരണം ശേഖരിക്കുന്നതിൽ നിന്ന് പുറത്തുകടക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    form_builder: "ഫോം ബിൽഡർ",
    form_builder_promo: "നിങ്ങളുടെ സർവേകൾക്കായുള്ള തൽക്ഷണ പ്രിവ്യൂ, ടെംപ്ലേറ്റുകൾ, തീമുകൾ",
    results: "ഫലങ്ങൾ",
    result_feature_promo: "നിങ്ങളുടെ സർവേ ഫലങ്ങൾ വിശകലനം ചെയ്ത് എക്സ്‌പോർട് ചെയ്യുക",
    notification: "അറിയിപ്പ്",
    delete_option_alert: "നിങ്ങൾക്ക് ഈ ഓപ്‌ഷൻ ഇല്ലാതാക്കാൻ താൽപ്പര്യമുണ്ടോ? [കുറിപ്പ്: ഈ പ്രവർത്തനം പഴയപടിയാക്കാൻ കഴിയില്ല]",
    question_type: "ചോദ്യ തരം",
    change_question_type_alert: "ചോദ്യ തരം മാറ്റാൻ ഇവിടെ ടാപ്പുചെയ്യുക",
    preview_not_available: "പ്രിവ്യൂ ലഭ്യമല്ല",
    delete_question: "ചോദ്യം ഡിലീറ്റ് ചെയ്യുക",
    delete_response_collected_question_alert: "ഈ ചോദ്യം ഡിലീറ്റ് ചെയ്യുന്നത് തീർച്ചയായും ശേഖരിച്ച പ്രതികരണങ്ങളെ ബാധിക്കും. \ n \ n നിങ്ങൾക്ക് ഉറപ്പാണോ, ഈ ചോദ്യം ഇല്ലാതാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    delete_question_alert: "നിങ്ങൾക്ക് ഉറപ്പാണോ, ഈ ചോദ്യം ഡിലീറ്റ് ചെയ്യാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    options: "ഓപ്ഷനുകൾ",
    option: 'ഓപ്ഷൻ',
    no_answer: "ഉത്തരമില്ല",
    no_response: "പ്രതികരണം ഇല്ല",
    responses: "പ്രതികരണങ്ങൾ",
    response: "പ്രതികരണം",
    serial_number: "എസ് നം",
    submit_time: "സമയം സമർപ്പിക്കുക",
    general_form_type_description: "പുതിയ ഫോം / സർവേ സൃഷ്ടിക്കുക",
    quiz_type_description: "പുതിയ ക്വിസ് സൃഷ്ടിക്കുക",
    sheet_disconnected: 'Google ഷീറ്റ് വിച്ഛേദിച്ചു',
    sheet_not_connected: 'ഷീറ്റ് ബന്ധിപ്പിച്ചിട്ടില്ല',
    sheet_not_connected_desc: 'Google ഷീറ്റ് ബന്ധിപ്പിക്കുന്നതിന് ഉടമയെ ബന്ധപ്പെടുക.',
    export_success: "വിജയകരമായി എക്‌സ്‌പോർട്ടുചെയ്‌തു",
    answer: "ഉത്തരം",
    answer_with_colan: "ഉത്തരം :",
    others: "മറ്റുള്ളവ",
    delete_response: "പ്രതികരണം ഡിലീറ്റ് ചെയ്യുക",
    deleted: "ഡിലീറ്റ് ചെയ്തിരിക്കുന്നു",
    my_themes: "എന്റെ തീമുകൾ",
    limit_reached: "പരിധി എത്തിയിരിക്കുന്നു",
    limit_reached_description: "നിങ്ങൾ ചിത്രം അപ്‌ലോഡ് ചെയ്ത പരിധിയിൽ എത്തി.",
    modified: "പരിഷ്‌ക്കരിച്ചു",
    delete_image: "ചിത്രം ഡിലീറ്റ് ചെയ്യുക",
    delete_image_alert: "നിങ്ങൾക്ക് ഉറപ്പാണോ, ഈ ചിത്രം ഡിലീറ്റ് ചെയ്യാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    delete_theme_image_desc: "നിങ്ങൾക്ക് ഈ ചിത്രം ഇല്ലാതാക്കണോ? നിങ്ങൾ തുടരുകയാണെങ്കിൽ, ഈ ചിത്രം ഉപയോഗിക്കുന്ന ഏതെങ്കിലും ഫോമുകൾക്കോ ക്വിസുകൾക്കോ ഈ ചിത്രം ലഭ്യമാകില്ല. ഈ പ്രവർത്തനം പഴയപടിയാക്കാനാകില്ല.",
    section_title: "സെക്ഷൻ ടൈറ്റിൽ",
    section_description: "സെക്ഷൻ വിവരണം",
    show_scale: 'സ്കെയിൽ കാണിക്കുക',
    show_options: "ഓപ്ഷനുകൾ കാണിക്കുക",
    options_required: "ഓപ്ഷനുകൾ ആവശ്യമാണ്",
    link_copied: "ലിങ്ക് പകർത്തി",
    app_not_found: "അപ്ലിക്കേഷൻ കണ്ടെത്താൻ കഴിഞ്ഞില്ല",
    default_text: "ഡീഫോൾട്",
    dark_mode: "ഡാർക്ക് മോഡ്",
    done: "ഡൺ",
    upload_image: "ചിത്രം അപ്‌ലോഡുചെയ്യുക",
    question: "ചോദ്യം",
    required: "ആവശ്യമാണ്",
    share: "പങ്കിടുക",
    form_link: "ഫോം ലിങ്ക്",
    copy_link: "ലിങ്ക് കോപ്പി ചെയ്യുക",
    view_form: "ഫോം കാണുക",
    menu: "മെനു",
    view_all: "എല്ലാം കാണുക",
    settings: "സെറ്റിംഗ്സ്",
    submit: "സബ്മിറ്റ്‌",
    change_theme: "തീം മാറ്റുക",
    tap_here_to_choose_theme: "തീം തിരഞ്ഞെടുക്കാൻ ഇവിടെ ടാപ്പുചെയ്യുക",
    show_logo: "ലോഗോ കാണിക്കുക",
    show_logo_alert: "നിങ്ങൾക്ക് ഫോമിൽ ലോഗോ കാണിക്കണോ?",
    welcome_page: "വെൽക്കം പേജ്",
    show_welcome_page_alert: "നിങ്ങൾക്ക് വെൽക്കം പേജ് കാണിക്കണോ?",
    start_button: "സ്റ്റാർട്ട് ബട്ടൺ",
    allow_multiple_responses: "ഒന്നിലധികം പ്രതികരണങ്ങൾ അനുവദിക്കുക",
    allow_more_response_alert: "ഒന്നിലധികം തവണ പ്രതികരിക്കാൻ ഉപയോക്താവിനെ അനുവദിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    allow_more_quiz_attempt_alert: "ഒന്നിലധികം തവണ ശ്രമിക്കാൻ ഉപയോക്താവിനെ അനുവദിക്കണോ?",
    show_summary: "സംഗ്രഹം കാണിക്കുക",
    show_results: "ഫലങ്ങൾ കാണിക്കുക",
    allow_responders_view_summary_alert: "സമർപ്പിച്ചതിനുശേഷം എല്ലാ പ്രതികരണങ്ങളുടെയും സംഗ്രഹം കാണാൻ പ്രതികരിക്കുന്നവരെ അനുവദിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    allow_result_view_quiz_alert: "ഫലങ്ങൾ കാണാൻ ഉപയോക്താവിനെ അനുവദിക്കണോ?",
    show_question_number: "ചോദ്യ നമ്പർ കാണിക്കുക",
    show_question_number_in_form: "ഫോമിൽ ചോദ്യ നമ്പറുകൾ കാണിക്കണോ?",
    show_question_number_in_quiz: "ക്വിസിൽ നിങ്ങൾക്ക് ചോദ്യ നമ്പറുകൾ കാണിക്കണോ?",
    shuffle_questions: "ചോദ്യങ്ങൾ ഷഫിൾ ചെയ്യുക",
    shuffle_questions_alert: "നിങ്ങൾക്ക് ഫോം ചോദ്യങ്ങൾ‌ മാറ്റാൻ‌ താൽ‌പ്പര്യമുണ്ടോ?",
    shuffle_quiz_questions_alert: "ക്വിസ് ചോദ്യങ്ങൾ ഷഫിൾ ചെയ്യണോ?",
    description: "വിവരണം",
    builder: "ബിൽഡർ",
    preview: "പ്രിവ്യൂ",
    form_status: "ഫോം സ്റ്റാറ്റസ്",
    active: "ആക്റ്റീവ്",
    inactive: "നിഷ്‌ക്രിയം",
    import_questions: 'ചോദ്യങ്ങൾ ഇറക്കുമതി ചെയ്യുക',
    search_by_question_title_from_forms: 'നിലവിലുള്ള ഫോമിൽ നിന്ന് ചോദ്യ ശീർഷകം അനുസരിച്ച് തിരയുക',
    search_by_question_title_from_quizzes: 'നിലവിലുള്ള ക്വിസുകളിൽ നിന്ന് ചോദ്യ ശീർഷകം അനുസരിച്ച് തിരയുക',
    view_responses: "പ്രതികരണങ്ങൾ കാണുക",
    edit_form: "ഫോം എഡിറ്റുചെയ്യുക",
    duplicate_form: "ഡ്യൂപ്ലിക്കേറ്റ് ഫോം",
    drafts: "ഡ്രാഫ്റ്റുകൾ",
    no_form_available: "ഫോം ലഭ്യമല്ല!",
    notifications: "അറിയിപ്പുകൾ",
    feedback: "ഫീഡ്‌ബാക്ക്",
    privacy_policy: "സ്വകാര്യതാ നയം",
    open: 'തുറക്കുക',
    open_individual_responses: "വ്യക്തിഗത പ്രതികരണങ്ങൾ തുറക്കുക",
    bar_chart: "ബാർ ചാർട്ട്്",
    pie_chart: "പൈ ചാർട്ട്",
    summary: "സംഗ്രഹം",
    invalidated_form_cannot_be_selected: "അസാധുവായ ഒരു ഫോം തിരഞ്ഞെടുക്കാൻ കഴിയില്ല.",
    invalidated_quiz_cannot_be_selected: "അസാധുവായ ഒരു ക്വിസ് തിരഞ്ഞെടുക്കാൻ കഴിയില്ല.",
    individual: "വ്യക്തിഗതം",
    search: "സേർച്ച് ...",
    image: "ചിത്രം",
    video: "വീഡിയോ",
    website: "വെബ്സൈറ്റ്",
    exit: "എക്സിറ്റ്",
    response_limit_reached: "പ്രതികരണ പരിധി എത്തിയിരിക്കുന്നു",
    response_limit_reached_alert: "ഈ ഫോം പ്രതികരണ ഡാറ്റയ്ക്കുള്ള അനുവദനീയമായ പരിധി കവിഞ്ഞു; അതിനാൽ, കൂടുതൽ പ്രതികരണങ്ങൾ സ്വീകരിക്കുന്നതിൽ നിന്ന് ഞങ്ങൾ ഈ ഫോമിനെ തടഞ്ഞു. \ n \ n നിലവിലുള്ള സർവേയിൽ തുടരാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുവെങ്കിൽ, അത് തനിപ്പകർപ്പാക്കി ലിങ്ക് പങ്കിടാൻ ഞങ്ങൾ നിർദ്ദേശിക്കുന്നു .",
    unapproved_form: "അംഗീകരിക്കാത്ത ഫോം",
    unapproved_form_alert: "ഈ ഫോമിൽ ഞങ്ങളുടെ നയങ്ങളെ ലംഘിക്കുന്ന വാക്കുകൾ ഉപയോഗിച്ചിരിക്കുന്നു. \ n \ n ഈ ഫോം തെറ്റായി തടഞ്ഞുവെന്ന് നിങ്ങൾ വിശ്വസിക്കുന്നുവെങ്കിൽ, ദയവായി ചുവടെയുള്ള ഫോം ലിങ്ക് തുറന്ന് ഫോമിൽ അഭ്യർത്ഥിച്ച വിവരങ്ങൾ നൽകുക.",
    choose_your_language: "നിങ്ങളുടെ ഭാഷ തിരഞ്ഞെടുക്കുക",
    provide_explanation: "വിശദീകരണം നൽകുക",
    selected: "തിരഞ്ഞെടുത്തു",
    review_button: "അവലോകനം",
    not_specified: "വ്യക്തമാക്കിയിട്ടില്ല",
    quiz_control: "ക്വിസ് നിയന്ത്രണം",
    allow_multiple_attempts: "ഒന്നിലധികം ശ്രമങ്ങൾ",
    user_info: "ഉപഭോക്താവിൻറെ വിവരം",
    name_s: "പേര്",
    duration: "ദൈർഘ്യം",
    set_duration_quiz_alert: "ക്വിസിനായി ഒരു ദൈർഘ്യം സജ്ജമാക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    user_information_description: "നിങ്ങൾക്ക് പങ്കെടുക്കുന്നവരുടെ വിവരങ്ങൾ ലഭിക്കണോ?",
    set_duration: "ദൈർഘ്യം സെറ്റ് ചെയ്യുക (മണിക്കൂർ: മിനിറ്റ്)",
    minutes: "മിനുട്ടുകൾ",
    hours: "മണിക്കൂർ",
    minute: "മിനിറ്റ്",
    hour: "മണിക്കൂർ",
    correct: "ശരിയാണ്",
    wrong: "തെറ്റാണ്",
    skipped: "ഒഴിവാക്കി",
    score: "സ്കോർ",
    forms: "ഫോമുകൾ",
    quizzes: "ക്വിസുകൾ",
    more: "കൂടുതൽ",
    quiz_status: "ക്വിസ് സ്റ്റാറ്റസ്",
    view_answers: "ഉത്തരങ്ങൾ കാണുക",
    view_quiz: "ക്വിസ് കാണുക",
    duplicate_quiz: "ഡ്യൂപ്ലിക്കേറ്റ് ക്വിസ്",
    no_quiz_available: "ക്വിസ് ലഭ്യമല്ല!",
    quiz_link: "ക്വിസ് ലിങ്ക്",
    unapproved_quiz: "അംഗീകരിക്കാത്ത ക്വിസ്",
    unapproved_quiz_alert: "ഈ ക്വിസ് ഞങ്ങളുടെ നയങ്ങളെ ലംഘിക്കുന്ന വാക്കുകളാണ് ഉപയോഗിക്കുന്നത്. \n \ n ഈ ക്വിസ് തെറ്റായി തടഞ്ഞുവെന്ന് നിങ്ങൾ വിശ്വസിക്കുന്നുവെങ്കിൽ, ദയവായി ചുവടെയുള്ള ഫോം ലിങ്ക് തുറന്ന് ഫോമിൽ അഭ്യർത്ഥിച്ച വിവരങ്ങൾ നൽകുക.",
    do_you_want_to_show_all_correct_answers_to_users: "നിങ്ങൾക്ക് ശരിയായ ഉത്തരം ഉപയോക്താക്കൾക്ക് കാണിക്കണോ",
    results_publish_date: "ഫലങ്ങൾ പ്രസിദ്ധീകരിക്കുന്ന തീയതി",
    disconnect: 'വിച്ഛേദിക്കുക',
    do_you_want_to_set_date_to_publish_results: "ഫലങ്ങൾ പ്രസിദ്ധീകരിക്കുന്നതിന് തീയതി സജ്ജീകരിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ",
    quiz_result: "ഉത്തരക്കടലാസ്",
    enter_answer: "ഉത്തരം",
    manage: "മാനേജ്",
    manage_storage: 'സംഭരണം നിയന്ത്രിക്കുക',
    select_all: 'എല്ലാം തിരഞ്ഞെടുക്കുക',
    shuffle_options: 'ഷഫിൾ ഓപ്ഷനുകൾ',
    shuffle_options_enabled: 'ഷഫിൾ ഓപ്‌ഷനുകൾ പ്രവർത്തനക്ഷമമാക്കി',
    shuffle_options_disabled: 'ഷഫിൾ ഓപ്‌ഷനുകൾ പ്രവർത്തനരഹിതമാക്കി',
    add_options_to_enable_this_feature: 'ഈ ഫീച്ചർ പ്രവർത്തനക്ഷമമാക്കാൻ ഓപ്ഷനുകൾ ചേർക്കുക',
    attach: 'അറ്റാച്ചുചെയ്യുക',
    shuffle: 'ഷഫിൾ ചെയ്യുക',
    marks: "മാർക്കുകൾ",
    mark: "മാർക്ക്",
    correction_status: "സ്റ്റാറ്റസ് :",
    status_validated: "സാധൂകരിച്ചു",
    pending: "ശേഷിക്കുന്നു",
    results_publish: "പ്രസിദ്ധീകരിക്കുക",
    publish: "പബ്ലിഷ്",
    publish_description: "ക്വിസ് ഫലങ്ങൾ പ്രസിദ്ധീകരിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    publish_description_note: "(മാർക്കുകൾ ഒരേ URL- ൽ പ്രസിദ്ധീകരിക്കും)",
    published_successfully: "വിജയകരമായി പ്രസിദ്ധീകരിച്ചു",
    choice_type_validation_note: "*ഒന്നിലധികം ചോയ്‌സ് യാന്ത്രികമായി സാധൂകരിക്കുന്നു",
    short_answer_type_validation_note: "*ഹ്രസ്വ ഉത്തരം സ്വപ്രേരിതമായി സാധൂകരിക്കുന്നു",
    long_answer_type_validation_note: "*ദൈർഘ്യമേറിയ ഉത്തരം സ്വപ്രേരിതമായി സാധൂകരിക്കപ്പെടുന്നില്ല",
    fileupload_answer_type_validation_note: "*ഫയൽ അപ്‌ലോഡ് സ്വയമേവ സാധൂകരിക്കപ്പെടുന്നില്ല",
    dropdown_answer_type_validation_note: "*ഡ്രോപ്പ്ഡൗൺ ചോയ്‌സ് സ്വയമേവ സാധൂകരിക്കുന്നു",
    save: "രക്ഷിക്കും",
    questions: "ചോദ്യങ്ങൾ",
    show_correct_answer: "ശരിയായ ഉത്തരം കാണിക്കുക",
    show_answer: "ഉത്തരം കാണിക്കുക",
    hide_answer: "ഉത്തരം മറയ്‌ക്കുക",
    no_marks: "അടയാളമില്ല",
    question_is_required: "ചോദ്യ ശീർഷകം ആവശ്യമാണ്",
    marks_is_required: "മാർക്കുകൾ ആവശ്യമാണ്",
    options_are_required: "ഓപ്ഷനുകൾ ആവശ്യമാണ്",
    options_limit_reached: 'ഓപ്ഷനുകൾ പരിധി എത്തി',
    options_limit_reached_description: 'ഒരു ചോദ്യത്തിൽ അനുവദനീയമായ ഓപ്‌ഷനുകളുടെ എണ്ണത്തിൽ നിങ്ങൾ എത്തിയിരിക്കുന്നു.',
    correct_answer_required: "ശരിയായ ഉത്തരം ആവശ്യമാണ്",
    enter_valid_mark: "സാധുവായ അടയാളം നൽകുക",
    evaluated: "വിലയിരുത്തി",
    saved: "സംരക്ഷിച്ചു",
    pending_evaluation: "ശേഷിക്കുന്ന മൂല്യനിർണ്ണയം",
    alphabet: "അക്ഷരമാല",
    not_evaluated: "വിലയിരുത്തിയിട്ടില്ല",
    percentage_ascending: "ശതമാനം - ആരോഹണം",
    percentage_descending: "ശതമാനം - അവരോഹണം",
    pending_evaluation_alert_message: "പ്രസിദ്ധീകരിക്കുന്നതിന് മുമ്പ് എല്ലാ ഉത്തരങ്ങളുടെയും വിലയിരുത്തൽ നിങ്ങൾ പൂർത്തിയാക്കണം.",
    show_unevaluated: "മൂല്യനിർണ്ണയം കാണിക്കുക",
    publish_success_alert_message: "പ്രതികരിക്കുന്നവർക്ക് അവരുടെ ഉത്തര ഉത്തരക്കടലാസുകളിൽ ഫലങ്ങൾ കാണാൻ കഴിയും.",
    publishing: "പ്രസിദ്ധീകരിക്കുന്നു",
    quiz_published: "ക്വിസ് പ്രസിദ്ധീകരിച്ചു",
    quiz_published_alert_before_edit_message: "ഈ ക്വിസ് പ്രസിദ്ധീകരിച്ചു, നിങ്ങൾക്ക് മാറ്റങ്ങൾ വരുത്തണമെങ്കിൽ, ക്വിസ് തനിപ്പകർപ്പാക്കി വീണ്ടും പങ്കിടണം.",
    quiz_has_answers: "ക്വിസിന് ഉത്തരങ്ങളുണ്ട്",
    quiz_has_answer_alert_message: "ഈ ക്വിസ് എഡിറ്റുചെയ്യുന്നതിന്, നിലവിലുള്ള എല്ലാ ഉത്തരങ്ങളും നിങ്ങൾ ഇല്ലാതാക്കണം.",
    unavailable_features_description: 'വെബ് ആപ്പിൽ ഇതുവരെ പിന്തുണയ്‌ക്കാത്ത സവിശേഷതകൾ ഈ ഫോമിൽ ഉണ്ട്',
    unavailable_quiz_features_description: 'വെബ് ആപ്പിൽ ഇതുവരെ പിന്തുണയ്‌ക്കാത്ത സവിശേഷതകൾ ഈ ക്വിസിൽ ഉണ്ട്',
    unavailable: 'ലഭ്യമല്ല',
    unpublished: "പ്രസിദ്ധീകരിച്ചിട്ടില്ല",
    published: "പ്രസിദ്ധീകരിച്ചു",
    setting_updated: "ക്രമീകരണം അപ്‌ഡേറ്റുചെയ്‌തു",
    account: "അക്കൗണ്ട്",
    on: "ഓണാണ്",
    off: "ഓഫാണ്",
    scan_qr_code: "QR കോഡ് സ്കാൻ ചെയ്യുക",
    answers: "ഉത്തരങ്ങൾ",
    answer_s: "ഉത്തരം",
    question_limit_reached: "ചോദ്യ പരിധി എത്തി",
    reached_limit_for_number_of_questions: "ഒരു ഫോമിൽ അനുവദനീയമായ ചോദ്യങ്ങളുടെ പരിധി നിങ്ങൾ എത്തി. അതിനാൽ, പുതിയ ചോദ്യങ്ങളൊന്നും ചേർക്കാൻ കഴിയില്ല",
    life_time: "ജീവിതകാലം",
    last_year: "കഴിഞ്ഞ വര്ഷം",
    last_30_days: "കഴിഞ്ഞ 30 ദിവസം",
    last_7_days: "കഴിഞ്ഞ 7 ദിവസം",
    last_24_hours: "കഴിഞ്ഞ 24 മണിക്കൂർ",
    custom_date: "കസ്റ്റം തീയതി",
    filter_by_date: "തീയതി പ്രകാരം ഫിൽട്ടർ ചെയ്യുക",
    filter_by_question: "ചോദ്യം അനുസരിച്ച് ഫിൽട്ടർ ചെയ്യുക",
    filter_text: "ടെക്സ്റ്റ് ഫിൽട്ടർ ചെയ്യുക",
    add_filter: "ഫിൽട്ടർ ചേർക്കുക",
    apply: "അപേക്ഷിക്കുക",
    from_date: "തീയതി മുതൽ",
    today: 'ഇന്ന്',
    to_date: "തീയതി",
    contains: "അടങ്ങിയിരിക്കുന്നു",
    equal_to: "തുല്യമാണ്",
    not_equal_to: "തുല്യമല്ല",
    from_date_required: "ആവശ്യമായ തീയതി മുതൽ",
    to_date_required: "തീയതി ആവശ്യമാണ്",
    webapp_promo_text: "നിങ്ങളുടെ ഫോമുകൾ, സർവേകൾ, പോളുകൾ & ക്വിസുകൾ ഓൺലൈനിൽ ആക്സസ് ചെയ്യുക",
    question_title: "നിങ്ങളുടെ ഫോമുകൾ, സർവേകൾ, വോട്ടെടുപ്പുകൾ, ക്വിസ് എന്നിവ ഓൺലൈനിൽ ആക്സസ് ചെയ്യുക!",
    invalid_date: "അസാധുവായ തീയതി",
    invalid_url: "അസാധുവായ URL",
    invalid_url_description: "URL സാധുതയുള്ളതായി തോന്നുന്നില്ല.",
    delete_response_alert: "നിങ്ങൾക്ക് ഈ പ്രതികരണം ഡിലീറ്റ് ചെയ്യണോ?",
    delete_responses_alert: "ഈ പ്രതികരണങ്ങൾ ഇല്ലാതാക്കണോ?",
    overview: "അവലോകനം",
    no_results_found: "ഒരു വിവരങ്ങളും കിട്ടിയില്ല",
    date_format: "dd-mm-yyyy",
    title_dashboard: "ഡാഷ്ബോർഡ്",
    title_notifications: "അറിയിപ്പുകൾ",
    select_one_response: "ഇല്ലാതാക്കാൻ ദയവായി ഒരു പ്രതികരണമെങ്കിലും തിരഞ്ഞെടുക്കുക",

//Untranslated strings

    your_account: "നിങ്ങളുടെ അക്കൗണ്ട്",
    app_name: "SurveyHeart",
    quizzory: "Quizzory",
    powered_by_surveyheart: "Powered by 'SurveyHeart'",
    feature_spotlight: 'ഫീച്ചർ സ്പോട്ട്ലൈറ്റ്',
    favourites: "പ്രിയപ്പെട്ടവ",
    shared: "പങ്കിട്ടു",
    hide: "മറയ്ക്കുക",
    use_templates: "ടെംപ്ലേറ്റുകൾ ഉപയോഗിക്കുക",
    quick_settings: "ദ്രുത ക്രമീകരണങ്ങൾ",
    leave_collaboration: "സഹകരണം ഉപേക്ഷിക്കുക",
    templates: "ടെംപ്ലേറ്റുകൾ",
    collapse: "ചുരുക്കുക",
    start_scale_from_zero: '0 മുതൽ സ്കെയിൽ ആരംഭിക്കുക',
    center_label_optional: 'കേന്ദ്ര ലേബൽ (ഓപ്ഷണൽ)',
    left_label_optional: 'ഇടത് ലേബൽ (ഓപ്ഷണൽ)',
    right_label_optional: 'വലത് ലേബൽ (ഓപ്ഷണൽ)',
    duplicate: "ഡ്യൂപ്ലിക്കേറ്റ്",
    row: "വരി",
    column: "കോളം",
    rows: "വരികൾ",
    add_column: 'കോളം ചേർക്കുക',
    add_row: 'വരി ചേർക്കുക',
    user_info_for_file_upload: "ഫയൽ അപ്‌ലോഡുകൾക്കുള്ള ഉപയോക്തൃ വിവരങ്ങൾ",
    user_info_for_file_upload_desc1: "നിങ്ങൾ ഒരു ഫയൽ അപ്‌ലോഡ് ചോദ്യം ചേർത്തതിനാൽ, ഉപയോക്തൃ വിവരങ്ങൾ ശേഖരിക്കുന്നതിനുള്ള ഓപ്ഷൻ പ്രവർത്തനക്ഷമമാക്കി. ഇത് ഡൗൺലോഡ് ചെയ്ത ഫയലുകൾ തിരിച്ചറിയുന്നത് എളുപ്പമാക്കും. പ്രതികരിക്കുന്നവർ അവരുടെ വിവരങ്ങൾ നൽകാൻ ഒരു ഡയലോഗ് കാണും.",
    user_info_for_file_upload_desc2: "ഡയലോഗ് കാണിക്കാൻ താൽപ്പര്യമില്ലെങ്കിൽ നിങ്ങൾക്ക് ക്രമീകരണങ്ങളിൽ ഈ ഓപ്‌ഷൻ മാറ്റാവുന്നതാണ്.",
    show_rows_and_columns: "വരികൾ കാണിക്കുക & നിരകൾ",
    scale_down: 'വെട്ടി കുറയ്ക്കുക',
    scale_up: 'സ്കെയിൽ അപ്പ്',
    dislike: 'ഇഷ്ടപ്പെടാത്തത്',
    like: 'ഇഷ്ടപ്പെടുക',
    best: 'മികച്ചത്',
    remove_favourite: "പ്രിയപ്പെട്ടത് നീക്കം ചെയ്യുക",
    add_favourite: "പ്രിയപ്പെട്ടവ ചേർക്കുക",
    collaborator: "സഹകാരി",
    collaborators: "സഹകാരികൾ",
    show_more: "കൂടുതൽ കാണിക്കുക",
    create_quiz: "ക്വിസ് സൃഷ്ടിക്കുക",
    collaborate: "സഹകരിക്കുക",
    share_QR_code: "QR കോഡ് പങ്കിടുക",
    add_image: "ചിത്രം ചേർക്കുക",
    invalid_file_type: "അസാധുവായ ഫയൽ തരം",
    drag_drop: "വലിച്ചിടുക",
    here: "ഇവിടെ",
    choose: "തിരഞ്ഞെടുക്കുക",
    add_account: 'അക്കൗണ്ട് ചേർക്കുക',
    choose_account: 'അക്കൗണ്ട് തിരഞ്ഞെടുക്കുക',
    maximum_allowed_file_size: "അനുവദനീയമായ പരമാവധി ഫയൽ വലുപ്പം",
    or: "അഥവാ",
    next: "അടുത്തത്",
    change_image: "ചിത്രം മാറ്റുക",
    upload: "അപ്‌ലോഡ് ചെയ്യുക",
    view: "കാണുക",
    collaboration: "സഹകരണം",
    share_collaboration_form: "ഈ ഫോം മറ്റുള്ളവരുമായി പങ്കിടാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    share_collaboration_quiz: "ഈ ക്വിസ് മറ്റുള്ളവരുമായി പങ്കിടാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    multiple_responses_summary: "ഒന്നിലധികം പ്രതികരണങ്ങൾ, സംഗ്രഹം",
    question_number_shuffle: "ചോദ്യ നമ്പർ, ചോദ്യചിഹ്നം, ഷഫിൾ ചെയ്യുക",
    general: "ജനറൽ",
    browse: "ബ്രൗസ് ചെയ്യുക",
    owner: "ഉടമ",
    something_went_wrong: "എന്തോ കുഴപ്പം സംഭവിച്ചു!",
    forget_your_password: "നിങ്ങളുടെ പാസ്‌വേഡ് മറക്കുക?",
    sign_in: "സൈൻ ഇൻ",
    sign_up: "സൈൻ അപ്പ് ചെയ്യുക",
    using_email: "ഇമെയിൽ ഉപയോഗിച്ച്",
    dont_have_account: "ഒരു അക്കൗണ്ട് ഇല്ല?",
    on_signing_up_agree_to_surveyhearts: "സൈൻ അപ്പ് ചെയ്യുന്നതിലൂടെ, നിങ്ങൾ SurveyHeart's അംഗീകരിക്കുന്നു",
    and: "ഒപ്പം",
    terms_of_use: "ഉപയോഗ നിബന്ധനകൾ",
    already_have_an_account: "ഇതിനകം ഒരു അക്കൗണ്ട് ഉണ്ടോ?",
    signed_in_previously_as_a_google_user: "നിങ്ങൾ മുമ്പ് ഒരു Google ഉപയോക്താവായി സൈൻ ഇൻ ചെയ്തിട്ടുണ്ട്.",
    sign_in_google_to_continue: "തുടരാൻ Google വഴി സൈൻ ഇൻ ചെയ്യുക.",
    no_account_found_email_address: "ഈ ഇമെയിൽ വിലാസത്തിൽ ഒരു അക്കൗണ്ടും കണ്ടെത്തിയില്ല",
    account_already_exist_email: "ഈ ഇമെയിലിനായി ഇതിനകം അക്കൗണ്ട് നിലവിലുണ്ട്.",
    install_surveyheart_app: "SurveyHeart ആപ്പ് ഇൻസ്റ്റാൾ ചെയ്യുക",
    easy_login_via_app: "ആപ്പ് വഴി എളുപ്പത്തിൽ ലോഗിൻ ചെയ്യുക",
    get_notification_instantly: "അറിയിപ്പ് തൽക്ഷണം നേടുക",
    create_form_quiz_on_the_go: "എവിടെയായിരുന്നാലും ഫോം/ക്വിസ് സൃഷ്‌ടിക്കുക",
    check_your_inbox_to_activate_your_account: "നിങ്ങളുടെ അക്കൗണ്ട് സജീവമാക്കാൻ ഇൻബോക്സ് പരിശോധിക്കുക",
    select_questions: "ചോദ്യങ്ങൾ തിരഞ്ഞെടുക്കുക",
    percentage: "ശതമാനം",
    free_App_to_create_forms: "ഫോമുകൾ സൃഷ്ടിക്കാൻ സൗജന്യ ആപ്പ് നേടുക",
    we_cant_find_the_page_that_you_are_looking_for: "നിങ്ങൾ തിരയുന്ന പേജ് ഞങ്ങൾക്ക് കണ്ടെത്താനായില്ല",
    answered: "ഉത്തരം നൽകി",
    completing_this_quiz: "ഈ ക്വിസ് പൂർത്തിയാക്കിയതിന് നന്ദി",
    connected_successfully: 'വിജയകരമായി ബന്ധിപ്പിച്ചു',
    seconds: "സെക്കന്റുകൾ",
    enter_your_details: "നിങ്ങളുടെ വിശദാംശങ്ങൾ നൽകുക",
    enter_all_required_information: "ആവശ്യമായ എല്ലാ വിവരങ്ങളും ദയവായി നൽകുക",
    answer_data_not_found: "ഉത്തര ഡാറ്റ കണ്ടെത്തിയില്ല",
    results_published_soon: "ഫലം ഉടൻ പ്രസിദ്ധീകരിക്കും",
    previous: 'മുമ്പത്തെ',
    save_link_for_future_reference: "ഭാവി റഫറൻസിനായി ഈ ലിങ്ക് സംരക്ഷിക്കുക",
    choice_questions_only: "ചോയ്‌സ് ചോദ്യങ്ങൾ മാത്രം",
    score_is_not_final: "ശ്രദ്ധിക്കുക: ഈ സ്കോർ അന്തിമമല്ല",
    tabular: "ടാബുലാർ",
    text: "വാചകം",
    answer_response_alert: "ഈ ചോദ്യത്തിന് ഒരു ഉത്തരം ആവശ്യമാണ്",
    invalid_email_address: "ഇമെയിൽ വിലാസം അസാധുവാണ്",
    answer_not_available: "ഉത്തരം ലഭ്യമല്ല",
    validated_automatically: "* യാന്ത്രികമായി സാധൂകരിക്കുന്നു",
    answer_link: "ഉത്തരം ലിങ്ക്",
    embed_code: 'കോഡ് ഉൾച്ചേർക്കുക',
    copy_code: 'കോഡ് പകർത്തുക',
    code_copied: 'കോഡ് പകർത്തി',
    share_via: "വഴി പങ്കിടുക",
    print: "അച്ചടിക്കുക",
    columns: 'നിരകൾ',
    columns_all: "നിരകൾ (എല്ലാം)",
    submitted_time: "സമർപ്പിച്ച സമയം",
    unable_to_submit: 'സമർപ്പിക്കാനായില്ല',
    unable_to_submit_alert_line1: 'ഈ ക്വിസിന് ഉത്തരങ്ങൾ ലഭിച്ചതിനാൽ, മാറ്റങ്ങൾ വരുത്താൻ നിങ്ങൾക്ക് പരിമിതമായ ആക്‌സസ് മാത്രമേയുള്ളൂ.',
    unable_to_submit_alert_line2: 'ഈ ക്വിസ് എഡിറ്റ് ചെയ്യുന്നതിനുള്ള പൂർണ്ണ ആക്‌സസ് ലഭിക്കുന്നതിന്, നിലവിലുള്ള എല്ലാ ഉത്തരങ്ങളും നിങ്ങൾ ഇല്ലാതാക്കണം.',
    action_blocked: 'പ്രവർത്തനം തടഞ്ഞു',
    action_blocked_alert: 'ഈ ക്വിസിന് നിലവിലുള്ള ഉത്തരങ്ങൾ കാരണം പ്രവർത്തനം തടഞ്ഞു. ഇത് അനുവദിക്കുന്നത് മുമ്പത്തെ ഉത്തരങ്ങളെ ബാധിച്ചേക്കാം.',
    quiz_edit_submit_alert: 'ക്വിസ് എഡിറ്റ് ചെയ്യുന്നത് മാർക്ക്, ശതമാനം എന്നിവയെ ബാധിക്കുകയും ചില ചോദ്യങ്ങളുടെ പുനർമൂല്യനിർണയം ആവശ്യമായി വന്നേക്കാം.',
    continue: 'തുടരുക',
    submitted: "സമർപ്പിച്ചു",
    submitting: "സമർപ്പിക്കുന്നു",
    filter: "ഫിൽട്ടർ ചെയ്യുക",
    exit_screen: "സ്ക്രീനിൽ നിന്ന് പുറത്തുകടക്കുക",
    full_screen: "പൂർണ്ണ സ്ക്രീൻ",
    tabular_view: "ടാബുലാർ കാഴ്ച",
    clear_all: "എല്ലാം മായ്ക്കുക",
    you_are_all_caught_up: "നിങ്ങൾ എല്ലാവരും പിടിക്കപ്പെട്ടു!",
    check_back_later_for_new_notifications: "പുതിയ അറിയിപ്പുകൾക്കായി പിന്നീട് വീണ്ടും പരിശോധിക്കുക.",
    requires_answer: "ഉത്തരം ആവശ്യമാണ്",
    enter_member_id: "അംഗ ഐഡി നൽകുക",
    viewers_can_read_form_responses: "കാഴ്ചക്കാർക്ക് ഫോം വായിക്കാനും പ്രതികരണങ്ങൾ കാണാനും കഴിയും",
    editors_edit_form_view_responses: "എഡിറ്റർമാർക്ക് ഫോം എഡിറ്റ് ചെയ്യാനും പ്രതികരണങ്ങൾ കാണാനും കഴിയും",
    admin_edit_form_add_collaborator: "അഡ്മിന് ഫോം എഡിറ്റ് ചെയ്യാനും സഹകാരിയെ ചേർക്കാനും കഴിയും",
    invalid_member_id: "അംഗ ഐഡി അസാധുവാണ്",
    already_member_added: "ഈ അംഗം ഇതിനകം ചേർത്തിട്ടുണ്ട്",
    add_collaborator: "സഹകാരിയെ ചേർക്കുക",
    admin: "അഡ്മിൻ",
    editor: "എഡിറ്റർ",
    viewer: "വ്യൂവർ",
    searched_form_not_found: "തിരഞ്ഞ ഫോം കണ്ടെത്തിയില്ല!",
    searched_quiz_not_found: "തിരഞ്ഞ ക്വിസ് കണ്ടെത്തിയില്ല!",
    no_shared_data_available: "പങ്കിട്ട ഡാറ്റ ലഭ്യമല്ല!",
    forms_server_is_not_available: "ഫോം സെർവർ ലഭ്യമല്ല",
    quiz_server_is_not_available: "ക്വിസ് സെർവർ ലഭ്യമല്ല",
    leave: 'വിട്ടേക്കുക',
    password: 'പാസ്‌വേഡ് ',
    new_password: 'പുതിയ പാസ്വേഡ്',
    retype_password: 'പാസ്വേഡ് വീണ്ടും ടൈപ്പുചെയ്യുക',
    create_password: 'പാസ്‌വേഡ് സൃഷ്‌ടിക്കുക',
    view_results: 'ഫലങ്ങൾ കാണുക',
    control: 'നിയന്ത്രണം',
    q_control: 'ക്വിസ് നിയന്ത്രണം',
    q_result: 'ക്വിസ് ഫലം',
    no_matches_found: "പൊരുത്തങ്ങളൊന്നും കണ്ടെത്തിയില്ല",
    dropdown: "ഡ്രോപ്പ് ഡൗൺ",
    checkboxes: "ചെക്ക്ബോക്സുകൾ",
    star_rating: 'സ്റ്റാർ റേറ്റിംഗ്',
    linear_scale: 'ലീനിയർ സ്കെയിൽ',
    multiple_choice_grid: 'മൾട്ടിപ്പിൾ ചോയ്സ് ഗ്രിഡ്',
    checkboxes_grid: 'ചെക്ക്ബോക്സ് ഗ്രിഡ്',
    smile: 'പുഞ്ചിരിക്കൂ',
    info_1: "വിവരം 1",
    info_2: "വിവരം 2",
    info_3: "വിവരം 3",
    new: 'പുതിയത്',
    download_all: 'എല്ലാം ഡൗൺലോഡ് ചെയ്യുക',
    download: 'ഡൗൺലോഡ്',
    sort_by: 'ഇങ്ങനെ അടുക്കുക',
    sorting: 'അടുക്കുന്നു',
    refresh: 'പുതുക്കുക',
    add: 'ചേർക്കുക',
    refresh_preview_description: "പ്രിവ്യൂ അപ്ഡേറ്റ് ചെയ്യാൻ 'റിഫ്രഷ്' ബട്ടണിൽ ക്ലിക്ക് ചെയ്യുക",
    enter_users_email_address: 'യൂസറുടെ ഇമെയിൽ ഐഡി നൽകുക',
    do_you_want_to_delete_these: 'നിങ്ങൾക്ക് ഇവ ഇല്ലാതാക്കണോ',
    do_you_want_to_delete_this_form: 'നിങ്ങൾക്ക് ഈ ഫോം ഇല്ലാതാക്കണോ',
    all_responses_will_also_be_deleted: 'എല്ലാ പ്രതികരണങ്ങളും ഇല്ലാതാക്കപ്പെടും',
    this_action_cannot_be_undone: 'ഈ പ്രവർത്തനം പഴയപടിയാക്കാനാകില്ല',
    do_you_want_to_delete_this_quiz: 'നിങ്ങൾക്ക് ഈ ക്വിസ് ഇല്ലാതാക്കണോ',
    all_answer_sheets_will_also_be_unavailable: 'എല്ലാ ഉത്തരക്കടലാസുകളും ലഭ്യമല്ല',
    change: 'മാറ്റുക',
    roles_rights: 'റോളുകളും അവകാശങ്ങളും',
    learn_more: 'കൂടുതലറിയുക',
    viewers_can_read_quiz_and_view_answers: 'കാഴ്ചക്കാർക്ക് ക്വിസ് വായിക്കാനും ഉത്തരങ്ങൾ കാണാനും കഴിയും',
    editors_can_edit_quiz_view_answers: 'എഡിറ്റർമാർക്ക് ക്വിസ് എഡിറ്റ് ചെയ്യാനും ഉത്തരങ്ങൾ കാണാനും കഴിയും',
    admin_can_edit_quiz_add_collaborator: 'അഡ്മിന് ക്വിസ് എഡിറ്റ് ചെയ്യാനും സഹകാരിയെ ചേർക്കാനും കഴിയും',
    change_roles: 'റോളുകൾ മാറ്റുക',
    remove: 'നീക്കം ചെയ്യുക',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: 'നിങ്ങൾ മാറ്റങ്ങൾ സമർപ്പിച്ചിട്ടില്ല. സമർപ്പിക്കാതെ പോയാൽ കുഴപ്പമുണ്ടോ?',
    select_language: 'ഭാഷ തിരഞ്ഞെടുക്കുക',
    are_you_sure_you_want_delete_this_collaborator: 'ഈ സഹകാരിയെ ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?',
    are_you_sure_you_want_to_delete_selected_files: 'തിരഞ്ഞെടുത്ത ഫയൽ ശാശ്വതമായി ഇല്ലാതാക്കണമെന്ന് തീർച്ചയാണോ?',
    please_download_before_deleting: 'ഇല്ലാതാക്കുന്നതിന് മുമ്പ് ദയവായി ഡൗൺലോഡ് ചെയ്യുക. ഫയലുകൾ ഒരിക്കൽ ഇല്ലാതാക്കിയാൽ, വീണ്ടെടുക്കാൻ കഴിയില്ല!',
    role_has_been_changed_successfully: 'റോൾ വിജയകരമായി മാറ്റി',
    do_you_want_to_leave_collaboration: 'നിങ്ങൾക്ക് സഹകരണം ഉപേക്ഷിക്കണോ?',
    form_builder_access_denied: 'ഫോം ബിൽഡർ ആക്സസ് നിരസിച്ചു',
    quiz_builder_access_denied: 'ക്വിസ് ബിൽഡർ ആക്സസ് നിരസിച്ചു',
    you_only_have_viewer_rights_for_quiz: 'ഈ ക്വിസിനുള്ള വ്യൂവർ റൈറ്റ്സ് മാത്രമേ നിങ്ങൾക്ക് ഉള്ളൂ.',
    you_only_have_viewer_rights_for_form: 'ഈ ഫോമിന് നിങ്ങൾക്ക് കാഴ്ചക്കാരുടെ അവകാശങ്ങൾ മാത്രമേ ഉള്ളൂ.',
    kindly_contact_the_form_owner_for_more_info: 'കൂടുതൽ വിവരങ്ങൾക്ക് ദയവായി ഫോം ഉടമയെ ബന്ധപ്പെടുക.',
    kindly_contact_the_quiz_owner_for_more_info: 'കൂടുതൽ വിവരങ്ങൾക്ക് ദയവായി ക്വിസ് ഉടമയുമായി ബന്ധപ്പെടുക.',
    incomplete_questions: 'അപൂർണ്ണമായ ചോദ്യങ്ങൾ:',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'നിങ്ങൾക്ക് സംരക്ഷിക്കാത്ത മാറ്റങ്ങളുണ്ട് - ഈ പേജ് വിടാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുവെന്ന് തീർച്ചയാണോ?',
    languages: 'ഭാഷ',
    copy: "പകർത്തുക",
    not_good: 'നല്ലതല്ല',
    not_allowed: 'അനുവദനീയമല്ല',
    not_allowed_description: 'പ്രതികരണങ്ങൾ ശേഖരിച്ച ശേഷം ചോയ്‌സ് ചോദ്യ തരം മാറ്റാൻ കഴിയില്ല.',
    neutral: 'നിഷ്പക്ഷ',
    good: 'നല്ലത്',
    very_good: 'വളരെ നല്ലത്',
    edit_rights_removed: 'എഡിറ്റ് അവകാശങ്ങൾ നീക്കം ചെയ്തു',
    your_rights_to_edit_this_form_have_been_removed: "ഈ ഫോം എഡിറ്റ് ചെയ്യാനുള്ള നിങ്ങളുടെ അവകാശങ്ങൾ നീക്കം ചെയ്‌തു.",
    your_rights_to_edit_this_quiz_have_been_removed: "ഈ ക്വിസ് എഡിറ്റ് ചെയ്യാനുള്ള നിങ്ങളുടെ അവകാശങ്ങൾ നീക്കം ചെയ്‌തു.",
    please_contact_quiz_owner_for_more_info: "കൂടുതൽ വിവരങ്ങൾക്ക് ദയവായി ഫോം ഉടമയെ ബന്ധപ്പെടുക.",
    please_contact_form_owner_for_more_info: "കൂടുതൽ വിവരങ്ങൾക്ക് ദയവായി ക്വിസ് ഉടമയെ ബന്ധപ്പെടുക.",
    last_edited_on: 'അവസാനം എഡിറ്റ് ചെയ്തത്',
    last_edited: 'അവസാനം എഡിറ്റ് ചെയ്തത്',
    by: 'വഴി',
    you: "നിങ്ങൾ",
    add_option: 'ഓപ്ഷൻ ചേർക്കുക',
    add_other_option: "'മറ്റ്' ഓപ്ഷൻ ചേർക്കുക",
    estimated_time: "കണക്കാക്കിയ സമയം",
    do_you_want_to_show_estimated_time_taking_quiz: "ക്വിസ് എടുക്കുന്നതിന് കണക്കാക്കിയ സമയം കാണിക്കണോ?",
    do_you_want_to_show_estimated_time_taking_form: "ഫോം എടുക്കുന്നതിന് കണക്കാക്കിയ സമയം കാണിക്കണോ?",
    show_question_count: "ചോദ്യങ്ങളുടെ എണ്ണം കാണിക്കുക",
    do_you_want_to_show_total_number_questions_quiz: "ക്വിസിലെ മൊത്തം ചോദ്യങ്ങളുടെ എണ്ണം കാണിക്കണോ?",
    do_you_want_to_show_total_number_questions_form: "ഫോമിലെ മൊത്തം ചോദ്യങ്ങളുടെ എണ്ണം കാണിക്കണോ?",
    show_question_marks: "ചോദ്യചിഹ്നങ്ങൾ കാണിക്കുക",
    show_question_marks_quiz: "ക്വിസിൽ ചോദ്യചിഹ്നങ്ങൾ കാണിക്കുക",
    you_dont_have_add_collaborator_permission: "നിങ്ങൾക്ക് സഹകാരിയെ ചേർക്കാനുള്ള അനുമതിയില്ല",
    modified_date: "പരിഷ്കരിച്ച തീയതി",
    created_date: "സൃഷ്ടിച്ച തീയതി",
    question_cant_be_added: "ചോദ്യം ചേർക്കാൻ കഴിയില്ല",
    another_file_upload_question_cant_added: "മറ്റൊരു ഫയൽ അപ്‌ലോഡ് ചോദ്യം ചേർക്കാൻ കഴിയില്ല",
    you_can_have_only: "നിങ്ങൾക്ക് മാത്രമേ കഴിയൂ",
    file_upload_questions_in_form: "ഒരു ഫോമിൽ ചോദ്യങ്ങൾ അപ്‌ലോഡ് ചെയ്യുക",
    file_upload_questions_in_quiz: "ഒരു ക്വിസിൽ ചോദ്യങ്ങൾ അപ്‌ലോഡ് ചെയ്യുക",
    storage_capacity_exceeded: "സംഭരണശേഷി കവിഞ്ഞു",
    kindly_inform_form_builder_that: "ദയവായി അത് ഫോം ബിൽഡറെ അറിയിക്കുക",
    kindly_inform_quiz_builder_that: "ദയവായി അത് ക്വിസ് ബിൽഡറെ അറിയിക്കുക",
    their_storage_capacity_file_uploads: "ഫയൽ അപ്‌ലോഡുകൾക്കുള്ള അവരുടെ സംഭരണ ​​ശേഷി",
    has_been_exceeded: "കവിഞ്ഞിരിക്കുന്നു",
    view_details: "വിശദാംശങ്ങൾ കാണുക",
    paste_your_video_link_here: "നിങ്ങളുടെ വീഡിയോ ലിങ്ക് ഇവിടെ ഒട്ടിക്കുക",
    paste_your_website_link_here: "നിങ്ങളുടെ വെബ്സൈറ്റ് ലിങ്ക് ഇവിടെ ഒട്ടിക്കുക",
    view_answer_sheet: "ഉത്തര ഷീറ്റ് കാണുക",
    response_count: "പ്രതികരണ എണ്ണം",
    count: "എണ്ണുക",
    password_least_8_characters: "പാസ്‌വേഡിന് കുറഞ്ഞത് 8 - 50 പ്രതീകങ്ങൾ ഉണ്ടായിരിക്കണം",
    password_contain_least_1_number: "പാസ്‌വേഡിൽ ഒരു നമ്പറെങ്കിലും ഉണ്ടായിരിക്കണം",
    password_contain_least_1_capital_letter: "പാസ്‌വേഡിൽ കുറഞ്ഞത് ഒരു വലിയ അക്ഷരമെങ്കിലും ഉണ്ടായിരിക്കണം",
    password_contain_least_1_special_character: "പാസ്‌വേഡിൽ ഒരു പ്രത്യേക പ്രതീകമെങ്കിലും ഉണ്ടായിരിക്കണം",
    please_fill_all_required_fields: "ആവശ്യമായ എല്ലാ ഫീൽഡുകളും പൂരിപ്പിക്കുക",
    passwords_dont_match: "പാസ്‌വേഡുകൾ പൊരുത്തപ്പെടുന്നില്ല",
    incorrect_password: "തെറ്റായ പാസ്‌വേഡ്",
    click_or_change_current_theme: "മറ്റൊരു തീം തിരഞ്ഞെടുക്കാൻ നിലവിലെ തീം അല്ലെങ്കിൽ താഴെയുള്ള 'മാറ്റുക' ക്ലിക്ക് ചെയ്യുക",
    select: "തിരഞ്ഞെടുക്കുക",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "നിങ്ങൾക്ക് ഈ ചിത്രം ഇല്ലാതാക്കണോ? നിങ്ങൾ തുടരുകയാണെങ്കിൽ, ഈ ചിത്രം ഉപയോഗിക്കുന്ന ഏതെങ്കിലും ഫോമുകൾക്കോ ​​ക്വിസുകൾക്കോ ​​ഈ ചിത്രം ലഭ്യമാകില്ല. ഈ പ്രവർത്തനം പഴയപടിയാക്കാനാകില്ല.",
    url_does_not_seem_to_valid: "URL സാധുതയുള്ളതായി തോന്നുന്നില്ല.",
    you_have_reached_image_uploaded_limit: "നിങ്ങൾ ചിത്രം അപ്‌ലോഡ് ചെയ്ത പരിധിയിൽ എത്തി.",
    undo: "പഴയപടിയാക്കുക",
    response_deleted: "പ്രതികരണം ഇല്ലാതാക്കി",
    question_deleted: "ചോദ്യം ഇല്ലാതാക്കി",
    answer_deleted: "ഉത്തരം ഇല്ലാതാക്കി",
    responses_deleted: "പ്രതികരണങ്ങൾ ഇല്ലാതാക്കി",
    questions_deleted: "ചോദ്യങ്ങൾ ഇല്ലാതാക്കി",
    answers_deleted: "ഉത്തരങ്ങൾ ഇല്ലാതാക്കി",
    collect_email_header: "ഇമെയിൽ ശേഖരിക്കുക",
    collect_email_alert_message: "ഈ ഫീച്ചർ പ്രവർത്തനക്ഷമമാക്കുന്നത്, ഫോം പൂരിപ്പിക്കുന്നതിന് പ്രതികരണക്കാരനെ അവരുടെ ജിമെയിൽ വിലാസം ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യാൻ പ്രേരിപ്പിക്കും.",
    collect_email_alert_note: "ശ്രദ്ധിക്കുക : Google അക്കൗണ്ട് മാത്രമേ പിന്തുണയ്ക്കൂ",
    allow_response_edit: "പ്രതികരണ എഡിറ്റ് അനുവദിക്കുക",
    allow_response_edit_description: "സമർപ്പിച്ചതിന് ശേഷം പ്രതികരണങ്ങൾ എഡിറ്റ് ചെയ്യാൻ പ്രതികരിക്കുന്നവരെ അനുവദിക്കണോ?",
    enter_desc_here: "ഇവിടെ വിവരണം നൽകുക",
    passcode_protection: "പാസ്‌കോഡ് സംരക്ഷണം",
    passcode_protection_description_quiz: "നിങ്ങളുടെ ക്വിസ് പാസ്‌കോഡ് പരിരക്ഷിക്കപ്പെടണമെന്ന് നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    passcode_protection_description: "നിങ്ങളുടെ ഫോം പാസ്‌കോഡ് പരിരക്ഷിക്കണമെന്ന് നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    crop_image: "ചിത്രം മുറിക്കുക",
    crop: "വിള",
    original: "ഒറിജിനൽ",
    collect_email: "ഇമെയിൽ വിലാസം ശേഖരിക്കുക",
    collect_email_description: "ഭാവിയിലെ റഫറൻസിനോ ഉപയോഗത്തിനോ വേണ്ടി പ്രതികരിക്കുന്നവരുടെ ഇമെയിൽ വിലാസങ്ങൾ ശേഖരിക്കാൻ നിങ്ങൾ ആഗ്രഹിക്കുന്നുണ്ടോ?",
    collect_email_description_quiz: "ഈ ഫീച്ചർ പ്രവർത്തനക്ഷമമാക്കുന്നത് ക്വിസ് പൂരിപ്പിക്കുന്നതിന് പ്രതികരിക്കുന്നവരെ അവരുടെ ജിമെയിൽ വിലാസം ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യാൻ പ്രേരിപ്പിക്കും.",
    collect_email_description_form: "ഈ ഫീച്ചർ പ്രവർത്തനക്ഷമമാക്കുന്നത് ഫോം പൂരിപ്പിക്കുന്നതിന് പ്രതികരിക്കുന്നവരെ അവരുടെ ജിമെയിൽ വിലാസം ഉപയോഗിച്ച് സൈൻ ഇൻ ചെയ്യാൻ നിർബന്ധിതരാക്കും.",
    opening_date: 'തുറക്കുന്ന തീയതി',
    closing_date: 'അവസാന തിയ്യതി',
    schedule_form_status: 'ഫോം സ്റ്റാറ്റസ് ഷെഡ്യൂൾ ചെയ്യുക',
    schedule_quiz_status: 'ക്വിസ് സ്റ്റാറ്റസ് ഷെഡ്യൂൾ ചെയ്യുക',
    schedule_opening_date_and_time: "തുറക്കുന്ന തീയതിയും സമയവും ഷെഡ്യൂൾ ചെയ്യുക",
    schedule_closing_date_and_time: "അവസാന തീയതിയും സമയവും ഷെഡ്യൂൾ ചെയ്യുക",
    schedule_opening_date_and_time_description: "സൂചിപ്പിച്ച തീയതിയിലും സമയത്തിലും ഫോം സജീവമാക്കുക",
    schedule_opening_date_and_time_description_quiz: "സൂചിപ്പിച്ച തീയതിയിലും സമയത്തിലും ക്വിസ് സജീവമാക്കുക",
    schedule_closing_date_and_time_description: "സൂചിപ്പിച്ച തീയതിയിലും സമയത്തും ഫോം നിർജ്ജീവമാക്കുക",
    schedule_closing_date_and_time_description_quiz: "സൂചിപ്പിച്ച തീയതിയിലും സമയത്തിലും ക്വിസ് നിർജ്ജീവമാക്കുക",
    upgrade: "നവീകരിക്കുക",
    gold_plan: "സ്വർണ്ണ പദ്ധതി",
    view_plans: "പ്ലാനുകൾ കാണുക",
    premium_plans: "പ്രീമിയം പ്ലാനുകൾ",
    plan_duration: "/ മാസം",
    plan_logic_jump: "ലോജിക് ജമ്പ്",
    plan_remove_ads: "പരസ്യങ്ങൾ നീക്കം ചെയ്യുക",
    cancel_subscription: "സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കുക",
    quiz_has_answer_edit_message: "ഈ ക്വിസ് ഇതിനകം സമർപ്പിക്കുകയും ഉത്തരങ്ങൾ ശേഖരിക്കുകയും ചെയ്തിട്ടുണ്ട്. ക്വിസ് എഡിറ്റ് ചെയ്യുന്നത് മുമ്പത്തെ ഉത്തരങ്ങളെ ബാധിച്ചേക്കാം.",
    quiz_published_alert_edit_message: "ഈ ക്വിസ് പ്രസിദ്ധീകരിച്ചു, നിങ്ങൾ എഡിറ്റ് ചെയ്‌താൽ ഈ ക്വിസ് പ്രസിദ്ധീകരിക്കപ്പെടാതിരിക്കും.",
    quiz_submit_after_edit_message: "ക്വിസ് എഡിറ്റ് ചെയ്യുന്നത് മാർക്ക്, ശതമാനം എന്നിവയെ ബാധിച്ചേക്കാം കൂടാതെ ചില ചോദ്യങ്ങളുടെ പുനർമൂല്യനിർണയം ആവശ്യമായി വന്നേക്കാം.",
    title_activity_privacy_policy: "സ്വകാര്യതാ നയ പ്രവർത്തനം",
    action_blocked_message: "ഈ ക്വിസിൻ്റെ നിലവിലുള്ള ഉത്തരങ്ങൾ കാരണം പ്രവർത്തനം തടഞ്ഞു. ഇത് അനുവദിക്കുന്നത് മുമ്പത്തെ ഉത്തരങ്ങളെ ബാധിച്ചേക്കാം.",
    email_addresses: "ഇമെയിൽ വിലാസങ്ങൾ",
    confirmation_message: "സ്ഥിരീകരണ സന്ദേശം",
    confirmation_message_description: "ഫോം സമർപ്പിച്ചതിന് ശേഷം നിങ്ങൾക്ക് സ്ഥിരീകരണ സന്ദേശം കാണിക്കണോ?",
    confirmation_message_description_quiz: "ക്വിസിന് ഉത്തരം നൽകിയതിന് ശേഷം നിങ്ങൾക്ക് സ്ഥിരീകരണ സന്ദേശം കാണിക്കണോ?",
    default_custom_thank_message: "ഈ ഫോം പൂർത്തിയാക്കിയതിന് നന്ദി.",
    default_custom_thank_message_quiz: "ഈ ക്വിസ് പൂർത്തിയാക്കിയതിന് നന്ദി.",
    upgrade_your_plan: "നിങ്ങളുടെ പ്ലാൻ നവീകരിക്കൂ!",
    upgrade_your_plan_1: "നിങ്ങളുടെ പ്ലാൻ നവീകരിക്കുക",
    upgrade_your_plan_content: "ഈ ഫീച്ചർ ആക്‌സസ് ചെയ്യാനും സ്റ്റോറേജ് സൈസ് വർദ്ധിപ്പിക്കാനും പ്രീമിയം പതിപ്പ് ദയവായി അപ്‌ഗ്രേഡ് ചെയ്യുക",
    alert_icon: "അലേർട്ട് ഐക്കൺ",
    usage_details: "ഉപയോഗ വിശദാംശങ്ങൾ",
    current_plan: "നിലവിലെ പ്ലാൻ",
    total_submission: "മൊത്തം സമർപ്പിക്കൽ",
    image_attachments: "ചിത്ര അറ്റാച്ച്മെൻ്റുകൾ",
    custom_themes: "ഇഷ്‌ടാനുസൃത തീമുകൾ",
    free_limit_reached: "നിങ്ങൾ സൗജന്യ പരിധിയിലെത്തി -",
    payment_done_but_server_error: "നിങ്ങളുടെ പേയ്‌മെൻ്റ് വിജയകരമായി കുറച്ചിരിക്കുന്നു. നിങ്ങളുടെ പേയ്‌മെൻ്റ് സ്ഥിരീകരിക്കാൻ ഞങ്ങളെ അനുവദിക്കുന്നതിന് കുറച്ച് സമയത്തിന് ശേഷം ആപ്പ് തുറക്കുക.",
    response_quota_reached: "നിങ്ങൾ സൗജന്യ പരിധിയിൽ എത്തിയിരിക്കുന്നു, എല്ലാ പ്രതികരണങ്ങളും ആക്‌സസ് ചെയ്യുന്നതിന് ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡ് ചെയ്യുക.",
    answers_quota_reached: "നിങ്ങൾ സൗജന്യ പരിധിയിലെത്തി, എല്ലാ ഉത്തരങ്ങളും ആക്‌സസ് ചെയ്യാൻ നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡ് ചെയ്യുക.",
    logic_jump_enabled: "ലോജിക് ജമ്പ് പ്രവർത്തനക്ഷമമാക്കി.",
    logic_jump_disabled: "ലോജിക് ജമ്പ് പ്രവർത്തനരഹിതമാക്കി.",
    cancel_subscription_message: "നിങ്ങളുടെ സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കണമെന്ന് തീർച്ചയാണോ? സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കുന്നത് എല്ലാ പ്രീമിയം ഫീച്ചറുകളിലേക്കുള്ള ആക്‌സസ് നഷ്‌ടമാക്കും.",
    plan_expired: "നിങ്ങളുടെ പ്ലാൻ കാലഹരണപ്പെട്ടു. ഈ ഡ്രാഫ്റ്റ് ഉപയോഗിക്കുന്നതിന് ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്ഗ്രേഡ് ചെയ്യുക.",
    plan_expired_edit_form: "നിങ്ങളുടെ പ്ലാൻ കാലഹരണപ്പെട്ടു. ഈ ഫോം എഡിറ്റ് ചെയ്യാൻ ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്ഗ്രേഡ് ചെയ്യുക",
    plan_expired_edit_quiz: "നിങ്ങളുടെ പ്ലാൻ കാലഹരണപ്പെട്ടു. ഈ ക്വിസ് എഡിറ്റ് ചെയ്യാൻ ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്ഗ്രേഡ് ചെയ്യുക",
    plan_expired_duplicate_form: "നിങ്ങളുടെ പ്ലാൻ കാലഹരണപ്പെട്ടു. ഈ ഫോം ഡ്യൂപ്ലിക്കേറ്റ് ചെയ്യാൻ ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്ഗ്രേഡ് ചെയ്യുക",
    plan_expired_duplicate_quiz: "നിങ്ങളുടെ പ്ലാൻ കാലഹരണപ്പെട്ടു. ഈ ക്വിസ് ഡ്യൂപ്ലിക്കേറ്റ് ചെയ്യാൻ ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്ഗ്രേഡ് ചെയ്യുക",
    premium_features: "പ്രീമിയം ഫീച്ചർ",
    plan_file_upload: "അൺലിമിറ്റഡ് ഇഷ്‌ടാനുസൃത തീം ചിത്രങ്ങൾ",
    plan_custom_theme: "അൺലിമിറ്റഡ് ഇഷ്‌ടാനുസൃത തീം ചിത്രങ്ങൾ",
    plan_call_support: "ഉയർന്ന മുൻഗണന - കോൾ പിന്തുണ",
    plan_email_support: "ഉയർന്ന മുൻഗണനയുള്ള ഇമെയിൽ പിന്തുണ",
    plan_custom_domain: "ഇഷ്‌ടാനുസൃത ഡൊമെയ്ൻ",
    subscribe: "സബ്സ്ക്രൈബ്",
    plan_remove_branding: "ബ്രാൻഡിംഗ് നീക്കം ചെയ്യുക",
    plan_on_call_support: "കോൾ സപ്പോർട്ടിൽ",
    plan_image_attachment: "അൺലിമിറ്റഡ് ഇമേജുകൾ അറ്റാച്ച്മെൻ്റുകൾ",
    plan_custom_thank_you: "ഇഷ്‌ടാനുസൃതമാക്കാവുന്ന നന്ദി പേജുകൾ",
    plan_allowed_responses: "അനുവദനീയമായ പ്രതികരണങ്ങളുടെ എണ്ണം 50000",
    trusted_by_number_of_user: "5 ദശലക്ഷത്തിലധികം ഉപയോക്താക്കൾ വിശ്വസിക്കുന്നു",
    email_support: "ഇമെയിൽ പിന്തുണ",
    call_support: "പിന്തുണയെ വിളിക്കുക",
    keyboard_shortcuts: "കീബോർഡ് കുറുക്കുവഴികൾ",
    premium_limit_reached: "നിങ്ങൾ പ്ലാൻ പരിധിയിൽ എത്തി. എല്ലാ പ്രീമിയം ഫീച്ചറുകളും ആക്‌സസ് ചെയ്യാൻ നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡ് ചെയ്യുക.",
    premium_limit_reached_mobile: "നിങ്ങൾ പ്ലാൻ പരിധിയിൽ എത്തി.",
    unlimited: "അൺലിമിറ്റഡ്",
    expired_on_next_renewal: "നിങ്ങളുടെ അടുത്ത പുതുക്കൽ തീയതിയിൽ ഇത് കാലഹരണപ്പെടും",
    upgrade_plan: "പ്ലാൻ നവീകരിക്കുക",
    downgrade_plan: "ഡൗൺഗ്രേഡ് പ്ലാൻ",
    file_upload_questions: "ഫയൽ അപ്‌ലോഡ് ചോദ്യങ്ങൾ",
    plan_response_edit: "പ്രതികരണം തിരുത്തുക",
    cant_process: "പ്രോസസ്സ് ചെയ്യാൻ കഴിയില്ല",
    you_have_subscribed_through_android: "നിങ്ങൾ ഞങ്ങളുടെ ആൻഡ്രോയിഡ് ആപ്പ് വഴി വരിക്കാരായി. പേയ്‌മെൻ്റുമായി ബന്ധപ്പെട്ട എല്ലാ അപ്‌ഡേറ്റുകൾക്കും ദയവായി SurveyHeart ആപ്പ് ഉപയോഗിക്കുക.",
    pay_now: "ഇപ്പോള് പണമടയ്ക്കൂ",
    update_payment_details: "പേയ്‌മെൻ്റ് വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യുക",
    limit_reached_alert_message: "നിങ്ങൾ സൗജന്യ പരിധിയിൽ എത്തിയിരിക്കുന്നു, എല്ലാ പ്രതികരണങ്ങളും ആക്‌സസ് ചെയ്യുന്നതിന് ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡ് ചെയ്യുക.",
    limit_reached_alert_message_quiz: "നിങ്ങൾ സൗജന്യ പരിധിയിലെത്തി, എല്ലാ ഉത്തരങ്ങളും ആക്‌സസ് ചെയ്യാൻ നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡ് ചെയ്യുക.",
    limit_reached_UI_message: "നിങ്ങളുടെ നിലവിലെ പ്ലാനിന് പ്രതികരണങ്ങൾ ലഭ്യമാകില്ല, എല്ലാ പ്രതികരണങ്ങളും കാണുന്നതിന് ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡ് ചെയ്യുക.",
    limit_reached_UI_message_quiz: "നിങ്ങളുടെ നിലവിലെ പ്ലാനിന് പ്രതികരണങ്ങൾ ലഭ്യമാകില്ല, എല്ലാ ഉത്തരങ്ങളും കാണുന്നതിന് ദയവായി നിങ്ങളുടെ പ്ലാൻ അപ്‌ഗ്രേഡ് ചെയ്യുക.",
    owner_is_not_premium_user: "ഉടമ ഒരു പ്രീമിയം ഉപയോക്താവല്ല",
    cancel_subscription_description: "നിങ്ങളുടെ സബ്‌സ്‌ക്രിപ്‌ഷൻ റദ്ദാക്കണമെന്ന് തീർച്ചയാണോ? റദ്ദാക്കുന്നത് എല്ലാ പ്രീമിയം ഫീച്ചറുകളിലേക്കുള്ള ആക്‌സസ് നഷ്‌ടമാക്കും.",
    bronze_storage_description: "ആയാസരഹിതമായ ഡാറ്റ മാനേജ്മെൻ്റിനായി 25GB സംഭരണം നേടുക.",
    silver_storage_description: "ആയാസരഹിതമായ ഡാറ്റ മാനേജ്മെൻ്റിന് 50GB സംഭരണം നേടുക.",
    gold_storage_description: "ആയാസരഹിതമായ ഡാറ്റ മാനേജ്മെൻ്റിന് 150GB സംഭരണം നേടുക.",
    bronze_responses_description: "ശക്തമായ സർവേ ഉൾക്കാഴ്ചകൾ നേടുന്നതിന് 35,000 പ്രതികരണങ്ങൾ ഉപയോഗിക്കുക.",
    silver_responses_description: "ശക്തമായ സർവേ ഉൾക്കാഴ്ചകൾ നേടുന്നതിന് 75,000 പ്രതികരണങ്ങൾ ഉപയോഗിക്കുക.",
    gold_responses_description: "ശക്തമായ സർവേ ഉൾക്കാഴ്ചകൾ നേടുന്നതിന് 1,50,000 പ്രതികരണങ്ങൾ ഉപയോഗിക്കുക.",
    bronze_image_description: "വിഷ്വൽ അപ്പീലിനായി 200 ചിത്രങ്ങൾ വരെ ഉപയോഗിച്ച് നിങ്ങളുടെ സർവേകൾ മെച്ചപ്പെടുത്തുക.",
    silver_image_description: "വിഷ്വൽ അപ്പീലിനായി 500 ചിത്രങ്ങൾ വരെ ഉപയോഗിച്ച് നിങ്ങളുടെ സർവേകൾ മെച്ചപ്പെടുത്തുക.",
    gold_image_description: "വിഷ്വൽ അപ്പീലിനായി അൺലിമിറ്റഡ് ഇമേജുകൾ ഉപയോഗിച്ച് നിങ്ങളുടെ സർവേകൾ മെച്ചപ്പെടുത്തുക.",
    bronze_theme_description: "നിങ്ങളുടെ ബ്രാൻഡിനായി നിങ്ങളുടെ സർവേകൾ വ്യക്തിഗതമാക്കുന്നതിന് 200 ഇഷ്‌ടാനുസൃത ചിത്രങ്ങൾ ചേർക്കുക.",
    silver_theme_description: "നിങ്ങളുടെ ബ്രാൻഡിനായി നിങ്ങളുടെ സർവേകൾ വ്യക്തിഗതമാക്കുന്നതിന് 500 ഇഷ്‌ടാനുസൃത ചിത്രങ്ങൾ ചേർക്കുക.",
    gold_theme_description: "നിങ്ങളുടെ ബ്രാൻഡിനായി നിങ്ങളുടെ സർവേകൾ വ്യക്തിഗതമാക്കുന്നതിന് പരിധിയില്ലാത്ത ഇഷ്‌ടാനുസൃത ചിത്രങ്ങൾ ചേർക്കുക.",
    custom_theme_image: "ഇഷ്‌ടാനുസൃത തീം ചിത്രം",
    file_upload_description: "അൺലിമിറ്റഡ് ഫയൽ അപ്‌ലോഡ് ചോദ്യങ്ങൾ പരിധിയില്ലാതെ സംയോജിപ്പിക്കുക.",
    logic_jump_description: "ഓരോ പ്രതികരിക്കുന്നയാളുടെയും അനുഭവത്തിനായി സർവേ ഫ്ലോ ഇഷ്‌ടാനുസൃതമാക്കാൻ ലോജിക് ജമ്പുകൾ ഉപയോഗിക്കുക",
    thank_you_message_description: "പ്രതികരിക്കുന്നവരെ ആകർഷിക്കാൻ നന്ദി പേജുകൾ വ്യക്തിഗതമാക്കുക.",
    response_edit_description: "പ്രതികരണ എഡിറ്റ് ഫീച്ചർ ഉപയോഗിച്ച് പ്രതികരണങ്ങൾ എളുപ്പത്തിൽ പരിഷ്കരിക്കുക.",
    remove_branding_description: "SurveyHeart ബ്രാൻഡിംഗ് നീക്കം ചെയ്തുകൊണ്ട് നിങ്ങളുടെ ബ്രാൻഡ് സാന്നിധ്യം ഉയർത്തുക.",
    bronze_remove_ads_description: "ഞങ്ങളുടെ ബ്രോൺസ് പ്ലാനിനൊപ്പം പരസ്യരഹിതമായി പോകൂ, നിങ്ങളുടെ സർവേകളിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കൂ.",
    silver_remove_ads_description: "ഞങ്ങളുടെ സിൽവർ പ്ലാനിനൊപ്പം പരസ്യരഹിതമായി പോയി നിങ്ങളുടെ സർവേകളിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുക.",
    gold_remove_ads_description: "ഞങ്ങളുടെ ഗോൾഡ് പ്ലാനിനൊപ്പം പരസ്യരഹിതമായി പോയി നിങ്ങളുടെ സർവേകളിൽ ശ്രദ്ധ കേന്ദ്രീകരിക്കുക.",
    bronze_email_support_description: "മുൻഗണന കുറഞ്ഞതിനായുള്ള ഇമെയിൽ പിന്തുണ ആക്‌സസ് ചെയ്യുക, സമയബന്ധിതമായ സഹായം ഉറപ്പാക്കുന്നു.",
    silver_email_support_description: "ഇടത്തരം മുൻഗണനയ്ക്കുള്ള ഇമെയിൽ പിന്തുണ ആക്‌സസ് ചെയ്യുക, സമയോചിതമായ സഹായം ഉറപ്പാക്കുന്നു.",
    gold_email_support_description: "ഉയർന്ന മുൻഗണനയ്‌ക്കായി ഇമെയിൽ പിന്തുണ ആക്‌സസ് ചെയ്യുക, സമയോചിതമായ സഹായം ഉറപ്പാക്കുന്നു.",
    bronze_email_support_title: "കുറഞ്ഞ മുൻഗണനയുള്ള ഇമെയിൽ പിന്തുണ",
    silver_email_support_title: "ഇടത്തരം മുൻഗണനയുള്ള ഇമെയിൽ പിന്തുണ",
    gold_email_support_title: "ഉയർന്ന മുൻഗണനയുള്ള ഇമെയിൽ പിന്തുണ",
    update_your_payment_details_mobile: "ദയവായി നിങ്ങളുടെ പേയ്‌മെൻ്റ് വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യുക.",
    update_your_payment_details_pc: "ഈ അക്കൗണ്ട് പുതുക്കുന്നതിൽ ഞങ്ങൾ ചില പ്രശ്നങ്ങൾ നേരിടുന്നു. നിങ്ങളുടെ പേയ്‌മെൻ്റ് വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യുക.",
    card_details_updated_successfully: "കാർഡ് വിശദാംശങ്ങൾ വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു",
    card_details_updated_successfully_description: "നിങ്ങളുടെ കാർഡ് വിശദാംശങ്ങൾ വിജയകരമായി അപ്ഡേറ്റ് ചെയ്തു. നിങ്ങൾക്ക് ഇപ്പോൾ നിങ്ങളുടെ അപ്ഡേറ്റ് ചെയ്ത പേയ്മെൻ്റ് വിവരങ്ങൾ ഉപയോഗിക്കുന്നത് തുടരാം.",
    Payment_successful: "പേയ്‌മെൻ്റ് വിജയിച്ചു",
    payment_successful_description: "നിങ്ങളുടെ പേയ്‌മെൻ്റ് വിജയകരമാണ്. നിങ്ങൾ ഇപ്പോൾ SurveyHeart-ൻ്റെ പ്രീമിയം പ്ലാനിലേക്ക് സബ്‌സ്‌ക്രൈബുചെയ്‌തിരിക്കുന്നു. സുഖമായിരിക്കുകയും നിങ്ങളുടെ വഴിയിൽ വരുന്ന അതിശയകരമായ എല്ലാ ഫീച്ചറുകളും ആസ്വദിക്കുകയും ചെയ്യുക.",
    Payment_failed: "പേയ്‌മെൻ്റ് പരാജയപ്പെട്ടു!",
    payment_failed_description: "നിർഭാഗ്യവശാൽ. SurveyHeart-ൻ്റെ പ്രീമിയം പ്ലാനിലേക്കുള്ള നിങ്ങളുടെ സബ്‌സ്‌ക്രിപ്‌ഷൻ ഞങ്ങൾക്ക് പ്രോസസ്സ് ചെയ്യാൻ കഴിഞ്ഞില്ല. ദയവായി വീണ്ടും ശ്രമിക്കുക.",
    note_for_five_rupees: "ശ്രദ്ധിക്കുക: നിങ്ങളുടെ കാർഡ് വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യുന്നതിന് 5 രൂപ ഈടാക്കും, അത് \n അതേ അക്കൗണ്ടിലേക്ക് 3 മുതൽ 4 പ്രവൃത്തി ദിവസങ്ങൾക്കുള്ളിൽ റീഫണ്ട് ചെയ്യപ്പെടും.",
    logic: "യുക്തി",
    re_generate_code: "കോഡ് വീണ്ടും ജനറേറ്റ് ചെയ്യുക",
    plan_change_restrictions: "പ്ലാൻ മാറ്റ നിയന്ത്രണങ്ങൾ",
    plan_change_restriction_desc_1: "UPI ഉപയോഗിച്ച് നിങ്ങളുടെ പ്ലാൻ മാറ്റാൻ കഴിയില്ല.",
    plan_change_restriction_desc_2: "അപ്‌ഗ്രേഡുകൾക്കും ഡൗൺഗ്രേഡുകൾക്കുമായി ഒരു കാർഡിലേക്ക് മാറുക, അല്ലെങ്കിൽ നിങ്ങൾ ആഗ്രഹിക്കുന്ന പ്ലാൻ റദ്ദാക്കി വീണ്ടും സബ്‌സ്‌ക്രൈബ് ചെയ്യുക.",
    update_card_details: "കാർഡ് വിശദാംശങ്ങൾ അപ്‌ഡേറ്റ് ചെയ്യുക",
    features_and_plans: "സവിശേഷതകളും പദ്ധതികളും",
    cancel_plan: "പദ്ധതി റദ്ദാക്കുക",
    cancel_plan_desc: "നിങ്ങൾ പ്ലാൻ റദ്ദാക്കി. നിങ്ങളുടെ പുതുക്കൽ തീയതി വരെ പണമടച്ചുള്ള ഫീച്ചറുകളിലേക്ക് നിങ്ങൾക്ക് തുടർന്നും ആക്സസ് ഉണ്ടായിരിക്കും.",
    explore_text: "ഇന്നത്തെ ഞങ്ങളുടെ പ്രീമിയം സബ്‌സ്‌ക്രിപ്‌ഷൻ പ്ലാനുകളിലൊന്നിൽ അസാധാരണമായ സവിശേഷതകൾ പര്യവേക്ഷണം ചെയ്യുക, അൺലോക്ക് ചെയ്യുക",
    cant_edit_this_form: "ഈ ഫോം എഡിറ്റ് ചെയ്യാൻ കഴിയില്ല",
    cant_edit_this_quiz: "ഈ ക്വിസ് എഡിറ്റ് ചെയ്യാൻ കഴിയില്ല",
    cant_duplicate_this_form: "ഈ ഫോം ഡ്യൂപ്ലിക്കേറ്റ് ചെയ്യാൻ കഴിയില്ല",
    cant_duplicate_this_quiz: "ഈ ക്വിസ് ഡ്യൂപ്ലിക്കേറ്റ് ചെയ്യാൻ കഴിയില്ല",
    kindly_upgrade_the_premium: "ഈ സവിശേഷത ആക്‌സസ് ചെയ്യുന്നതിന് ദയവായി പ്രീമിയം പതിപ്പ് നവീകരിക്കുക.",
    check_box: 'ചെക്ക് ബോക്സ്',
    continue_to_next_question: "അടുത്ത ചോദ്യത്തിലേക്ക് തുടരുക",
    add_logic: "യുക്തി ചേർക്കുക",
    submit_form: "ഫോം സമർപ്പിക്കുക",
    save_logic: "ലോജിക് സംരക്ഷിക്കുക",
    remove_logic: "ലോജിക് നീക്കം ചെയ്യുക",
}