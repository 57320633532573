// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._2ck9gLZE8a6M1lRsk986k4 {\n    position: relative;\n}\n\n._2-sqeddLlhBmA8SNopiqBe {\n    width: 300px;\n    padding: 6px 12px;\n    border-radius: 25px;\n    border: 1px solid #a1a1a1;\n    display: flex;\n    flex-direction: row;\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25)\n}\n\n._3kbDSlaLZam29j6Wq1BmB9 {\n    width: 300px;\n    position: absolute;\n    z-index: 2;\n    padding: 6px 12px;\n    margin-top: 2px;\n    border-radius: 12px;\n    background: white;\n    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25)\n}\n\n._2RJ8V7zYc8hXqJrcH8szpu {\n    width: 100%;\n}\n\n.CA0m1SnTT4MT7nmrYpZEa:hover {\n    cursor: pointer;\n}\n", ""]);
// Exports
exports.locals = {
	"container": "_2ck9gLZE8a6M1lRsk986k4",
	"search_box": "_2-sqeddLlhBmA8SNopiqBe",
	"drop_down_list_container": "_3kbDSlaLZam29j6Wq1BmB9",
	"drop_down_option_card": "_2RJ8V7zYc8hXqJrcH8szpu",
	"search_drop_down_icon": "CA0m1SnTT4MT7nmrYpZEa"
};
module.exports = exports;
