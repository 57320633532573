const screenHeight = screen.availHeight;
const screenWidth = screen.availWidth;

/** this function will return the position style properties for the
    popup element according to the clicked element's position. **/

/** Inputs: popupElementHeight = type is Number ex: 300
    popupElementWidth = type is Number   ex: 300
    clickedElement = clicked Element    ex: form control popup container element **/

export default function getPopUpElementPositionStyles (popupElementWidth, popupElementHeight, clickedElement) {
    const clickedXAxisPosition = clickedElement.clientX;
    const clickedYAxisPosition = clickedElement.clientY;
        if (clickedYAxisPosition >= (screenHeight - popupElementHeight)) {
            if (clickedXAxisPosition >= (screenWidth - (popupElementWidth))) {
                return {right: '0', top: 'auto', bottom: '50px'};
            } else {
                return {top: 'auto', bottom: '0px', left: '0'};
            }
        } else if (clickedXAxisPosition >= (screenWidth - (popupElementWidth))) {
            return {right: '0', top: '50px', bottom: 'auto'};
        } else {
            return {top: '50px', bottom: 'auto', left: '0'};
        }
}