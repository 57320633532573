import React, {useState, useEffect} from 'react'
import '../css/templates.css'
import FormCard, {CARD_TYPE} from "./FormCard";
import {isMobileOnly} from "react-device-detect";
import expandLess from "../../../../public/images/expand_less.svg";
import expandMore from "../../../../public/images/expand_more.svg";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {TEMPLATE_ACTION_TYPE} from "../../reducers/templateReducer";
import {useDispatch, useSelector} from "react-redux";
import {t} from "i18next";

const CategorizedTemplatesContainer = (props) => {
    const [formList, setFormList] = useState(undefined);
    const [heightRatio, setHeightRatio] = useState(1);
    const isFormTemplates = props.isFormTemplates;
    const showAllFormsOfThisCategory = useSelector(state => state.templateReducer.showAllFormsOfThisCategory)
    const dispatch = useDispatch()

    const setMinimumFormList = () => {
        (screen.width > 1750) ? setFormList(props.formList.slice(0, 5)) :
            (screen.width > 1350) ? setFormList(props.formList.slice(0, 4)) :
                (screen.width > 1050) ? setFormList(props.formList.slice(0, 3)) :
                    setFormList(props.formList.slice(0, 2))
    }

    useEffect(() => {
        (showAllFormsOfThisCategory !== props.category) ?
            setMinimumFormList() : null

        if (isFormTemplates !== props.isFormTemplates) {
            setFormList(props.formList);
        }
        setHeightRatio(props.formList.length / (formList ? formList.length : formList ? formList.length : props.formList));
        if ((showAllFormsOfThisCategory !== props.category)
            && (showAllFormsOfThisCategory === props.category)) {
            setFormList(props.formList);
            setHeightRatio(heightRatio)
        } else {
            ((showAllFormsOfThisCategory === props.category)
                && (showAllFormsOfThisCategory !== props.category)) ?
                setMinimumFormList() : null
        }

    }, [heightRatio])

    if (formList && props.formList?.length) {
        // instead of below commented code which calculates height for category opened i added height: auto, which automatically adjusting the height
        // const height = !isMobileOnly ?
        //     (0 < heightRatio && heightRatio <= 1) ? '270px'
        //         : (1 < heightRatio && heightRatio <= 2) ? '540px'
        //             : (2 < heightRatio && heightRatio <= 3) ? '810px'
        //                 : (3 < heightRatio && heightRatio <= 4) ? '1080px'
        //                     : (4 < heightRatio && heightRatio <= 5) ? '1350px' : null :
        //     screen.width > 380 ?
        //         (4 < heightRatio && heightRatio <= 5) ? '880px'
        //             : (3 < heightRatio && heightRatio <= 4) ? '700px'
        //                 : (2 < heightRatio && heightRatio <= 3) ? '530px'
        //                     : (1 < heightRatio && heightRatio <= 2) ? '350px'
        //                         : null
        //         : (4 < heightRatio && heightRatio <= 5) ? '750px' :
        //             (3 < heightRatio && heightRatio <= 4) ? '600px' :
        //                 (2 < heightRatio && heightRatio <= 3) ? '450px' :
        //                     (1 < heightRatio && heightRatio <= 2) ? '300px' : null
        const templateCards = props.formList.map((formData, index) =>
            <FormCard key={index}
                      homePage={props.homePage}
                      CARD_TYPE={CARD_TYPE.TEMPLATE_FORM}
                      cardIndex={index}
                      previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                      formData={formData}/>);
        return (
            <div id='categorized-template' className='category-container cards-container-width'
                 style={{width: props.homePage ? "100%" : null}}>
                <p id='template-category-title' className='category-title'
                   style={props.homePage ? {color: "#004BA0", fontWeight: "bold"} : null}>{props.category}</p>
                <div id='template-card' className='template-cards-container'
                     style={(showAllFormsOfThisCategory === props.category) ? {height: "auto"} :
                         !isMobileOnly ? {height: '270px'} : screen.width > 380 ? {height: '170px'} : {
                             height: '150px',
                             width: '360px'
                         }}
                >{templateCards}</div>
                {(showAllFormsOfThisCategory === props.category) ?
                    <div id='hide-show' className='show-hide-button-container'>
                        <div className={props.homePage ? "show-hide-home-page" : 'show-hide-button'}
                             onClick={() => {
                                 dispatch({
                                     type: TEMPLATE_ACTION_TYPE.SET_SHOW_ALL_FORMS_OF_THIS_CATEGORY,
                                     payload: 'none'
                                 })
                             }}>
                            <span className='show-hide-text'>{t(translationKeys.hide)}</span>
                            <img style={!isMobileOnly ? {width: '24px'} : {width: '16px'}}
                                 src={expandLess} alt='hide'/>
                        </div>
                    </div> :
                    (props.formList.length > formList.length) ?
                        <div className='show-hide-button-container'>
                            <div className={props.homePage ? "show-hide-home-page" : 'show-hide-button'}
                                 onClick={() => {
                                     dispatch({
                                         type: TEMPLATE_ACTION_TYPE.SET_SHOW_ALL_FORMS_OF_THIS_CATEGORY,
                                         payload: props.category
                                     })
                                 }}>
                                <span className='show-hide-text'>{t(translationKeys.show_more)}</span>
                                <img style={!isMobileOnly ? {width: '24px'} : {width: '16px'}}
                                     src={expandMore} alt='show-more'/>
                            </div>
                        </div> : null}
            </div>
        )
    } else {
        return null;
    }
}

export default CategorizedTemplatesContainer
