import {uploadUserJson} from "./upload-file-to-aws";
import {AWS_UPLOAD_TYPE} from "./aws";

let awsUserJson;

export function setAwsUserJson(imageJSON) {
    if (imageJSON) {
        awsUserJson = imageJSON;
    }
}

export function addImagePathInUserFile(uploadType, userId, newlyAddedImagePath, successFunction, failureFunction) {
    const userObject = getUserFileObject(uploadType, newlyAddedImagePath, awsUserJson);
    uploadUserJson(userId, userObject.form_images, userObject.theme_images, function () {
        awsUserJson = userObject;
        successFunction(userObject)
    }, function () {
        failureFunction();
    });
}

export function deleteImagePathInUserFile(AWSUploadType, userId, deletedImagePath, deleteSuccessFunction) {
    if (AWSUploadType === AWS_UPLOAD_TYPE.THEME) {
        if (awsUserJson.theme_images) {
            let themeImages = awsUserJson.theme_images;
            let formImages;

            if (themeImages.length) {
                for (let i = 0; i < themeImages.length; i++) {
                    if (themeImages[i] === deletedImagePath) {
                        themeImages.splice(i, 1);
                        if (awsUserJson.form_images) {
                            formImages = awsUserJson.form_images;
                        } else {
                            formImages = [];
                        }
                        uploadUserJson(userId, formImages, themeImages, function () {
                            awsUserJson = {form_images: formImages, theme_images: themeImages};
                            deleteSuccessFunction(awsUserJson.theme_images);
                        }, function () {
                            alert('Image Delete Failed in Server !');
                        });
                        return;
                    }
                }
            }
        }
    }
    if (AWSUploadType === AWS_UPLOAD_TYPE.FORM) {
        if (awsUserJson.form_images) {
            let formImages = awsUserJson.form_images;
            let themeImages;

            if (formImages.length) {
                for (let i = 0; i < formImages.length; i++) {
                    if (formImages[i] === deletedImagePath) {
                        formImages.splice(i, 1);
                        if (awsUserJson.theme_images) {
                            themeImages = awsUserJson.theme_images;
                        } else {
                            themeImages = [];
                        }
                        uploadUserJson(userId, formImages, themeImages, function () {
                            awsUserJson = {form_images: formImages, theme_images: themeImages};
                            deleteSuccessFunction(awsUserJson.form_images);
                        }, function () {
                            alert('Image Delete Failed in Server !');
                        });
                        return;
                    }
                }
            }
        }
    }
}

/**
 *
 * @param {AWS_UPLOAD_TYPE} uploadType
 * @param {string} newlyAddedImagePath
 * @param  awsUserJson
 * @returns {{form_images: [], theme_images: []}}
 */
export function getUserFileObject(uploadType, newlyAddedImagePath, awsUserJson) {
    const userObject = {
        form_images: [],
        theme_images: []
    };

    if (!awsUserJson) {
        if (uploadType === AWS_UPLOAD_TYPE.FORM) {
            userObject.form_images = [newlyAddedImagePath];
            userObject.theme_images = [];
        } else if (uploadType === AWS_UPLOAD_TYPE.THEME) {
            userObject.form_images = [];
            userObject.theme_images = [newlyAddedImagePath];
        }

        return userObject
    }

    if (newlyAddedImagePath) {
        let updatedThemeImages
        let updatedFormImages


        if (awsUserJson.theme_images) {
            updatedThemeImages = awsUserJson.theme_images;
        }

        if (awsUserJson.form_images) {
            updatedFormImages = awsUserJson.form_images;
        }

        if (uploadType === AWS_UPLOAD_TYPE.FORM) {
            updatedFormImages.push(newlyAddedImagePath);
        } else if (uploadType === AWS_UPLOAD_TYPE.THEME) {
            updatedThemeImages.push(newlyAddedImagePath);
        }

        if (awsUserJson.form_images) {
            userObject.form_images = updatedFormImages;
        }

        if (awsUserJson.theme_images) {
            userObject.theme_images = updatedThemeImages;
        }

        return userObject;
    }
}
