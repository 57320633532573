import React, {useEffect, useRef, useState} from 'react'
import '../../dashboard/css/dashboard.css'
import remove from '../../../../public/images/delete_grey.svg'
import removeMobile from '../../../../public/images/close_circle_grey.svg'
import {isMobileOnly} from "react-device-detect";
import {MDCSelect} from "@material/select/index";
import {changeFormCollaboratorRole, removeFormCollaborator} from "../../../shared/utils/sash-v2-api";
import {COLLABORATOR_ACTION_TYPE} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {changeQuizCollaboratorRole, removeQuizCollaborator} from "../../../shared/utils/quiz-api-services";
import {COLLABORATOR_ROLES} from "../enums/collaboratorsRoles";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import Message from "../../../shared/containers/ConfirmMessage";
import ModalDialog from "../../../shared/components/ModalDialog";
import {registerGAEvent} from "../../../shared/utils/analytics";
import MaterialSelectOptionsContainer from "../../../shared/components/MaterialSelectOptionsContainer";

const CollaboratorRow = (props) => {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const [showAcknowledgement, setShowAcknowledgement] = useState(false)
    const [showDeleteCollabPopup, setShowDeleteCollabPopup] = useState(false)
    const [Role, setRole] = useState(props.role)
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const formId = useSelector(state => state.appReducer.formData?._id)
    const collaborator = useSelector(state => state.collaboratorsData?.collaborators)
    const dispatch = useDispatch()

    const getColor = (alphabet) => {
        let upperCaseAlphabet = alphabet.toUpperCase();
        const alphabets = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
        const colors = ['#FF8271', '#FF8660', '#FFAC60', '#E4B845', '#D7BB73', '#C9BC4A', '#C8DA5A', '#3FD7BB', '#57E8CE', '#42E0F5', '#9A9DE4', '#BCBEF3', '#C0A7F3', '#BB9BFF', '#BF9EE0', '#CE7AC6', '#D79CD1', '#D79CAD', '#EEA8BD', '#FF8DAF', '#DC86A0', '#DC8686', '#F19090', '#FF9D9D', '#FA8C8C', '#DF8181']
        for (let i = 0; i < 25; i++) {
            if (alphabets[i] === upperCaseAlphabet) {
                return colors[i];
            }
        }
        return '#005cb2'
    }

    const accountBadgeColor = getColor(props.userId ? props.userId[0] : '0')

    const handleSelection = (role) => {
        if (role !== Role) {
            setRole(role)
            setShowAcknowledgement(true)
            if (formId && !isQuiz) {
                registerGAEvent('Form-Builder', 'Settings', `changed-collab-role`)
                changeFormCollaboratorRole(userId, formId, props.collaboratorId, role === t(translationKeys.viewer) ? COLLABORATOR_ROLES.VIEWER : role === t(translationKeys.editor) ? COLLABORATOR_ROLES.EDITOR : COLLABORATOR_ROLES.ADMINISTRATOR, (res) => {
                    if (res.result) {
                        dispatch({
                            type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_ROLE,
                            payload: {collaborator_id: props.collaboratorId, role: role}
                        })
                    }
                }, (err) => {
                    console.log(err)
                })
            } else if (formId && isQuiz) {
                registerGAEvent('Quiz-Builder', 'Settings', `changed-collab-role`)
                changeQuizCollaboratorRole(formId, props.collaboratorId, role === t(translationKeys.viewer) ? COLLABORATOR_ROLES.VIEWER : role === t(translationKeys.editor) ? COLLABORATOR_ROLES.EDITOR : COLLABORATOR_ROLES.ADMINISTRATOR, (res) => {
                    if (res.result) {
                        dispatch({
                            type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_ROLE,
                            payload: {collaborator_id: props.collaboratorId, role: role}
                        })
                    }
                }, (err) => {
                    console.log(err)
                })
            } else {
                if (isQuiz) {
                    registerGAEvent('Quiz-Builder', 'Settings', `changed-collab-role`)
                } else {
                    registerGAEvent('Form-Builder', 'Settings', `changed-collab-role`)
                }
                dispatch({type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_ROLE, payload: role})
                collaborator?.map(collaborator => {
                    if (collaborator.user_id === props.userId) {
                        collaborator.role = role === t(translationKeys.viewer) ? COLLABORATOR_ROLES.VIEWER : role === t(translationKeys.editor) ? COLLABORATOR_ROLES.EDITOR : COLLABORATOR_ROLES.ADMINISTRATOR
                    }
                })
            }
        }
    }

    const removeCollaborator = () => {
        if (formId && !isQuiz) {
            registerGAEvent('Form-Dashboard', 'Collaboration', 'clicked-delete-collaborator');
            removeFormCollaborator(userId, formId, props.collaboratorId, (res) => {
                if (res.result) {
                    dispatch({type: COLLABORATOR_ACTION_TYPE.REMOVE_COLLABORATOR, payload: {user_id: props.userId}})
                }
            }, (err) => {
                console.log(err)
            })
        } else if (formId && isQuiz) {
            registerGAEvent('Quiz-Dashboard', 'Collaboration', 'clicked-delete-collaborator');
            removeQuizCollaborator( formId, props.collaboratorId, (res) => {
                if (res.result) {
                    dispatch({type: COLLABORATOR_ACTION_TYPE.REMOVE_COLLABORATOR, payload: {user_id: props.userId}})
                }
            }, (err) => {
                console.log(err)
            })
        } else {
            dispatch({type: COLLABORATOR_ACTION_TYPE.REMOVE_COLLABORATOR, payload: {user_id: props.userId}})
        }
    }

    return (
        <>
            {showDeleteCollabPopup ? <ModalDialog header={t(translationKeys.delete) +' '+ t(translationKeys.collaborator)}
                                                  body={[t(translationKeys.are_you_sure_you_want_delete_this_collaborator)]}
                                                  buttons={[{
                                                      text: t(translationKeys.cancel).toUpperCase(),
                                                      action: () => {
                                                          setShowDeleteCollabPopup(false)
                                                      }
                                                  }, {
                                                      text: t(translationKeys.delete).toUpperCase(),
                                                      action: () => {
                                                          removeCollaborator()
                                                          setShowDeleteCollabPopup(false)
                                                      }
                                                  }]}/> : null}
            {showAcknowledgement ? <Message width={'250px'} message={`${props.userId}'s ` + t(translationKeys.role_has_been_changed_successfully)} setShowAcknowledgement={setShowAcknowledgement}/> : null}
            <div id={'acc_logo_container'}
                 className={isMobileOnly ? "account-logo-container-mobile" : "account-logo-container"}
                 style={{width: '100%', justifyContent: 'flex-start', height: isMobileOnly ? 'auto' : '20%'}}
            >
                <div id={'acc-logo'} style={{
                    backgroundColor: accountBadgeColor,
                    margin: isMobileOnly ? '5px' : '0 15px',
                    width: isMobileOnly ? '20px' : null,
                    height: isMobileOnly ? '20px' : null
                }}
                     className={isMobileOnly ? 'acc-logo-mobile-collab' : "acc-logo-collab"}
                >
                    <span className={isMobileOnly ? 'profile-initial-mobile' : "profile-initial"}
                          style={{fontSize: isMobileOnly ? '14px' : null}}>
                        {props.userId ? props.userId[0].toUpperCase() : '0'}
                    </span>
                </div>
                <span id={'profile-user-id'} style={{
                    overflowWrap: 'anywhere',
                    color: '#6b6b6b',
                    fontSize: isMobileOnly ? '13px' : '18px',
                    width: '60%'
                }}>{props.userId}</span>

                {props.role ?
                    (props.role === t(translationKeys.admin) && !props.owner) ||
                    (props.role !== t(translationKeys.admin) && !props.owner && props.editor || props.viewer) ?
                        <span  style={{
                            color: '#9e9c9c',
                            fontSize: isMobileOnly ? '14px' : '20px',
                            margin: '2%',
                            fontStyle: 'italic'
                        }}>{props.role}</span> :
                        <>
                            <DropDown id={'user-role'} handleSelection={handleSelection} role={props.role} owner={props.owner}/>
                            <img alt={'remove'} style={{cursor: 'pointer', margin: '0 15px 0 0'}} src={isMobileOnly ? removeMobile : remove}
                                 onClick={() => setShowDeleteCollabPopup(true)}/>
                        </> :
                    <span style={{color: '#9e9c9c', fontSize: isMobileOnly ? '14px' : '21px', margin: '2%', fontStyle: 'italic'}}>{t(translationKeys.owner)}</span>}
            </div>
        </>
    )
}

const DropDown = (props) => {
    const {t} = useTranslation()
    const [position, setPosition] = useState({})
    const showPreviewPane = useSelector(state => state.builderReducer.showPreviewPane)

    const mdcSelectMenuRef = useRef(null);
    useEffect(() => {
        const select = new MDCSelect(mdcSelectMenuRef.current);
        if (props.initialValueToSelect !== undefined) {
            select.value = props.initialValueToSelect
        }
    }, [props.role])

    const selectorStyle = {
        '--mdc-theme-primary': '#212121',
        fontSize: '14px',
        width: '100%'
    }

    const selectTextStyle = {
        fontSize: isMobileOnly ? '14px' : '18px'
    }

    const selectValues = props.owner ? [t(translationKeys.admin), t(translationKeys.editor), t(translationKeys.viewer)] : [t(translationKeys.editor), t(translationKeys.viewer)]

    return (
        <div id={props.id} className={'mdc-select mdc-select--outlined'}
             onClick={e => {
                 if (e.clientY >= 450) {
                     setPosition({bottom: '350%'})
                 } else {
                     setPosition({bottom: '108%'})
                 }
             }}
             style={{color: '#212121', fontSize: '21px', background: 'transparent', width: isMobileOnly ? '25%' : showPreviewPane ? '20%' : '12%'}}
             ref={mdcSelectMenuRef}>
            <div className="mdc-select__anchor"
                 aria-labelledby="outlined-select-label"
                 style={{width: selectorStyle.width ? selectorStyle.width : null}}>
                <span className="mdc-select__selected-text-container">
                    <span className="mdc-select__selected-text" style={selectTextStyle}/>
                </span>
                <span className="mdc-select__dropdown-icon" style={{margin: isMobileOnly ? '0' : null}}>
                   <svg
                       className="mdc-select__dropdown-icon-graphic"
                       viewBox="7 10 10 5" focusable="false">
                     <polygon
                         className="mdc-select__dropdown-icon-inactive"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 10 12 15 17 10">
                     </polygon>
                     <polygon
                         className="mdc-select__dropdown-icon-active"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 15 12 10 17 15">
                     </polygon>
                   </svg>
                </span>
            </div>
            <div style={{
                position: 'absolute',
                top: position && position.top ? position.top : null,
                bottom: position && position.bottom ? position.bottom : null,
                right: position && position.right ? position.right : null,
                left: position && position.left ? position.left : null,
            }}>
                <MaterialSelectOptionsContainer selectValues={selectValues}
                                                selectTextStyle={selectTextStyle}
                                                handleSelection={props.handleSelection}
                                                initialValueToSelect={props.role}/>
            </div>
        </div>
    )
}

export default CollaboratorRow