export const sortFields = {
    DATE_CREATED: 'date_created',
    DATE_EDITED: 'date_edited',
    DATE_FAVOURED: 'date_favoured',
    TITLE: 'title'
};

export function sortForms(formList, sortOrder) {
    const descPropComparer = (propName) => (a, b) => a[propName] === b[propName] ? 0 : a[propName] < b[propName] ? 1 : -1;
    switch (sortOrder) {
        case sortFields.DATE_CREATED:
            formList?.sort(descPropComparer(sortFields.DATE_CREATED));
            break;
        case sortFields.DATE_EDITED:
            formList?.sort(descPropComparer(sortFields.DATE_EDITED));
            break;
        case sortFields.DATE_FAVOURED:
            formList?.sort(descPropComparer(sortFields.DATE_FAVOURED));
            break;
        case sortFields.TITLE:
            formList?.sort(ascTitlePropComparer);
            break;
        /* case sortFields.isPinned:
             break;*/
    }
    return formList;
}

function ascTitlePropComparer(a, b) {
    const a1 = a.welcome_screen['title'].trim();
    const b1 = b.welcome_screen['title'].trim();
    return ('' + a1).localeCompare(b1);
}