import React, {useEffect, useState} from 'react'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {TEMPLATE_ACTION_TYPE} from "../../reducers/templateReducer";
import back from "../../../../public/images/back_white.svg";
import {useSelector, useDispatch} from "react-redux";
import {templateTypes} from "./DashBoardContainer";
import {isMobileOnly} from "react-device-detect";
import {t} from "i18next";
import FormCard, {CARD_TYPE} from "./FormCard";
import {PAGE_HASH_LOCATION} from "../../utils/pages";
import SearchContainer from "../../../admin/containers/SearchContainer";
import {TemplateButtonsContainer} from "./TemplateFormsContainer";

const categories = []
const categoriesQuiz = []

const MobileTemplateContainer = (props) => {
    const [searchText, setSearchText] = useState("")
    const templateType = useSelector(state => state.appDashboardReducer.templateType)
    const currentQuizTemplate = useSelector(state => state.getCurrentQuizTemplateReducer.quizTemplate)
    const currentFormTemplate = useSelector(state => state.getCurrentFormTemplateReducer.formTemplate)
    const [selectedCategory, setSelectedCategory] = useState(templateType === templateTypes.FORM ? currentFormTemplate[0].category : currentQuizTemplate[0].category)
    const dispatch = useDispatch()

    useEffect(() => {
        window.location.hash = PAGE_HASH_LOCATION.templates;
        dispatch({type: TEMPLATE_ACTION_TYPE.SET_IS_SHOWING_FORMS, payload: true})
    }, [])

    useEffect(() => {
        if (props.homePage) {
            setSelectedCategory(templateType === templateTypes.FORM ? currentFormTemplate[0].category : currentQuizTemplate[0].category)
        }
    }, [templateType])

    window.onhashchange = () => {
        if (window.location.hash === PAGE_HASH_LOCATION.dashboard) {
            if (templateType === templateTypes.FORM) {
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})
            } else {
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_QUIZ})
            }
        }
    }

    let currentTemplate = templateType === templateTypes.FORM ? currentFormTemplate : currentQuizTemplate
    if (templateType === templateTypes.FORM) {
        let categoriesCount = []
        let categoryIndex
        for (let i = 0; i < currentTemplate.length; i++) {
            categoryIndex = categories.indexOf(currentTemplate[i].category)
            if (categoryIndex === -1) {
                categories.push(currentTemplate[i].category)
                categoriesCount.push(1)
            } else {
                categoriesCount[categoryIndex] = categoriesCount[categoryIndex] + 1
            }
        }
    } else {
        let categoriesCountQuiz = []
        let categoryIndex
        for (let i = 0; i < currentTemplate.length; i++) {
            categoryIndex = categoriesQuiz.indexOf(currentTemplate[i].category)
            if (categoryIndex === -1) {
                categoriesQuiz.push(currentTemplate[i].category)
                categoriesCountQuiz.push(1)
            } else {
                categoriesCountQuiz[categoryIndex] = categoriesCountQuiz[categoryIndex] + 1
            }
        }
    }

    const getFormsByCategory = (categoryTitle) => {
        let categorizedForms = [];
        let currentTemplate = templateType === templateTypes.FORM ? currentFormTemplate : currentQuizTemplate
        for (let i = 0; i < currentTemplate.length; i++) {
            if (currentTemplate[i].category === categoryTitle) {
                categorizedForms.push(currentTemplate[i]);
            }
        }
        return categorizedForms;
    }

    const handleTextChange = (text) => {
        setSearchText(text)
    }

    const handleKeyDown = () => {

    }


    return (<div className="template-forms-container cards-container-width">
        <div id={'dashboard-content-container'} className='dashboard-content-container'
             style={{
                 display: 'flex', flexFlow: 'column', alignItems: 'center', paddingBottom: isMobileOnly ? '50px' : null
             }}>
            {props.homePage ? <TemplateButtonsContainer homePage={props.homePage}/> : null}
            {props.homePage ? <div style={{width: "100%", margin: "20px", textAlign: "-webkit-center"}}>
                <SearchContainer handleTextChange={handleTextChange}
                                 width={"80%"}
                                 height={"30px"}
                                 handleKeyDown={handleKeyDown}/>
            </div> : null}
            <div style={{
                display: props.homePage && searchText ? "none" : "inline-grid",
                position: props.homePage ? "relative" : "fixed",
                zIndex: 2,
                backgroundColor: '#ffffff'
            }}>
                {props.homePage ? null : <TemplateHeaderContainer/>}
                <div className={"template-header"}>
                    {templateType === templateTypes.FORM ? React.Children.toArray(categories.map((categoryTitle) => {
                        return <span className={'template-header-button'}
                                     style={selectedCategory === categoryTitle ? {
                                         background: "#004ba0",
                                         color: "#ffffff",
                                         boxShadow: "#00000040 1px 1px 5px 1px"
                                     } : {
                                         background: "#f5f5f5",
                                         color: "#4d4d4d",
                                         boxShadow: "#00000040 1px 1px 5px 1px"
                                     }}
                                     onClick={() => setSelectedCategory(categoryTitle)}>
                            {categoryTitle}
                        </span>
                    })) : React.Children.toArray(categoriesQuiz.map((categoryTitle) => {
                        return <span className={'template-header-button'}
                                     style={selectedCategory === categoryTitle ? {
                                         background: "#004ba0",
                                         color: "#ffffff",
                                         boxShadow: "#00000040 1px 1px 5px 1px"
                                     } : {
                                         background: "#f5f5f5",
                                         color: "#4d4d4d",
                                         boxShadow: "#00000040 1px 1px 5px 1px"
                                     }}
                                     onClick={() => setSelectedCategory(categoryTitle)}>
                            {categoryTitle}
                        </span>
                    }))}
                </div>
            </div>
            <div style={{width: "100%", marginTop: props.homePage ? null : "25%"}}>
                {templateType === templateTypes.FORM ? (searchText ? currentFormTemplate.filter((template) => template?.welcome_screen?.title?.toLowerCase()?.includes(searchText?.toLowerCase())) : getFormsByCategory(selectedCategory)).map((formData, index) => {
                    return (
                        <FormCard key={index}
                                  homePage={props.homePage}
                                  CARD_TYPE={CARD_TYPE.TEMPLATE_FORM}
                                  cardIndex={index}
                                  previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                  formData={formData}/>
                    )
                }) : (searchText ? currentQuizTemplate.filter((template) => template?.welcome_screen?.title?.toLowerCase()?.includes(searchText?.toLowerCase())) : getFormsByCategory(selectedCategory)).map((formData, index) => {
                    return (
                        <FormCard key={index}
                                  homePage={props.homePage}
                                  CARD_TYPE={CARD_TYPE.TEMPLATE_FORM}
                                  cardIndex={index}
                                  previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                  formData={formData}/>
                    )
                })}
            </div>
        </div>
    </div>)
}

export default MobileTemplateContainer

const TemplateHeaderContainer = () => {
    const templateType = useSelector(state => state.appDashboardReducer.templateType)
    const dispatch = useDispatch()

    return (
        <div style={{width: '100%', height: '45px', display: 'flex', backgroundColor: '#1976d2', color: 'white'}}>
            <img src={back} alt={'back icon'}
                 onClick={() => dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})}
                 style={{width: '20px', height: '15px', margin: '15px'}}/>
            <h3 style={{
                alignItems: 'center',
                display: 'flex',
                margin: "auto 25%",
                whiteSpace: "nowrap",
                color: "white",
                fontSize: "20px"
            }}>
                {templateType === templateTypes.FORM ? (t(translationKeys.form) + ' ' + t(translationKeys.templates)) : (t(translationKeys.quiz) + ' ' + t(translationKeys.templates))}
            </h3>
        </div>
    )
}
