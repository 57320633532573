import React, {useState} from 'react'
import {isMobileOnly} from "react-device-detect";
import back from "../../../../public/images/back_white.svg";
import {TABS_DATA} from "./SharesContainer";
import style from "../../../shared/css/add-collaboration-popup.module.css";
import tick from "../../../../public/images/tick_green.svg";
import cross from "../../../../public/images/cross_red.svg";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

const MobileCollabRolesScreen = (props) => {
    const isQuiz = useSelector(state => state.appReducer?.isQuiz)
    const selectedLanguage = useSelector(state => state.languageReducer?.selectedLanguage)
    const [isQuizTab, setIsQuizTab] = useState(isQuiz)

    const tamilAndMalayalamStyle = {
        rolePopUpTableHeading: {
            padding: '5px',
            fontSize: '11px'
        },
        rolePopUpTableRowSpan: {
            fontSize: '10px'
        }
    }

    return (
        <div id={'dashboard-content-container-mobile'} className={'dashboard-content-container-mobile'}
             style={{height: '100%'}}>
            <div id={'mobile-header-tab-row'} className='mobile-header-tab-row'>
                <div style={{
                    display: 'flex',
                    height: '100%',
                    width: isMobileOnly ? '100%' : null,
                    paddingTop: isMobileOnly ? '10px' : null
                }}>
                    <img src={back} alt={'back icon'}
                         onClick={() => props.setShowRolesPopUp(false)}
                         style={{width: '20px', height: '15px', margin: '13px 10px'}}/>
                    <div
                        className={'header-button center'} style={{justifyContent: 'flex-start', width: '100%'}}>
                                    <span
                                        style={{
                                            fontSize: '18px',
                                            color: 'white',
                                            paddingBottom: '7px',
                                            fontWeight: '400'
                                        }}>
                                        {t(translationKeys.roles_rights) }
                                    </span>
                    </div>
                </div>
            </div>
            <ButtonsContainer setIsQuizTab={setIsQuizTab} selected={isQuiz ? TABS_DATA.QUIZ : TABS_DATA.FORM}/>
            <div className={style.rolePopUp}>
                {isQuizTab ? <table style={{border: "hidden"}}>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}></th>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.admin)}</th>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.editor)}</th>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.viewer)}</th>
                    </tr>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.collaborator)}</th>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.view)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.add)}/{t(translationKeys.remove)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.change_roles)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.quizzes)}</th>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.edit)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.publish)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.delete)}</span>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.answer_s)}</th>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.view)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.download)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.edit)} {t(translationKeys.marks)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.delete)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                </table> : <table style={{border: "hidden"}}>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}></th>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.admin)}</th>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.editor)}</th>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.viewer)}</th>
                    </tr>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.collaborator)}</th>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.view)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.add)}/{t(translationKeys.remove)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.change_roles)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.forms)}</th>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.edit)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.delete)}</span>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                    <tr style={{color: '#004BA0'}}>
                        <th style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableHeading : null}>{t(translationKeys.responses)}</th>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.view)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.download)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                    </tr>
                    <tr>
                        <span style={(isMobileOnly && (selectedLanguage === 'ta' || selectedLanguage === 'ml' || selectedLanguage === 'fr')) ? tamilAndMalayalamStyle.rolePopUpTableRowSpan : null}>{t(translationKeys.delete)}</span>
                        <td><img alt={'tick/cross'} src={tick}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                        <td><img alt={'tick/cross'} src={cross}/></td>
                    </tr>
                </table>}
            </div>
        </div>
    )
}

export default MobileCollabRolesScreen

const ButtonsContainer = (props) => {
    const buttonContainerStyle = {width: '100%', display: isMobileOnly ? 'block' : 'flex', justifyContent: 'center'};
    const form = {title: TABS_DATA.FORM, selected: props.selected === TABS_DATA.FORM}
    const quiz = {title: TABS_DATA.QUIZ, selected: props.selected === TABS_DATA.QUIZ}
    const [containerTabItems, setContainerTabItems] = useState([form, quiz])

    const updateTabContainer = clickedItem => {
        if (clickedItem.title === containerTabItems.filter(tabItem => tabItem.selected)[0].title) {
            return
        }

        // Deselect previous Tab Item and Update Current Selection
        setContainerTabItems(oldItems => {
            const newItems = [...oldItems]

            newItems.forEach((tabItem) => {
                    tabItem.selected = tabItem.title === clickedItem.title
                }
            )

            return newItems
        })
        props.setIsQuizTab(clickedItem.title === TABS_DATA.QUIZ)
    }

    return <div style={buttonContainerStyle}>
        <div id={'button-container-mobile-header-row'} className='mobile-header-tab-row'>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                height: '100%',
                width: isMobileOnly ? '100%' : null,
                paddingTop: isMobileOnly ? '10px' : null
            }}>
                {
                    containerTabItems.map((tabItem, index) => (
                        () => {
                            return (
                                <div
                                    id={index}
                                    key={index}
                                    className={'header-button center'}
                                    style={{width: '50%', borderBottom: '1px solid transparent'}}
                                    onClick={() => updateTabContainer(tabItem)}>
                                        <span id={'mobile-header-button'}
                                            className={tabItem.selected ? 'mobile-header-tab-row-item-settings mobile-header-tab-row-shared-item-clicked-settings' : 'mobile-header-tab-row-item-settings'}
                                            style={{width: '100%', textAlign: "center", height: '3.5vh'}}>
                                            {tabItem.title === TABS_DATA.FORM ? t(translationKeys.forms) : t(translationKeys.quizzes)}
                                        </span>
                                </div>
                            )
                        }
                    )())
                }
            </div>
        </div>
    </div>
}