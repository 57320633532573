import React, {useState} from 'react'
import PropTypes from "prop-types";
import MaterialTextFieldOutlined from "../../shared/components/MaterialTextFieldOutlined";

const MaterialTextFieldOutlinedContainer = props => {
    const [showHelperText, setShowHelperText] = useState(false)

    const handleValidation = value => {
        if (props.validationFunction) {
            setShowHelperText(!props.validationFunction(value))
        }
    }
    const handleChange = value => {
        handleValidation(value)
        if (props.onChange) props.onChange(value)
    }
    const handleOnBlur = value => {
        handleValidation(value)
        if (props.onBlur) props.onBlur(value)
    }

    return (
        <MaterialTextFieldOutlined placeHolder={props.placeHolder} onChange={handleChange}
                                   onBlur={handleOnBlur}
                                   readOnly={props.readOnly}
                                   onFocus={props.onFocus}
                                   defaultValue={props.defaultValue}
                                   customStyleObject={props.customStyleObject}
                                   textStyleObject={props.textStyleObject}
                                   maxLength={props.maxLength}
                                   textArea={props.textArea}
                                   autoFocus={props.autoFocus}
                                   hasValidation={!!props.validationFunction} inputType={props.inputType}
                                   showError={showHelperText} errorText={props.errorText}/>
    )
}
export default MaterialTextFieldOutlinedContainer

MaterialTextFieldOutlinedContainer.propTypes = {
    customStyleObject: PropTypes.object,
    // helperStyleObject: PropTypes.object,
    defaultValue: PropTypes.string,
    errorText: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    placeHolder: PropTypes.string,
    validationFunction: PropTypes.func,
    textarea: PropTypes.bool,
    readOnly: PropTypes.bool
}

MaterialTextFieldOutlinedContainer.defaultProps = {
    errorText: 'Error',
    placeHolder: 'Name',
    readOnly: false
}