import React from "react"
import "../css/rc-policy.css"

const RCPolicy = () => {
    return (
        <div className="rc_body_content">
            <h2 className="company_name">SURVEYHEART</h2>
            <h1 className="title">REFUND POLICY</h1>
            <div className="content">
                <p>At SurveyHeart, we strive to deliver top-quality service to our customers. Please read our refund
                    policy
                    carefully before making a purchase.
                </p>
                <h2 className="content_title">1. No Refunds</h2>
                <p>Once a subscription to any of our paid plans is made, the amount paid is non-refundable. This policy
                    applies
                    to all subscription levels, add-ons, and custom services offered by SurveyHeart.
                </p>
                <h2 className="content_title">2. Subscription Cancellations</h2>
                <p>You may cancel your subscription at any time, but please note that no refunds will be provided for
                    any unused
                    portion of the subscription. After cancellation, your service will remain active until the end of
                    your
                    current billing period.
                </p>
                <h2 className="content_title">3. Exceptions</h2>
                <p>In very rare cases, such as billing errors or duplicate charges, refunds within 5-7 business days may be
                    considered at the discretion of SurveyHeart. If you believe there has been an error with your billing,
                    please contact us immediately at support@surveyheart.com.
                </p>
                <h2 className="content_title">4. How to Request a Review</h2>
                <p>If you believe your situation warrants an exception, please reach out to our support team at
                    support@surveyheart.com with the following details:
                    <ul>
                        <li>Your account email address.</li>
                        <li>The date of purchase.</li>
                        <li>A detailed explanation of the issue.</li>
                    </ul>
                    Our team will review your request and respond within 5-7 business days. Please be aware that
                    approval of such
                    requests is rare and will be handled on a case-by-case basis.
                </p>
                <h2 className="content_title">5. Changes to this Refund Policy</h2>
                <p>SurveyHeart reserves the right to modify this refund policy at any time. Changes to the policy will
                    be
                    communicated through our website, and the updated policy will be effective immediately.
                    If you have any questions or concerns regarding this refund policy, please contact us at
                    <a href="mailto:support@surveyheart.com"> support@surveyheart.com</a>.
                </p>
            </div>
            <h2 className="company_name">SURVEYHEART</h2>
            <h1 className="title">CANCELLATION POLICY</h1>
            <div className="content">
                <p>At SurveyHeart, we understand that circumstances may change, and you may need to cancel your
                    subscription.
                    This policy outlines the steps and conditions for canceling your subscription, ensuring a clear
                    understanding of what happens when you choose to do so.
                </p>
                <h2 className="content_title">1. Subscription Cancellation Process</h2>
                <p>Customers can cancel their subscription at any time through the following steps:
                    <ul>
                        <li><b>Access Your Account:</b> Log in to your SurveyHeart account using your credentials.</li>
                        <li><b>Navigate to Subscription Settings:</b> Once logged in, go to the “Billing” or
                            “Subscription” section
                            within your account settings. This section provides an overview of your current subscription
                            details,
                            including the plan you are on and your billing cycle.
                        </li>
                        <li><b>Initiate the Cancellation:</b> In the Subscription Settings, you will find an option to
                            “Cancel
                            Subscription.” Clicking this will start the cancellation process.
                        </li>
                        <li><b>Confirmation of Cancellation:</b> After selecting “Cancel Subscription,” a confirmation
                            prompt will
                            appear. You may be asked to confirm your decision or provide feedback on why you are
                            canceling. Once
                            confirmed, your subscription will be marked for cancellation at the end of the current
                            billing cycle.
                        </li>
                    </ul>
                </p>
                <h2 className="content_title">2. Effective Date of Cancellation</h2>
                <p>
                    <ul>
                        <li><b>Current Billing Cycle:</b> The cancellation will only take effect at the end of your
                            current billing
                            cycle. During this period, you will continue to have access to all the features and services
                            included in
                            your subscription plan.
                        </li>
                        <li><b>No Auto-Renewal:</b> Once the billing cycle is completed, your subscription will not be
                            auto-renewed.
                            You will receive a notification confirming the end of your subscription, and access to paid
                            features
                            will cease at that point.
                        </li>
                    </ul>
                </p>
                <h2 className="content_title">3. Impact on Your Account</h2>
                <p>
                    <ul>
                        <li><b>Continued Service Until End of Billing Cycle:</b> Even after initiating the cancellation,
                            you can
                            continue using all premium features and services until your current billing cycle ends. This
                            allows you
                            to make full use of your subscription without any interruptions.
                        </li>
                        <li><b>Account Downgrade:</b> After the billing cycle ends and your subscription is not renewed,
                            your
                            account will automatically revert to the free plan, if available. You will still have access
                            to your
                            account, but the features will be limited based on the free plan offerings.
                        </li>
                    </ul>
                </p>
                <h2 className="content_title">4. No Prorated Refunds</h2>
                <p>
                    SurveyHeart does not offer prorated refunds for any remaining time in your billing cycle after you
                    initiate
                    a cancellation. This means that even if you cancel your subscription partway through the billing
                    cycle, you
                    will not receive a refund for the unused portion of the subscription period. Your access to the
                    service will
                    continue until the end of the billing cycle.
                </p>
                <h2 className="content_title">5. Subscription Reactivation</h2>
                <p>
                    <ul>
                        <li><b>Reactivation:</b> If you change your mind and wish to continue your subscription, you can
                            reactivate
                            it at any time before the current billing cycle ends. Simply log in to your account,
                            navigate to the
                            subscription settings, and select the subscribe option.
                        </li>
                        <li><b>New Subscription:</b> If your subscription has already ended, you can start a new
                            subscription by
                            selecting a plan from the available options on our website.
                        </li>
                    </ul>
                </p>
                <h2 className="content_title">6. Customer Support</h2>
                <p>If you encounter any issues during the cancellation process or have any questions about your
                    subscription,
                    our customer support team is here to help. You can reach us at support@surveyheart.com. We aim to
                    respond to
                    all inquiries within 2 - 4 business days.
                </p>
                <h2 className="content_title">7. Changes to this Cancellation Policy</h2>
                <p>SurveyHeart reserves the right to modify or update this cancellation policy at any time. Any changes
                    to the
                    policy will be posted on our website and will take effect immediately. We encourage you to review
                    this
                    policy periodically to stay informed about how we handle subscription cancellations.
                </p>
                <p>If you have any questions or concerns regarding this cancellation policy, please contact us at
                    <a href="mailto:support@surveyheart.com"> support@surveyheart.com</a>.
                </p>
                <h3>Thank You!</h3>
            </div>
        </div>
    )
}

export default RCPolicy
