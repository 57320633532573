import formAutocompleteSuggestions from "../../../public/json/templates/sh_templates_en.json"
import quizAutocompleteSuggestions from "../../../public/json/templates/quiz_templates_en.json"
import {BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE, BUILDER_TYPE} from "../../shared/utils/constants"

export const AUTOCOMPLETE_ACTION_TYPE = {
    CLEAR_SELECTED_VALUE: 'CLEAR_SELECTED_VALUE',
    CLEAR_SUGGESTIONS: 'CLEAR_SUGGESTIONS',
    SEARCH: 'SEARCH',
    IMPORT_QUESTION_SEARCH: 'IMPORT_QUESTION_SEARCH',
    SET_BUILDER_TYPE: 'SET_BUILDER_TYPE',
    SET_CURRENT_TEXT_BOX_INDEX: 'SET_CURRENT_TEXT_BOX_INDEX',
    SET_SELECTED_VALUE: 'SET_SELECTED_VALUE',
    STORE_QUESTION_AUTOCOMPLETE_COORDINATES: 'STORE_QUESTION_AUTOCOMPLETE_COORDINATES',
    STORE_NEW_QUESTIONS_IN_LOCAL_STORAGE: 'STORE_NEW_QUESTIONS_IN_LOCAL_STORAGE',
    SUGGESTION_CLICKED: 'SUGGESTION_CLICKED'
}

const locallyStoredFormQuestion = window && window.localStorage && window.localStorage?.getItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.FORM_QUESTION.key)
    ? JSON.parse(window.localStorage?.getItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.FORM_QUESTION.key)) : null;
const locallyStoredQuizQuestion = window && window.localStorage && window.localStorage?.getItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.QUIZ_QUESTION.key)
    ? JSON.parse(window.localStorage?.getItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.QUIZ_QUESTION.key)) : null;
let initialState = {
    builderType: null,
    currentTextBoxIndex: -1,
    formSuggestionsFromLocalStorage: locallyStoredFormQuestion,
    leftPos: -1,
    matches: [],
    quizSuggestionsFromLocalStorage: locallyStoredQuizQuestion,
    selectedValue: '',
    suggestions: [],
    suggestionClicked: false,
    width: -1
}

const getFormSuggestions = (localStoreItems, importQuestion) => {
    const allFormSuggestedQuestions = []

    if (localStoreItems) {
        localStoreItems.map(localStorageItem => {
                allFormSuggestedQuestions.push(localStorageItem.question)
            }
        )
    }

    if (!importQuestion) {
        for (let ctr = 0; ctr < formAutocompleteSuggestions.length - 1; ctr++) {
            formAutocompleteSuggestions[ctr].pages[0].questions.map(newQ => {
                    if (!allFormSuggestedQuestions.find(existingQs => existingQs.toLowerCase() === newQ.title.toLowerCase())) {
                        allFormSuggestedQuestions.push(newQ.title)
                    }
                }
            )
        }
    }

    return allFormSuggestedQuestions
}

const getQuizSuggestions = (localStoreItems = null, importQuestion) => {
    const suggestionsFromLocalStorage = localStoreItems === null ? initialState.quizSuggestionsFromLocalStorage : localStoreItems
    const allQuizSuggestedQuestions = []

    if (suggestionsFromLocalStorage) {
        suggestionsFromLocalStorage.map(localStorageItem => {
                allQuizSuggestedQuestions.push(localStorageItem.question)
            }
        )
    }

    if (!importQuestion) {
        for (let ctr = 0; ctr < quizAutocompleteSuggestions.length - 1; ctr++) {
            quizAutocompleteSuggestions[ctr].pages[0].questions.map(newQ => {
                    if (!allQuizSuggestedQuestions.find(existingQs => existingQs.toLowerCase() === newQ.title.toLowerCase())) {
                        allQuizSuggestedQuestions.push(newQ.title)
                    }
                }
            )
        }
    }

    // Quiz Suggestions are not initially populated from a Template File as Forms are.
    return allQuizSuggestedQuestions
}

const autocompleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case AUTOCOMPLETE_ACTION_TYPE.CLEAR_SELECTED_VALUE:
            return {
                ...state,
                selectedValue: ''
            }
        case AUTOCOMPLETE_ACTION_TYPE.CLEAR_SUGGESTIONS:
            return {
                ...state,
                matches: []
            }
        case AUTOCOMPLETE_ACTION_TYPE.SET_BUILDER_TYPE:
            if (action.payload === BUILDER_TYPE.FORM) {
                return {
                    ...state,
                    suggestions: getFormSuggestions(state.formSuggestionsFromLocalStorage),
                    importQuestionSuggestions: getFormSuggestions(state.formSuggestionsFromLocalStorage, true),
                    builderType: action.payload
                }
            } else {
                // action.payload === BUILDER_TYPE.QUIZ
                return {
                    ...state,
                    suggestions: getQuizSuggestions(state.quizSuggestionsFromLocalStorage),
                    importQuestionSuggestions: getQuizSuggestions(state.quizSuggestionsFromLocalStorage, true),
                    builderType: action.payload
                }
            }
        case AUTOCOMPLETE_ACTION_TYPE.SET_CURRENT_TEXT_BOX_INDEX:
            return {
                ...state,
                currentTextBoxIndex: action.payload
            }
        case AUTOCOMPLETE_ACTION_TYPE.SET_SELECTED_VALUE:
            return {
                ...state,
                selectedValue: action.payload
            }
        case AUTOCOMPLETE_ACTION_TYPE.SEARCH:
            return {
                ...state,
                matches: state.suggestions.filter(f => f.toLowerCase().includes(action.payload.toLowerCase()))
            }
        case AUTOCOMPLETE_ACTION_TYPE.IMPORT_QUESTION_SEARCH:
            return {
                ...state,
                matches: state.importQuestionSuggestions.filter(f => f.toLowerCase().includes(action.payload.toLowerCase()))
            }
        case AUTOCOMPLETE_ACTION_TYPE.STORE_QUESTION_AUTOCOMPLETE_COORDINATES:
            return {
                ...state,
                leftPos: action.payload.leftPos,
                width: action.payload.width
            }
        case AUTOCOMPLETE_ACTION_TYPE.STORE_NEW_QUESTIONS_IN_LOCAL_STORAGE:
            // Save New Questions in Local Storage
            if (state.builderType === BUILDER_TYPE.FORM) {
                let localStorageItems = state.formSuggestionsFromLocalStorage === null ? [] : state.formSuggestionsFromLocalStorage

                action.payload.map((newQ) => {
                    let localStorageItem = {
                        question: newQ
                    }

                    localStorageItems.push(localStorageItem)
                })

                localStorage.setItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.FORM_QUESTION.key, JSON.stringify(localStorageItems))

                return {
                    ...state,
                    formSuggestionsFromLocalStorage: localStorageItems,
                    suggestions: getFormSuggestions(localStorageItems)
                }
            } else {
                // state.builderType === BUILDER_TYPE.QUIZ
                let localStorageItems = state.quizSuggestionsFromLocalStorage === null ? [] : state.quizSuggestionsFromLocalStorage

                action.payload.map((newQ) => {
                    let localStorageItem = {
                        question: newQ
                    }

                    localStorageItems.push(localStorageItem)
                })

                localStorage.setItem(BUILDER_SUGGESTION_TYPES_FOR_LOCAL_STORAGE.QUIZ_QUESTION.key, JSON.stringify(localStorageItems))

                return {
                    ...state,
                    quizSuggestionsFromLocalStorage: localStorageItems,
                    suggestions: getQuizSuggestions(localStorageItems)
                }
            }
        case AUTOCOMPLETE_ACTION_TYPE.SUGGESTION_CLICKED:
            return {
                ...state,
                suggestionClicked: action.payload
            }
        default:
            return state
    }
}

export default autocompleteReducer
