import useFeatureSpotlightDialogMgr from "../../hooks/useFeatureSpotlightDialogMgr"
import {useState} from "react";
import ImageViewerComponent from "../../../form/components/questions/ImageViewerComponent";

export const MobileFeatureSpotlightDialog = () => {
    const [imageViewer, setImageViewer] = useState(false)
    const {
        React,
        close,
        featureSpotlightBulb,
        featureSpotlightImageOverlay,
        FEATURE_SPOTLIGHT_ACTION_TYPE,
        newFeatures,
        reduxDispatch,
        registerGAEvent,
        selectedLanguage,
        t,
        translationKeys,
    } = useFeatureSpotlightDialogMgr()

    const popUpStyle = {
        position: "fixed",
        height: "100%",
        width: "100%",
        backgroundColor: '#fff',
        top: "0",
        left: "0",
        overflow: 'auto'
    }

    const showFeatureDetails = (feature, showDividerLine, index) => {
        return <>
            {/*<div className={'feature-spotlight-row-date'}>*/}
            {/*    {*/}
            {/*        (() => {*/}
            {/*            if (selectedLanguage === 'fa' || selectedLanguage === 'ar') {*/}
            {/*                return `\u202A${feature.date}\u202C`*/}
            {/*            }*/}

            {/*            return feature.date*/}
            {/*        })()*/}
            {/*    }*/}
            {/*</div>*/}
            <div className={'feature-spotlight-row-title'}>
                {feature.title}
            </div>
            <div className={'feature-spotlight-row-description'}>
                {feature.description}
            </div>
            <div onClick={() => {
                if (feature.videoLink) {
                    open(feature.videoLink)
                } else {
                    setImageViewer(feature.thumbnail)
                }
            }}>
                <div style={{position: "relative", display: "flex", justifyContent: "center"}}
                     onClick={() => {
                         if (feature.videoLink) {
                             open(feature.videoLink)
                         } else {
                             setImageViewer(feature.thumbnail)
                         }
                     }}>
                    <img
                        id={`image_thumbnail_${index}`}
                        style={{
                            position: "relative",
                            width: "-webkit-fill-available",
                            borderRadius: '5px',
                            visibility: feature.thumbnail === '' ? 'hidden' : 'visible'
                        }}
                        src={feature.thumbnail} alt={'new feature'}
                    />
                    {feature.videoLink ? <img
                        id={`image_overlay_${index}`}
                        style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)'
                        }}
                        src={featureSpotlightImageOverlay} alt={'new feature'}
                    /> : null}
                </div>
            </div>
            {
                (() => {
                    if (showDividerLine === true) {
                        return (
                            <div className="feature-spotlight-line-div"/>
                        )
                    }
                })()
            }
        </>
    }

    return (
        <div style={popUpStyle}>
            <div className={'feature-spotlight-header'}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <img
                        src={featureSpotlightBulb} alt={'light bulb'}
                    />
                    <div className={'feature-spotlight-header-text'}>
                        {t(translationKeys.feature_spotlight)}
                    </div>
                </div>
                <img onClick={() => {
                    registerGAEvent('Feature Spotlight', 'pop-up', 'close')
                    reduxDispatch({
                        type: FEATURE_SPOTLIGHT_ACTION_TYPE.TOGGLE_VISIBILITY
                    })
                }}
                     className={'feature-spotlight-close-img'}
                     src={close} alt={t(translationKeys.close)}
                />
            </div>
            <div style={{
                position: 'relative',
                backgroundColor: 'white',
                paddingTop: '5px',
                paddingLeft: '15px',
                paddingRight: '15px',
                paddingBottom: '10px',
                top: '50px'
            }}>
                {
                    newFeatures?.map((feature, index) => {
                        let showDividerLine = index < (newFeatures.length - 1)

                        return (
                            <div key={index}>
                                {
                                    showFeatureDetails(feature, showDividerLine, index)
                                }
                            </div>
                        )
                    })
                }
            </div>
            {imageViewer ? <ImageViewerComponent attachment={imageViewer} close={() => setImageViewer(false)}/> : null}
        </div>
    )
}

export default MobileFeatureSpotlightDialog