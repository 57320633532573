const initialState = {
    quizId: -1,
    deletableQuizId: null,
    quizQuickSetting: null,
    showDeletePopUp: false,
    lastQuizzesSortedBy: null,
    showQuizQuickSettings: false,
    showMultipleDeleteOption: false,
    multipleQuizzesSelectedArray: []
}

export const QUIZZES_ACTION_TYPE = {
    SET_QUIZ_ID: 'SET_QUIZ_ID',
    SET_DELETABLE_QUIZ_ID: 'SET_DELETABLE_QUIZ_ID',
    SET_QUIZ_QUICK_SETTING: 'SET_QUIZ_QUICK_SETTING',
    SET_SHOW_DELETE_POP_UP: 'SET_SHOW_DELETE_POP_UP',
    SET_LAST_QUIZZES_SORTED_BY: 'SET_LAST_QUIZZES_SORTED_BY',
    SET_SHOW_QUIZ_QUICK_SETTINGS: 'SET_SHOW_QUIZ_QUICK_SETTINGS',
    SET_SHOW_MULTIPLE_DELETE_OPTION: 'SET_SHOW_MULTIPLE_DELETE_OPTION',
    SET_MULTIPLE_QUIZZES_SELECTED_ARRAY: 'SET_MULTIPLE_QUIZZES_SELECTED_ARRAY'
}

const quizzesReducer = (state = initialState, action) => {
    switch (action.type) {
        case (QUIZZES_ACTION_TYPE.SET_LAST_QUIZZES_SORTED_BY):
            return {
                ...state,
                lastQuizzesSortedBy: action.payload
            }
        case (QUIZZES_ACTION_TYPE.SET_SHOW_QUIZ_QUICK_SETTINGS):
            return {
                ...state,
                showQuizQuickSettings: action.payload
            }
        case (QUIZZES_ACTION_TYPE.SET_QUIZ_QUICK_SETTING):
            return {
                ...state,
                quizQuickSetting: action.payload
            }
        case (QUIZZES_ACTION_TYPE.SET_QUIZ_ID):
            return {
                ...state,
                quizId: action.payload
            }
        case (QUIZZES_ACTION_TYPE.SET_DELETABLE_QUIZ_ID):
            return {
                ...state,
                deletableQuizId: action.payload
            }
        case (QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP):
            return {
                ...state,
                showDeletePopUp: action.payload
            }
        case (QUIZZES_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION):
            return {
                ...state,
                showMultipleDeleteOption: action.payload
            }
        case (QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY):
            return {
                ...state,
                multipleQuizzesSelectedArray: action.payload
            }
        default:
            return state
    }
}

export default quizzesReducer