const initialState = {
    screenName: localStorage?.getItem('lastAdminScreen') ? localStorage?.getItem('lastAdminScreen') : 'Dashboard',
    showAcknowledgement: false,
    showAdminSummary: false,
    showFormDetails: false,
    showBottomBar: false,
    showUserPopUp: false,
    showAdminMorePopUp: false,
    message: ''
}

export const SCREEN_NAME_ACTION_TYPE = {
    SET_MESSAGE: 'SET_MESSAGE',
    SET_SCREEN_NAME: 'SET_SCREEN_NAME',
    SET_SHOW_BOTTOM_BAR: 'SET_SHOW_BOTTOM_BAR',
    SET_SHOW_USER_POPUP: 'SET_SHOW_USER_POPUP',
    SET_SHOW_FORM_DETAILS: 'SET_SHOW_FORM_DETAILS',
    SET_SHOW_ADMIN_SUMMARY: 'SET_SHOW_ADMIN_SUMMARY',
    SET_SHOW_ACKNOWLEDGEMENT: 'SET_SHOW_ACKNOWLEDGEMENT',
    SET_SHOW_ADMIN_MORE_POPUP: 'SET_SHOW_ADMIN_MORE_POPUP'
}

const screenNameReducer = (state = initialState, action) => {
    switch (action.type) {
        case (SCREEN_NAME_ACTION_TYPE.SET_SHOW_ADMIN_SUMMARY):
            return {
                ...state,
                showAdminSummary: action.payload
            }
        case (SCREEN_NAME_ACTION_TYPE.SET_SHOW_BOTTOM_BAR):
            return {
                ...state,
                showBottomBar: action.payload
            }
        case (SCREEN_NAME_ACTION_TYPE.SET_SCREEN_NAME):
            return {
                ...state,
                screenName: action.payload
            }
        case (SCREEN_NAME_ACTION_TYPE.SET_SHOW_USER_POPUP):
            return {
                ...state,
                showUserPopUp: action.payload
            }
        case (SCREEN_NAME_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT):
            return {
                ...state,
                showAcknowledgement: action.payload
            }
        case (SCREEN_NAME_ACTION_TYPE.SET_MESSAGE):
            return {
                ...state,
                message: action.payload
            }
        case (SCREEN_NAME_ACTION_TYPE.SET_SHOW_FORM_DETAILS):
            return {
                ...state,
                showFormDetails: action.payload
            }
        case (SCREEN_NAME_ACTION_TYPE.SET_SHOW_ADMIN_MORE_POPUP):
            return {
                ...state,
                showAdminMorePopUp: action.payload
            }
        default:
            return state
    }
}

export default screenNameReducer