const initialState = {
    deletableFormId: null,
    showDeletePopUp: false,
    lastFormsSortedBy: null,
    multipleFormsSelectedArray: [],
    showMultipleDeleteOption: false
}

export const FORMS_ACTION_TYPE = {
    SET_DELETABLE_FORM_ID: 'SET_DELETABLE_FORM_ID',
    SET_SHOW_DELETE_POP_UP: 'SET_SHOW_DELETE_POP_UP',
    SET_LAST_FORMS_SORTED_BY: 'SET_LAST_FORMS_SORTED_BY',
    SET_SHOW_MULTIPLE_DELETE_OPTION: 'SET_SHOW_MULTIPLE_DELETE_OPTION',
    SET_MULTIPLE_FORMS_SELECTED_ARRAY: 'SET_MULTIPLE_FORMS_SELECTED_ARRAY'
}

const formsReducer = (state = initialState, action) => {
    switch (action.type) {
        case (FORMS_ACTION_TYPE.SET_LAST_FORMS_SORTED_BY):
            return {
                ...state,
                lastFormsSortedBy: action.payload
            }
        case (FORMS_ACTION_TYPE.SET_DELETABLE_FORM_ID):
            return {
                ...state,
                deletableFormId: action.payload
            }
        case (FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP):
            return {
                ...state,
                showDeletePopUp: action.payload
            }
        case (FORMS_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION):
            return {
                ...state,
                showMultipleDeleteOption: action.payload
            }
        case (FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY):
            return {
                ...state,
                multipleFormsSelectedArray: action.payload
            }
        default:
            return state
    }
}

export default formsReducer