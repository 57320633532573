export function getDateWithTime(time) {
    let formattedDate;
    let formattedTime;
    let hour;
    let timeStamp;
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    if (time) {
        let date = new Date(parseInt(time));
        let isAfterNoon = date.getHours() > 12;
        if (date.getHours() >= 12) {
            timeStamp = ' PM'
        } else {
            timeStamp = ' AM'
        }
        if (isAfterNoon) {
            hour = date.getHours() - 12
        } else {
            hour = date.getHours()
        }
        formattedDate = date.getDate() + ' ' + month[date.getMonth()] + ' ' + date.getFullYear();
        formattedTime = ('0' + hour).slice(('0' + hour).length - 2) + ':' + ('0' + date.getMinutes()).slice(('0' + date.getMinutes()).length - 2);
        let formattedDateWithTime = formattedDate + ' - ' + formattedTime + timeStamp;
        return (formattedDateWithTime);
    } else return ''
}