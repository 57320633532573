import axios from "axios";
import {REQUEST_METHOD} from "./helper";

export function submitFormQuiz(apiRoute, formSubmitData, successFunction, failureFunction) {
    axios.post(apiRoute, formSubmitData)
        .then(successFunction)
        .catch(failureFunction)
}

export const responderSignIn = (credential, successFunction, failureFunction) => {
    axios.post("/respondersignin", {credential})
        .then(successFunction)
        .catch(failureFunction)
}

export const quizResponderSignIn = (credential, successFunction, failureFunction) => {
    axios.post("/quizrespondersignin", {credential})
        .then(successFunction)
        .catch(failureFunction)
}

export const addResponse = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/response", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const addAnswer = (requestBody, successFunction, failureFunction) => {
    makeAuthorizedServerRequest("/answer", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const getResponsesOfResponder = (formId, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        form_id: formId
    })
    makeAuthorizedServerRequest("/getresponderresponses", REQUEST_METHOD.POST, requestBody, successFunction, failureFunction)
}

export const editResponse = (responseId, formId, newResponseData, successFunction, failureFunction) => {
    const requestBody = JSON.stringify({
        response_id: responseId,
        form_id: formId,
        new_response_data: newResponseData,
        last_edit_time: String(new Date().getTime())
    })
    makeAuthorizedServerRequest("/response", REQUEST_METHOD.PUT, requestBody, successFunction, failureFunction)
}

const makeAuthorizedServerRequest = (requestURL, requestMethod, requestBody, successCallBackFunction, failureCallBackFunction, authToken) => {
    const requestConfiguration = {
        method: requestMethod,
        url: requestURL,
        data: requestBody,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'authorization': 'Bearer ' + (authToken ? authToken : window?.localStorage?.getItem("responder_token"))
        }
    };
    axios(requestConfiguration)
        .then(
            function (serverResponse) {
                successCallBackFunction(serverResponse.data, serverResponse.status)
            }
        )
        .catch(function (err) {
            console.log('error',err)
            failureCallBackFunction(err.response)
        })
}
