import React, {useEffect, useState} from 'react'
import AccInfoContainer from "./AccInfoContainer";
import DashboardSearchContainer from "./DashboardSearchContainer";
import MobileSearchBar from "./MobileSearchBar";
import {Pages} from "../../utils/pages";
import MobileSortContainer from "./MobileSortContainer";
import {isMobileOnly} from "react-device-detect";
import {registerGAEvent} from "../../../shared/utils/analytics";
import surveyHeartIconSmall from '../../../../public/images/surveyheart_icon_small.svg';
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {templateTypes} from "./DashBoardContainer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {useTranslation} from "react-i18next";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import back from "../../../../public/images/back_white.svg";
import deleteIcon from "../../../../public/images/delete_white.svg";
import favouriteIcon from "../../../../public/images/favourite_white.svg";
import removeFavouriteIcon from "../../../../public/images/favorite_remove.svg";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";
import {setMultipleFormFavourite} from "../../../shared/utils/sash-v2-api";
import {setMultipleQuizFavourite} from "../../../shared/utils/quiz-api-services";

function DashBoardHeader(props) {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const showQuiz = useSelector(state => state.appDashboardReducer.showQuiz)
    const showForms = useSelector(state => state.appDashboardReducer.showForms)
    const formsList = useSelector(state => state.appDashboardReducer.formsList)
    const showShare = useSelector(state => state.appDashboardReducer.showShared)
    const isMoreShowing = useSelector(state => state.appDashboardReducer.showMore)
    const quizzesList = useSelector(state => state.appDashboardReducer.quizzesList)
    const isFormsShowing = useSelector(state => state.appDashboardReducer.showForms)
    const showSortIcon = useSelector(state => state.appDashboardReducer.showSortIcon)
    const isQuizzesShowing = useSelector(state => state.appDashboardReducer.showQuiz)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const templateType = useSelector(state => state.appDashboardReducer.templateType)
    const isTemplatesShowing = useSelector(state => state.appDashboardReducer.showTemplates)
    const multipleFormsSelectedArray = useSelector(state => state.formsReducer.multipleFormsSelectedArray)
    const multipleQuizzesSelectedArray = useSelector(state => state.quizzesReducer.multipleQuizzesSelectedArray)

    const [showMobileSearchInputElement, setShowMobileSearchInputElement] = useState(false);
    const selected = useSelector(state => state.languageReducer.selectedLanguage)

    const dispatch = useDispatch()

    useEffect(() => {
        setShowMobileSearchInputElement(false);
    }, [isQuizzesShowing, isTemplatesShowing, isMoreShowing])

    const handleMultiFavClick = (isQuiz) => {
        const successFunction = () => {
            dispatch({type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY, payload: []})
            dispatch({type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY, payload: []})
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        if (showSortIcon) {
            if (isQuiz) {
                setMultipleQuizFavourite(multipleQuizzesSelectedArray, null, successFunction, failureFunction)
            } else {
                setMultipleFormFavourite(multipleFormsSelectedArray, userId, null, successFunction, failureFunction)
            }
        } else {
            if (isQuiz) {
                setMultipleQuizFavourite(multipleQuizzesSelectedArray, new Date(), successFunction, failureFunction)
            } else {
                setMultipleFormFavourite(multipleFormsSelectedArray, userId, new Date(), successFunction, failureFunction)
            }
        }
    }

    return (
        <>
            {isMobileOnly && isTemplatesShowing ? null :
                <div id={'dashboard-header-container'}
                  className={isMobileOnly ? 'dashboard-header-container-mobile' : 'dashboard-header-container'}>
                {!showMobileSearchInputElement && isMobileOnly ?
                    <div id={'header_logo_container'} className='header-logo-container' style={{right: selectedLanguage === 'fa' || selectedLanguage === 'ar' ? "0" : null}}>
                        <img className='product-logo' alt='logo' src={surveyHeartIconSmall}/>
                        <span
                            className={selected === 'fa' ? 'product-name-fa' : null}>{t(translationKeys.app_name)}</span>
                    </div> : null}

                {/*create new form button*/}
                {!isMobileOnly ?

                    <div id={'create-new-button-container'} className="create-new-button-container">
                        {!showShare ?
                            <div id={'create-new-button-container'} className='create-new-button' onClick={() => {
                                if ((isQuizzesShowing || templateType === templateTypes.QUIZ)) {
                                    registerGAEvent('Dashboard', 'Create-Quiz', 'click');
                                    dispatch({
                                        type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                                        payload: BUILDER_CREATION_SOURCE.NEW
                                    })
                                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
                                dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: false})
                                    dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: true})
                                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: null})
                                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
                                } else {
                                    registerGAEvent('Dashboard', 'Create-form', 'click');
                                    dispatch({
                                        type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                                        payload: BUILDER_CREATION_SOURCE.NEW
                                    })
                                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
                                dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: false})
                                    dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: false})
                                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: null})
                                    dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.NEW})
                                    dispatch({type:APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
                                }
                            }}>
                                <span style={{fontSize: '26px', marginRight: '5px'}}>+</span>
                                <span>{(isQuizzesShowing || templateType === templateTypes.QUIZ) ? t(translationKeys.create_quiz) : t(translationKeys.create_form)}</span>
                            </div> : null}
                    </div> : null}
                {/*Delete*/}
                {isMobileOnly && multipleFormsSelectedArray.length > 0 && isFormsShowing ?
                    <div style={{
                        zIndex: '1',
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        position: 'absolute',
                        background: '#1976d2',
                        justifyContent: 'space-between'
                    }}>
                        <div style={{
                            display: 'flex',
                            height: '100%',
                            width: '100%',
                            paddingTop: '10px'
                        }}>
                            <img src={back} alt={'back icon'}
                                 style={{width: '20px', height: '15px', margin: '7px 10px'}}
                                 onClick={() => {
                                     dispatch({type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY, payload: []})
                                 }}/>
                            <div
                                className={'header-button center'}
                                style={{justifyContent: 'flex-start', margin: 'auto', marginLeft: '0'}}>
                                    <span
                                        style={{fontSize: '20px', color: 'white', fontWeight: '400', whiteSpace: "nowrap"}}>
                                        {multipleFormsSelectedArray.length + " " + t(translationKeys.selected)}
                                    </span>
                            </div>
                            <img src={showSortIcon ? favouriteIcon : removeFavouriteIcon} alt={'favourite icon'}
                                 style={{width: '30px'}}
                                 onClick={() => handleMultiFavClick(false)}/>
                            <img src={deleteIcon} alt={'delete icon'}
                                 style={{width: '30px', margin: '0 15px 0 10px'}}
                                 onClick={() => {
                                     dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
                                 }}/>
                        </div>
                    </div> : isMobileOnly && multipleQuizzesSelectedArray.length && isQuizzesShowing > 0 ?
                        <div style={{
                            zIndex: '1',
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            background: '#1976d2',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{
                                display: 'flex',
                                height: '100%',
                                width: '100%',
                                paddingTop: '10px'
                            }}>
                                <img src={back} alt={'back icon'}
                                     style={{width: '20px', height: '15px', margin: '7px 10px'}}
                                     onClick={() => {
                                         dispatch({
                                             type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY,
                                             payload: []
                                         })
                                     }}/>
                                <div
                                    className={'header-button center'}
                                    style={{justifyContent: 'flex-start', margin: 'auto', marginLeft: '0'}}>
                                    <span
                                        style={{fontSize: '20px', color: 'white', fontWeight: '400', whiteSpace: "nowrap"}}>
                                        {multipleQuizzesSelectedArray.length + " " + t(translationKeys.selected)}
                                    </span>
                                </div>
                                <img src={showSortIcon ? favouriteIcon : removeFavouriteIcon} alt={'favourite icon'}
                                     style={{width: '30px'}}
                                     onClick={() => handleMultiFavClick(true)}/>
                                <img src={deleteIcon} alt={'delete icon'}
                                     style={{width: '30px', margin: '0 15px'}}
                                     onClick={() => {
                                         dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
                                     }}/>
                            </div>
                        </div> : null}
                    {(() => {
                        if ((showForms && formsList?.length > 0) || (showQuiz && quizzesList?.length > 0)) {
                            return <>
                                {/*Search bar*/}
                                {isMobileOnly && !isTemplatesShowing && !isMoreShowing && !showShare && multipleQuizzesSelectedArray.length === 0 && multipleFormsSelectedArray.length === 0 ?
                                    <MobileSearchBar updateShowSearchInput={setShowMobileSearchInputElement}
                                                     showInputElement={showMobileSearchInputElement}
                                    /> :
                                    !isTemplatesShowing ? <DashboardSearchContainer/> : null}
                                {/*Sort*/}
                                {isMobileOnly && showSortIcon &&!isTemplatesShowing && !isMoreShowing && !showShare && !showMobileSearchInputElement ?
                                    <MobileSortContainer previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}/> : null}
                            </>
                        }
                    })()}
                {/*Account Logo container*/}
                {!showMobileSearchInputElement ?
                    <AccInfoContainer previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}/> : null}
            </div>}
        </>
    )
}

export default DashBoardHeader;
