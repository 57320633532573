import React from 'react'
import {isMobileOnly} from "react-device-detect";
import blocked from '../../../../public/images/warning.svg'
import MaterialButton from "../../../shared/components/MaterialButton";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";

export function BlockedUI() {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)

    window.onhashchange = (() => {
        window.location.hash = ''
    })

    return (
        <div id='blocked-ui' style={{
            height: '100%', width: '100%', display: 'flex', position: 'fixed',
            zIndex: 11, backgroundColor: '#f5f5f5', justifyContent: 'center',
            alignItems: 'center', top: 0, left: 0
        }}>
            <div
                style={{
                    height: 'auto',
                    width: isMobileOnly ? 'auto' : '470px',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <img style={{
                        width: isMobileOnly ? '100px' : '150px',
                        height: isMobileOnly ? '100px' : '150px',
                        textAlign: 'center',
                        marginBottom: '15px'
                    }}
                         src={blocked}
                         alt="warning"/>
                </div>
                <div id='account-blocked' style={{width: '100%'}}>
                    <span style={{
                        color: '#9e9e9e', fontSize: !isMobileOnly ? '36px' : '24px',
                        fontWeight: 'bold', margin: '0'
                    }
                    }>{t(translationKeys.account_blocked)}</span>
                </div>
                <div id='blocked-t&c' style={{margin: '20px'}}>
                    <span style={{
                        margin: '0',
                        fontSize: !isMobileOnly ? '22px' : '16px', color: '#9e9e9e',
                    }}>{t(translationKeys.account_blocked_alert)}</span>
                </div>
                <MaterialButton id={'provide-explanation-btn'} data={{
                    title: t(translationKeys.provide_explanation).toUpperCase(),
                    fontSize: isMobileOnly ? '12px' : '14px',
                    bg_color: '#1976d2',
                    customButtonContainerStyle: {
                        borderRadius: '20px',
                        padding: '20px'
                    }
                }}
                                handleClick={() => {
                                    const data = JSON.stringify([{
                                        qid: '5eb4e0c90be7456b687e61fd',
                                        data: userId
                                    }]);
                                    const domainName = location.href.split('/').slice(0, 3).join('/');
                                    open(encodeURI(domainName + '/form/5f2144e489672f100af71a75?prefilleddata=' + data + '#form/0'));
                                }}/>
            </div>
        </div>
    )
}
