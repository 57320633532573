import React, {useEffect} from 'react';
import '../css/material-button.css'
import {MDCRipple} from '@material/ripple/index';

export const ButtonTypes = {
    CONTAINED: 'CONTAINED',
    OUTLINED: 'OUTLINED'
}

function MaterialButton(props) {
    useEffect(() => {
        [].map.call(document.querySelectorAll('.mdc-button'), function (element) {
            return new MDCRipple(element);
        });
    });

    useEffect(() => {
        if (props.handleClick === undefined
            || props.handleClick === null) {
            console.error("Handle Click Event must be defined -> props.handleClick()")
        }
    }, []);

    const getImgSrc = () => {
        if (props.data.base64ImageData) {
            return props.data.base64ImageData
        }

        if (props.data.imageFileName) {
            return `../../../images/${props.data.imageFileName}`
        }

        return null
    }

    return (
        <div id={props.id ? props.id : null} className="mdc-touch-target-wrapper">
            <button
                id={'mdc-button'}
                type={props.data.isButton ? "button" : null}
                className={props.data.buttonType === ButtonTypes.OUTLINED ? "mdc-button mdc-button--touch mdc-button--outlined" :
                    "mdc-button mdc-button--touch mdc-button--raised"}
                style={{
                    ...{"--mdc-theme-primary": props.data.bg_color ? props.data.bg_color : null},
                    backgroundColor: props.color ? props.color : null,
                    ...props.data.customButtonContainerStyle
                }}

                onClick={(e) => props.handleClick(e)}>
                <div className="mdc-button__ripple"/>
                {props.data.base64ImageData || props.data.imageFileName ? <img alt='button' className="mdc-button__icon"
                                                                               style={props.data.customImageStyle ? {...props.data.customImageStyle} : null}
                                                                               src={getImgSrc()}/> : null}
                {props.data.title && props.data.title.length > 0 ?
                    <span className="mdc-button__label" style={{
                        ...{
                            fontFamily: props.data.fontFamily ? props.data.fontFamily : "Nunito Sans",
                            textTransform: props.data.textTransform ? props.data.textTransform : "none",
                            fontSize: props.data.fontSize ? props.data.fontSize : '16px',
                            letterSpacing: props.data.letterSpacing ? props.data.letterSpacing : 'normal'
                        },
                        ...props.data.customButtonTextStyle
                    }}>{props.data.title}</span> : null}
                {props.data.endImageFileName ? <img alt='button' className="mdc-button__icon"
                                                    style={props.data.customImageStyle ? {...props.data.customImageStyle} : null}
                                                    src={'../../../images/' + props.data.endImageFileName}/> : null}
                <div id={props.data.title} className="mdc-button__touch"/>
            </button>
        </div>
    );
}

export default MaterialButton
