import React from "react"
import TopNavBar from "./TopNavBar";
import TemplateFormsContainer from "../../app/dashboard/components/TemplateFormsContainer";
import {Provider} from "react-redux";
import appStore from "../../app/store/appStore";
import {isMobileOnly} from "react-device-detect";
import MobileTemplateContainer from "../../app/dashboard/components/MobileTemplateContainer";

const Templates = () => {

    document.body.scrollTop = 0

    return (
        <div>
            <TopNavBar color={isMobileOnly ? null : "#000000"} burgerColor={"#212121"}/>
            <div style={{marginTop: isMobileOnly ? "40px" : "10px"}}>
                <Provider store={appStore}>
                    {isMobileOnly ? <MobileTemplateContainer homePage={true}/> : <TemplateFormsContainer homePage={true}/>}
                </Provider>
            </div>
        </div>
    )
}

export default Templates
