// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._3Q60yiNeb_F2kO2z7zHKRs {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n._3Q60yiNeb_F2kO2z7zHKRs img{\n    width: 16px;\n    height: 16px;\n}\n\n._3Q60yiNeb_F2kO2z7zHKRs img:hover {\n    cursor: pointer;\n}\n\n._3lxL8JRkpyUmJDvI-3Hzg- {\n    width: 100%;\n    display: flex;\n    align-items: center;\n}\n\n._3lxL8JRkpyUmJDvI-3Hzg- img {\n    width: 14px;\n    height: 14px;\n}\n\ninput {\n    width: 90%;\n    outline: none;\n    border-color: transparent;\n    font-size: 14px;\n    font-family: \"Nunito Sans\", Sans-serif, serif;\n}\n\n._2gWpJjq2M6KiVtugHtFo17 {\n    visibility: hidden;\n}\n\n.F__Wi2MtrHCuND0g329pf {\n    display: none;\n}\n\n", ""]);
// Exports
exports.locals = {
	"search_base_component": "_3Q60yiNeb_F2kO2z7zHKRs",
	"search_inner_container": "_3lxL8JRkpyUmJDvI-3Hzg-",
	"hide": "_2gWpJjq2M6KiVtugHtFo17",
	"remove": "F__Wi2MtrHCuND0g329pf"
};
module.exports = exports;
