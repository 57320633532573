import useFeatureSpotlightDialogMgr from "../../app/hooks/useFeatureSpotlightDialogMgr"
import ImageViewerComponent from "../../form/components/questions/ImageViewerComponent";
import {useState} from "react";

/**
 *
 * @returns {JSX.Element}
 *
 */
export const FeatureSpotlightDialog = () => {
    const [imageViewer, setImageViewer] = useState(false)
    const {
        React,
        close,
        featureSpotlightBulb,
        featureSpotlightImageOverlay,
        FEATURE_SPOTLIGHT_ACTION_TYPE,
        newFeatures,
        reduxDispatch,
        registerGAEvent,
        selectedLanguage,
        t,
        translationKeys
    } = useFeatureSpotlightDialogMgr()

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '11',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0',
        cursor: 'default'
    }

    const showFeatureDetails = (feature, showDividerLine, index) => {
        return <>
            <div className={index === 0 ? 'feature-spotlight-row-first-parent' : 'feature-spotlight-row-parent'}>
                <div className={'feature-spotlight-row-link'}
                     style={{marginLeft: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '10px' : null}}
                     onClick={() => {
                         if (feature.videoLink) {
                             open(feature.videoLink)
                         } else {
                             setImageViewer(feature.thumbnail)
                         }
                     }}>
                    <img
                        className={'feature-spotlight-image'}
                        src={feature.thumbnail} alt={'new feature'}
                        style={{display: feature.thumbnail === '' ? 'none' : 'block'}}
                    />
                    {feature.videoLink ?
                        <img className={'feature-spotlight-image-overlay'}
                             src={featureSpotlightImageOverlay} alt={'new feature'}
                        /> : null}
                </div>
                <div className={'feature-spotlight-right-column'}>
                    <div className={'feature-spotlight-row-title'}>
                        {feature.title}
                    </div>
                    <div className={'feature-spotlight-row-description'}>
                        {feature.description}
                    </div>
                </div>
            </div>
            {
                (() => {
                    if (showDividerLine === true) {
                        return (
                            <div className="feature-spotlight-line-div"/>
                        )
                    }
                })()
            }
        </>
    }

    return (
        <div style={popUpStyle}>
            <div id={'feature-spotlight-popup'} className={'feature-spotlight-dialog'}>
                <div className={'feature-spotlight-header'}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <img
                            src={featureSpotlightBulb} alt={'light bulb'}
                        />
                        <div className={'feature-spotlight-header-text'}>
                            {t(translationKeys.feature_spotlight)}
                        </div>
                    </div>
                    <div>
                        <img onClick={() => {
                            registerGAEvent('Feature Spotlight', 'pop-up', 'close')
                            reduxDispatch({
                                type: FEATURE_SPOTLIGHT_ACTION_TYPE.TOGGLE_VISIBILITY
                            })
                        }}
                             className={'feature-spotlight-close-img'}
                             src={close} alt={t(translationKeys.close)}
                        />
                    </div>
                </div>
                {
                    (() => {
                            return (
                                <div className={'feature-spotlight-body'}>
                                    <div className={'feature-spotlight-body-header'}>
                                        {t(translationKeys.note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side)}
                                    </div>
                                    <div className={'feature-spotlight-body-items'}>
                                        {
                                            newFeatures?.map((feature, index) => {
                                                let showDividerLine = index < (newFeatures.length - 1)

                                                return (
                                                    <div key={index}>
                                                        {
                                                            showFeatureDetails(feature, showDividerLine, index)
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                    )()
                }
                <div className='feature-spotlight-footer'>
                    &nbsp;
                </div>
            </div>
            {imageViewer ? <ImageViewerComponent attachment={imageViewer} close={() => setImageViewer(false)}/> : null}
        </div>
    )
}

export default FeatureSpotlightDialog
