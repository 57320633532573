const formTemplates_en = require('../../../public/json/templates/sh_templates_en.json');
const formTemplates_fr = require('../../../public/json/templates/sh_templates_fr.json');
const formTemplates_in = require('../../../public/json/templates/sh_templates_in.json');
const formTemplates_hi = require('../../../public/json/templates/sh_templates_hi.json');
const formTemplates_pa = require('../../../public/json/templates/sh_templates_pa.json');
const formTemplates_ml = require('../../../public/json/templates/sh_templates_ml.json');
const formTemplates_mr = require('../../../public/json/templates/sh_templates_mr.json');
const formTemplates_fa = require('../../../public/json/templates/sh_templates_fa.json');
const formTemplates_pt = require('../../../public/json/templates/sh_templates_pt.json');
const formTemplates_sp = require('../../../public/json/templates/sh_templates_es.json');
const formTemplates_ta = require('../../../public/json/templates/sh_templates_ta.json');
const formTemplates_te = require('../../../public/json/templates/sh_templates_te.json');
const formTemplates_ar = require('../../../public/json/templates/sh_templates_ar.json');

const quizTemplates_en = require('../../../public/json/templates/quiz_templates_en.json');
const quizTemplates_fr = require('../../../public/json/templates/quiz_templates_fr.json');
const quizTemplates_in = require('../../../public/json/templates/quiz_templates_in.json');
const quizTemplates_hi = require('../../../public/json/templates/quiz_templates_hi.json');
const quizTemplates_pa = require('../../../public/json/templates/quiz_templates_pa.json');
const quizTemplates_ml = require('../../../public/json/templates/quiz_templates_ml.json');
const quizTemplates_mr = require('../../../public/json/templates/quiz_templates_mr.json');
const quizTemplates_fa = require('../../../public/json/templates/quiz_templates_fa.json');
const quizTemplates_pt = require('../../../public/json/templates/quiz_templates_pt.json');
const quizTemplates_sp = require('../../../public/json/templates/quiz_templates_es.json');
const quizTemplates_ta = require('../../../public/json/templates/quiz_templates_ta.json');
const quizTemplates_te = require('../../../public/json/templates/quiz_templates_te.json');
const quizTemplates_ar = require('../../../public/json/templates/quiz_templates_ar.json');

const selected = localStorage?.getItem("i18nextLng") || "en";

const LANGUAGE_FORM_SELECTED = {
    FORM_SELECTED: 'FORM_SELECTED'
}

const LANGUAGE_QUIZ_SELECTED = {
    QUIZ_SELECTED: 'QUIZ_SELECTED'
}

export const LANGUAGE_SELECTED = {
    SELECTED: 'SELECTED'
}


const getCurrentQuizTemplate = () => {
    switch (selected) {
        case 'en':
            return quizTemplates_en
        case 'fr':
            return quizTemplates_fr
        case 'in':
            return quizTemplates_in
        case 'hi':
            return quizTemplates_hi
        case 'pa':
            return quizTemplates_pa
        case 'ml':
            return quizTemplates_ml
        case 'mr':
            return quizTemplates_mr
        case 'fa':
            return quizTemplates_fa
        case 'pt':
            return quizTemplates_pt
        case 'es':
            return quizTemplates_sp
        case 'ta':
            return quizTemplates_ta
        case 'te':
            return quizTemplates_te
        case 'ar':
            return quizTemplates_ar
        default :
            return quizTemplates_en
    }
}

const getCurrentFormTemplate = () => {
    switch (selected) {
        case 'en':
            return formTemplates_en
        case 'fr':
            return formTemplates_fr
        case 'in':
            return formTemplates_in
        case 'hi':
            return formTemplates_hi
        case 'pa':
            return formTemplates_pa
        case 'ml':
            return formTemplates_ml
        case 'mr':
            return formTemplates_mr
        case 'fa':
            return formTemplates_fa
        case 'pt':
            return formTemplates_pt
        case 'es':
            return formTemplates_sp
        case 'ta':
            return formTemplates_ta
        case 'te':
            return formTemplates_te
        case 'ar':
            return formTemplates_ar
        default :
            return formTemplates_en
    }
}

let initialState = {
    selectedLanguage: selected,
}

export const languageReducer = (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGE_SELECTED.SELECTED :
            return {
                ...state,
                selectedLanguage: action.payload
            }
        default :
            return state
    }
}


let initialFormTemplateState = {
    formTemplate: getCurrentFormTemplate()
}

export const getCurrentFormTemplateReducer = (state = initialFormTemplateState, action) => {
    switch (action.type) {
        case LANGUAGE_FORM_SELECTED.FORM_SELECTED :
            return {...state, formTemplate: action.payload}
        default :
            return state
    }
}

let initialQuizTemplateState = {
    quizTemplate: getCurrentQuizTemplate()
}

export const getCurrentQuizTemplateReducer = (state = initialQuizTemplateState, action) => {
    switch (action.type) {
        case LANGUAGE_QUIZ_SELECTED.QUIZ_SELECTED :
            return {...state, quizTemplate: action.payload}
        default :
            return state
    }
}
