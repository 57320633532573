import React, {useEffect} from "react";
import '../../../answer-sheet/css/circle-progress.css'
import '../css/StorageComponent.css'
import PropTypes from "prop-types";
import {MAX_USER_FILE_STORAGE} from "../../../shared/utils/constants"
import {formatBytes} from "../../../shared/utils/helper"
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import {isMobileOnly} from "react-device-detect";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {Slider} from "@mui/material";
import {STORAGE_MANAGEMENT_ACTION_TYPE} from "../../reducers/storageManagementReducer";
import {PAGE_HASH_LOCATION} from "../../utils/pages";

const StorageBandComponent = (props) => {
    const {t} = useTranslation()
    const totalStorageUsed = useSelector(state => state.appReducer.totalStorageUsed)
    const storageUsed = totalStorageUsed
    const percentage = ((storageUsed / (1000 * 1024)) / props.totalStorage) * 100
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const dispatch = useDispatch()

    const handleManageClick = () => {
        if (isMobileOnly) {
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_FORQ_POP_UP, payload: true})
        }
    }

    const handlePremiumClick = () => {
        window.location.hash = PAGE_HASH_LOCATION.dashboard
        dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
    }

    return (
        <div id={'storageComponent'} style={{width: "100%", textAlign: "center"}}>
            <div className={isMobileOnly ? null : "storage-background"}>
                {props.showManageButton ? <h3>{t(translationKeys.storage)}</h3> : null}
                <div className={'storageComponent'}>
                    {isMobileOnly ? <PercentageCircle percentage={percentage}/> :
                        <Slider value={percentage}
                                min={0}
                                max={100}
                                sx={{
                                    '& .MuiSlider-track': {
                                        border: 'none',
                                        color: getStorageBandPercentageColor(percentage)
                                    },
                                    '& .MuiSlider-thumb': {
                                        display: "none"
                                    }
                                }}
                        />}
                </div>
                <h3>{formatBytes(storageUsed, totalStorageUsed > MAX_USER_FILE_STORAGE ? 0 : 5, false, "GB")} / {props.totalStorage / 1000}GB</h3>
            </div>
            <div className={isMobileOnly ? null : "storage-background"} style={{
                display: "flex",
                justifyContent: "space-around",
                margin: "10px",
                alignItems: "center",
                padding: "0"
            }}>
                {props.showManageButton && isMobileOnly ? <MaterialButton data={{
                        title: t(translationKeys.manage),
                        customButtonContainerStyle: {
                            borderRadius: "20px"
                        },
                        fontSize: '14px'
                    }} handleClick={handleManageClick}/> :
                    <p>{subscriptionData?.subscription_plan === "premium-on-hold" ? "HOLD" : `${subscriptionData?.subscription_plan.split("-")[0].toUpperCase()}`}</p>
                }
                {!isMobileOnly ? <MaterialButton data={{
                    title: t(translationKeys.upgrade),
                    buttonType: ButtonTypes.CONTAINED,
                    customButtonContainerStyle: {
                        color: "#fff",
                        width: "100px",
                        borderRadius: "20px",
                        backgroundColor: "#1976D2",
                        border: "1px solid #1976D2"
                    },
                    customButtonTextStyle: {
                        fontWeight: "bold"
                    },
                    fontSize: '14px'
                }} handleClick={handlePremiumClick}/> : null}
            </div>
        </div>
    )
}
export default StorageBandComponent

StorageBandComponent.prototype = {
    totalStorage: PropTypes.number.isRequired,
    storageUsed: PropTypes.number.isRequired,
    clickHandler: PropTypes.func
}
StorageBandComponent.defaultProps = {
    totalStorage: 1000,
    storageUsed: 0
}

const PercentageCircle = (props) => {
    const circleElement = React.useRef(null);
    const textElement = React.useRef(null);
    useEffect(() => {
        setPercentageCircle(circleElement.current, textElement.current, props.percentage, undefined)
    }, [props.percentage])

    return <div id={'percentage-circle'} ref={circleElement} className="percentage-circle">
        <div id={'percentage-circle-inner'} className="percentage-circle-inner"
             style={!isMobileOnly ? {width: "80%", height: "80%"} : null}>
            <div ref={textElement}>0%</div>
        </div>
    </div>
}

function setPercentageCircle(circleElement, textElement, percentage, increment) {
    if (parseFloat(percentage) < 1 && parseFloat(percentage) !== 0) {
        textElement.innerHTML = ("< 1%");
        circleElement.style.cssText = 'background-image: linear-gradient(' + (90 + increment) + 'deg, transparent 50%, #eeeeee 50%),linear-gradient(90deg, #eeeeee 50%, transparent 50%)';
        circleElement.style.backgroundColor = getStorageBandPercentageColor(0);
        circleElement.style.height = '80%'
        circleElement.style.width = '80%'
        circleElement.style.margin = 'auto'
        return
    }

    if (percentage < 0 || typeof percentage === 'undefined')
        percentage = 0;
    else if (percentage > 100)
        percentage = 100;
    if (typeof increment === 'undefined')
        increment = 0;
    let currentPercentage = (100 * increment) / 360;
    let percentageColor = getStorageBandPercentageColor(percentage);
    textElement.innerHTML = (Math.round(percentage) + "%");
    if (increment <= 180) {
        circleElement.style.cssText = 'background-image: linear-gradient(' + (90 + increment) + 'deg, transparent 50%, #eeeeee 50%),linear-gradient(90deg, #eeeeee 50%, transparent 50%)';
    } else {
        circleElement.style.cssText = 'background-image: linear-gradient(' + (increment - 90) + 'deg, transparent 50%, ' + percentageColor + ' 50%),linear-gradient(90deg, #eeeeee 50%, transparent 50%)';
    }
    circleElement.style.backgroundColor = percentageColor;
    circleElement.style.height = '80%'
    circleElement.style.width = '80%'
    circleElement.style.margin = 'auto'
    if (currentPercentage < percentage) {
        setTimeout(function () {
            setPercentageCircle(circleElement, textElement, percentage, increment + 2);
        }, 1);
    }
}

function getStorageBandPercentageColor(percentage) {
    if (percentage > 90) {
        return '#f75b5b'
    } else {
        return '#1976D2'
    }
}