import React, { useState, useRef} from 'react'
import FormControlContainer from "./FormControlContainer";
import getPopUpElementPositionStyles from "../../../shared/utils/get-popup-element-positions";
import {handlePreviouslyOpenedSortCard} from "./AccInfoContainer";
import {isMobileOnly} from "react-device-detect";
import moreWhite from '../../../../public/images/more-white.svg';
import {useSelector} from "react-redux";


const FormCardMoreButtonContainer = (props) => {
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const [showFormControl, setShowFormControl] = useState(false);
    const ref = useRef(null);
    const positionRef = useRef(null);

    const handleOutSideClick = () => {
        document.onmousedown = e => {
            e.stopPropagation();
            if (ref.current && !ref.current.contains(e.target)) {
               document.onmousedown = null;
                setShowFormControl(false);
            }
        };
    }

    const position = positionRef.current ? positionRef.current : undefined;

    return (
        isMobileOnly ? null : <div id={'more-button-' + props.cardIndex} className='more-button-container'
                                       ref={ref}>
        <img className="form-control-icon" src={moreWhite}
             alt="/images/more-white.svg"
             onClick={e => {
                 positionRef.current = getPopUpElementPositionStyles(330, 330, e)
                 if (props.previouslyOpenedDropDownRef.current && !showFormControl) {
                     handlePreviouslyOpenedSortCard(props.previouslyOpenedDropDownRef.current);
                 }
                 props.previouslyOpenedDropDownRef.current = {
                     element: ref.current,
                 };
                 showFormControl ? setShowFormControl(false) : setShowFormControl(true);
                 showFormControl ? document.onmousedown = null : handleOutSideClick();
                 e.stopPropagation();
             }}/>
        <div id={'form-control-drop-down-container'} className="form-control-drop-down-container"
             style={{
                 height: "auto",
                 top: position && position.top ? position.top : null,
                 bottom: position && position.bottom ? position.bottom : null,
                 right: position && position.right ? position.right : null,
                 left: position && position.left ? position.left : null,
                 display: showFormControl ? 'inline-block' : 'none',
                 width: selectedLanguage === "ml" || selectedLanguage === "pt" || selectedLanguage === "ta" || selectedLanguage === "es" ? "330px" : null
             }}>
            {showFormControl ? <FormControlContainer formData={props.formData}
                                                     cardIndex={props.cardIndex}
                                                     showEdit={props.showEdit}
                                                     favouriteClicked={props.favouriteClicked}
                                                     previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                                     setShowFormControl={setShowFormControl}
                                                     showDataEditWarning={props.showDataEditWarning}
                                                     updateStatus={props.updateStatus}
            /> : null}
        </div>
    </div>)
}

export default FormCardMoreButtonContainer;
