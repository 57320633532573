import React, {useRef, useState} from 'react';
import SearchBaseComponent from "../components/SearchBaseComponent";
import styles from '../css/search-container.module.css'
import SearchDropDownIcon from "../components/SearchDropDownIcon";
import {MaterialRadioSelection} from "../../form/components/MaterialRadioSelection";
import FabButtonContainer from "../../shared/containers/FabButtonContainer";
import {useDispatch, useSelector} from "react-redux";
import {ABUSE_SCAN_ACTION_TYPE} from "../reducers/abuseScanReducers";

export default function SearchContainer(props) {
    const adminColor = useSelector(state => state.serviceReducer?.adminColor)
    const [showDropDownOptions, setShowDropDownOptions] = useState(false)
    const dropDownListRef = useRef(null)
    const dropDownIconRef = useRef(null)
    const handleOutsideClick = () => {
        document.onmousedown = (e) => {
            if (dropDownListRef.current
                && dropDownIconRef.current !== e.target
                && !dropDownListRef.current.contains(e.target)) {
                setShowDropDownOptions(false)
            }
        }
    }

    const handleSearchDropDownIconClick = () => {
        setShowDropDownOptions(!showDropDownOptions)
        handleOutsideClick()
    }
    return (
        <>
            <div className={styles.container}>
                <div className={styles.search_box} style={{width: props.width ? props.width : null, height: props.height ? props.height : null}}>
                    <SearchBaseComponent value={props.value} handleKeyDown={props.handleKeyDown} handleTextChange={props.handleTextChange}/>
                    {props.optionTextArray && Array.isArray(props.optionTextArray) && props.optionTextArray.length > 0 ?
                        <SearchDropDownIcon angle={showDropDownOptions ? '180' : '0'}
                                            dropDownIconRef={dropDownIconRef}
                                            handleClick={handleSearchDropDownIconClick}/>
                        : null}
                </div>
                {showDropDownOptions ?
                    <div className={styles.drop_down_list_container} ref={dropDownListRef}>
                        {props.optionTextArray.map((optionText, index) => {
                            return (
                                <DropDownOptionCard key={index} optionText={optionText}
                                                    index={index}/>
                            )
                        })}
                    </div>
                    : null}
                {props.searchButton ? <FabButtonContainer containerStyle={{top: '0', left: '103%'}}
                                     iconName={'search'}
                                     buttonSize={'40px'}
                                     iconSize={'18px'}
                                     bgColor={adminColor}
                                     handleButtonClick={props.handleKeyDown}/>
                    : null}
            </div>
        </>
    )
}

const DropDownOptionCard = props => {
    const adminColor = useSelector(state => state.serviceReducer.adminColor)
    const selectedOption = useSelector(state => state.abuseScanReducers.selectedOption)
    const dispatch = useDispatch()
    const onChoiceSelected = () => {
        dispatch({type: ABUSE_SCAN_ACTION_TYPE.SET_SELECTED_OPTION, payload: props.optionText})
    }
    return (
        <div className={styles.drop_down_option_card}>
            <MaterialRadioSelection label={props.optionText}
                                    customStyle={{
                                        radioButtonColor: adminColor,
                                        radioButtonLabelColor: '#212121',
                                        fontSize: '14px'
                                    }}
                                    isClearRadioSelection={selectedOption !== props.optionText}
                                    onChange={onChoiceSelected}/>
        </div>
    )
}