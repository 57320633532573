import React, {useEffect, useRef} from 'react'
import PropTypes from "prop-types";
import {MDCTextField} from "@material/textfield/index";
import '../css/material-text-field-outlined.css'

const MaterialTextFieldOutlined = props => {
    const TextFieldRef = useRef(null)

    useEffect(() => {
        const MDCText = new MDCTextField(TextFieldRef.current);
        (props.defaultValue !== undefined) ? MDCText.value = props.defaultValue : null;
    }, [props.defaultValue])

    const setErrorUI = () => {
        if (props.hasValidation) {
            if (props.showError) {
                TextFieldRef.current.classList.add('mdc-text-field--invalid')
            } else {
                TextFieldRef.current.classList.remove('mdc-text-field--invalid')
            }
        }
    }

    const handleBlur = e => {
        e.stopPropagation();
        if (props.onBlur) props.onBlur(e.target.value.trim())
        setErrorUI()
    }

    const handleFocus = e => {
        e.stopPropagation();
        if (props.onFocus) props.onFocus(e.target.value.trim())
    }

    const handleChange = e => {
        e.stopPropagation();
        if (props.onChange) props.onChange(e.target.value.trim())
    }

    return (
        <>
            <label id={'text-field-outline'} ref={TextFieldRef}
                   className={props.showError ? `mdc-text-field mdc-text-field--outlined ${props.textArea ? ' mdc-text-field--textarea ' : ''} mdc-text-field--invalid`
                       : `mdc-text-field mdc-text-field--outlined ${props.textArea ? ' mdc-text-field--textarea ' : ''}`}
                   style={{width: '100%', ...props.customStyleObject}}>
                    <span className="mdc-notched-outline">
                        <span className="mdc-notched-outline__leading"/>
                            <span className="mdc-notched-outline__notch">
                                <span className="mdc-floating-label">{props.placeHolder}</span>
                            </span>
                        <span className="mdc-notched-outline__trailing"/>
                    </span>
                {props.textArea ? <textarea onBlur={handleBlur}
                                            onChange={handleChange}
                                            onFocus={handleFocus}
                                            maxLength={props.maxLength}
                                            style={{...props.textStyleObject}}
                                            autoFocus={props.autoFocus}
                                            className="mdc-text-field__input"
                                            aria-labelledby="my-label-id"
                /> : <input type={props.inputType ? props.inputType : 'email'}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onFocus={handleFocus}
                            readOnly={props.readOnly ? props.readOnly : false}
                            maxLength={props.maxLength}
                            style={{...props.textStyleObject}}
                            className="mdc-text-field__input"
                            aria-labelledby="my-label-id"
                            autoFocus={props.autoFocus}
                />}
            </label>
            {props.hasValidation ?
                <div className="mdc-text-field-helper-line">
                    <div style={{visibility: props.showError ? 'visible' : 'hidden'}}
                         className="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                         aria-hidden="true">{props.errorText}</div>
                </div> : null}
        </>
    )
}

export default MaterialTextFieldOutlined

MaterialTextFieldOutlined.propTypes = {
    customStyleObject: PropTypes.object,
    defaultValue: PropTypes.string,
    errorText: PropTypes.string,
    hasValidation: PropTypes.bool,
    maxLength: PropTypes.number,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    placeHolder: PropTypes.string,
    showError: PropTypes.bool
}