import React from 'react'
import styles from '../css/search-container.module.css'

const SearchDropDownIcon = props => {
    return (
        <div onClick={props.handleClick} ref={props.ref}>
            <span className={'material-icons ' + styles.search_drop_down_icon}
                  ref={props.dropDownIconRef}
                  style={{color: '#9e9e9e',
                      transition: '300ms ease',
                      transform: props.angle ? ('rotate(' + props.angle + 'deg)') : null}}>arrow_drop_down</span>
        </div>
    )
}

export default SearchDropDownIcon