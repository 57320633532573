import {LOGIN_CONTAINER_SECTION} from "../utils/constants";

const url = new URL(location.href);
const isSignUp = url.searchParams.get("u") ? parseInt(url.searchParams.get("u")) : false;

const initialState = {
    isSignIn: !isSignUp,
    userEmail: undefined,
    emailResendData: undefined,
    loginContainerSection: !isSignUp ? LOGIN_CONTAINER_SECTION.SIGN_IN : LOGIN_CONTAINER_SECTION.SIGN_UP
};

export const LOGIN_ACTION_TYPE = {
    SET_USER_EMAIL: 'SET_USER_EMAIL',
    SET_SIGN_IN_STATUS: 'SET_SIGN_IN_STATUS',
    SET_EMAIL_RESEND_DATA: 'SET_EMAIL_RESEND_DATA',
    SET_LOGIN_CONTAINER_SECTION: 'SET_LOGIN_CONTAINER_SECTION'
}

export default function loginReducer(state = initialState, action) {
    switch (action.type) {
        case (LOGIN_ACTION_TYPE.SET_USER_EMAIL):
            return {
                ...state,
                userEmail: action.payload
            }
        case (LOGIN_ACTION_TYPE.SET_EMAIL_RESEND_DATA):
            return {
                ...state,
                emailResendData: action.payload
            }
        case (LOGIN_ACTION_TYPE.SET_LOGIN_CONTAINER_SECTION):
            return {
                ...state,
                loginContainerSection: action.payload
            }
        case LOGIN_ACTION_TYPE.SET_SIGN_IN_STATUS:
            return {
                ...state,
                isSignIn: action.payload
            }
        default:
            return state;
    }
}
