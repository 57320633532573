import React, {useEffect, useState} from "react"
import "../css/careers.css"
import "../../home/css/top-nav-bar.css"
import JobCard from "./JobCard";
import noJobs from "../../../public/images/no-jobs.svg"
import {getCareersJson} from "../../app/builder/utils/aws";

const Careers = () => {

    const [careersData, setCareersData] = useState(null)

    useEffect(() => {
        if (careersData === null) {
            getCareersJson((res) => {
                if (res) {
                    setCareersData(JSON.parse(res.Body.toString("utf-8")))
                }
            })
        }
    }, [])

    return (
        <>
            <div className={"top-navigator-bar"} style={{justifyContent: "center"}}>
                <div className="title-banner" style={{margin: "0", width: "auto"}}>
                    <img id={"app-logo"} alt={"logo"} src="images/surveyheart1.png"/>
                </div>
            </div>
            {careersData?.filter((job) => job.isActive)?.length ? <>
                <div className={"careers-header"}>
                    <h1>Job listings <span>at SurveyHeart</span></h1>
                    <p>Explore our current job listings to discover exciting career opportunities that match our skills
                        sets and interests. Each job listing provide comprehensive details about the role,
                        responsibilities and benefits. Whether you are an experienced professional or a fresh graduate,
                        We welcome talents to join our team.</p>
                </div>
                <div className={"carers-body"}>
                    <div className={"careers-note"}>
                        <p><span>NOTE: </span>
                            SurveyHeart does not promote any work-from-home jobs. All the positions listed below are
                            exclusively work-from-office roles.
                        </p>
                    </div>
                    {careersData?.map((jobDetails) => {
                        if (jobDetails.isActive) {
                            return <JobCard jobDetails={jobDetails}/>
                        }
                    })}
                </div>
            </> : <div className={"no-jobs-available"}>
                <img src={noJobs} alt={"no jobs available"}/>
                <p>There are no job openings available at the moment.</p>
            </div>}
        </>
    )
}

export default Careers
