import React from "react"
import "../css/payment-success-popup.css"
import whiteTick from "../../../../public/images/done.svg"
import whiteCross from "../../../../public/images/close_white.svg"
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";

const PaymentSuccessPopUp = (props) => {
    return (
        <div className={"premium-plans-popup"}>
            <div className={"success-pop-up"}>
                <div className={props.success ? "green-tick" : "red-tick"}>
                    <img src={props.success ? whiteTick : whiteCross} alt={"success"}/>
                </div>
                <h2 style={{textAlign: "center"}}>{props.title}</h2>
                <p>{props.description}</p>
                <MaterialButton data={{
                    title: "OKAY",
                    buttonType: ButtonTypes.OUTLINED,
                    bg_color: '#1976d2',
                    customButtonContainerStyle: {
                        marginTop: '20px',
                    }
                }} handleClick={() => {
                    props.handlePopUpClick();
                }}/>
            </div>
        </div>
    )
}

export default PaymentSuccessPopUp
