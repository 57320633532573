export const Pages = {
    dashboard: 'DashBoard',
    responses: 'Responses',
    builder: 'Builder',
    login: 'Login',
    email_verified: 'emailVerified',
    reset_password: 'resetPassword',
    collaborator: 'collaborator',
    notification: 'notification',
    storage: 'storage',
    share: 'share',
    premium_plans: 'premiumPlans',
    usage: 'usage',
    mail_support: 'mailSupport',
    collab_roles: 'collabRoles'
}

export const PAGE_HASH_LOCATION = {
    dashboard: '#dashboard',
    login: '#login',
    form_control: '#form-control',
    image_viewer: "#image-viewer",
    summary: '#summary',
    preview: '#preview',
    email_verified: '#email-verified',
    reset_password: '#reset-password',
    form_builder: '#form_builder',
    quiz_builder: '#quiz_builder',
    question: '#question',
    templates: "#templates"
}

