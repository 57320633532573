import {UPDATE_FORM_DATA} from "../utils/constants";


let initialState = {
    updatedFormData: null,
}


const updatedFormDataReducers = (state = initialState, action) => {

    switch (action.type) {
        case UPDATE_FORM_DATA:
            state.updatedFormData = action.payload
            return {...state, state: action.payload}
        default:
            return state
    }
}

export default updatedFormDataReducers