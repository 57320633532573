import React, {useEffect, useRef} from 'react'
import FormsContainer from "./FormsContainer";
import Loader from "../../../shared/components/Loader";
import TemplateFormsContainer from "./TemplateFormsContainer";
import QuizzesContainer from "./QuizzesContainer";
import {isMobileOnly} from "react-device-detect";
import {Pages} from "../../utils/pages";
import ModalDialog from "../../../shared/components/ModalDialog";
import {DashBoardFABButton} from "./DashboardFAB";
import MobileMoreContainer from "./MobileMoreContainer"
import SharesContainer, {TABS_DATA} from "./SharesContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {templateTypes} from "./DashBoardContainer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {t} from "i18next";
import MobileTemplateContainer from "./MobileTemplateContainer";
import {STORAGE_MANAGEMENT_ACTION_TYPE} from "../../reducers/storageManagementReducer";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";

let dataCollectedTitle = ''
let dataCollectedWarning = [];

const ContentContainer = props => {
    const showQuiz = useSelector(state => state.appDashboardReducer.showQuiz)
    const showMore = useSelector(state => state.appDashboardReducer.showMore)
    const showForms = useSelector(state => state.appDashboardReducer.showForms)
    const showShare = useSelector(state => state.appDashboardReducer.showShared)
    const showShareScreen = useSelector(state => state.sharedReducer.showShareScreen)
    const templateType = useSelector(state => state.appDashboardReducer.templateType)
    const showTemplates = useSelector(state => state.appDashboardReducer.showTemplates)
    const showEditPremiumPopUp = useSelector(state => state.appDashboardReducer.showEditPremiumPopUp)
    const showDuplicatePremiumPopUp = useSelector(state => state.appDashboardReducer.showDuplicatePremiumPopUp)
    const isShowingMobileFormControl = useSelector(state => state.appDashboardReducer.isShowingMobileFormControl)
    const multipleFormsSelectedArray = useSelector(state => state.formsReducer.multipleFormsSelectedArray)
    const showDataCollectedWarning = useSelector(state => state.appDashboardReducer.showDataCollectedWarning)
    const multipleQuizzesSelectedArray = useSelector(state => state.quizzesReducer.multipleQuizzesSelectedArray)
    const formControlFormData = useRef(null);
    const timeOutForLongPress = useRef(null);
    const dispatch = useDispatch()

    useEffect(() => {
        if (showTemplates && templateType === undefined) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.FORM})
        }
    }, [showTemplates])

    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_VERSION, payload: false})
            dispatch({type: APP_ACTION_TYPE.SET_SHOW_COLLABORATE_POP_UP, payload: false})
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: false})
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DATA_COLLECTED_WARNING, payload: false})
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_MANAGEMENT_POP_UP, payload: false})
        } else if (window.event.ctrlKey && window.event.shiftKey && e.key === "?") {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: true})
        }
    }

    const populateDataCollectedWarning = (formData) => {
        if (formData.is_quiz) {

            dataCollectedTitle = formData.is_publish ? t(translationKeys.quiz_published) : t(translationKeys.warning).toUpperCase()
            dataCollectedWarning = [
                formData.is_publish ? t(translationKeys.quiz_published_alert_before_edit_message) : t(translationKeys.quiz_edit_alert)
            ]
        } else {
            dataCollectedTitle = t(translationKeys.warning).toUpperCase()
            dataCollectedWarning = [
                t(translationKeys.form_edit_alert)
            ]
        }
    }
    const setDataEditWarning = (boolean, formData) => {
        formControlFormData.current = formData;
        populateDataCollectedWarning(formData);
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DATA_COLLECTED_WARNING, payload: boolean});
    }
    return (
        <>
            <div id='content-container'
                 className={isMobileOnly ? 'dashboard-content-outer-container-mobile' : 'dashboard-content-outer-container'}
                 style={{paddingBottom: isMobileOnly && isShowingMobileFormControl ? 0 : null, height: isMobileOnly ? null : "75%"}}>
                {showForms ?
                    <FormsContainer previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                    showDataEditWarning={setDataEditWarning}
                                    timeOutForLongPress={timeOutForLongPress}
                                    refreshStorageTotals={props.refreshStorageTotals}
                    />
                    : null}
                {showQuiz ?
                    <QuizzesContainer previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                      searchedDataList={props.searchedDataList}
                                      showDataEditWarning={setDataEditWarning}
                                      timeOutForLongPress={timeOutForLongPress}
                                      refreshStorageTotals={props.refreshStorageTotals}
                    />
                    : null}
                {showShare ?
                    <SharesContainer previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                     showDataEditWarning={setDataEditWarning}
                                     timeOutForLongPress={timeOutForLongPress}
                                     refreshStorageTotals={props.refreshStorageTotals}
                    />
                    : null}
                {showTemplates && !isMobileOnly ?
                    (templateType === undefined) ? <Loader/> :
                        <TemplateFormsContainer
                            previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}/> : null}
                {showTemplates && isMobileOnly ?
                    (templateType === undefined) ? <Loader/> :
                        <MobileTemplateContainer
                            previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}/> : null}
                {showMore ? <MobileMoreContainer/> : null}
            </div>
            {!isMobileOnly && !showShare ?
                <DashBoardFABButton
                    timeOutForLongPress={timeOutForLongPress}
                    multipleSelectedArrayRef={showQuiz ? multipleQuizzesSelectedArray : showForms ? multipleFormsSelectedArray : null}
                    bodyContainerElementRef={props.bodyContainerElementRef}
                />
                : null}
            {(showDataCollectedWarning && formControlFormData.current) ?
                <ModalDialog
                    header={dataCollectedTitle}
                    body={dataCollectedWarning}
                    buttons={
                        [
                            {
                                text: t(translationKeys.cancel).toUpperCase(),
                                action:
                                    () => {
                                        // this.showHoverCursor(true)
                                        dispatch({
                                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DATA_COLLECTED_WARNING,
                                            payload: false
                                        });
                                    }
                            },
                            {
                                text:  formControlFormData.current?.is_quiz && formControlFormData.current?.is_publish ? t(translationKeys.continue).toUpperCase() : t(translationKeys.edit).toUpperCase(),
                                action:
                                    () => {
                                        const getChangePageParameters = () => {
                                                dispatch({
                                                    type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                                                    payload: BUILDER_CREATION_SOURCE.SAVED
                                                })
                                                dispatch({
                                                    type: APP_ACTION_TYPE.SET_TEMPLATE_THEME,
                                                    payload: formControlFormData.current.theme
                                                })
                                                dispatch({
                                                    type: APP_ACTION_TYPE.SET_FORM_DATA,
                                                    payload: formControlFormData.current
                                                })
                                                dispatch({
                                                    type: APP_ACTION_TYPE.SET_IS_QUIZ,
                                                    payload: formControlFormData.current.is_quiz
                                                })
                                                dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShare})
                                                dispatch({
                                                    type: APP_ACTION_TYPE.SET_CURRENT_PAGE,
                                                    payload: Pages.builder
                                                })
                                        }
                                        getChangePageParameters()
                                        dispatch({
                                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DATA_COLLECTED_WARNING,
                                            payload: false
                                        });
                                    }
                            }
                        ]
                    }
                /> : null
            }
            {showEditPremiumPopUp ? <ModalDialog header={`${showQuiz || showShareScreen === TABS_DATA.QUIZ ? t(translationKeys.cant_edit_this_quiz).toUpperCase() : t(translationKeys.cant_edit_this_form).toUpperCase()}`}
                                                 body={[showQuiz || showShareScreen === TABS_DATA.QUIZ ? t(translationKeys.plan_expired_edit_quiz) : t(translationKeys.plan_expired_edit_form)]}
                                                 buttons={
                                                     [
                                                         {
                                                             text: t(translationKeys.close).toUpperCase(),
                                                             action: () => {
                                                                 dispatch({
                                                                     type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP,
                                                                     payload: false
                                                                 })
                                                             }
                                                         },
                                                         {
                                                             text: t(translationKeys.upgrade),
                                                             action: () => {
                                                                 dispatch({
                                                                     type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP,
                                                                     payload: false
                                                                 })
                                                                 if (isMobileOnly) {
                                                                     dispatch({
                                                                         type: APP_ACTION_TYPE.SET_CURRENT_PAGE,
                                                                         payload: Pages.premium_plans
                                                                     })
                                                                 } else {
                                                                     dispatch({
                                                                         type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP,
                                                                         payload: true
                                                                     })
                                                                 }
                                                             }
                                                         }
                                                     ]
                                                 }
            /> : null}
            {showDuplicatePremiumPopUp ? <ModalDialog header={`${showQuiz || showShareScreen === TABS_DATA.QUIZ ? t(translationKeys.cant_duplicate_this_quiz).toUpperCase() : t(translationKeys.cant_duplicate_this_form).toUpperCase()}`}
                                                      body={[`${showQuiz || showShareScreen === TABS_DATA.QUIZ ? t(translationKeys.plan_expired_duplicate_quiz) : t(translationKeys.plan_expired_duplicate_form)}`]}
                                                      buttons={
                                                          [
                                                              {
                                                                  text: t(translationKeys.close).toUpperCase(),
                                                                  action: () => {
                                                                      dispatch({
                                                                          type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DUPLICATE_PREMIUM_POP_UP,
                                                                          payload: false
                                                                      })
                                                                  }
                                                              },
                                                              {
                                                                  text: t(translationKeys.upgrade).toUpperCase(),
                                                                  action: () => {
                                                                      dispatch({
                                                                          type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DUPLICATE_PREMIUM_POP_UP,
                                                                          payload: false
                                                                      })
                                                                      if (isMobileOnly) {
                                                                          dispatch({
                                                                              type: APP_ACTION_TYPE.SET_CURRENT_PAGE,
                                                                              payload: Pages.premium_plans
                                                                          })
                                                                      } else {
                                                                          dispatch({
                                                                              type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP,
                                                                              payload: true
                                                                          })
                                                                      }
                                                                  }
                                                              }
                                                          ]
                                                      }
            /> : null}
        </>
    )
}

export default ContentContainer

