const initialState = {
    isShowingForms: true,
    showAllFormsOfThisCategory: null
}

export const TEMPLATE_ACTION_TYPE = {
    SET_IS_SHOWING_FORMS: 'SET_IS_SHOWING_FORMS',
    SET_SHOW_ALL_FORMS_OF_THIS_CATEGORY: 'SET_SHOW_ALL_FORMS_OF_THIS_CATEGORY'
}

const templateReducer = (state = initialState, action) => {
    switch (action.type) {
        case (TEMPLATE_ACTION_TYPE.SET_SHOW_ALL_FORMS_OF_THIS_CATEGORY):
            return {
                ...state,
                showAllFormsOfThisCategory: action.payload
            }
        case (TEMPLATE_ACTION_TYPE.SET_IS_SHOWING_FORMS):
            return {
                ...state,
                isShowingForms: action.payload
            }
        default:
            return state
    }
}

export default templateReducer
