import React, {useEffect, useState} from 'react'
import ActionButtonContainer from "../../../shared/containers/ActionButtonContainer";
import add_image from "../../../../public/images/icons/add-white-24dp.svg";
import CollaboratorRow from "./CollaboratorRow";
import PopUpContainer from "../../../shared/components/PopUpContainer";
import AddCollaboratorPopup from "../../../shared/components/AddCollaboratorPopup";
import {useDispatch, useSelector} from "react-redux";
import {COLLABORATOR_ACTION_TYPE} from "../../utils/constants";
import {isMobileOnly} from "react-device-detect";
import addPeople from '../../../../public/images/group_add_blue.svg'
import {COLLABORATOR_ROLES} from "../enums/collaboratorsRoles";
import {registerGAEvent} from "../../../shared/utils/analytics";
import {EMAIL_ACTION_TYPE} from "../../reducers/collabAutocompleteReducer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import Loader from "../../../shared/components/Loader";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {getSpecificFormData} from "../../../shared/utils/sash-v2-api";
import {getQuizById} from "../../../shared/utils/quiz-api-services";
import {PAGE_HASH_LOCATION, Pages} from "../../utils/pages";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";

const COLLABORATOR_ROLES_TEXT = {
    ADMINISTRATOR: t(translationKeys.admin),
    EDITOR: t(translationKeys.editor),
    VIEWER: t(translationKeys.viewer)
}

const SettingsCollaboratorContainer = (props) => {

    const [userId, setUserId] = useState()
    const [role, setRole] = useState(2)
    const [showPopUp, setShowPopUp] = useState(false)
    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const formId = useSelector(state => state.appReducer.formData?._id)
    const currentUserId = useSelector(state => state.userReducer?.userId)
    const selected = useSelector(state => state.languageReducer?.selectedLanguage)
    const collaborator = useSelector(state => state.collaboratorsData?.collaborators)
    const builderCreationSource = useSelector(state => state.appReducer.builderCreationSource)
    const dispatch = useDispatch()

    window.onhashchange = () => {
        if ((window.location.hash === PAGE_HASH_LOCATION.form_builder || window.location.hash === PAGE_HASH_LOCATION.dashboard) && !props.builder && isMobileOnly) {
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
        }
    }

    document.onkeydown = (e) => {
        if (e.key === "Escape") {
            setShowPopUp(false)
        }
    }

    useEffect(() => {
        const successFunction = (res) => {
            if (res) {
                setUserId(res.user_id)
                dispatch({type: COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_DATA, payload: res.collaborators})
                res.collaborators?.map((collaborator) => {
                    if (collaborator.user_id === currentUserId) {
                        setRole(collaborator.role)
                    }
                })
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        if (formId && !isQuiz && builderCreationSource !== BUILDER_CREATION_SOURCE.DUPLICATE) {
            getSpecificFormData(formId, successFunction, failureFunction)
        } else if (formId && isQuiz && builderCreationSource !== BUILDER_CREATION_SOURCE.DUPLICATE) {
            getQuizById(formId, successFunction, failureFunction)
        } else {
            setUserId(currentUserId)
        }
        dispatch({type: EMAIL_ACTION_TYPE.SET_SUGGESTIONS})
    }, [])

    return (
        <>
            {userId ? null : <Loader width={isMobileOnly ? '100%' : '90%'} height={'100%'}/>}
            <div id={'settings-collaborator-container'} style={{display: 'flex'}}>
                <img style={{height: '26px', margin: '20px 10px'}} src={addPeople} alt={'addPeople'}/>
                <div id={'settings-add-collaborator'} style={{width: '-webkit-fill-available'}}>
                    <h5 style={{
                        fontWeight: '400',
                        fontSize: '18px',
                        margin: '20px 0'
                    }}>{t(translationKeys.collaboration)}</h5>
                    <p style={{color: '#8E8E8E', fontSize: '13px'}}>
                        {role === COLLABORATOR_ROLES.VIEWER || role === COLLABORATOR_ROLES.EDITOR ?
                            t(translationKeys.you_dont_have_add_collaborator_permission) :
                            isQuiz ? t(translationKeys.share_collaboration_quiz) : t(translationKeys.share_collaboration_form)
                        }
                    </p>
                    {role === COLLABORATOR_ROLES.VIEWER || role === COLLABORATOR_ROLES.EDITOR ? null :
                        <ActionButtonContainer buttonText={t(translationKeys.add_collaborator)}
                                               fontSize={'15px'}
                                               buttonWidth={selected === 'ta' ? '255px' : '225px'}
                                               height={'45px'}
                                               handleClick={() => {
                                                   if (isQuiz) {
                                                       registerGAEvent('Quiz-Builder', 'Settings', 'clicked-add-collaborator')
                                                   } else {
                                                       registerGAEvent('Form-Builder', 'Settings', 'clicked-add-collaborator')
                                                   }
                                                   setShowPopUp(true)
                                               }}
                                               base64ImageData={add_image}/>}
                    <CollaboratorRow userId={userId}/>
                    {collaborator ? collaborator.map(collaborator => {
                        if (collaborator) {
                            return <CollaboratorRow ownerId={userId}
                                                    role={collaborator.role === COLLABORATOR_ROLES.VIEWER ? COLLABORATOR_ROLES_TEXT.VIEWER : collaborator.role === COLLABORATOR_ROLES.EDITOR ? COLLABORATOR_ROLES_TEXT.EDITOR : COLLABORATOR_ROLES_TEXT.ADMINISTRATOR}
                                                    key={collaborator.user_id}
                                                    owner={currentUserId === userId}
                                                    collaboratorId={collaborator._id}
                                                    editor={role === COLLABORATOR_ROLES.EDITOR}
                                                    viewer={role === COLLABORATOR_ROLES.VIEWER}
                                                    userId={collaborator.user_id}/>
                        }
                    }) : null}
                    {showPopUp ? <PopUpContainer children={<AddCollaboratorPopup setShowPopUp={setShowPopUp}
                                                                                 userId={userId}
                                                                                 owner={currentUserId === userId}
                                                                                 cardTitle={t(translationKeys.add_collaborator)}/>}/> : null}
                </div>
            </div>
        </>
    )
}

export default SettingsCollaboratorContainer