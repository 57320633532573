import Jimp from 'jimp/es';

import {addImagePathInUserFile} from "./prepare-aws-user-json";
import {setAwsFolderName, uploadFile, AWS_UPLOAD_TYPE, AWS_FOLDER_NAMES} from "./aws";

/**
 *
 * @param AWSUploadType AWS_UPLOAD_TYPE
 * @param file
 * @param successFunction
 * @param failureFunction
 * @param userId
 */
export function uploadImageFile(AWSUploadType, file, successFunction, failureFunction, userId) {
    switch (AWSUploadType) {
        case AWS_UPLOAD_TYPE.FORM:
            setAwsFolderName(AWS_FOLDER_NAMES.IMAGES_FOLDER);
            break;
        case AWS_UPLOAD_TYPE.LOGO:
            setAwsFolderName(AWS_FOLDER_NAMES.LOGOS_FOLDER);

            if (file.type === 'image/jpeg' || file.type === 'image/jpg') {
                // Use Jimp to convert the file to PNG and then upload it to AWS.
                Jimp.read(URL.createObjectURL(file))
                    .then(convertedFile => {
                        return convertedFile
                            .resize(Jimp.AUTO, 200)
                            .getBase64(Jimp.MIME_PNG, (err, result) => {
                                fetch(result)
                                    .then(res => res.blob())
                                    .then(blob => {
                                        const pngFile = new File([blob], file.name, {type: "image/png"})

                                        doFileUpload(pngFile, AWSUploadType, userId, successFunction, failureFunction)
                                    })
                            })
                    })
                    .catch(err => {
                        console.error(err)
                    })

                return
            }

            break;
        case AWS_UPLOAD_TYPE.THEME:
            setAwsFolderName(AWS_FOLDER_NAMES.THEMES_FOLDER);
            break;
        case AWS_UPLOAD_TYPE.ADMIN_CAREER:
            setAwsFolderName(AWS_FOLDER_NAMES.ADMIN_FOLDER + "/careers");
            break;
    }

    doFileUpload(file, AWSUploadType, userId, successFunction, failureFunction)
}

function doFileUpload(file, uploadType, userId, successFunction, failureFunction) {
    uploadFile(file, uploadType, userId, function (data) {
        const dataJSONString = JSON.stringify(data);
        const JSONData = JSON.parse(dataJSONString);

        if (uploadType === AWS_UPLOAD_TYPE.LOGO) {
            successFunction(uploadType, null, JSONData.Location)
        } else if (uploadType === AWS_UPLOAD_TYPE.FILE) {
            successFunction(data)
        } else if (uploadType === AWS_UPLOAD_TYPE.ADMIN_CAREER) {
            successFunction(uploadType, null, JSONData.Location)
        } else if (JSONData.Location) {
            addImagePathInUserFile(uploadType, userId, JSONData.Location, (userJson) => {
                successFunction(uploadType, userJson, JSONData.Location);
            });
        }
    }, function () {
        failureFunction();
    });
}

export function uploadUserJson(userId, formImages, themeImages, successFunction, failureFunction) {
    let newUserJson;

    if (formImages && themeImages) {
        newUserJson = {
            form_images: formImages,
            theme_images: themeImages
        };
    } else if (!formImages && themeImages) {
        newUserJson = {
            form_images: [],
            theme_images: themeImages
        };
    } else if (!themeImages && formImages) {
        newUserJson = {
            form_images: formImages,
            theme_images: []
        };
    }

    const fileName = userId + '.json';
    const content = JSON.stringify(newUserJson);
    const userFile = new File([content], fileName, {type: "application/json"});
    setAwsFolderName(AWS_FOLDER_NAMES.USERS_JSON_FOLDER);
    uploadFile(userFile, false, null, function (data) {
        successFunction(data);
    }, function () {
        failureFunction();
    });
}