import React, {useRef, useState} from 'react';
import PropTypes from "prop-types";
import styles from '../css/search-base-component.module.css'
import search_icon from '../../../public/images/search_ash.png'
import close_icon from '../../../public/images/close_black.svg'

export default function SearchBaseComponent(props) {
    const [showCloseIcon, setShowCloseIcon] = useState(false)
    const inputRef = useRef(null)

    const handleOnFocus = (e) => {
        e.stopPropagation()
        setShowCloseIcon(true)
    }

    const handleCloseIconClick = (e) => {
        e.stopPropagation()
        setShowCloseIcon(false)
        inputRef.current.value = ''
        props.handleTextChange('')
    }
    return (
        <div className={styles.search_base_component}>
            <div className={styles.search_inner_container}>
                <img className={showCloseIcon ? styles.remove : null}  alt='search_icon' src={search_icon}/>
                <input onFocus={handleOnFocus}
                       ref={inputRef}
                       placeholder='Search'
                       onKeyDown={props.handleKeyDown}
                       value={props.value}
                       onInput={e => props.handleTextChange(e.target.value)}/>
            </div>
            <img alt='close' className={showCloseIcon ? null : styles.hide} src={close_icon}
                 onClick={e => handleCloseIconClick(e)}/>
        </div>
    );
}

SearchBaseComponent.propTypes = {
    handleTextChange: PropTypes.func,
    handleKeyDown: PropTypes.func
}

SearchBaseComponent.defaultProps = {
    handleTextChange: value => console.log(value),
    handleKeyDown: event => console.log(event)
}
