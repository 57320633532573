import React from "react"
import {useSelector} from "react-redux";

const usePremiumMgr = () => {
    const userId = useSelector(state => state.userReducer?.userId)

    const loadRazorpay = () => {
        return new Promise((resolve) => {
            const script = document.createElement("script")
            script.src = "https://checkout.razorpay.com/v1/checkout.js"
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }

    const displayRazorpay = async (subscriptionId, updateCard, verifyRazorpayPayment, failureFunction) => {
        const load = await loadRazorpay()

        if (!load) {
            alert("Something went wrong!")
            return
        }

        const options = {
            key: process.env.REACT_APP_ENV_RAZORPAY_KEY,
            currency: "INR",
            name: "SurveyHeart LLP",
            description: "Description",
            image: "https://surveyheart.com/images/surveyheart1.png",
            prefill: {
                email: userId
            },
            subscription_card_change: updateCard,
            subscription_id: subscriptionId,
            recurring: true,
            theme: {
                "color": "#004BA0"
            },
            handler: verifyRazorpayPayment
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", (err) => {
            if (err) {
                failureFunction()
            }
        })
        paymentObject.open()
    }

    return {
        loadRazorpay,
        displayRazorpay
    }
}

export default usePremiumMgr
