// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/radio/dist/mdc.radio.min.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js!../../../node_modules/@material/form-field/dist/mdc.form-field.min.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, ".mdc-form-field {\n    width: 100%;\n    font-family: 'Nunito Sans', Sans-serif, sans-serif;\n    margin-bottom: 6px;\n}\n\n/* to remove the character count for the option question types */\n.star-line .mdc-text-field-character-counter {\n    display: none;\n}\n", ""]);
// Exports
module.exports = exports;
