export const translationsEnglish = {
    all: 'All',
    grouped_bar_chart: 'Grouped Bar Chart',
    stacked_bar_chart: 'Stacked Bar Chart',
    tabular_chart: 'Tabular Chart',
    ago: "ago",
    now: "Now",
    start: "Start",
    add_column: 'Add Column',
    add_row: 'Add Row',
    user_info_for_file_upload: "User Info For File Uploads",
    user_info_for_file_upload_desc1: "Since you have added a File Upload Question, the option to collect User Info has been enabled. This will make it easier to identify downloaded files. Respondents will see a dialog to enter their info.",
    user_info_for_file_upload_desc2: "You can change this option in Settings if you don't want the dialog to be shown.",
    your_account: "Your Account",
    account_blocked: "ACCOUNT BLOCKED",
    account_blocked_alert: "This account has been blocked for violating our Terms and Conditions.",
    sign_out: "Sign Out",
    short_answer: "Short Answer",
    long_answer: "Long Answer",
    email: "Email",
    number: "Number",
    app_name: "SurveyHeart",
    file: 'File',
    files: 'Files',
    file_upload: "File Upload",
    faq_videos: "FAQ Videos",
    question_error: "Question can\'t be added",
    storage: "Storage",
    form: "Form",
    quiz: "Quiz",
    fup_desc: "You can now allow respondents to your Forms and Quizzes to upload files!\n\nSelect the File Upload Question Type to get started. ",
    new_fup_feature_title: "New File Upload Feature!",
    multiple_choice: "Multiple Choice",
    multiple_choice_grid: "Multiple Choice Grid",
    date_text: "Date",
    time_text: "Time",
    uploading: "Uploading…",
    add_question: "Add Question",
    add_question_alert: "Kindly add at least one question before submitting the form.",
    add_question_alert_quiz: "Kindly add at least one question before submitting the quiz.",
    ok: "OK",
    title: "Title",
    title_required: "Title is required",
    form_name_alert: "Kindly name the form before submitting it",
    quiz_name_alert: "Kindly name the quiz before submitting it",
    no_connection: "No Connection",
    check_connection: "Kindly Check your network connection.",
    finish_incomplete_question: "Kindly finish the incomplete questions in the form",
    draft: "Draft",
    save_to_draft_alert: "Would you like to save this form as a draft to avoid losing your changes?",
    save_to_draft_quiz_alert: "Would you like to save this quiz as a draft to avoid losing your changes?",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    live_preview: "Live Preview",
    themes: "Themes",
    tap_to_set_theme: "Tap here to set theme for the form.",
    select_image: "Select Image",
    created: "Created",
    form_created_successfully: "Form created successfully",
    warning: "Warning",
    favourites: "Favourites",
    form_edit_alert: "This form has already been submitted and responses have been collected. Editing the form may affect the previous responses.",
    quiz_edit_alert: "This quiz has already been submitted and answers have been collected. Editing the quiz may affect the previous answers.",
    edit: "Edit",
    download_as: "Download as",
    delete_form: "Delete Form",
    delete_form_alert: "Do you want to delete this form? This action cannot be undone.",
    delete_quizzes_alert: "Do you want to delete these quizzes? This action cannot be undone.",
    delete_forms_alert: "Do you want to delete these forms? This action cannot be undone.",
    delete_quiz_alert: "Do you want to delete this quiz? This action cannot be undone.",
    delete_answers_alert: "Do you want to delete these answers?",
    delete_answer_alert: "Do you want to delete this answer?",
    delete: "Delete",
    delete_all: "Delete All",
    section: "Section",
    worst: "Worst",
    server_error_alert: "The server could not be found. Please try again after some time!!",
    signed_in_as: "Signed in as",
    signing_out: "Signing out…",
    create_form: "Create Form",
    create_blank_form: "Create Blank Form",
    create_blank_quiz: "Create Blank Quiz",
    close: "Close",
    smile: "Smile",
    dislike: 'Dislike',
    like: 'Like',
    best: 'Best',
    response_collection_exit_alert: "Do you want to exit from collecting response?",
    form_builder: "Form Builder",
    form_builder_promo: "Instant Preview, Templates & Themes for Forms & Quizzes",
    results: "Results",
    result_feature_promo: "Analyze and Export your survey results",
    notification: "Instant Notifications",
    delete_option_alert: "Do you want to delete this option?  [Note: This action cannot be undone]",
    question_type: "Question Type",
    change_question_type_alert: "Tap here to change question type",
    preview_not_available: "Preview Not Available",
    delete_question: "Delete Question",
    delete_response_collected_question_alert: "Deleting this question will affect previous collected responses.\n\nAre you sure, you want to proceed?",
    delete_question_alert: "Are you sure, do you want to delete this question?",
    add_option: 'Add Option',
    add_other_option: "Add 'Other' Option",
    general_form_type_description: "Create new Form/Survey",
    quiz_type_description: 'Create new Quiz',
    sheet_disconnected: 'Google Sheet Disconnected',
    sheet_not_connected: 'Sheet Not Connected',
    sheet_not_connected_desc: 'Please contact the owner to connect the google sheet.',
    options: "Options",
    option: 'Option',
    no_answer: "No Answer",
    no_response: "No Response",
    responses: "Responses",
    response: "Response",
    serial_number: "S.No",
    submit_time: "Submit Time",
    export_success: "Exported Successfully",
    answer_with_colan: "Answer :",
    others: "Others",
    delete_response: "Delete Response",
    deleted: "Deleted",
    my_themes: "My Themes",
    limit_reached: "Limit Reached",
    limit_reached_description: "You have reached the image uploaded limit.",
    modified: "Modified",
    delete_image: "Delete Image",
    delete_image_alert: "Are you sure, do you want to delete this image?",
    delete_theme_image_desc: "Do you want to delete this image? If you proceed, this image will not be available to any Forms or Quizzes that are using it. This action cannot be undone.",
    section_title: "Section title",
    section_description: "Section description",
    show_scale: 'Show Scale',
    show_options: "Show Options",
    show_rows_and_columns: "Show Rows & Columns",
    options_required: "Options required",
    link_copied: "Link Copied",
    app_not_found: "App not found",
    default_text: "Default",
    dark_mode: "Dark Mode",
    done: "Done",
    upload_image: "Upload Image",
    question: "Question",
    required: "Required",
    share: "Share",
    form_link: "Form Link",
    copy_link: "Copy Link",
    view_form: "View Form",
    menu: "Menu",
    view_all: "View All",
    settings: "Settings",
    submit: "Submit",
    change_theme: "Change Theme",
    tap_here_to_choose_theme: "Tap here to choose theme",
    click_or_change_current_theme: "Click the current Theme or 'Change' below to choose a different Theme",
    show_logo: "Show Logo",
    show_logo_alert: "Do you want to show your Logo?",
    welcome_page: "Welcome Page",
    show_welcome_page_alert: "Do you want to show a Welcome Page?",
    start_button: "Start Button",
    allow_multiple_responses: "Allow Multiple Responses",
    allow_more_response_alert: "Do you want to allow the user to respond more than once?",
    allow_more_quiz_attempt_alert: "Do you want to allow the user to attempt more than once?",
    show_summary: "Show Summary",
    show_results: "Show Quiz Results",
    allow_responders_view_summary_alert: "Do you want to allow respondents to view a summary of all responses after submitting?",
    allow_result_view_quiz_alert: "Do you want to allow the user to view results?",
    show_question_number: "Show Question Number",
    show_question_number_in_form: "Do you want to show question numbers on the form?",
    show_question_number_in_quiz: "Do you want to show question numbers on the quiz?",
    shuffle_questions: "Shuffle Questions",
    shuffle_questions_alert: "Do you want to shuffle the form questions?",
    shuffle_quiz_questions_alert: "Do you want to shuffle the quiz questions?",
    description: "Description",
    disconnect: 'Disconnect',
    builder: "Builder",
    preview: "Preview",
    form_status: "Form Status",
    active: "Active",
    inactive: "Inactive",
    search_by_question_title_from_forms: 'Search by question title from existing forms',
    search_by_question_title_from_quizzes: 'Search by question title from existing quizzes',
    view_responses: "View Responses",
    edit_form: "Edit Form",
    duplicate_form: "Duplicate Form",
    drafts: "Drafts",
    no_form_available: "No Form Available!",
    notifications: "Notifications",
    feedback: "Feedback",
    privacy_policy: "Privacy Policy",
    open_individual_responses: "Open Individual Responses",
    bar_chart: "BAR CHART",
    pie_chart: "PIE CHART",
    summary: "Summary",
    invalidated_form_cannot_be_selected: "An Invalidated Form can not be selected.",
    invalidated_quiz_cannot_be_selected: "An Invalidated Quiz can not be selected.",
    individual: "Individual",
    search: "Search",
    image: "Image",
    video: "Video",
    website: "Website",
    exit: "Exit",
    response_limit_reached: "Response Limit Reached",
    response_limit_reached_alert: "This form has exceeded the allowed threshold for response data; therefore, we prevented this form from accepting any more responses.\n\nIf you really need to continue with the existing Survey, we suggest that you duplicate it and share the link.",
    unapproved_form: "Unapproved Form",
    unapproved_form_alert: "This form is using words that may violate our policies. \n\nIf you believe that this form has been blocked in error, please open the form link below and provide the information requested in the form.",
    choose_your_language: "Choose Your Language",
    provide_explanation: "Provide Explanation",
    selected: "Selected",
    review_button: "Review",
    row: "Row",
    not_specified: "Not Specified",
    quiz_control: "Quiz Control",
    allow_multiple_attempts: "Allow Multiple Attempts",
    user_info: "User Info",
    name_s: "Name",
    duration: "Duration",
    set_duration_quiz_alert: "Do you want to set a duration for the quiz?",
    user_information_description: "Do you want to get participant information?",
    set_duration: "Set Duration (Hours : Minutes)",
    minutes: "Minutes",
    hours: "Hours",
    minute: "Minute",
    hour: "Hour",
    correct: "Correct",
    wrong: "Wrong",
    skipped: "Skipped",
    score: "Score",
    forms: "Forms",
    quizzes: "Quizzes",
    more: "More",
    quiz_status: "Quiz Status",
    view_answers: "View Answers",
    view_quiz: "View Quiz",
    duplicate_quiz: "Duplicate Quiz",
    no_quiz_available: "No Quiz Available!",
    quiz_link: "Quiz Link",
    unapproved_quiz: "Unapproved Quiz",
    unapproved_quiz_alert: "This quiz is using words that may violate our policies. \n\nIf you believe that this quiz has been blocked in error, please open the form link below and provide the information requested in the form.",
    do_you_want_to_show_all_correct_answers_to_users: "Do you want to show correct answer to users?",
    results_publish_date: "Results Publish Date",
    do_you_want_to_set_date_to_publish_results: "Do you want to set date to publish results",
    quiz_result: "Quiz Result",
    enter_answer: "Answer",
    manage: "Manage",
    manage_storage: 'Manage Storage',
    shuffle_options: 'Shuffle Options',
    shuffle_options_enabled: 'Shuffle Options Enabled',
    shuffle_options_disabled: 'Shuffle Options Disabled',
    add_options_to_enable_this_feature: 'Add Options to Enable this Feature',
    attach: 'Attach',
    shuffle: 'Shuffle',
    marks: "Marks",
    mark: "Mark",
    correction_status: "Status:",
    status_validated: "Validated",
    pending: "Pending",
    results_publish: "Publish",
    publish: "Publish",
    publish_description: "Do you want to publish quiz results?",
    publish_description_note: "(Final Score will be visible in respondent\'s answer sheet)",
    published_successfully: "Published Successfully",
    choice_type_validation_note: "*Multiple choice validated automatically",
    short_answer_type_validation_note: "*Short answer validated automatically",
    long_answer_type_validation_note: "*Long answer NOT validated automatically",
    fileupload_answer_type_validation_note: "*File Upload NOT validated automatically",
    dropdown_answer_type_validation_note: "*Dropdown choice validated automatically",
    save: "Save",
    questions: "Questions",
    show_correct_answer: "Show Correct Answer",
    show_answer: "Show answer",
    hide_answer: "Hide answer",
    no_marks: "No Marks",
    question_is_required: "Question title is required",
    marks_is_required: "Marks is required",
    options_are_required: "Options are required",
    options_limit_reached: 'options limit reached',
    options_limit_reached_description: 'You have reached the limit for number of options allowed on a question.',
    correct_answer_required: "Correct answer is required",
    enter_valid_mark: "Enter valid mark",
    evaluated: "Evaluated",
    saved: "Saved",
    pending_evaluation: "Pending Evaluation",
    alphabet: "Alphabet",
    not_evaluated: "Not evaluated",
    percentage_ascending: "Percentage - Ascending",
    percentage_descending: "Percentage - Descending",
    pending_evaluation_alert_message: "You must complete evaluation of all the answers before publishing.",
    show_unevaluated: "Show Unevaluated",
    publish_success_alert_message: "Respondents can see their results in their corresponding answer sheets.\n\nThis quiz has been automatically closed.",
    publishing: "Publishing",
    quiz_published: "Quiz Published",
    quiz_published_alert_before_edit_message: "The quiz has been published. If you edit this quiz will be unpublished",
    quiz_has_answers: "Quiz has Answers",
    quiz_has_answer_alert_message: "In order to edit this quiz, you must delete all of the existing answers.",   // two strings
    unavailable_features_description: 'This Form has features that are not yet supported in the Web App',
    unavailable_quiz_features_description: 'This Quiz has features that are not yet supported in the Web App',
    unavailable: 'Unavailable',
    unpublished: "Unpublished",
    published: "Published",
    setting_updated: "Setting Updated",
    account: "Account",
    on: "ON",
    off: "OFF",
    quizzory: "Quizzory",
    scan_qr_code: "Scan QR code",
    answers: "Answers",
    answer_s: "Answer",
    question_limit_reached: "Question Limit Reached",
    reached_limit_for_number_of_questions: "You have reached the limit for number of questions allowed on a form. Therefore, no new questions can be added",
    life_time: "Lifetime",
    last_year: "Last Year",
    last_30_days: "Last 30 Days",
    last_7_days: "Last 7 Days",
    last_24_hours: "Last 24 Hours",
    custom_date: "Custom Date",
    filter_by_date: "Filter By Date",
    filter_by_question: "Filter By Question",
    filter_text: "Filter text",
    add_filter: "Add Filter",
    apply: "Apply",
    from_date: "From Date",
    today: 'Today',
    to_date: "To Date",
    contains: "Contains",
    equal_to: "Equal to",
    not_equal_to: "Not Equal to",
    from_date_required: "From Date Required",
    to_date_required: "To Date Required",
    webapp_promo_text: "Access your Forms, Surveys, Polls & Quizzes Online!",
    question_title: "Question",
    invalid_date: "Invalid Date",
    invalid_url: "Invalid URL",
    invalid_url_description: "The URL does not seem to be valid.",
    delete_response_alert: "Do you want to delete this response?",
    delete_responses_alert: "Do you want to delete these responses?",
    overview: "Overview",
    no_results_found: "No Results Found",
    date_format: "dd-mm-yyyy",
    powered_by_surveyheart: "Powered by \'SurveyHeart\'",
    title_dashboard: "Dashboard",
    title_notifications: "Notifications",
    select_one_response: "Please select at least one response to delete",
    //New String
    shared: "Shared",
    hide: "Hide",
    use_templates: "Use Templates",
    quick_settings: "Quick Settings",
    leave_collaboration: "Leave Collaboration",
    templates: "Templates",
    collapse: "Collapse",
    remove_favourite: "Remove Favourite",
    add_favourite: "Add Favourite",
    collaborator: "Collaborator",
    collaborators: "Collaborators",
    show_more: "Show more",
    create_quiz: "Create Quiz",
    collaborate: "Collaborate",
    share_QR_code: "Share QR Code",
    add_image: "Add image",
    invalid_file_type: "Invalid File Type",
    drag_drop: "Drag & Drop",
    here: "Here",
    choose: "Choose",
    add_account: 'Add Account',
    choose_account: 'Choose Account',
    maximum_allowed_file_size: "Maximum allowed file size is ",
    or: "or",
    next: "Next",
    change_image: "Change Image",
    upload: "Upload",
    view: "View",
    collaboration: "Collaboration",
    share_collaboration_form: "Do you want to Share this form with others?",
    share_collaboration_quiz: "Do you want to Share this quiz with others?",
    multiple_responses_summary: "Multiple Responses, Summary, Collect Email",
    question_number_shuffle: "Question number, Question marks, Shuffle",
    general: "General",
    browse: "Browse",
    owner: "Owner",
    something_went_wrong: "Something went wrong!",
    forget_your_password: "Forget your password?",
    sign_in: "Sign in",
    sign_up: "Sign up",
    using_email: "using Email",
    dont_have_account: "Don't have an account?",
    on_signing_up_agree_to_surveyhearts: "By signing up, you agree to SurveyHeart's",
    and: "and",
    terms_of_use: "Terms of Use",
    already_have_an_account: "Already have an account?",
    signed_in_previously_as_a_google_user: "You have signed in previously as a Google user.",
    sign_in_google_to_continue: "Please sign in through Google to continue.",
    no_account_found_email_address: "No account found with this email address",
    account_already_exist_email: "Account already exist for this email.",
    install_surveyheart_app: "Install SurveyHeart App",
    easy_login_via_app: "Easy Login via App",
    get_notification_instantly: "Get Notification Instantly",
    create_form_quiz_on_the_go: "Create Form/Quiz On The Go",
    check_your_inbox_to_activate_your_account: "Please check your inbox to activate your account",
    select_questions: "Select Questions",
    percentage: "Percentage",
    free_App_to_create_forms: "Get the free App to create forms",
    we_cant_find_the_page_that_you_are_looking_for: "We can't find the page that you are looking for",
    answered: "Answered",
    completing_this_quiz: "Thanks for completing this quiz",
    connected_successfully: 'Connected Successfully',
    seconds: "Seconds",
    enter_your_details: "Enter your Details",
    enter_all_required_information: "Please enter all required information",
    answer_data_not_found: "Answer Data Not Found",
    results_published_soon: "Results will be published soon...",
    previous: 'Previous',
    save_link_for_future_reference: "Save this link for future reference",
    choice_questions_only: "Choice Questions Only",
    score_is_not_final: "Note: This score is not final",
    tabular: "Tabular",
    text: "text",
    answer_response_alert: "This question requires an answer",
    invalid_email_address: "Invalid email address",
    answer_not_available: "Answer Not Available",
    validated_automatically: "* validated automatically",
    answer_link: "Answer Link",
    embed_code: 'Embed Code',
    copy_code: 'Copy Code',
    code_copied: 'Code Copied',
    share_via: "Share via",
    print: "Print",
    column: "Column",
    columns: "Columns",
    rows: "Rows",
    columns_all: "Columns (All)",
    submitted_time: "Submitted time",
    unable_to_submit: 'Unable To Submit',
    unable_to_submit_alert_line1: 'Since this Quiz has received Answers, you have limited access to make changes.',
    unable_to_submit_alert_line2: 'In order to get full access to edit this quiz, you must delete all the existing answers.',
    action_blocked: 'Action Blocked',
    action_blocked_alert: 'Action blocked due to existing answers for this quiz. Allowing this might impact previous answers.',
    quiz_edit_submit_alert: 'Editing the Quiz may affect the Marks, Percentage and require re-evaluation of certain questions.',
    continue: 'Continue',
    submitted: 'Submitted',
    submitting: 'Submitting...',
    filter: "Filter",
    exit_screen: "Exit Screen",
    full_screen: "Full Screen",
    tabular_view: "Tabular View",
    clear_all: "Clear All",
    you_are_all_caught_up: "You’re all caught up!",
    check_back_later_for_new_notifications: "Check back later for new notifications.",
    requires_answer: "requires answer",
    enter_member_id: "Enter member id",
    viewers_can_read_form_responses: "Viewers can read the form and view the responses",
    editors_edit_form_view_responses: "Editors can edit the form and view the responses",
    admin_edit_form_add_collaborator: "Admin can edit the form and add collaborator",
    invalid_member_id: "Invalid member id",
    already_member_added: "This user already has access",
    add_collaborator: "Add Collaborator",
    add_template: "Add Template",
    template_category: "Template Category",
    add_category: "Add Category",
    admin: "Admin",
    editor: "Editor",
    viewer: "Viewer",
    searched_form_not_found: 'Searched Form Not Found!',
    searched_quiz_not_found: 'Searched Quiz Not Found!',
    no_shared_data_available: 'No Shared Data Available!',
    forms_server_is_not_available: 'Forms Server is not available',
    quiz_server_is_not_available: 'Quiz Server is not available',
    leave: 'leave',
    password: 'Password',
    new_password: 'New Password',
    retype_password: 'Retype Password',
    create_password: 'Create Password',
    view_results: 'View Results',
    control: 'Control',
    q_control: 'Q.Control',
    q_result: 'Q.Result',
    no_matches_found: "No Matches Found",
    dropdown: "Dropdown",
    checkboxes: "Checkboxes",
    checkboxes_grid: "Checkboxes Grid",
    star_rating: 'Star Rating',
    linear_scale: 'Linear Scale',
    info_1: "Info 1",
    info_2: "Info 2",
    info_3: "Info 3",
    new: 'new',
    download_all: 'Download All',
    download: 'Download',
    sorting: 'Sorting',
    sort_by: 'Sort by',
    refresh: 'Refresh',
    add: 'Add',
    refresh_preview_description: "Click the 'Refresh' button to update the Preview",
    enter_users_email_address: "Enter User's Email Address",
    do_you_want_to_delete_these: 'Do you want to delete these',
    do_you_want_to_delete_this_form: 'Do you want to delete this Form',
    all_responses_will_also_be_deleted: 'All responses will also be deleted',
    this_action_cannot_be_undone: 'This action cannot be undone.',
    do_you_want_to_delete_this_quiz: 'Do you want to delete this Quiz',
    all_answer_sheets_will_also_be_unavailable: 'All answer sheets will also be unavailable.',
    change: 'Change',
    roles_rights: 'Roles & Rights',
    learn_more: 'Learn more',
    viewers_can_read_quiz_and_view_answers: 'Viewers can read the quiz and view the answers',
    editors_can_edit_quiz_view_answers: 'Editors can edit the quiz and view the answers',
    admin_can_edit_quiz_add_collaborator: ' Admin can edit the quiz and add collaborator',
    change_roles: 'Change Roles',
    remove: 'Remove',
    you_havent_submitted_your_changes_is_it_okay_to_leave_without_submitting: "You haven't submitted your changes. Is it okay to leave without submitting?",
    select_language: 'Select Language',
    are_you_sure_you_want_delete_this_collaborator: 'Are you sure you want to delete this collaborator?',
    are_you_sure_you_want_to_delete_selected_files: 'Are you sure you want to permanently delete the selected file?',
    please_download_before_deleting: 'Please download before deleting. Files once deleted, cannot be recovered!',
    role_has_been_changed_successfully: 'role has been changed successfully',
    do_you_want_to_leave_collaboration: 'Do you want to leave collaboration? ',
    form_builder_access_denied: 'Form Builder Access Denied',
    quiz_builder_access_denied: 'Quiz Builder Access Denied',
    you_only_have_viewer_rights_for_quiz: 'You only have Viewer Rights for this Quiz.',
    you_only_have_viewer_rights_for_form: 'You only have Viewer Rights for this Form.',
    kindly_contact_the_form_owner_for_more_info: 'Kindly contact the Form Owner for more info.',
    kindly_contact_the_quiz_owner_for_more_info: 'Kindly contact the Quiz Owner for more info.',
    incomplete_questions: 'Incomplete Questions :',
    you_have_unsaved_changes_you_sure_you_wish_leave_this_page: 'You have unsaved changes - are you sure you wish to leave this page?',
    languages: 'Languages',
    copy: "Copy",
    duplicate: "Duplicate",
    check_box_grid: 'Check Box Grid',
    start_scale_from_zero: 'Start scale from 0',
    scale_down: 'Scale Down',
    scale_up: 'Scale Up',
    center_label_optional: 'Center Label (Optional)',
    left_label_optional: 'Left Label (Optional)',
    right_label_optional: 'Right Label (Optional)',
    not_good: 'Not Good',
    not_allowed: 'not allowed',
    not_allowed_description: 'Choice question type cannot be changed after collecting responses.',
    neutral: 'Neutral',
    good: 'Good',
    very_good: 'Very Good',
    edit_rights_removed: 'Edit Rights Removed',
    your_rights_to_edit_this_form_have_been_removed: "Your Rights to edit this Form have been removed.",
    your_rights_to_edit_this_quiz_have_been_removed: "Your Rights to edit this Quiz have been removed.",
    please_contact_form_owner_for_more_info: " Please contact the Form Owner for more info.",
    please_contact_quiz_owner_for_more_info: " Please contact the Quiz Owner for more info.",
    last_edited_on: 'Last Edited on ',
    last_edited: 'Last Edited ',
    by: "by",
    you: "You",
    estimated_time: "Estimated Time",
    do_you_want_to_show_estimated_time_taking_quiz: "Do you want to show the estimated time for taking the quiz?",
    do_you_want_to_show_estimated_time_taking_form: "Do you want to show the estimated time for taking the form?",
    show_question_count: "Show Question Count",
    do_you_want_to_show_total_number_questions_quiz: "Do you want to show total number of questions in the quiz?",
    do_you_want_to_show_total_number_questions_form: "Do you want to show total number of questions in the form?",
    show_question_marks: "Show Question Marks",
    show_question_marks_quiz: "Do you want to show question marks in the quiz",
    you_dont_have_add_collaborator_permission: "You don't have add collaborator permission",
    modified_date: "Modified Date",
    created_date: "Created Date",
    question_cant_be_added: "QUESTION CAN\'T BE ADDED",
    another_file_upload_question_cant_added: "ANOTHER FILE UPLOAD QUESTION CAN'T BE ADDED",
    you_can_have_only: "You can have only",
    file_upload_questions_in_form: "File Upload Questions in a Form",
    file_upload_questions_in_quiz: "File Upload Questions in a Quiz",
    storage_capacity_exceeded: "Storage Capacity Exceeded",
    kindly_inform_form_builder_that: "Kindly inform the Form Builder that",
    kindly_inform_quiz_builder_that: "Kindly inform the Quiz Builder that",
    their_storage_capacity_file_uploads: "their storage capacity for File Uploads",
    has_been_exceeded: "has been exceeded",
    view_details: "VIEW DETAILS",
    paste_your_video_link_here: "Paste your video link here",
    paste_your_website_link_here: "Paste your website link here",
    view_answer_sheet: "View Answer Sheet",
    response_count: "Response Count",
    count: "Count",
    password_least_8_characters: "Password must be 8 - 50 characters",
    password_contain_least_1_number: "Password must contain at least 1 number",
    password_contain_least_1_capital_letter: "Password must contain at least 1 capital letter",
    password_contain_least_1_special_character: "Password must contain at least 1 special character",
    please_fill_all_required_fields: "Please fill all required fields",
    passwords_dont_match: "Passwords don't match",
    incorrect_password: "Incorrect password",
    delete_this_image_will_not_be_available_any_forms_or_quizzes_that_using: "Do you want to delete this image? If you proceed, this image will not be available to any Forms or Quizzes that are using it. This action cannot be undone.",
    url_does_not_seem_to_valid: "The URL does not seem to be valid.",
    you_have_reached_image_uploaded_limit: "You have reached the image uploaded limit.",
    select: "Select",
    feature_spotlight: 'Feature Spotlight',
    note_to_see_this_info_again_click_more_in_the_menu_on_the_left_side: "Note: To see this info again, click 'More' in the Menu on the Left Side",
    import_questions: "Import Questions",
    do_search: "Do Search",
    clear: "Clear",
    back: "Back",
    select_all: "Select All",
    select_all_2: "Select all",
    undo: "Undo",
    response_deleted: "Response Deleted",
    question_deleted: "Question Deleted",
    answer_deleted: "Answer Deleted",
    responses_deleted: "Responses Deleted",
    questions_deleted: "Questions Deleted",
    answers_deleted: "Answers Deleted",
    collect_email_header: "COLLECT EMAIL",
    collect_email_alert_message: "Enabling this feature will force responder to sign in with their gmail address to fill form.",
    collect_email_alert_note: "NOTE : Only Google account is supported",
    allow_response_edit: "Allow Response Edit",
    allow_response_edit_description: "Do you want to allow the responders to edit responses after submitting?",
    enter_desc_here: "Enter Description here",
    passcode_protection: "Passcode Protection",
    passcode_protection_description: "Do you want your form to be passcode protected?",
    passcode_protection_description_quiz: "Do you want your quiz to be passcode protected?",
    crop_image: "Crop Image",
    crop: "Crop",
    original: "Original",
    collect_email: "Collect Email Address",
    collect_email_description: "Do you want to gather the email addresses of respondents for potential future reference or utilization?",
    collect_email_description_quiz: "Enabling this feature will force the responders to sign in with their gmail address to fill quiz.",
    collect_email_description_form: "Enabling this feature will force the responders to sign in with their gmail address to fill form.",
    opening_date: 'Opening Date',
    closing_date: 'Closing Date',
    schedule_form_status: 'Schedule Form Status',
    schedule_quiz_status: 'Schedule Quiz Status',
    schedule_opening_date_and_time: "Schedule Opening Date and Time",
    schedule_closing_date_and_time: "Schedule Closing Date and Time",
    schedule_opening_date_and_time_description: "Activate the form on the mentioned date and time",
    schedule_opening_date_and_time_description_quiz: "Activate the quiz on the mentioned date and time",
    schedule_closing_date_and_time_description: "Deactivate the form on the mentioned date and time",
    schedule_closing_date_and_time_description_quiz: "Deactivate the quiz on the mentioned date and time",
    upgrade: "Upgrade",
    gold_plan: "Gold Plan",
    view_plans: "View plans",
    premium_plans: "Premium plans",
    plan_duration: "/ Month",
    plan_logic_jump: "Logic jump",
    plan_remove_ads: "Remove Ads",
    cancel_subscription: "Cancel Subscription",
    quiz_has_answer_edit_message: "This quiz has already been submitted and answers have been collected. Editing the quiz may affect the previous answers.",
    quiz_published_alert_edit_message: "This quiz has been published, If you edit this quiz will be unpublished.",
    quiz_submit_after_edit_message: "Editing the Quiz may affect the Marks, Percentage and require re-evaluation of certain questions.",
    title_activity_privacy_policy: "Privacy Policy Activity",
    action_blocked_message: "Action blocked due to existing answers for this quiz. Allowing this might impact previous answers.",
    email_addresses: "Email Addresses",
    confirmation_message: "Confirmation Message",
    confirmation_message_description: "Do you want to show confirmation message after submitting the form?",
    confirmation_message_description_quiz: "Do you want to show confirmation message after answering the quiz?",
    default_custom_thank_message: "Thanks for completing this form.",
    default_custom_thank_message_quiz: "Thanks for completing this quiz.",
    upgrade_your_plan_1: "Upgrade your plan!",
    upgrade_your_plan: "Upgrade Your Plan",
    upgrade_your_plan_content: "Kindly upgrade the premium version to access this feature and increase the storage size, etc",
    alert_icon: "Alert Icon",
    usage_details: "Usage Details",
    current_plan: "Current Plan",
    total_submission: "Total Submission",
    image_attachments: "Image Attachments",
    custom_themes: "Custom Themes",
    free_limit_reached: "You have reached free limit -",
    payment_done_but_server_error: "Your payment has been successfully deducted. Please open the app after some time to allow us to verify your payment.",
    response_quota_reached: "You have reached the plan limit, Kindly upgrade your plan to access all responses.",
    answers_quota_reached: "You have reached the plan limit, Kindly upgrade your plan to access all answers.",
    logic_jump_enabled: "Logic jump enabled.",
    logic_jump_disabled: "Logic jump disabled.",
    cancel_subscription_message: "Are you sure you want to cancel your subscription? Canceling subscription will result in the loss of access to all premium features.",
    plan_expired: "Your plan has been expired. Kindly upgrade your plan to use this draft.",
    plan_expired_edit_form: "Your plan has been expired. Kindly upgrade your plan to edit this form",
    plan_expired_edit_quiz: "Your plan has been expired. Kindly upgrade your plan to edit this quiz",
    plan_expired_duplicate_form: "Your plan has been expired. Kindly upgrade your plan to duplicate this form",
    plan_expired_duplicate_quiz: "Your plan has been expired. Kindly upgrade your plan to duplicate this quiz",
    premium_feature: "Premium Feature",
    plan_file_upload: "Unlimited Custom theme Images",
    plan_custom_theme: "Unlimited Custom theme Images",
    plan_call_support: "High Priority On - Call Support",
    plan_email_support: "High Priority Email Support",
    plan_custom_domain: "Custom Domain",
    subscribe: "Subscribe",
    plan_remove_branding: "Remove Branding",
    plan_on_call_support: "On Call Support",
    plan_image_attachment: "Unlimited Images attachments",
    plan_custom_thank_you: "Customizable Thank You pages",
    plan_allowed_responses: "Allowed Response count 50000",
    trusted_by_number_of_user: "Trusted by over 5 million users",
    email_support: "Email Support",
    call_support: "Call Support",
    keyboard_shortcuts: "Keyboard Shortcuts",
    premium_limit_reached: "You have reached the plan limit. Kindly upgrade your plan to access all premium features.",
    premium_limit_reached_mobile: "You have reached the plan limit.",
    unlimited: "Unlimited",
    expired_on_next_renewal: "It will be expires on your next renewal date",
    upgrade_plan: "Upgrade Plan",
    downgrade_plan: "Downgrade Plan",
    file_upload_questions: "File Upload Questions",
    plan_response_edit: "Response Edit",
    cant_process: "Can't Process",
    you_have_subscribed_through_android: "You have subscribed through our Android app. Please use SurveyHeart app for any payment related updates.",
    pay_now: "Pay Now",
    update_payment_details: "Update Payment Details",
    limit_reached_alert_message: "You have reached the free limit, Kindly upgrade your plan to access all responses.",
    limit_reached_alert_message_quiz: "You have reached the free limit, kindly upgrade your plan to access all answers.",
    limit_reached_UI_message: "Responses will not be available for your current plan, Kindly upgrade your plan to see all responses.",
    limit_reached_UI_message_quiz: "Answers will not be available for your current plan, Kindly upgrade your plan to see all answers.",
    owner_is_not_premium_user: "Owner is not a premium user",
    cancel_subscription_description: "Are you sure you want to cancel your subscription? Cancelling will result in the loss of access to all premium features.",
    bronze_storage_description: "Get 25GB storage for effortless data management.",
    silver_storage_description: "Get 50GB storage for effortless data management.",
    gold_storage_description: "Get 150GB storage for effortless data management.",
    bronze_responses_description: "Use 35,000 responses to gain powerful survey insights.",
    silver_responses_description: "Use 75,000 responses to gain powerful survey insights.",
    gold_responses_description: "Use 1,50,000 responses to gain powerful survey insights.",
    bronze_image_description: "Enhance your surveys with up to 200 images for visual appeal.",
    silver_image_description: "Enhance your surveys with up to 500 images for visual appeal.",
    gold_image_description: "Enhance your surveys with Unlimited images for visual appeal.",
    bronze_theme_description: "Add 200 custom images to personalize your surveys for your brand.",
    silver_theme_description: "Add 500 custom images to personalize your surveys for your brand.",
    gold_theme_description: "Add Unlimited custom images to personalize your surveys for your brand.",
    custom_theme_image: "Custom Theme Image",
    file_upload_description: "Seamlessly integrate unlimited file upload questions.",
    logic_jump_description: "Use logic jumps to customize survey flow for each respondent's experience",
    thank_you_message_description: "Personalize Thank You pages to impress respondents.",
    response_edit_description: "Easily refine responses using the Response Edit feature.",
    remove_branding_description: "Elevate your brand presence by removing SurveyHeart branding.",
    bronze_remove_ads_description: "Go ad-free with our Bronze Plan and focus on your surveys.",
    silver_remove_ads_description: "Go ad-free with our Silver Plan and focus on your surveys.",
    gold_remove_ads_description: "Go ad-free with our Gold Plan and focus on your surveys.",
    bronze_email_support_description: "Access email support for low-priority, ensuring timely assistance.",
    silver_email_support_description: "Access email support for medium-priority, ensuring timely assistance.",
    gold_email_support_description: "Access email support for high-priority, ensuring timely assistance.",
    bronze_email_support_title: "Low Priority Email Support",
    silver_email_support_title: "Medium Priority Email Support",
    gold_email_support_title: "High Priority Email Support",
    update_your_payment_details_mobile: "Please update your payment details.",
    update_your_payment_details_pc: "We are facing some issues with renewing this account. Please update your payment details.",
    card_details_updated_successfully: "Card Details Updated Successfully!",
    card_details_updated_successfully_description: "Your card details have been updated successfully. You can now continue using your updated payment information.",
    payment_successful: "Payment Successful",
    payment_successful_description: "Your payment is successful. You are now subscribed to SurveyHeart's premium plan. Get comfy and enjoy all the amazing features coming your way.",
    payment_failed: "Payment Failed!",
    payment_failed_description: "Unfortunately. We couldn't process your subscription to SurveyHeart's premium plan. Please try again.",
    note_for_five_rupees: "Note: There is a Rs. 5 charge for updating your card details, which will \n be refunded within 3 to 4 business days to the same account.",
    logic: "Logic",
    re_generate_code: "Re-generate Code",
    plan_change_restrictions: "Plan Change Restrictions",
    plan_change_restriction_desc_1: "You can't change your plan using UPI.",
    plan_change_restriction_desc_2: "Switch to a card for upgrades and downgrades, or cancel and resubscribe to your desired plan.",
    update_card_details: "Update Card Details",
    features_and_plans: "Features and Plans",
    cancel_plan: "Cancel Plan",
    cancel_plan_desc: "You have canceled the plan. You will still have access to the paid features until your renewal date.",
    explore_text: "Explore and Unlock Exceptional Features in One of Our Premium Subscription Plans Today",
    cant_edit_this_form: "Can't Edit This Form",
    cant_edit_this_quiz: "Can't Edit This Quiz",
    cant_duplicate_this_form: "Can't Duplicate This Form",
    cant_duplicate_this_quiz: "Can't Duplicate This Quiz",
    kindly_upgrade_the_premium: "Kindly upgrade the premium version to access this feature.",
    check_box: 'Check Box',
    continue_to_next_question: "Continue to Next Question",
    add_logic: "Add Logic",
    submit_form: "Submit Form",
    save_logic: "Save Logic",
    remove_logic: "Remove Logic",
}