import React, {useState} from "react"
import "../../home/css/top-nav-bar.css"
import "../css/pricing-body.css"
import tick from '../../../public/images/tick_green.svg'
import cross from '../../../public/images/cross_red.svg'
import {formatBytes, PremiumJSON} from "../../shared/utils/helper";
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";
import {isMobileOnly} from "react-device-detect";

const PricingBody = () => {
    const [clickedPlan, setClickedPlan] = useState(0)

    const pricingBodyRow = (heading, description, freeValue, bronzeValue, silverValue, goldValue, image, lastText) => {
        return (
            <>
                {isMobileOnly ? <div className={"pricing-body-row"}>
                    <div className={"info"}>
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div>
                    {clickedPlan === 0 ? <>
                        {image ? <h3><img src={freeValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{freeValue === Infinity ? "Unlimited" : freeValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                    {clickedPlan === 1 ? <>
                        {image ? <h3><img src={bronzeValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{bronzeValue === Infinity ? "Unlimited" : bronzeValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                    {clickedPlan === 2 ? <>
                        {image ? <h3><img src={silverValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{silverValue === Infinity ? "Unlimited" : silverValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                    {clickedPlan === 3 ? <>
                        {image ? <h3><img src={goldValue ? tick : cross} alt={"image"}/></h3> :
                            <h3>{goldValue === Infinity ? "Unlimited" : goldValue} {lastText ? lastText : null}</h3>}
                    </> : null}
                </div> : <div className={"pricing-body-row"}>
                    <div className={"info"}>
                        <h3>{heading}</h3>
                        <p>{description}</p>
                    </div>
                    {image ? <h3><img src={freeValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{freeValue === Infinity ? "Unlimited" : freeValue} {lastText ? lastText : null}</h3>}
                    {image ? <h3><img src={bronzeValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{bronzeValue === Infinity ? "Unlimited" : bronzeValue} {lastText ? lastText : null}</h3>}
                    {image ? <h3><img src={silverValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{silverValue === Infinity ? "Unlimited" : silverValue} {lastText ? lastText : null}</h3>}
                    {image ? <h3><img src={goldValue ? tick : cross} alt={"image"}/></h3> :
                        <h3>{goldValue === Infinity ? "Unlimited" : goldValue} {lastText ? lastText : null}</h3>}
                </div>}
            </>
        )
    }

    return (
        <>
            <div className={"top-navigator-bar"} style={{justifyContent: "center"}}>
                <div className="title-banner" style={{margin: "0", width: "auto"}}>
                    <img id={"app-logo"} alt={"logo"} src="images/surveyheart1.png"/>
                </div>
            </div>
            <div className={"pricing-body"}>
                <div className={"body-heading"}>
                    <h1>Find Your Ideal Plan with Us</h1>
                    <span style={{fontSize: "18px"}}>From basic beginnings to premium perks, our plans cover every need.</span>
                </div>
                <div className={"pricing"}>
                    {isMobileOnly ? <div className={"pricing-header-row"}>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 0 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(0)}>
                                <h2 style={{color: clickedPlan === 0 ? "#004BA0" : null}}>Basic</h2>
                            </div>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 1 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(1)}>
                                <h2 style={{color: clickedPlan === 1 ? "#004BA0" : null}}>Bronze</h2>
                            </div>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 2 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(2)}>
                                <h2 style={{color: clickedPlan === 2 ? "#004BA0" : null}}>Silver</h2>
                            </div>
                            <div className={"header-cell"}
                                 style={{borderBottom: clickedPlan === 3 ? "2px solid #004BA0" : null}}
                                 onClick={() => setClickedPlan(3)}>
                                <h2 style={{color: clickedPlan === 3 ? "#004BA0" : null}}>Gold</h2>
                            </div>
                        </div> :
                        <div className={"pricing-header-row"}>
                            <h2>Free</h2>
                            <div style={{textAlign: "center"}}>
                                <h2>Bronze</h2>
                                <span>₹ {PremiumJSON["bronze-monthly"].price} /<br/> month</span>
                            </div>
                            <div>
                                <h2>Silver</h2>
                                <span>₹ {PremiumJSON["silver-monthly"].price} /<br/> month</span>
                            </div>
                            <div>
                                <h2>Gold</h2>
                                <span>₹ {PremiumJSON["gold-monthly"].price} /<br/> month</span>
                            </div>
                        </div>}
                    {isMobileOnly && clickedPlan !== 0 ?
                        <p className={"amount-mobile"}>₹ {PremiumJSON[clickedPlan === 1 ? "bronze-monthly" : clickedPlan === 2 ? "silver-monthly" : clickedPlan === 3 ? "gold-monthly" : "free-user"].price} /
                            month</p> :
                        <>{isMobileOnly ? <p className={"amount-mobile"}>Free</p> : null}</>
                    }
                    {pricingBodyRow("Image Attachments", "Enhance your surveys with up to more images for visual appeal.", PremiumJSON["free-user"].question_attachments, PremiumJSON["bronze-monthly"].question_attachments, PremiumJSON["silver-monthly"].question_attachments, PremiumJSON["gold-monthly"].question_attachments)}
                    {pricingBodyRow("Custom Theme Image", "Add custom images to personalize your surveys for your brand.", PremiumJSON["free-user"].theme_attachments, PremiumJSON["bronze-monthly"].theme_attachments, PremiumJSON["silver-monthly"].theme_attachments, PremiumJSON["gold-monthly"].theme_attachments)}
                    {pricingBodyRow("File Upload Question", "Seamlessly integrate unlimited file upload questions.", PremiumJSON["free-user"].file_upload_questions_per_form, PremiumJSON["bronze-monthly"].file_upload_questions_per_form, PremiumJSON["silver-monthly"].file_upload_questions_per_form, PremiumJSON["gold-monthly"].file_upload_questions_per_form)}
                    {pricingBodyRow("Logic Jump", "Use logic jumps to customize survey flow for each respondent's experience", PremiumJSON["free-user"].logic_jump, PremiumJSON["bronze-monthly"].logic_jump, PremiumJSON["silver-monthly"].logic_jump, PremiumJSON["gold-monthly"].logic_jump, true)}
                    {pricingBodyRow("Responses Count", "Get more responses to gain powerful survey insights.", PremiumJSON["free-user"].response_limit.access, PremiumJSON["bronze-monthly"].response_limit.access, PremiumJSON["silver-monthly"].response_limit.access, PremiumJSON["gold-monthly"].response_limit.access, false, "/Lifetime")}
                    {pricingBodyRow("Response Edit", "Easily refine responses using the Response Edit feature.", PremiumJSON["free-user"].response_edit, PremiumJSON["bronze-monthly"].response_edit, PremiumJSON["silver-monthly"].response_edit, PremiumJSON["gold-monthly"].response_edit, true)}
                    {pricingBodyRow("Storage", "Get more storage for effortless data management.", formatBytes(PremiumJSON["free-user"].storage, 0, false, "GB"), formatBytes(PremiumJSON["bronze-monthly"].storage, 0, false, "GB"), formatBytes(PremiumJSON["silver-monthly"].storage, 0, false, "GB"), formatBytes(PremiumJSON["gold-monthly"].storage, 0, false, "GB"))}
                    {pricingBodyRow("Customizable Thank You Pages", "Personalize Thank You pages to impress respondents.", PremiumJSON["free-user"].customizable_thank_you, PremiumJSON["bronze-monthly"].customizable_thank_you, PremiumJSON["silver-monthly"].customizable_thank_you, PremiumJSON["gold-monthly"].customizable_thank_you, true)}
                    {pricingBodyRow("Remove SurveyHeart Branding", "Elevate your brand presence by removing SurveyHeart branding.", PremiumJSON["free-user"].remove_branding, PremiumJSON["bronze-monthly"].remove_branding, PremiumJSON["silver-monthly"].remove_branding, PremiumJSON["gold-monthly"].remove_branding, true)}
                    {pricingBodyRow("Remove Ads", "Go ad-free with our Bronze Plan and focus on your surveys", !PremiumJSON["free-user"].advertising, !PremiumJSON["bronze-monthly"].advertising, !PremiumJSON["silver-monthly"].advertising, !PremiumJSON["gold-monthly"].advertising, true)}
                    {pricingBodyRow("On-call Support", "Access email support for your inquiries, ensuring timely assistance.", PremiumJSON["free-user"].on_call_support, PremiumJSON["bronze-monthly"].on_call_support, PremiumJSON["silver-monthly"].on_call_support, PremiumJSON["gold-monthly"].on_call_support, true)}
                    {pricingBodyRow("Email Support", "Access high, medium and low priority email support for your inquiries, ensuring timely assistance.", PremiumJSON["free-user"].email_support_rank, PremiumJSON["bronze-monthly"].email_support_rank, PremiumJSON["silver-monthly"].email_support_rank, PremiumJSON["gold-monthly"].email_support_rank, true)}
                    {isMobileOnly ? <div style={{textAlign: "center"}}>
                        {clickedPlan ? <MaterialButton data={{
                            title: "SUBSCRIBE",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#004BA0',
                            fontSize: '20px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '50px',
                                padding: '15px 35px',
                                width: '300px',
                                height: '50px'
                            }
                        }} handleClick={() => {
                            localStorage.setItem("site-plan-rank", clickedPlan)
                            window.open("/app")
                        }}/> : null}
                    </div> : <>
                        <div className={"pricing-buttons-row"}>
                            <div>
                                <MaterialButton data={{
                                    title: "SUBSCRIBE",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#004BA0',
                                    fontSize: '14px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        width: '130px',
                                    }
                                }} handleClick={() => {
                                    localStorage.setItem("site-plan-rank", 1)
                                    window.open("/app")
                                }}/>
                            </div>
                            <div>
                                <MaterialButton data={{
                                    title: "SUBSCRIBE",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#004BA0',
                                    fontSize: '14px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        width: '130px',
                                    }
                                }} handleClick={() => {
                                    localStorage.setItem("site-plan-rank", 2)
                                    window.open("/app")
                                }}/>
                            </div>
                            <div>
                                <MaterialButton data={{
                                    title: "SUBSCRIBE",
                                    buttonType: ButtonTypes.CONTAINED,
                                    bg_color: '#004BA0',
                                    fontSize: '14px',
                                    customButtonContainerStyle: {
                                        margin: '10px',
                                        borderRadius: '50px',
                                        padding: '15px 35px',
                                        width: '130px',
                                    }
                                }} handleClick={() => {
                                    localStorage.setItem("site-plan-rank", 3)
                                    window.open("/app")
                                }}/>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
        </>
    )
}

export default PricingBody
