import {QUESTION_TYPE} from "../../shared/utils/constants";

export const IMPORT_QUESTIONS_ACTION_TYPE = {
    DESELECT_ALL_QUESTIONS: 'DESELECT_ALL_QUESTIONS',
    SELECT_ALL_QUESTIONS: 'SELECTED_ALL_QUESTIONS',
    SET_CURRENT_FORM: 'SET_CURRENT_FORM',
    SET_SHOW_IMPORT_QUESTIONS_DIALOG: 'SET_SHOW_IMPORT_QUESTIONS_DIALOG',
    SET_SHOW_SEARCH_AND_FORMS_LIST: 'SET_SHOW_SEARCH_AND_FORMS_LIST',
    SET_QUESTIONS_SEARCH_RESULTS: 'SET_QUESTIONS_SEARCH_RESULTS',
    UPDATE_QUESTION_SELECTED_STATE: 'UPDATE_QUESTION_SELECTED_STATE',
    SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN: 'SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN'
}

export const SOURCE_OF_QUESTIONS_LIST = {
    CURRENT_FORM: 'CURRENT_FORM',
    SEARCH_RESULTS: 'SEARCH_RESULTS',
    NONE: 'NONE'
}

const initialState = {
    currentForm: null,
    showImportQuestionsDialog: false,
    showMobileImportQuestionsScreen: false,
    showSearchAndFormsList: true,
    showLoader: false,
    sourceOfQuestionsList: SOURCE_OF_QUESTIONS_LIST.NONE,
    questionSearchResults: []
}

const importQuestionsReducer = (state = initialState, action) => {
    let tempCurrentForm
    let updatedQuestions

    switch (action.type) {
        case IMPORT_QUESTIONS_ACTION_TYPE.DESELECT_ALL_QUESTIONS:
            if (state.sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.CURRENT_FORM) {
                tempCurrentForm = {...state.currentForm}

                updatedQuestions = tempCurrentForm.pages[0].questions.map((q) => ({
                    ...q,
                    selected: false
                }))

                tempCurrentForm.pages[0].questions = updatedQuestions

                return {
                    ...state,
                    currentForm: tempCurrentForm
                }
            } else if (state.sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS) {

                return {
                    ...state,
                    questionSearchResults: []
                }
            }

            break
        case IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_IMPORT_QUESTIONS_DIALOG:
            return {
                ...state,
                showImportQuestionsDialog: action.payload
            }
        case IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_MOBILE_IMPORT_QUESTIONS_SCREEN:
            return {
                ...state,
                showMobileImportQuestionsScreen: action.payload
            }
        case IMPORT_QUESTIONS_ACTION_TYPE.SET_SHOW_SEARCH_AND_FORMS_LIST:
            return {
                ...state,
                showSearchAndFormsList: action.payload
            }
        case IMPORT_QUESTIONS_ACTION_TYPE.SET_CURRENT_FORM:
            // Set all Questions in the Form as not Selected

            tempCurrentForm = action.payload
            updatedQuestions = tempCurrentForm.pages[0].questions.map((q) => ({
                ...q,
                selected: false
            }))

            tempCurrentForm.pages[0].questions = updatedQuestions

            return {
                ...state,
                sourceOfQuestionsList: SOURCE_OF_QUESTIONS_LIST.CURRENT_FORM,
                currentForm: tempCurrentForm
            }
        case IMPORT_QUESTIONS_ACTION_TYPE.UPDATE_QUESTION_SELECTED_STATE:
            if (state.sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.CURRENT_FORM) {
                tempCurrentForm = {...state.currentForm}
                tempCurrentForm.pages[0].questions[action.payload.question_index].selected = action.payload.new_selected_state

                return {
                    ...state,
                    currentForm: tempCurrentForm
                }
            } else if (state.sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS) {
                let tempQuestionSearchResults = [...state.questionSearchResults]

                tempQuestionSearchResults[action.payload.question_index].selected = action.payload.new_selected_state

                return {
                    ...state,
                    questionSearchResults: tempQuestionSearchResults
                }
            }

            break
        case IMPORT_QUESTIONS_ACTION_TYPE.SELECT_ALL_QUESTIONS:
            if (state.sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.CURRENT_FORM) {
                tempCurrentForm = {...state.currentForm}

                updatedQuestions = tempCurrentForm.pages[0].questions.map((q) => ({
                    ...q,
                    selected: ((!(q.type === QUESTION_TYPE.FILE_UPLOAD.key && action.payload)) && q.type !== QUESTION_TYPE.SECTION.key)
                }))

                tempCurrentForm.pages[0].questions = updatedQuestions

                return {
                    ...state,
                    currentForm: tempCurrentForm
                }
            } else if (state.sourceOfQuestionsList === SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS) {

                return {
                    ...state,
                    questionSearchResults: action.payload
                }
            }

            break
        case IMPORT_QUESTIONS_ACTION_TYPE.SET_QUESTIONS_SEARCH_RESULTS        :
            return {
                ...state,
                sourceOfQuestionsList: SOURCE_OF_QUESTIONS_LIST.SEARCH_RESULTS,
                questionSearchResults: action.payload
            }
        default:
            return state
    }
}

export default importQuestionsReducer