import ReactGA from 'react-ga';

export function initializeGoogleAnalytics(analyticsID) {
    ReactGA.initialize(analyticsID);
}

export function registerGAEvent(category, action, label) {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
}
