import React, { useState, useEffect } from 'react'
import FormCard, {CARD_TYPE} from "./FormCard";
import Loader from "../../../shared/components/Loader";
import DataUnavailableComponent from "./DataUnavailableComponent";
import {isMobile, isMobileOnly} from "react-device-detect";
import {removeDataFromDataList} from "../utils/remove-data-from-datalist";
import {
    getSharedForms, leaveFormCollaboration,
} from "../../../shared/utils/sash-v2-api";
import {sortFields, sortForms} from "../utils/sort-form-list";
import {RESPONSE_CODE} from "../../../shared/utils/helper";
import {Pages} from "../../utils/pages";
import MobileFormControlContainer from "./MobileFormControlContainer"
import {registerGAEvent} from "../../../shared/utils/analytics";
import {getSharedQuizzes, leaveQuizCollaboration} from "../../../shared/utils/quiz-api-services";
import ModalDialog from "../../../shared/components/ModalDialog";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {SHARED_ACTION_TYPE} from "../../reducers/sharedReducer";
import ShareCard from "../../../shared/components/ShareCard";
import CollaborateCard from "../../../shared/components/CollaborateCard";
const SharesContainer = (props) => {

    const isQuiz = useSelector(state => state.appReducer.isQuiz)
    const userId = useSelector(state => state.userReducer?.userId)
    const formData = useSelector(state => state.appReducer.formData)
    const searchText = useSelector(state => state.appDashboardReducer.searchText)
    const sharesList = useSelector(state => state.appDashboardReducer.sharesList)
    const showShareScreen = useSelector(state => state.sharedReducer.showShareScreen)
    const showDeletePopUp = useSelector(state => state.sharedReducer.showDeletePopUp)
    const deletableFormData = useSelector(state => state.sharedReducer.deletableForqData)
    // const showQuizQuickSettings = useSelector(state => state.quizzesReducer.showQuizQuickSettings)
    const searchResultTimeStamp = useSelector(state => state.appDashboardReducer.searchResultTimeStamp)
    const isShowingMobileFormControl = useSelector(state => state.appDashboardReducer.isShowingMobileFormControl)
    const showCollaboratePopUp = useSelector(state => state.appReducer.showCollaboratePopUp)
    const showSharePopUp = useSelector(state => state.appReducer.showSharePopUp)
    const shareFormId = useSelector(state => state.appReducer.formData?._id)
    let startSwipeX, startSwipeY, distX, startTime, elapsedTime, distY;
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch()
    const allowedTime = 200;

    const getDataFromServer = (type) => {
        setIsLoading(true);
        if (type === TABS_DATA.FORM) {
            getSharedForms(userId, (responseData) => {
                let formsList;
                let resJson = responseData;
                if (resJson.result.length > 1) {
                    formsList = sortForms(resJson.result, sortFields.DATE_EDITED);
                } else if (resJson.result) {
                    formsList = resJson.result;
                }
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHARES_LIST, payload: formsList})
                setIsLoading(false);

            }, (error) => {
                if (error) {
                    if (error.status === RESPONSE_CODE.BLOCKED_USER) {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER, payload: true})
                    } else if (error.status === RESPONSE_CODE.FORBIDDEN) {
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.login})
                        setIsLoading(false);
                    } else {
                        console.log(error)
                        setIsLoading(false);
                    }
                } else {
                    console.log(error)
                    setIsLoading(false);
                }
            });
        } else if (type === TABS_DATA.QUIZ) {
            //call get shared quizzes
            getSharedQuizzes((responseData) => {
                let formsList;
                let resJson = responseData;
                if (resJson.result.length > 1) {
                    formsList = sortForms(resJson.result, sortFields.DATE_EDITED);
                } else if (resJson.result) {
                    formsList = resJson.result;
                }
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHARES_LIST, payload: formsList})
                setIsLoading(false);

            }, (error) => {
                if (error) {
                    if (error.status === RESPONSE_CODE.BLOCKED_USER) {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER, payload: true})
                    } else if (error.status === RESPONSE_CODE.FORBIDDEN) {
                        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.login})
                        setIsLoading(false);
                    } else {
                        console.log(error)
                        setIsLoading(false);
                    }
                } else {
                    console.log(error)
                    setIsLoading(false);
                }
            });
        }
    }

    // componentDidMount

    useEffect(() => {
        if (!sharesList) {
            getDataFromServer(showShareScreen);
        } else {
            // Check for an Updated or New Form
            if (props.updatedFormData) {
                let updatedFormsList = sharesList;
                let formToUpdate = updatedFormsList.filter(form => form._id === props.updatedFormData._id)[0]
                if (formToUpdate === undefined) {
                    // This is a new Form that should be inserted first
                    updatedFormsList.unshift(props.updatedFormData)
                } else {
                    // If an updated Form is found .... Update form data by matching the ID
                    let indexOfFormToUpdate = updatedFormsList.indexOf(formToUpdate)
                    if (indexOfFormToUpdate === 0) {
                        // Update the First Form
                        if (formToUpdate?.response_count <= props.updatedFormData?.response_count) {
                            updatedFormsList.splice(0, 1, props.updatedFormData)
                        }
                    } else if (formToUpdate.response_count && (formToUpdate.response_count !== props.updatedFormData.response_count)) {
                        //update response count without disturbing the forms order.
                        updatedFormsList.splice(indexOfFormToUpdate, 1, props.updatedFormData)
                    } else {
                        // Move the updated Form to the start of the Form's List
                        updatedFormsList.splice(indexOfFormToUpdate, 1)
                        if (formToUpdate?.response_count <= props.updatedFormData?.response_count) {
                            updatedFormsList.unshift(props.updatedFormData)
                        } else {
                            updatedFormsList.unshift(formToUpdate)
                        }
                    }
                }
                updateFormsList(updatedFormsList);
            }
            setIsLoading(false);
        }
    }, []);   // empty dependency to stop continuous re-rendering.

    const updateFormsList = (formsList) => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHARES_LIST, payload: JSON.parse(JSON.stringify(formsList))})
        props.refreshStorageTotals()
    }

    const updateFormStatusInFormsList = (formId, isActive) => {
        let formList = sharesList;
        for (let i = 0; i < formList.length; i++) {
            if (formList[i]._id === formId) {
                formList[i].setting.is_active = isActive;
                updateFormsList(formList);
                return;
            }
        }
    }

    const removeDeletedFormDataInFormsList = (formId) => {
        let removedFormsList = sharesList;
        if (Array.isArray(formId)) {
            for (let i = 0; i < formId.length; i++) {
                removedFormsList = removeDataFromDataList(removedFormsList, formId[i]);
            }

        } else {
            removedFormsList = removeDataFromDataList(removedFormsList, formId);
        }

        updateFormsList(removedFormsList)
    }

    const removeCollaborator = (formData) => {
        const successFunction = (res) => {
            if (res.result) {
                removeDeletedFormDataInFormsList(formData._id)
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        if (formData.is_quiz) {
            leaveQuizCollaboration(formData._id, successFunction, failureFunction)
        } else {
            leaveFormCollaboration(formData._id, successFunction, failureFunction)
        }
    }

    // componentDidUpdate

    useEffect(() => {
        if (showShareScreen !== isQuiz ? TABS_DATA.QUIZ : TABS_DATA.FORM) {
            dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN, payload: isQuiz ? TABS_DATA.QUIZ : TABS_DATA.FORM})
            setData(isQuiz ? TABS_DATA.QUIZ : TABS_DATA.FORM)
        }

        if (sharesList && isLoading) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHARES_LIST, payload: sharesList})
        }

    }, [isShowingMobileFormControl, searchResultTimeStamp, props.mobileSortOrder, isQuiz]);

    const getFormCard = (formData, index) => {
        let role
        formData.collaborators?.map(collaborator => {
            if (collaborator.user_id === userId) {
                role = collaborator.role
            }
        })

        return <FormCard key={formData._id} CARD_TYPE={CARD_TYPE.USER_FORM}
                         cardIndex={index}
                         formData={formData}
                         showEdit={role !== 0}
                         removeCollaborator={removeCollaborator}
                         updateStatus={updateFormStatusInFormsList}
                         showDataEditWarning={props.showDataEditWarning}
                         timeOutForLongPress={props.timeOutForLongPress}
                         previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
        />
    }

    const getForms = () => {
        if (!sharesList) return null

        if (searchText === "") {
            return (
                () => {
                    return sharesList.map((formData, index) =>
                        getFormCard(formData, index)
                    )
                }
            )()
        }

        // Filter the Forms by the Search Text
        return (
            () => {
                return sharesList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).map((formData, index) =>
                    getFormCard(formData, index)
                )
            }
        )()
    }

    const setData = (type) => {
        getDataFromServer(type)
    }

    const formsSectionShouldBeVisible = (formsList) => {
        if (searchText === '') {
            return formsList?.length >= 0
        } else return formsList?.length > 0 && formsList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length > 0
    }

    if (!formsSectionShouldBeVisible(sharesList)) return (
        <DataUnavailableComponent data={showShareScreen === TABS_DATA.FORM ? t(translationKeys.searched_form_not_found) : t(translationKeys.searched_quiz_not_found)}/>)


    if (isLoading) return <Loader/>

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0',
        cursor: 'default'
    }
    return (
        <>
            {
                (() => {

                    if (isMobileOnly)
                        return (// ************************** Mobile Version ************************** //
                            <>
                                <div id={'forms-container-mobile'} className={'forms-container-mobile'}
                                     onTouchStart={(e) => {
                                         if (isMobile) {
                                             startSwipeX = e.changedTouches[0].pageX;
                                             startSwipeY = e.changedTouches[0].pageY;
                                             startTime = new Date().getTime();
                                         }
                                     }} onTouchEnd={e => {
                                    if (isMobile) {
                                        distX = e.changedTouches[0].pageX - startSwipeX;
                                        distY = e.changedTouches[0].pageY - startSwipeY;
                                        elapsedTime = new Date().getTime() - startTime;
                                        if (elapsedTime <= allowedTime && distY < 80) {
                                            if (distX < -120) {
                                                dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN, payload: TABS_DATA.QUIZ})
                                                setData(TABS_DATA.QUIZ)
                                            } else if (distX > 120) {
                                                dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN, payload: TABS_DATA.FORM})
                                                setData(TABS_DATA.FORM);
                                            }
                                        }
                                    }
                                }}
                                     style={isShowingMobileFormControl ? {marginBottom: '0'} : null}>
                                    {isShowingMobileFormControl ?
                                        <div className={'dashboard-content-container-mobile'}
                                             style={{padding: '0'}}>
                                            <MobileFormControlContainer
                                                deleteFormInFormList={removeDeletedFormDataInFormsList}
                                                updateStatus={updateFormStatusInFormsList}
                                                showDataEditWarning={props.showDataEditWarning}
                                            />
                                        </div> :
                                        <div className='cards-container cards-container-width'>
                                            <div className='cards-container cards-container-width'>
                                                <SharedButtonsContainer setData={setData}/>
                                                {
                                                    getForms()
                                                }
                                                {sharesList?.length === 0 ? <DataUnavailableComponent data={t(translationKeys.no_shared_data_available)}/> : null
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </>
                        )
                    else {
                        return (
                            // ************************** Desktop Version ************************** //
                            <>
                                <div id={'forms-container'} className='forms-container'>
                                    {
                                        (() => {
                                                let forms = getForms();

                                                return (
                                                    <div className={'dashboard-content-container'}>
                                                        <div
                                                            className='cards-container cards-container-width'>
                                                            <>
                                                                <SharedButtonsContainer setData={setData}/>
                                                                {
                                                                    forms
                                                                }
                                                            </>
                                                            {sharesList?.length === 0 ? <DataUnavailableComponent data={t(translationKeys.no_shared_data_available)}/> : null
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )()
                                    }
                                </div>
                            </>
                        )
                    }
                })()
            }
            {/*{showQuizQuickSettings ?*/}
            {/*    <QuizQuickSettingsContainer/>*/}
            {/*    : null}*/}
            {showDeletePopUp ?
            <ModalDialog
                header={t(translationKeys.leave_collaboration)}
                body={[t(translationKeys.do_you_want_to_leave_collaboration) + t(translationKeys.this_action_cannot_be_undone)]}
                buttons={
                    [
                        {
                            text: t(translationKeys.cancel).toUpperCase(),
                            action: () => dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
                        }, {
                        text: t(translationKeys.leave).toUpperCase(),
                        action:
                            () => {
                                removeCollaborator(deletableFormData)
                                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                                dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
                        }
                    }
                    ]
                }
            /> : null}
            {/*** share popup ***/}
            {showSharePopUp ?
                <div style={popUpStyle}>
                    <ShareCard isQuiz={isQuiz}
                               passcode={formData?.setting?.passcode}
                               formId={shareFormId}/>
                </div> : null}
            {/*** collaborator popup ***/}
            {showCollaboratePopUp ?
                <div style={popUpStyle}>
                    <CollaborateCard updateCollabIcons={() => getDataFromServer(showShareScreen.title)}/>
                </div> : null}
        </>
    )

}

export default SharesContainer

export const TABS_DATA = {
    FORM: 'form',
    QUIZ: 'quiz'
}

const SharedButtonsContainer = (props) => {
    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    const showShareScreen = useSelector(state => state.sharedReducer.showShareScreen)
    const buttonContainerStyle = {width: '100%', display: isMobileOnly ? 'block' : 'flex', justifyContent: 'center'};
    const form = {title: TABS_DATA.FORM, selected: showShareScreen === TABS_DATA.FORM}
    const quiz = {title: TABS_DATA.QUIZ, selected: showShareScreen === TABS_DATA.QUIZ}
    const [containerTabItems, setContainerTabItems] = useState([form, quiz])
    const dispatch = useDispatch()

    const updateTabContainer = clickedItem => {
        if (clickedItem.title === containerTabItems.filter(tabItem => tabItem.selected)[0].title) {
            return
        }

        // Deselect previous Tab Item and Update Current Selection
        setContainerTabItems(oldItems => {
            const newItems = [...oldItems]

            newItems.forEach((tabItem) => {
                    tabItem.selected = tabItem.title === clickedItem.title
                }
            )

            return newItems
        })
        dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN, payload: clickedItem.title})
        props.setData(clickedItem.title);
    }

    return <div style={buttonContainerStyle}>
        {isMobileOnly ? <div className='mobile-header-tab-row'>
            <div style={{
                display: 'flex',
                justifyContent: 'space-around',
                height: '100%',
                width: isMobileOnly ? '100%' : null,
                paddingTop: isMobileOnly ? '10px' : null
            }}>
                {
                    containerTabItems.map((tabItem, index) => (
                        () => {
                            return (
                                <div
                                    id={index}
                                    key={index}
                                    className={'header-button center'}
                                    style={{width: '50%', borderBottom: '1px solid transparent' }}
                                    onClick={() => updateTabContainer(tabItem)}>
                                        <span
                                            className={tabItem.selected ? 'mobile-header-tab-row-item-settings mobile-header-tab-row-shared-item-clicked-settings' : 'mobile-header-tab-row-item-settings'}
                                            style={{width: '100%', textAlign: "center", height: '3.5vh'}}>
                                            {tabItem.title === TABS_DATA.FORM ? t(translationKeys.forms).toUpperCase() : t(translationKeys.quizzes).toUpperCase()}
                                        </span>
                                </div>
                            )
                        }
                    )())
                }
            </div>
        </div> :
            <>
                <div
                    className={showShareScreen === TABS_DATA.FORM ? (selected === 'fa' || selected === 'ar') ? 'template-button form-template-button-fa active-template-button' : 'template-button form-template-button active-template-button' : (selected === 'fa' || selected === 'ar') ? 'template-button form-template-button-fa' : 'template-button form-template-button'}
                    onClick={() => {
                        registerGAEvent('Dashboard', 'Shared', 'forms-view');
                        dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN, payload: TABS_DATA.FORM})
                        props.setData(TABS_DATA.FORM);
                    }}>
                    {t(translationKeys.forms)}
                </div>
                <div
                    className={showShareScreen === TABS_DATA.QUIZ ?  (selected === 'fa' || selected === 'ar')  ? 'template-button quiz-template-button-fa active-template-button' : 'template-button quiz-template-button active-template-button' : (selected === 'fa' || selected === 'ar') ? 'template-button quiz-template-button-fa' : 'template-button quiz-template-button'}
                    onClick={() => {
                    registerGAEvent('Dashboard', 'Shared', 'quizzes-view');
                    dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN, payload: TABS_DATA.QUIZ})
                    props.setData(TABS_DATA.QUIZ);
                }}>
                    {t(translationKeys.quizzes)}
                </div>
            </>
        }
    </div>
}
