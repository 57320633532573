import React from "react";
import "../css/usage-component.css"
import {Slider} from "@mui/material";

const UsageComponent = (props) => {
    return (
        <div className={"usage-component"}>
            <div className={"usage"}>
                <p>{props.title}</p>
                <span>{props.description}</span>
            </div>
            <div className={"usage-slider"}>
                <Slider value={props.used}
                        max={props.max}
                        min={0}
                        slots={{thumb: 'div'}}/>
            </div>
        </div>
    )
}

export default UsageComponent
