import {Provider, useDispatch, useSelector} from "react-redux";
import React from "react";
import {registerGAEvent} from "../utils/analytics";
import {EMAIL_ACTION_TYPE} from "../../app/reducers/collabAutocompleteReducer";
import appStore from "../../app/store/appStore";
import {isMobileOnly} from "react-device-detect";

const EmailAutoCompleteSuggestion = () => {
    const searchMatches = useSelector(state => state.collabAutocompleteReducer.matches)
    const collaboratorsList = useSelector(state => state.collaboratorsData.collaborators)
    const collaborators = collaboratorsList.map((collab) => {
        return collab.user_id
    })
    const matchShow = searchMatches.filter(val => !collaborators.includes(val));
    const reduxDispatch = useDispatch()

    const updateEmailText = match => (e) => {
        registerGAEvent('Builder', 'Question', 'used-question-auto-suggestion');
        e.stopPropagation();
        reduxDispatch({type: EMAIL_ACTION_TYPE.SET_SELECTED_EMAIL_VALUE, payload: match})
        reduxDispatch({type: EMAIL_ACTION_TYPE.SUGGESTION_CLICKED, payload: true})
        reduxDispatch({type: EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS})
    }

    return (
        <Provider store={appStore}>
            {matchShow?.length > 0 ? <div className='autocomplete-dropdown autocomplete-dropdown-box-shadow'
                                          style={{position: "absolute", top: isMobileOnly ? '39%' : '41%', width: isMobileOnly ? '67%' : '69%', marginBottom: '5px'}}>
                {
                    matchShow.map((match, index) => {
                        return (
                            <div className='autocomplete-suggestion-row' key={index}
                                 onClick={updateEmailText(match)}>
                                {match}
                            </div>
                        )
                    })
                }
            </div> : null}
        </Provider>
    )
}

export default EmailAutoCompleteSuggestion