import React from "react"
import close from "../../../public/images/close.png"
import featureSpotlightBulb from "../../../public/images/feature_spotlight_bulb.svg"
import featureSpotlightImageOverlay from "../../../public/images/feature_spotlight_image_overlay.svg"
import {FEATURE_SPOTLIGHT_ACTION_TYPE} from "../reducers/featureSpotlightReducer"
import {getOGDataFromUrl} from "../../shared/utils/sash-v2-api"
import {registerGAEvent} from "../../shared/utils/analytics"
import {translationKeys} from "../../localizations/translationKeys-localization"
import {t} from "i18next"
import {useDispatch, useSelector} from "react-redux"
import featureSpotlightEnJson from "../../../public/json/featureSpotlight/feature_spot_light_en.json"
import featureSpotlightArJson from "../../../public/json/featureSpotlight/feature_spot_light_ar.json"
import featureSpotlightEsJson from "../../../public/json/featureSpotlight/feature_spot_light_es.json"
import featureSpotlightFaJson from "../../../public/json/featureSpotlight/feature_spot_light_fa.json"
import featureSpotlightFrJson from "../../../public/json/featureSpotlight/feature_spot_light_fr.json"
import featureSpotlightHiJson from "../../../public/json/featureSpotlight/feature_spot_light_hi.json"
import featureSpotlightInJson from "../../../public/json/featureSpotlight/feature_spot_light_in.json"
import featureSpotlightMlJson from "../../../public/json/featureSpotlight/feature_spot_light_ml.json"
import featureSpotlightMrJson from "../../../public/json/featureSpotlight/feature_spot_light_mr.json"
import featureSpotlightPaJson from "../../../public/json/featureSpotlight/feature_spot_light_pa.json"
import featureSpotlightPtJson from "../../../public/json/featureSpotlight/feature_spot_light_pt.json"
import featureSpotlightTaJson from "../../../public/json/featureSpotlight/feature_spot_light_ta.json"
import featureSpotlightTeJson from "../../../public/json/featureSpotlight/feature_spot_light_te.json"

const useFeatureSpotlightDialogMgr = () => {
    const reduxDispatch = useDispatch()
    const newFeatures = useSelector(state => state.featureSpotlightReducer?.features)
    const selectedLanguage = (() => {
        let lang = localStorage?.getItem("i18nextLng") || "en"

        return lang.length > 2 ? lang.substring(0, 2) : lang
    })()

    const getNewFeatures = async () => {
        switch (selectedLanguage) {
            case ('en'):
                return featureSpotlightEnJson
            case ('fr'):
                return featureSpotlightFrJson
            case ('in'):
                return featureSpotlightInJson
            case ('hi'):
                return featureSpotlightHiJson
            case ('pa'):
                return featureSpotlightPaJson
            case ('ml'):
                return featureSpotlightMlJson
            case ('mr'):
                return featureSpotlightMrJson
            case ('fa'):
                return featureSpotlightFaJson
            case ('pt'):
                return featureSpotlightPtJson
            case ('es'):
                return featureSpotlightEsJson
            case ('ta'):
                return featureSpotlightTaJson
            case ('te'):
                return featureSpotlightTeJson
            case ('ar'):
                return featureSpotlightArJson
            default:
                return featureSpotlightEnJson
        }
    }

    const customDateFormat = (date) => {
        const monthNames = ["January", "February", "March", "April",
            "May", "June", "July", "August",
            "September", "October", "November", "December"]

        const day = date.getDate()
        const monthIndex = date.getMonth()
        const monthName = monthNames[monthIndex]
        const year = date.getFullYear()

        return `${day} ${monthName} ${year}`
    }

    async function popNewFeaturesAndThumbnails() {
        const tempNewFeatures = await getNewFeatures()

        let filteredNewFeatures = tempNewFeatures?.map(f => {
            return {...f, date: customDateFormat(new Date(parseInt(f.date)))}
        })

        reduxDispatch({
            type: FEATURE_SPOTLIGHT_ACTION_TYPE.POP_NEW_FEATURES,
            payload: filteredNewFeatures
        })

        // Get Thumbnails
        filteredNewFeatures.map(async (nf, index) => {
                if (nf.thumbnail.includes("youtube.com")) {
                    try {
                        await getOGDataFromUrl(nf.thumbnail, res => {
                            nf.videoLink = nf.thumbnail
                            nf.thumbnail = res.data.ogImage
                            reduxDispatch({
                                type: FEATURE_SPOTLIGHT_ACTION_TYPE.UPDATE_THUMBNAIL,
                                payload: {index: index, thumbnail: res.data.ogImage}
                            })
                        }, error => {
                            console.error('Error Getting Image Thumbnails:', error)
                        })
                    } catch (error) {
                        console.error('Error Getting Image Thumbnails:', error)
                    }
                }
            }
        )
    }

    return {
        React,
        close,
        featureSpotlightBulb,
        featureSpotlightImageOverlay,
        FEATURE_SPOTLIGHT_ACTION_TYPE,
        newFeatures,
        reduxDispatch,
        popNewFeaturesAndThumbnails,
        registerGAEvent,
        selectedLanguage,
        t,
        translationKeys,
        useFeatureSpotlightDialogMgr
    }
}

export default useFeatureSpotlightDialogMgr
