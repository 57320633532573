import React from "react"
import "../css/terms.css"

const Terms = () => {
    return (
        <div className={"terms_body_content"}>
            <h2 id={"company_name"}>SURVEYHEART</h2>
            <h1 id={"title"}>TERMS OF SERVICE</h1>
            <div className={"content"}>
                <h4 id={"last_modified"}>Last Modified: November 15, 2022 </h4>
                <h2 className={"content_title"}>Welcome to SurveyHeart !</h2>
                <p>Thanks for using our products and services (“Services”). The Services are provided by SurveyHeart
                    (“SurveyHeart”), located at SurveyHeart LLP, Awfis Space Solutions, 2nd Floor, Survey No 34,
                    Kothaguda Junction, Kondapur, Hyderabad, Telangana, India - 500084.</p>
                <p>By using our Services, you are agreeing to these terms. Please read them carefully.</p>
                <p>Our Services are very diverse, so sometimes additional terms or product requirements (including age
                    requirements) may apply. Additional terms will be available with the relevant Services, and those
                    additional terms become part of your agreement with us if you use those Services.</p>

                <h2 className={"content_title"}>Using our Services</h2>
                <p>You must follow any policies made available to you within the Services.</p>
                <p>Don’t misuse our Services. For example, don’t interfere with our Services or try to access them using
                    a method other than the interface and the instructions that we provide. You may use our Services
                    only as permitted by law, including applicable export and re-export control laws and regulations. We
                    may suspend or stop providing our Services to you if you do not comply with our terms or policies or
                    if we are investigating suspected misconduct.</p>
                <p>Using our Services does not give you ownership of any intellectual property rights in our Services or
                    the content you access. You may not use content from our Services unless you obtain permission from
                    its owner or are otherwise permitted by law. These terms do not grant you the right to use any
                    branding or logos used in our Services. Don’t remove, obscure, or alter any legal notices displayed
                    in or along with our Services.</p>
                <p>Our Services display some content that is not SurveyHeart’s. This content is the sole responsibility
                    of the entity that makes it available. We may review content to determine whether it is illegal or
                    violates our policies, and we may remove or refuse to display content that we reasonably believe
                    violates our policies or the law. But that does not necessarily mean that we review content, so
                    please don’t assume that we do.</p>
                <p>In connection with your use of the Services, we may send you service announcements, administrative
                    messages, and other information. You may opt out of some of those communications.</p>
                <p>Some of our Services are available on mobile devices. Do not use such Services in a way that
                    distracts you and prevents you from obeying traffic or safety laws.</p>

                <h2 className={"content_title"}>Your SurveyHeart Account</h2>
                <p>You may need a SurveyHeart Account in order to use our Services. You may create your own SurveyHeart
                    Account, or your SurveyHeart Account may be assigned to you by an administrator, such as your
                    employer or educational institution. If you are using a SurveyHeart Account assigned to you by an
                    administrator, different or additional terms may apply and your administrator may be able to access
                    or disable your account.</p>
                <p> You are responsible for the activity that happens on or through your SurveyHeart Account. If you
                    learn of any unauthorized use of <your></your> SurveyHeart Account, follow these instructions.
                </p>

                <h2 className={"content_title"}>Privacy and Copyright Protection</h2>
                <p>SurveyHeart’s privacy policies explain how we treat your personal data and protect your privacy when
                    you use our Services. By using our Services, you agree that SurveyHeart can use such data in
                    accordance with our privacy policies.</p>
                <p>We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers
                    according to the process set out in the Indian Copyright Law. The Copyright Act 1957 (as amended by
                    the Copyright Amendment Act 2012)</p>
                <p>We provide information to help copyright holders manage their intellectual property online. If you
                    think somebody is violating your copyrights and want to notify us, you can report us via abuse
                    report form which is available in the bottom of form page.</p>

                <h2 className={"content_title"}>Your Content in our Services</h2>
                <p>Some of our Services allow you to upload, submit, store, send or receive content. You retain
                    ownership of any intellectual property rights that you hold in that content. In short, what belongs
                    to you stays yours.</p>
                <p>When you upload, submit, store, send or receive content to or through our Services, you give
                    SurveyHeart (and those we work with) a worldwide license to use, host, store, reproduce, modify,
                    create derivative works (such as those resulting from translations, adaptations or other changes we
                    make so that your content works better with our Services), communicate, publish, publicly perform,
                    publicly display and distribute such content. The rights you grant in this license are for the
                    limited purpose of operating, promoting, and improving our Services, and to develop new ones. This
                    license continues even if you stop using our Services. Some Services may offer you ways to access
                    and remove content that has been provided to that Service. Also, in some of our Services, there are
                    terms or settings that narrow the scope of our use of the content submitted in those Services. Make
                    sure you have the necessary rights to grant us this license for any content that you submit to our
                    Services.</p>
                <p>Our automated systems analyze your content (including emails) to provide you personally relevant
                    product features, such as customized search results, tailored advertising, and spam and malware
                    detection. This analysis occurs as the content is sent, received, and when it is stored.</p>
                <p>If you have a SurveyHeart Account, we may display your Profile name, Profile photo, and actions you
                    take on SurveyHeart or on third-party applications connected to your SurveyHeart Account in our
                    Services, including displaying in ads and other commercial contexts. We will respect the choices you
                    make to limit sharing or visibility settings in your SurveyHeart Account.</p>
                <p>You can find more information about how SurveyHeart uses and stores content in the privacy policy or
                    additional terms for particular Services. If you submit feedback or suggestions about our Services,
                    we may use your feedback or suggestions without obligation to you.</p>

                <h2 className={"content_title"}>About Software in our Services</h2>
                <p>When a Service requires or includes downloadable software, this software may update automatically on
                    your
                    device once a new version or feature is available. Some Services may let you adjust your automatic
                    update
                    settings.</p>
                <p>SurveyHeart gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license
                    to use
                    the software provided to you by SurveyHeart as part of the Services. This license is for the sole
                    purpose of
                    enabling you to use and enjoy the benefit of the Services as provided by SurveyHeart, in the manner
                    permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our
                    Services or
                    included software, nor may you reverse engineer or attempt to extract the source code of that
                    software,
                    unless laws prohibit those restrictions or you have our written permission.</p>
                <p>Open source software is important to us. Some software used in our Services may be offered under an
                    open
                    source license that we will make available to you. There may be provisions in the open source
                    license that
                    expressly override some of these terms.</p>

                <h2 className={"content_title"}>Modifying and Terminating our Services</h2>
                <p>We are constantly changing and improving our Services. We may add or remove functionalities or
                    features, and
                    we may suspend or stop a Service altogether.</p>
                <p>You can stop using our Services at any time, although we’ll be sorry to see you go. SurveyHeart may
                    also stop
                    providing Services to you, or add or create new limits to our Services at any time.</p>
                <p>We believe that you own your data and preserving your access to such data is important. If we
                    discontinue a
                    Service, where reasonably possible, we will give you reasonable advance notice and a chance to get
                    information out of that Service.</p>

                <h2 className={"content_title"}>Our Warranties and Disclaimers</h2>
                <p>We provide our Services using a commercially reasonable level of skill and care and we hope that you
                    will
                    enjoy using them. But there are certain things that we don’t promise about our Services.</p>
                <p>Other than as expressly set out in these terms or additional terms, neither SurveyHeart nor its
                    suppliers or
                    distributors make any specific promises about the Services. For example, we don’t make any
                    commitments about
                    the content within the Services, the specific functions of the Services, or their reliability,
                    availability,
                    or ability to meet your needs. We provide the Services “as is”.</p>
                <p>Some jurisdictions provide for certain warranties, like the implied warranty of merchantability,
                    fitness for
                    a particular purpose and non-infringement. To the extent permitted by law, we exclude all
                    warranties.</p>

                <h2 className={"content_title"}>Liability for our Services</h2>
                <p>When permitted by law, SurveyHeart will not be responsible for lost profits, revenues, or data,
                    financial
                    losses or indirect, special, consequential, exemplary, or punitive damages.</p>
                <p>To the extent permitted by law, the total liability of SurveyHeart for any claims under these terms,
                    including for any implied warranties, is limited to the amount you paid us to use the Services (or,
                    if we
                    choose, to supplying you the Services again).</p>
                <p>In all cases, SurveyHeart will not be liable for any loss or damage that is not reasonably
                    foreseeable.</p>

                <h2 className={"content_title"}>Business uses of our Services</h2>
                <p>If you are using our Services on behalf of a business, that business accepts these terms. It will
                    hold
                    harmless and indemnify SurveyHeart and its employees from any claim, suit or action arising from or
                    related
                    to the use of the Services or violation of these terms, including any liability or expense arising
                    from
                    claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees.</p>

                <h2 className={"content_title"}>About these Terms</h2>
                <p>We may modify these terms or any additional terms that apply to a Service to, for example, reflect
                    changes to
                    the law or changes to our Services. You should look at the terms regularly. We’ll post notice of
                    modifications to these terms on this page. We’ll post notice of modified additional terms in the
                    applicable
                    Service. Changes will not apply retroactively and will become effective no sooner than fourteen days
                    after
                    they are posted. However, changes addressing new functions for a Service or changes made for legal
                    reasons
                    will be effective immediately. If you do not agree to the modified terms for a Service, you should
                    discontinue your use of that Service.</p>
                <p>If there is a conflict between these terms and the additional terms, the additional terms will
                    control for
                    that conflict.</p>
                <p>These terms control the relationship between SurveyHeart and you. They do not create any third party
                    beneficiary rights.</p>
                <p>If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we
                    are
                    giving up any rights that we may have (such as taking action in the future).</p>
                <p>If it turns out that a particular term is not enforceable, this will not affect any other terms.</p>
                <h3>Thank You!</h3>
            </div>
        </div>
    )
}

export default Terms
