import React from "react"
import warningIcon from "../../../../public/images/warning.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {useDispatch} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

const DashboardLimitReachedUI = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    return (
        <div className={"quiz-summary-inner-wrapper"}
             style={{
                 display: "flex",
                 marginTop: "0",
                 height: "10%",
                 padding: "0 5px",
                 width: isMobileOnly ? "95%" : null,
                 maxWidth: isMobileOnly ? null : "1180px",
                 background: isMobileOnly ? "#eeeeee" : null
             }}>
            <img src={warningIcon} alt={"Info"} style={{margin: "auto 10px", height: "25px"}}/>
            <p style={{fontSize: isMobileOnly ? "4.5vw" : "18px", alignSelf: "center"}}>
                {isMobileOnly ? t(translationKeys.premium_limit_reached_mobile) : t(translationKeys.premium_limit_reached)}
            </p>
            {isMobileOnly ?
                <p style={{color: '#1976d2', textDecoration: "underline", fontSize: "4.5vw"}}
                   onClick={() => {
                       dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.premium_plans})
                   }}>{t(translationKeys.view_plans)}</p>
                : <div style={{margin: "auto 0"}}>
                    <MaterialButton data={{
                        title: t(translationKeys.view_plans),
                        customButtonContainerStyle: {
                            borderRadius: '10px',
                            backgroundColor: '#1976d2',
                            width: '120px',
                            margin: "10px"
                        },
                        customButtonTextStyle: {
                            fontFamily: 'Nunito Sans, Sans-serif, serif',
                            fontSize: '14px'
                        }
                    }} handleClick={() => {
                        dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
                    }}/>
                </div>}
        </div>
    )
}

export default DashboardLimitReachedUI
