import React, {useEffect, useRef} from 'react'
import close from '../../../public/images/close_white.svg'
import tick from '../../../public/images/tick_green.svg'
import cross from '../../../public/images/cross_red.svg'
import style from '../css/add-collaboration-popup.module.css'
import {translationKeys} from "../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useSelector} from "react-redux";

const RolesPopUp = (props) => {
    const isQuiz = useSelector(state => state.appReducer.isQuiz) ? useSelector(state => state.appReducer.isQuiz) : props.isQuiz
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const cardContainerRef = useRef(null)
    const handleOutSideClick = e => {
        if (cardContainerRef.current && !cardContainerRef.current.contains(e.target)) {
            props.setShowRolePopUp(false);
            document.onclick = null;
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleOutSideClick, true);
        return () => {
            document.removeEventListener('click', handleOutSideClick, true);
        }
    }, [])
    return (
        <div id={'roles-popup'} className={style.rolePopUp} ref={cardContainerRef} style={selectedLanguage === 'te' || selectedLanguage === 'fr' || selectedLanguage === 'ta' || selectedLanguage === 'ml' ? {top: '0'} : null}>
            <div id={'roles-title'} style={{display: "flex", backgroundColor: '#1976D2', color: "white", justifyContent: "space-between"}}>
                <h5 style={{margin: '6px'}}>{t(translationKeys.roles_rights)}</h5>
                <img onClick={() => props.setShowRolePopUp(false)} src={close} alt={'close'} style={{width: "initial", cursor: 'pointer', margin: '6px 10px'}}/>
            </div>
            {isQuiz ? <table style={{border: "hidden"}}>
                <tr id={'user-roles'} style={{color: '#004BA0'}}>
                    <th></th>
                    <th id={'admin-role'}>{t(translationKeys.admin)}</th>
                    <th id={'editor-role'}>{t(translationKeys.editor)}</th>
                    <th id={'viewer-role'}>{t(translationKeys.viewer)}</th>
                </tr>
                <tr id={'collaborators-role'} style={{color: '#004BA0'}}>
                    <th>{t(translationKeys.collaborators)}</th>
                </tr>
                <tr>
                    <span id={"roles-view"}>{t(translationKeys.view)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                </tr>
                <tr>
                    <span id={'roles-add-remove'}>{t(translationKeys.add)}/{t(translationKeys.remove)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr>
                    <span id={'roles-change'}>{t(translationKeys.change_roles)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr style={{color: '#004BA0'}}>
                    <th id={'roles-for-quizzes'}>{t(translationKeys.quizzes)}</th>
                </tr>
                <tr>
                    <span id={'roles-edit'}>{t(translationKeys.edit)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr>
                    <span id={'roles-publish'}>{t(translationKeys.publish)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr>
                    <span id={'roles-delete'}>{t(translationKeys.delete)}</span>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr style={{color: '#004BA0'}}>
                    <th id={'answers'}>{t(translationKeys.answers)}</th>
                </tr>
                <tr>
                    <span id={'roles-view1'}>{t(translationKeys.view)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                </tr>
                <tr>
                    <span id={'roles-popup-download'}>{t(translationKeys.download)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                </tr>
                <tr>
                    <span id={'roles-edit-marks'}>{t(translationKeys.edit)} {t(translationKeys.marks)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr>
                    <span id={'roles-delete1'}>{t(translationKeys.delete)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
            </table> : <table style={{border: "hidden"}}>
                <tr style={{color: '#004BA0'}}>
                    <th></th>
                    <th id={'admin-role1'}>{t(translationKeys.admin)}</th>
                    <th id={'editor-role1'}>{t(translationKeys.editor)}</th>
                    <th id={'viewer-role1'}>{t(translationKeys.viewer)}</th>
                </tr>
                <tr style={{color: '#004BA0'}}>
                    <th id={'roles-popup-collaborator'}>{t(translationKeys.collaborators)}</th>
                </tr>
                <tr>
                    <span id={'roles-view2'}>{t(translationKeys.view)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                </tr>
                <tr>
                    <span id={'roles-popup-add-remove'}>{t(translationKeys.add)}/{t(translationKeys.remove)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr>
                    <span id={'user-change-roles'}>{t(translationKeys.change_roles)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr style={{color: '#004BA0'}}>
                    <th id={'roles-for-forms'}>{t(translationKeys.forms)}</th>
                </tr>
                <tr>
                    <span id={'edit-roles'}>{t(translationKeys.edit)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr>
                    <span id={'delete-roles'}>{t(translationKeys.delete)}</span>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
                <tr style={{color: '#004BA0'}}>
                    <th id={'roles-responses'}>{t(translationKeys.responses)}</th>
                </tr>
                <tr>
                    <span id={'roles-view3'}>{t(translationKeys.view)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                </tr>
                <tr>
                    <span id={'roles-download'} >{t(translationKeys.download)} </span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                </tr>
                <tr>
                    <span id={'roles-delete'}>{t(translationKeys.delete)}</span>
                    <td><img alt={'tick/cross'} src={tick}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                    <td><img alt={'tick/cross'} src={cross}/></td>
                </tr>
            </table>}
        </div>
    )
}

export default RolesPopUp