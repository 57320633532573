const initialState = {
    showShareScreen: null,
    showDeletePopUp: false,
    deletableForqData: null
}

export const SHARED_ACTION_TYPE = {
    RESET_ALL: 'RESET_ALL',
    SET_SHOW_SHARE_SCREEN: 'SET_SHOW_SHARE_SCREEN',
    SET_SHOW_DELETE_POP_UP: 'SET_SHOW_DELETE_POP_UP',
    SET_DELETABLE_FORQ_DATA: 'SET_DELETABLE_FORQ_DATA'
}

const sharedReducer = (state = initialState, action) => {
    switch (action.type) {
        case (SHARED_ACTION_TYPE.SET_SHOW_SHARE_SCREEN):
            return {
                ...state,
                showShareScreen: action.payload
            }
        case (SHARED_ACTION_TYPE.SET_DELETABLE_FORQ_DATA):
            return {
                ...state,
                deletableForqData: action.payload
            }
        case (SHARED_ACTION_TYPE.SET_SHOW_DELETE_POP_UP):
            return {
                ...state,
                showDeletePopUp: action.payload
            }
        case (SHARED_ACTION_TYPE.RESET_ALL):
            state = initialState
            return {
                ...state
            }
        default:
            return state
    }
}

export default sharedReducer
