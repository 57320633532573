import {DATE_FORMATS} from "../../admin/constants";

export function getDateTimeWithFormat(time, format) {
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let formattedDateTime
    let formattedDate
    let formattedTime
    let timeStamp
    let hour
    if (format === DATE_FORMATS.DDMMYYYY) {
        if (time) {
            let date = new Date(parseInt(time))
            formattedDate = date.getDate() + '/' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/' + date.getFullYear()
            let isAfterNoon = date.getHours() > 12;
            if (date.getHours() >= 12) {
                timeStamp = 'PM'
            } else {
                timeStamp = 'AM'
            }
            if (isAfterNoon) {
                hour = date.getHours() - 12
            } else {
                hour = date.getHours()
            }
            formattedTime = ('0' + hour).slice(('0' + hour).length - 2) + ':' + ('0' + date.getMinutes()).slice(('0' + date.getMinutes()).length - 2);
            formattedDateTime = formattedDate + '-' + formattedTime + timeStamp
            return (formattedDateTime)
        } else return ''
    } else if (format === DATE_FORMATS.MMDDYYYY) {
        if (time) {
            let date = new Date(parseInt(time))
            formattedDate = (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/' + date.getDate() + '/' + date.getFullYear()
            let isAfterNoon = date.getHours() > 12;
            if (date.getHours() >= 12) {
                timeStamp = 'PM'
            } else {
                timeStamp = 'AM'
            }
            if (isAfterNoon) {
                hour = date.getHours() - 12
            } else {
                hour = date.getHours()
            }
            formattedTime = ('0' + hour).slice(('0' + hour).length - 2) + ':' + ('0' + date.getMinutes()).slice(('0' + date.getMinutes()).length - 2);
            formattedDateTime = formattedDate + '-' + formattedTime + timeStamp
            return (formattedDateTime)
        } else return ''
    } else if (format === DATE_FORMATS.YYYYMMDD) {
        if (time) {
            let date = new Date(parseInt(time))
            formattedDate = date.getFullYear() + '/' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/' + date.getDate()
            let isAfterNoon = date.getHours() > 12;
            if (date.getHours() >= 12) {
                timeStamp = 'PM'
            } else {
                timeStamp = 'AM'
            }
            if (isAfterNoon) {
                hour = date.getHours() - 12
            } else {
                hour = date.getHours()
            }
            formattedTime = ('0' + hour).slice(('0' + hour).length - 2) + ':' + ('0' + date.getMinutes()).slice(('0' + date.getMinutes()).length - 2);
            formattedDateTime = formattedDate + '-' + formattedTime + timeStamp
            return (formattedDateTime)
        } else return ''
    } else if (format === DATE_FORMATS.TIME_DDMMYYYY) {
        if (time) {
            let date = new Date(parseInt(time))
            formattedDate = date.getDate() + '/' + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '/' + date.getFullYear()
            let isAfterNoon = date.getHours() > 12;
            if (date.getHours() >= 12) {
                timeStamp = 'PM'
            } else {
                timeStamp = 'AM'
            }
            if (isAfterNoon) {
                hour = date.getHours() - 12
            } else {
                hour = date.getHours()
            }
            formattedTime = ('0' + hour).slice(('0' + hour).length - 2) + ':' + ('0' + date.getMinutes()).slice(('0' + date.getMinutes()).length - 2);
            formattedDateTime = formattedTime + timeStamp + '-' + formattedDate
            return (formattedDateTime)
        } else return ''
    } else if (format === DATE_FORMATS.DD_MM_YYYY_TIME) {
        if (time) {
            let date = new Date(parseInt(time))
            formattedDate = date.getDate() + ' ' + month[date.getMonth()] + ' ' + date.getFullYear()
            let isAfterNoon = date.getHours() > 12;
            if (date.getHours() >= 12) {
                timeStamp = 'PM'
            } else {
                timeStamp = 'AM'
            }
            if (isAfterNoon) {
                hour = date.getHours() - 12
            } else {
                hour = date.getHours()
            }
            formattedTime = ('0' + hour).slice(('0' + hour).length - 2) + ':' + ('0' + date.getMinutes()).slice(('0' + date.getMinutes()).length - 2);
            formattedDateTime = formattedDate + ' ' + formattedTime + timeStamp
            return (formattedDateTime)
        } else return ''
    } else if (format === DATE_FORMATS.DD_MM_YYYYCOMATIME) {
        if (time) {
            let date = new Date(parseInt(time))
            formattedDate = (date.getDate() < 9 ? '0' + date.getDate() : date.getDate()) + ' ' + month[date.getMonth()] + ' ' + date.getFullYear()
            let isAfterNoon = date.getHours() > 12;
            if (date.getHours() >= 12) {
                timeStamp = ' PM'
            } else {
                timeStamp = ' AM'
            }
            if (isAfterNoon) {
                hour = date.getHours() - 12
            } else {
                hour = date.getHours()
            }
            formattedTime = ('0' + hour).slice(('0' + hour).length - 2) + ':' + ('0' + date.getMinutes()).slice(('0' + date.getMinutes()).length - 2);
            formattedDateTime = formattedDate + ', ' + formattedTime + timeStamp
            return (formattedDateTime)
        } else return ''
    } else return ''
}