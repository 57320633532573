import React from 'react'
import '../css/bottom-bar.css'
import ChangeContainerMenuItem from "../classes/ChangeContainerMenuItem";
import NavigationMenuItem from "../classes/NavigationMenuItem";
import PopupMenuItem from "../classes/PopupMenuItem";
import MaterialFloatingActionButton from "./MaterialFab";
import {useSelector} from "react-redux";
import {isMobileOnly} from "react-device-detect";


const BottomBar = (props) => {
    const selected = useSelector(state => state.languageReducer?.selectedLanguage)
    const GetMenuContainer = (menuItem, index) => {
        if (menuItem instanceof ChangeContainerMenuItem) return (ChangeContainerDiv(menuItem, index, props.updateContainer, selected))
        else if (menuItem instanceof NavigationMenuItem || menuItem instanceof PopupMenuItem) return (
            NavigationAndPopupMenuDiv(menuItem, index, props.doNavigation, props.doPopupAction, selected)
        )
    }
    return (
        <div id={'bottom-bar-container'} className='bottom-bar-container'
             style={{
                 justifyContent: props.isCenterFABPresent ? 'space-between' : 'space-around',
                 zIndex: props.isCenterFABPresent ? '2' : null
             }}>
            {props.isCenterFABPresent ?
                <React.Fragment>
                    <div className="half-container">
                        {GetMenuContainer(props.BottomBarMenuItems[0])}
                        {GetMenuContainer(props.BottomBarMenuItems[1])}
                    </div>
                    <div id={'center-FAB-container'}
                         className={(selected === 'fa' || selected === 'ar') ? 'center-FAB-container-fa' : 'center-FAB-container'}
                         style={{filter: props.disableCenterFab ? "opacity(0.2)" : null}}>
                        <MaterialFloatingActionButton iconName={'add'} handleClick={props.centerFABClickFunction}
                                                      backgroundColor={'#004BA0'}/>
                    </div>
                    <div className="half-container">
                        {GetMenuContainer(props.BottomBarMenuItems[2])}
                        {GetMenuContainer(props.BottomBarMenuItems[3])}
                    </div>
                </React.Fragment>
                :
                props.BottomBarMenuItems.map((menuItem, index) => (
                    GetMenuContainer(menuItem, index)
                ))
            }
        </div>
    )
}

export default BottomBar

const ChangeContainerDiv = (menuItem, index, updateContainer, selected) => {

    return (
        <div id={menuItem.getKey()} key={menuItem.getKey()} className="icon-container"
             onClick={() => updateContainer(menuItem)}
        >
            <img src={menuItem.getSelected() ?
                `${menuItem.getIconInfo().getImgSrcPath()}_blue.svg` :
                `${menuItem.getIconInfo().getImgSrcPath()}_grey.svg`}
                 alt={menuItem.getIconInfo().getAltText()}
                 style={{
                     width: '30px',
                     filter: menuItem.getSelected() && isMobileOnly ? 'hue-rotate(370deg) brightness(75%)' : null
                 }}/>
            <span className={(selected === 'fa' || selected === 'ar') ? "icon-title-fa" : "icon-title"}
                  style={menuItem.getSelected() ? {color: '#004BA0', whiteSpace: "nowrap"} : {
                      color: '#5E5E5E',
                      whiteSpace: "nowrap"
                  }}>
            {menuItem.getTitle()}
            </span>
        </div>
    )
}

const NavigationAndPopupMenuDiv = (menuItem, index, doNavigation, doPopupAction, selected) => {
    return (
        <div id={menuItem.getKey()} key={menuItem.getKey()} className="icon-container" style={{backgroundColor: '#fff'}}
             onClick={() => {
                 if (menuItem instanceof NavigationMenuItem) return (doNavigation(menuItem))
                 if (menuItem instanceof PopupMenuItem) return (doPopupAction(menuItem))
             }}
        >
            <img src={`${menuItem.getIconInfo().getImgSrcPath()}`}
                 style={{width: '30px'}}
                 alt={menuItem.getIconInfo().getAltText()}/>
            <span className="icon-title" style={{color: '#424242', fontSize: selected === 'ta' ? "10px" : null}}>
            {menuItem.getTitle()}
            </span>
        </div>
    )
}
/*

function BottomBarMobileView(props) {
    const {showForms, showQuiz, showTemplates} = props.parentState;
    return (
        <div className='mobile-bottom-bar'>
            <div className="bottom-left-half-container">
                <div className="left-nav-icon-container"
                     style={{backgroundColor: '#fff'}}
                     onClick={() => {
                         props.handleClick('Forms')
                     }}>
                    <img alt="Home-icon" style={{width: '22px'}}
                         src={showForms ? "../../../../images/summary_blue.png" : "../../../../images/summary_grey.png"}/>
                    <span className="icon-title"
                          style={showForms ? {color: '#1976d2'} : {
                              color: '#424242',
                          }}>Forms</span>
                </div>
                <div className="left-nav-icon-container"
                     style={{backgroundColor: '#fff'}}
                     onClick={() => {
                         props.handleClick('Quizzes')
                     }}>
                    <img alt="Home-icon" style={{width: '22px'}}
                         src={showQuiz ? "../../../../images/quiz_blue.png" : "../../../../images/quiz_grey.png"}/>
                    <span className="icon-title"
                          style={showQuiz ? {color: '#1976d2'} : {
                              color: '#424242',
                          }}>Quizzes</span>
                </div>
            </div>
            <div className='create-new-container'>
                <div className='create-new-button-mobile'
                     onClick={(e) => {
                         props.showCreateNewBanner(true);
                         e.stopPropagation();
                     }}>
                    <img className='create-new-image' src='../../../../images/add_white.png' alt={'blank form'}/>
                </div>
            </div>
            <div className="bottom-right-half-container">
                <div className="left-nav-icon-container"
                     style={{backgroundColor: '#fff'}}
                     onClick={() => {
                         props.handleClick('Templates')
                     }}>
                    <img alt="Templates-icon" style={{width: '22px'}}
                         src={showTemplates ? "../../../../images/template_blue.png" : "../../../../images/template_grey.png"}/>
                    <span className="icon-title" style={showTemplates ? {color: '#1976d2'} : {
                        color: '#424242',
                    }}>Templates</span>
                </div>

                <div className="left-nav-icon-container" onClick={() => {
                    // window.open('/form/5e2ad7279ddceb28451312d4')
                    props.showMorePopUp()
                }}>
                    <img alt="Feedback-icon" style={{width: '22px'}} src="../../../../images/more_grey.png"/>
                    <span className="icon-title">More</span>
                </div>
            </div>
        </div>
    )
}

export default BottomBarMobileView*/
