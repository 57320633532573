import React, {useEffect, useState} from "react"
import "../css/carousel-component.css"
import {isMobileOnly} from "react-device-detect";

const CarouselComponent = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        {
            src: "../../images/homeCarouselImages/data-collect.png",
            title: "Data Collection",
            description: "Collect market research, academic study data, and customer feedback efficiently with customizable surveys."
        },
        {
            src: "../../images/homeCarouselImages/employee-feedback.png",
            title: "Employee feedback",
            description: "Collect feedback from employees to improve workplace culture and operations. Regular feedback can boost morale and productivity."
        },
        {
            src: "../../images/homeCarouselImages/surveys.png",
            title: "Surveys",
            description: "Conduct surveys to gather opinions, preferences, and feedback from your audience. Tailor your services or products based on the insights gained."
        },
        {
            src: "../../images/homeCarouselImages/polls-and-voting.png",
            title: "Polls and Voting",
            description: "Create interactive polls to engage visitors and gather instant responses on various topics. Analyze poll results to understand public opinion quickly."
        },
        {
            src: "../../images/homeCarouselImages/online-quizzes.png",
            title: "Online Quizzes",
            description: "Design quizzes to educate, entertain, or assess users, enhancing user engagement. Quizzes can also help in lead generation and customer retention."
        },
        {
            src: "../../images/homeCarouselImages/getting-customer-satisfaction.png",
            title: "Getting Customer Satisfaction",
            description: "Measure customer satisfaction through surveys and feedback forms to improve services. Use the feedback to enhance customer experience and loyalty."
        },
        {
            src: "../../images/homeCarouselImages/job-applications.png",
            title: "Job applications",
            description: "Simplify the hiring process by allowing candidates to apply through your forms. Streamline applicant tracking and communication with potential hires."
        },
        {
            src: "../../images/homeCarouselImages/registration-form.png",
            title: "Registration form",
            description: "Facilitate user sign-ups for events, newsletters, or memberships with an easy-to-use form. Ensure a seamless onboarding process for new users."
        },
        {
            src: "../../images/homeCarouselImages/market-research.png",
            title: "Market Research",
            description: "Conduct market research to gather insights and trends from your target audience. Use this data to make informed business decisions and strategies."
        },
        {
            src: "../../images/homeCarouselImages/restaurant-forms.png",
            title: "Restaurant forms",
            description: "Share your dining experience through feedback forms to improve our services. Use your input to enhance customer satisfaction by enhancing service quality."
        },
        {
            src: "../../images/homeCarouselImages/hospital-forms.png",
            title: "Hospital forms",
            description: "Complete essential documents for patient care and provide feedback to improve our healthcare services. Your input helps increasing patient’s experience."
        },
        {
            src: "../../images/homeCarouselImages/yoga-forms.png",
            title: "Yoga forms",
            description: "Register for classes, provide health details, and share feedback to improve our yoga offerings. Use your feedback to elevate your yoga journey and experience."
        }
    ]

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
        }, 5000)
        return () => clearTimeout(timer)
    }, [currentIndex])

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - 1 : prevIndex - 1));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="carousel">
            <button onClick={goToPrevious} style={{top: isMobileOnly ? "20%" : null}} className={"carousel-button carousel-button-left"}>
                &#10094;
            </button>
            <div className="carousel-content" style={{transform: `translateX(${-100 * currentIndex}%)`}}>
                {slides.map((slide, index) => (
                    <div key={index}
                         style={{
                             backgroundImage: `url(${slide.src})`,
                             backgroundSize: isMobileOnly ? "contain" : "cover",
                             height: isMobileOnly ? "21vh" : "91vh"
                         }}
                         className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}>
                        {isMobileOnly ? null : <div style={{
                            color: "#FFFFFF",
                            textAlign: "start",
                            alignSelf: "end",
                            margin: "50px",
                            width: "100%"
                        }}>
                            <h2 style={{margin: "5px 0"}}>{slide.title}</h2>
                            <h3 style={{fontSize: "25px", fontWeight: "normal", margin: "0"}}>{slide.description}</h3>
                        </div>}
                    </div>
                ))}
            </div>
            <button onClick={goToNext} style={{top: isMobileOnly ? "20%" : null}} className={"carousel-button carousel-button-right"}>
                &#10095;
            </button>
            {isMobileOnly ? <div className="carousel-content" style={{transform: `translateX(${-100 * currentIndex}%)`}}>
                {slides.map((slide, index) => (
                    <div key={index} className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}>
                        <div style={{color: "#000000", margin: "20px", width: "100%"}}>
                            <h2 style={{margin: "5px 0", color: "#1976d2"}}>{slide.title}</h2>
                            <h3 style={{fontSize: "20px", fontWeight: "normal", margin: "0"}}>{slide.description}</h3>
                        </div>
                    </div>
                ))}
            </div> : null}
            <div className={"dots"}>
                {slides.map((slide, index) => <span
                    style={{backgroundColor: index === currentIndex ? "#8E8E8E" : null}}/>)}
            </div>
        </div>
    )
}

export default CarouselComponent
