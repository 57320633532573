import React from 'react'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";

export default function ContentHeaderFavourite(props) {
    const {t} = useTranslation()
    return (
        <div id={'content-header'} className="content-header">
            <div id={'form-sort-container'} className="form-sort-container">
                <div className="sort-forms-fav">{t(translationKeys.favourites)} <span
                    style={{marginLeft: '4px'}}>({props.favCount})</span></div>
                {
                    (() => {
                        if (props.showViewAll_Collapse) return (
                            <div style={{cursor: 'pointer'}}>
                                <div id={'sort-forms'} className="forms-fav"
                                     style={props.viewAllFavs ? {
                                         color: '#004BA3',
                                         borderBottomLeftRadius: 0,
                                         borderBottomRightRadius: 0
                                     } : null}
                                     onClick={e => {
                                         e.stopPropagation();
                                         props.viewAll_Collapse_Clicked();
                                     }}>
                                <span style={{marginRight: '15px'}}>
                                    {props.viewAllFavs ? t(translationKeys.collapse) : t(translationKeys.view_all)} </span>
                                    <div style={{display: 'grid'}}>
                                        <img style={{width: '24px', height: '18px'}}
                                             src={props.viewAllFavs ? "../../../images/sort_more.svg" : "../../../images/sort_minimize.svg"}
                                             alt="more-icon"/>
                                        <img style={{width: '24px', height: '18px'}}
                                             src={props.viewAllFavs ? "../../../images/sort_minimize.svg" : "../../../images/sort_more.svg"}
                                             alt="more-icon"/>
                                    </div>
                                </div>
                            </div>
                        )
                    })()
                }
            </div>
        </div>
    )
}
