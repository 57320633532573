import React from 'react'
import FormCardMoreButtonContainer from "./FormCardMoreButtonContainer";
import {CARD_TYPE} from "./FormCard";
import {isMobileOnly} from "react-device-detect";
import {getDate} from "../utils/get-date";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";

const FormCardBottomContainer = props => {
    const formData = props.formData

    return (
        <div id={'bottom-container-' + props.cardIndex} className="bottom-container">
            <div id={'bottom-text-container-' + props.cardIndex} className="bottom-text-container"
                 style={(props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ? {width: '100%'} : null}>
                <p id={'card-form-title'} className="card-form-title"
                   style={(props.CARD_TYPE === CARD_TYPE.USER_FORM) ? null :
                       (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ? {width: '100%'} : null}>
                    {formData.welcome_screen.title}
                </p>
                {(props.CARD_TYPE === CARD_TYPE.USER_FORM) ?
                    <p id={'form-modified-date'} className='secondary-data'>
                        {isMobileOnly ?
                            getResponseCountText(formData.response_count, formData.is_quiz) + ' - ' + t(translationKeys.modified)+':'+ ' ' + getDate(formData.date_edited) : (t(translationKeys.modified)+':'+' ' + getDate(formData.date_edited))}
                    </p> :
                    (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ?
                        <p className='secondary-data'
                           style={{width: '100%'}}>{formData.welcome_screen.description}</p>
                        : null}
            </div>
            {(props.CARD_TYPE === CARD_TYPE.USER_FORM) && formData.is_valid ?
                <FormCardMoreButtonContainer formData={formData}
                                             showEdit={props.showEdit}
                                             favouriteClicked={props.favouriteClicked}
                                             cardIndex={props.cardIndex}
                                             updateStatus={props.updateStatus}
                                             previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                             showDataEditWarning={props.showDataEditWarning}
                /> : null
            }
        </div>
    )
}

export default FormCardBottomContainer

const getResponseCountText = (responseCount, isQuiz) => {
    return (
        responseCount ?
            (responseCount + (isQuiz ? responseCount > 1 ? ' ' + t(translationKeys.answers) : ' ' + t(translationKeys.answer_s) : responseCount > 1 ? ' ' + t(translationKeys.responses).toLowerCase() : ' ' + t(translationKeys.response))) : (isQuiz ? '0 '+t(translationKeys.answer_s) : '0 '+t(translationKeys.response))
    )
}
