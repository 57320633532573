import {CURRENTLY_SELECTED_MENU} from "../../shared/utils/constants";

const initialState = {
    newQuestion: false,
    formUserInfoText: "",
    questionImages: false,
    showPreviewPane: true,
    formUserInfoCheck: false,
    showModalDialog: false,
    sectionTitleToFocus: -1,
    optionDeleteAction: null,
    refreshAnswerErrors: false,
    selectedPreviewScreen: null,
    showQuizActionBlockedPopUp: false,
    showOptionDeletePopUp: false,
    showPremiumPopUp: false,
    formAbuseAndBlockedWordsList: null,
    quizAbuseAndBlockedWordsList: null,
    isManuallyChangedFormUserInfo: false,
    currentlySelectedMenuItem: CURRENTLY_SELECTED_MENU.BUILDER,
    isDragAndDropDisabled: false,
    builderToastMessage: false,
    templateCategories: [],
}

export const BUILDER_ACTION_TYPE = {
    SET_NEW_QUESTION: "SET_NEW_QUESTION",
    SET_QUESTION_IMAGES: 'SET_QUESTION_IMAGES',
    SET_SHOW_PREVIEW_PANE: 'SET_SHOW_PREVIEW_PANE',
    SET_FORM_USER_INFO_TEXT: "SET_FORM_USER_INFO_TEXT",
    SET_FORM_USER_INFO_CHECK: "SET_FORM_USER_INFO_CHECK",
    SET_SHOW_MODAL_DIALOG: "SET_SHOW_MODAL_DIALOG",
    SET_SHOW_PREMIUM_POP_UP: "SET_SHOW_PREMIUM_POP_UP",
    SET_OPTION_DELETE_ACTION: "SET_OPTION_DELETE_ACTION",
    SET_REFRESH_ANSWER_ERRORS: "SET_REFRESH_ANSWER_ERRORS",
    SET_SECTION_TITLE_TO_FOCUS: "SET_SECTION_TITLE_TO_FOCUS",
    SET_SELECTED_PREVIEW_SCREEN: 'SET_SELECTED_PREVIEW_SCREEN',
    SET_SHOW_OPTION_DELETE_POP_UP: "SET_SHOW_OPTION_DELETE_POP_UP",
    SET_CURRENTLY_SELECTED_MENU_ITEM: 'SET_CURRENTLY_SELECTED_MENU_ITEM',
    SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP: 'SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP',
    SET_QUIZ_ABUSE_AND_BLOCKED_WORDS_LIST: "SET_QUIZ_ABUSE_AND_BLOCKED_WORDS_LIST",
    SET_FORM_ABUSE_AND_BLOCKED_WORDS_LIST: "SET_FORM_ABUSE_AND_BLOCKED_WORDS_LIST",
    SET_IS_MANUALLY_CHANGED_FORM_USER_INFO: 'SET_IS_MANUALLY_DISABLED_FORM_USER_INFO',
    SET_IS_DRAG_AND_DROP_DISABLED: 'SET_IS_DRAG_AND_DROP_DISABLED',
    SET_BUILDER_TOAST_MESSAGE: 'SET_BUILDER_TOAST_MESSAGE',
    SET_BUILDER_MESSAGE: 'SET_BUILDER_MESSAGE',
    SET_TEMPLATE_CATEGORIES_LIST: "SET_TEMPLATE_CATEGORIES_LIST",
}

const builderReducer = (state = initialState, action) => {
    switch (action.type) {
        case (BUILDER_ACTION_TYPE.SET_NEW_QUESTION):
            return {
                ...state,
                newQuestion: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_QUIZ_ABUSE_AND_BLOCKED_WORDS_LIST):
            return {
                ...state,
                quizAbuseAndBlockedWordsList: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_FORM_ABUSE_AND_BLOCKED_WORDS_LIST):
            return {
                ...state,
                formAbuseAndBlockedWordsList: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_SHOW_MODAL_DIALOG):
            return {
                ...state,
                showModalDialog: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_SHOW_PREMIUM_POP_UP):
            return {
                ...state,
                showPremiumPopUp: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_REFRESH_ANSWER_ERRORS):
            return {
                ...state,
                refreshAnswerErrors: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_SECTION_TITLE_TO_FOCUS):
            return {
                ...state,
                sectionTitleToFocus: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_CHECK):
            return {
                ...state,
                formUserInfoCheck: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_FORM_USER_INFO_TEXT):
            return {
                ...state,
                formUserInfoText: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_CURRENTLY_SELECTED_MENU_ITEM):
            return {
                ...state,
                currentlySelectedMenuItem: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_SELECTED_PREVIEW_SCREEN):
            return {
                ...state,
                selectedPreviewScreen: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_SHOW_PREVIEW_PANE):
            return {
                ...state,
                showPreviewPane: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_OPTION_DELETE_ACTION):
            return {
                ...state,
                optionDeleteAction: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_SHOW_OPTION_DELETE_POP_UP):
            return {
                ...state,
                showOptionDeletePopUp: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_SHOW_QUIZ_ACTION_BLOCKED_POP_UP):
            return {
                ...state,
                showQuizActionBlockedPopUp: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_QUESTION_IMAGES):
            return {
                ...state,
                questionImages: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_IS_MANUALLY_CHANGED_FORM_USER_INFO):
            return {
                ...state,
                isManuallyChangedFormUserInfo: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED):
            return {
                ...state,
                isDragAndDropDisabled: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE):
            return {
                ...state,
                builderToastMessage: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_BUILDER_MESSAGE):
            return {
                ...state,
                builderMessage: action.payload
            }
        case (BUILDER_ACTION_TYPE.SET_TEMPLATE_CATEGORIES_LIST):
            return {
                ...state,
                templateCategories: action.payload
            }
        default:
            return state
    }
}

export default builderReducer