import React, {useEffect, useRef} from 'react';
import '../css/material-checkbox.css';
import {MDCCheckbox} from "@material/checkbox/component";

function MaterialCheckBox(props) {
    const checkBoxRef = useRef(null)

    //Initializing UI
    useEffect(() => {
        const checkBox = new MDCCheckbox(checkBoxRef.current);
        if (props.isChecked !== undefined) {
            checkBox.checked = props.isChecked
        }
        if(props.checkBoxRef) {
            props.checkBoxRef.current = checkBox
        }
    })
    return (
        <div id={'material-check-box'} className="mdc-touch-target-wrapper" style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: props.checkboxLabel ? 'initial' : 'center',
            width: props.removeWidth ? '' :'100%',
            background: props.background ? props.background : null,
            marginBottom: props.showFullLabelText ? '6px' : null
        }}>
            <div id={'mdc-checkbox'} className="mdc-checkbox" ref={checkBoxRef} onClick={(e) => props.onChange(e.target.checked)}
                 test-id={props.test_id ? props.test_id : null}
                 style={{'--mdc-theme-secondary': (props.customStyle && props.customStyle.checkedColor) ? props.customStyle.checkedColor : props.service === 'Quiz' ? '#329d58' : '#1976d2'}}>
                <input type="checkbox"
                       onFocus={props.onFocus}
                       name={props.name ? props.name : undefined}
                       id={props.id ? props.id : null}
                       value={props.id ? props.id : undefined}
                       defaultChecked={props.isChecked ? props.isChecked : false}
                       disabled={props.isDisabled}
                       className="mdc-checkbox__native-control"/>
                <div className="mdc-checkbox__background"
                     style={{
                         // backgroundColor: (props.customStyle && props.customStyle.checkboxBackgroundColor) ? props.customStyle.checkboxBackgroundColor : '#212121',
                         borderColor: (props.customStyle && props.customStyle.checkboxBorderColor) ? props.customStyle.checkboxBorderColor : props.service === 'Quiz' ? '#329d58' : '#1976d2'
                     }}>
                    <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                        <path className="mdc-checkbox__checkmark-path"
                              fill="none"
                              d="M1.73,12.91 8.1,19.28 22.79,4.59"/>
                    </svg>
                </div>
                <div id={props.id} className="mdc-checkbox__ripple"/>
            </div>
            {props.checkboxLabel ?
                <label
                    style={{
                        textOverflow: !props.showFullLabelText ? 'ellipsis': null,
                        whiteSpace:  !props.showFullLabelText ? 'nowrap': null,
                        overflow:  !props.showFullLabelText ? 'hidden' : null,
                        fontSize: '18px',
                        marginLeft: '10px',
                        color: (props.customStyle && props.customStyle.checkboxLabelColor) ? props.customStyle.checkboxLabelColor : props.service === 'Quiz' ? '#329d58' : '#1976d2'
                    }}>{props.checkboxLabel}
                </label> : null}
        </div>
    );
}

export default MaterialCheckBox
