import React from 'react'
import {isMobileOnly} from "react-device-detect";
import {UPLOAD_TYPE} from "../../../shared/utils/constants";
import uploadGrey from "../../../../public/images/upload_grey.png";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {translationsEnglish} from "../../../localizations/en-localization";

const ChooseImageContainer = (props) => {
    const {t} = useTranslation()
    return (
        <div id={'choose-image-text-container'} className="choose-image-text-container center"
             style={props.show ? {} : {display: 'none'}}>
            <img style={{marginBottom: '25px'}} src={uploadGrey}
                 alt="Upload Image"/>
            {(() => {
                if (props.showFileUploadMaxSizeError) return (
                    <div className='file-upload-validation'>* {props.isFiller ? translationsEnglish.maximum_allowed_file_size : t(translationKeys.maximum_allowed_file_size)}
                        <b>{props.uploadType === UPLOAD_TYPE.FILE ? '50' : '4'}MB.</b>
                    </div>)
            })()}
            {(() => {
                if (props.showInvalidFileTypeError) return (<div className='file-upload-validation'>
                    * {props.isFiller ? translationsEnglish.invalid_file_type : t(translationKeys.invalid_file_type)}
                </div>)
            })()}
            <div id={'popup-file-upload-button'} className="popup-positive-button center"
                 onClick={props.openLocalStorage}>{props.isFiller ? translationsEnglish.choose : t(translationKeys.choose)} {props.uploadType === UPLOAD_TYPE.FILE ? props.isFiller ? translationsEnglish.file : t(translationKeys.file) : t(translationKeys.image)}
            </div>
            <div style={{
                display: isMobileOnly ? 'none' : 'flex',
                alignItems: 'center',
                width: '200px',
                color: '#9e9e9e',
                marginTop: '20px'
            }}>
                <div style={{width: '50%', height: '0', border: '1px solid #e0e0e0'}}/>
                <span style={{color: '#9e9e9e'}}>{props.isFiller ? translationsEnglish.or.toUpperCase() : t(translationKeys.or).toUpperCase()}</span>
                <div style={{width: '50%', height: '0', border: '1px solid #e0e0e0'}}/>
            </div>
            <span id={'drag-drop-image-here'} style={{
                color: '#636363', fontSize: '16px', marginTop: '15px'
            }}>{props.isFiller ? translationsEnglish.drag_drop : t(translationKeys.drag_drop)} {props.uploadType === UPLOAD_TYPE.FILE ? props.isFiller ? translationsEnglish.file : t(translationKeys.file) : props.isFiller ? translationsEnglish.image : t(translationKeys.image)} {props.isFiller ? translationsEnglish.here : t(translationKeys.here)}</span>
        </div>
    );
}

export default ChooseImageContainer
