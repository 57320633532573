import React, {useState, useEffect} from 'react'
import FormCard, {CARD_TYPE} from "./FormCard";
import MobileFormControlContainer from "./MobileFormControlContainer";
import DataUnavailableComponent from "./DataUnavailableComponent";
import {
    deleteManyQuizzes, deleteMultipleAnswersInQuiz, deleteQuizAnswerService,
    deleteQuizService,
    getQuizzesListService,
    setQuizFavouriteStatus
} from "../../../shared/utils/quiz-api-services";
import Loader from "../../../shared/components/Loader";
import ContentHeader from "./ContentHeader";
import {sortFields, sortForms} from "../utils/sort-form-list";
import {isMobile, isMobileOnly} from "react-device-detect";
import {removeDataFromDataList} from "../utils/remove-data-from-datalist";
import BottomAlertContainer from "../../../shared/components/BottomAlertContainer";
import ModalDialog from "../../../shared/components/ModalDialog";
import {RESPONSE_CODE} from "../../../shared/utils/helper";
import {showResponseDeleteAlert} from "../utils/dashboard-helper";
import {getForms} from "../../../shared/utils/sash-v2-api";
import ContentHeaderFavourite from "./ContentHeaderFavourite"
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {UPDATE_FORM_DATA} from "../../utils/constants";
import {registerGAEvent} from "../../../shared/utils/analytics"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";
import ShareCard from "../../../shared/components/ShareCard";
import CollaborateCard from "../../../shared/components/CollaborateCard";
import deleteIcon from "../../../../public/images/delete_white.svg";
import Message from "../../../shared/containers/ConfirmMessage";

const BLOCKED_USER = 'account-blocked';

const CURRENT_MOBILE_TAB = {
    NONFAV: 'nonfav',
    FAV: 'fav'
}

const QuizzesContainer = (props) => {

    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const searchText = useSelector(state => state.appDashboardReducer.searchText)
    const quizzesList = useSelector(state => state.appDashboardReducer.quizzesList)
    const showDeletePopUp = useSelector(state => state.quizzesReducer.showDeletePopUp)
    const deletableQuizId = useSelector(state => state.quizzesReducer.deletableQuizId)
    const mobileSortOrder = useSelector(state => state.appDashboardReducer.mobileSortOrder)
    const lastQuizzesSortedBy = useSelector(state => state.quizzesReducer.lastQuizzesSortedBy)
    const updatedFormData = useSelector(state => state.updatedFormDataReducers.updatedFormData)
    // const showQuizQuickSettings = useSelector(state => state.quizzesReducer.showQuizQuickSettings)
    const searchResultTimeStamp = useSelector(state => state.appDashboardReducer.searchResultTimeStamp)
    const showMultipleDeleteOption = useSelector(state => state.quizzesReducer.showMultipleDeleteOption)
    const multipleQuizzesSelectedArray = useSelector(state => state.quizzesReducer.multipleQuizzesSelectedArray)
    const isShowingMobileFormControl = useSelector(state => state.appDashboardReducer.isShowingMobileFormControl)
    const showCollaboratePopUp = useSelector(state => state.appReducer.showCollaboratePopUp)
    const showSharePopUp = useSelector(state => state.appReducer.showSharePopUp)
    const shareFormId = useSelector(state => state.appReducer.formData?._id)
    let startSwipeX, startSwipeY, distX, startTime, elapsedTime, distY;
    const builderToastMessage = useSelector(state => state.builderReducer?.builderToastMessage)
    const builderMessage = useSelector(state => state.builderReducer?.builderMessage)
    const [isLoading, setIsLoading] = useState(true);
    const [viewAllFavs, setViewAllFavs] = useState(false);
    const [favQuizzesList, setFavQuizzesList] = useState([]);
    const [currentMobileTab, setCurrentMobileTab] = useState(CURRENT_MOBILE_TAB.FAV);
    const [numberOfFavCardsToShowInARow, setNumberOfFavCardsToShowInARow] = useState(0);
    const dispatch = useDispatch()
    const allowedTime = 200;
    const quizData = useSelector(state => state.appReducer?.formData)

    const getDataFromServer = () => {
        getQuizzesListService(resJson => {
            let quizzesList;
            if (resJson === BLOCKED_USER) {
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER, payload: true})
            } else if (resJson.result.length > 1) {
                quizzesList = sortForms(resJson.result, sortFields.DATE_EDITED);
            } else if (resJson.result) {
                quizzesList = resJson.result;
            }
            dispatch({
                type: APP_DASHBOARD_ACTION_TYPE.SET_QUIZZES_LIST,
                payload: sortForms(JSON.parse(JSON.stringify(quizzesList)), lastQuizzesSortedBy)
            })
            setIsLoading(false);
            setFavQuizzesList(sortFavQuizzes(quizzesList.filter((formData) => formData.date_favoured)));

            if (quizData?.is_quiz) {
                const updatedQuizzesListData = [...quizzesList]
                updatedQuizzesListData.filter((quiz) => quiz._id === quizData._id)[0].response_count = quizData.response_count
                dispatch({
                    type: APP_DASHBOARD_ACTION_TYPE.SET_QUIZZES_LIST,
                    payload: updatedQuizzesListData
                })
            }

            if (quizzesList.filter((formData) => formData.date_favoured).length === 0) {
                setCurrentMobileTab(CURRENT_MOBILE_TAB.NONFAV)
            }
            if (updatedFormData) {
                setCurrentMobileTab(CURRENT_MOBILE_TAB.NONFAV)
                dispatch({type: UPDATE_FORM_DATA, payload: null})
            }

        }, err => {
            if (err) {
                if (err.status === RESPONSE_CODE.BLOCKED_USER) {
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER, payload: true})
                } else {
                    console.log(err)
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
            console.log(err)
        })
        getForms(() => {
            },
            (error) => {
                if (error) {
                    if (error.status === RESPONSE_CODE.BLOCKED_USER) {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_BLOCKED_USER, payload: true})
                    } else {
                        console.log(error)
                        setIsLoading(false);
                    }
                } else {
                    console.log(error)
                    setIsLoading(false);
                }
            }
        );
    }

    //server call to get all the quizzes of the user.

    useEffect(() => {
        const storedValue = localStorage.getItem('deletedResponse');

        if (storedValue) {
            try {
                const storedObject = JSON.parse(storedValue);
                if (Array.isArray(storedObject.responseId)) {
                    deleteMultipleAnswersInQuiz(storedObject.formId, storedObject.responseId, () =>{localStorage.setItem('deletedResponse', '')}, () =>{})
                }
                else {
                    deleteQuizAnswerService(storedObject.formId, storedObject.responseId, () =>{localStorage.setItem('deletedResponse', '')}, () =>{})
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
            }
        }
    }, [])

    if (currentMobileTab === CURRENT_MOBILE_TAB.NONFAV) {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON, payload: true})
    }

    useEffect(() => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_MOBILE_SORT_ORDER, payload: sortFields.DATE_EDITED})
        getDataFromServer()
    }, [])

    useEffect(() => {
        if (multipleQuizzesSelectedArray.length === 0 && isMobileOnly) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_MOBILE_SORT_ORDER, payload: sortFields.DATE_EDITED})
            getDataFromServer()
        }
    }, [multipleQuizzesSelectedArray])

    const viewAll_Collapse_Clicked = () => {
        setViewAllFavs(!viewAllFavs);
    }

    //    componentDidUpdate

    useEffect(() => {
        if (isMobileOnly && mobileSortOrder) {
            setSortOrder(mobileSortOrder)
        }

    }, [isShowingMobileFormControl, searchResultTimeStamp, mobileSortOrder]);

    const handleFavMobileViewTabClick = () => {
        setCurrentMobileTab(CURRENT_MOBILE_TAB.FAV)
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON, payload: false})
    };

    const handleNonFavMobileViewTabClick = () => {
        setCurrentMobileTab(CURRENT_MOBILE_TAB.NONFAV)
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON, payload: true})
    };

    const onFavCardsContainerChange = (node) => {
        // '270' pixels is the width of a Form Card
        setNumberOfFavCardsToShowInARow(node ? Math.floor(node.offsetWidth / 270) : 0)
    };

    const quizzesSectionShouldBeVisible = (quizzesList, favoriteSection) => {
        if (searchText === '') {
            return quizzesList.length > 0
        } else {
            if (favoriteSection) {
                return quizzesList.length > 0 && quizzesList.filter((quizData) => quizData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length > 0
            } else {
                return quizzesList.length > 0 && quizzesList.filter((quizData) => !quizData.date_favoured && quizData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length > 0
            }
        }
    }

    const setSortOrder = (sortOrder) => {
        dispatch({
            type: APP_DASHBOARD_ACTION_TYPE.SET_QUIZZES_LIST,
            payload: sortForms(JSON.parse(JSON.stringify(quizzesList)), sortOrder)
        })
    }

    const updateQuizzesList = (quizzesList) => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_QUIZZES_LIST, payload: JSON.parse(JSON.stringify(quizzesList))})
    }

    const updateFormStatusInQuizzesList = (formId, isActive) => {
        let quizList = quizzesList;
        for (let i = 0; i < quizList.length; i++) {
            if (quizList[i]._id === formId) {
                if (quizList[i].date_favoured) {
                    favQuizzesList.find((quiz) => quiz._id === formId).setting.is_active = isActive;
                }
                quizList[i].setting.is_active = isActive;
                updateQuizzesList(quizList);
                return;
            }
        }
    }

    const removeDeletedQuizInQuizzesList = (quizId) => {
        let removedQuizzesList = quizzesList;

        if (Array.isArray(quizId)) {
            for (let i = 0; i < quizId.length; i++) {
                removedQuizzesList = removeDataFromDataList(removedQuizzesList, quizId[i]);
            }

            for (let ctr = 0; ctr < quizId.length; ctr++) {
                if (favQuizzesList.find(quiz => quiz._id === quizId[ctr])) {
                    // Remove the Quiz from Favs
                    setFavQuizzesList(removeDataFromDataList(favQuizzesList, quizId[ctr]))
                }
            }
        } else {
            removedQuizzesList = removeDataFromDataList(removedQuizzesList, quizId);
            if (favQuizzesList.find(quiz => quiz._id === quizId)) {
                // Remove the Quiz from Favs
                setFavQuizzesList(sortFavQuizzes(favQuizzesList.filter(favQuiz => favQuiz._id !== quizId)));
            }
        }

        updateQuizzesList(removedQuizzesList)
    }

    const getFavQuizzes = () => {
        if (numberOfFavCardsToShowInARow === 0) return <></>
        if (searchText === "") {
            return (
                () => {
                    if (isMobileOnly || viewAllFavs) {
                        // Return all Quizzes.
                        return favQuizzesList.map((formData, index) => getFormCard(formData, index)
                        )
                    }

                    if (favQuizzesList.length > numberOfFavCardsToShowInARow) {
                        // Return first X Fav Quizzes based on the width of the Screen.
                        return favQuizzesList.filter((formData, index) => index < numberOfFavCardsToShowInARow).map((formData, index) =>
                            getFormCard(formData, index)
                        )
                    } else {
                        // Return all Quizzes.
                        return favQuizzesList.map((formData, index) =>
                            getFormCard(formData, index)
                        )
                    }
                }
            )()
        }

        // Filter the Fav Quizzes by the Search Text
        return (
            () => {
                if (!viewAllFavs && favQuizzesList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length > numberOfFavCardsToShowInARow) {
                    // Return first X Fav Quizzes that match the Search text.
                    if (isMobileOnly) {
                        return favQuizzesList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).map((formData, index) =>
                            getFormCard(formData, index)
                        )
                    }
                    return favQuizzesList.filter((formData, index) => index < numberOfFavCardsToShowInARow && formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).map((formData, index) =>
                        getFormCard(formData, index)
                    )
                } else {
                    // Return all Fav Quizzes.
                    return favQuizzesList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).map((formData, index) =>
                        getFormCard(formData, index)
                    )
                }
            }
        )()
    }

    const updateCollabIcons = () => {
        getDataFromServer();
    }

    const getFormCard = (formData, index) => {
        return <FormCard key={formData._id} CARD_TYPE={CARD_TYPE.USER_FORM}
                         cardIndex={index}
                         formData={formData}
                         favouriteClicked={favouriteClicked}
                         updateCollabIcons={updateCollabIcons}
                         updateStatus={updateFormStatusInQuizzesList}
                         previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                         showDataEditWarning={props.showDataEditWarning}
                         timeOutForLongPress={props.timeOutForLongPress}
        />
    }

    const getNonFavQuizzes = () => {
        if (!quizzesList) return null
        if (searchText === "") {
            return (
                () => {
                    return quizzesList.filter((formData) => !formData.date_favoured).map((formData, index) =>
                        getFormCard(formData, index)
                    )
                }
            )()
        }

        // Filter the Forms by the Search Text
        return (
            () => {
                return quizzesList.filter((formData) => !formData.date_favoured && formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).map((formData, index) =>
                    getFormCard(formData, index)
                )
            }
        )()
    }

    const favouriteClicked = (quizId, dateFavouredValue) => {
        setQuizFavouriteStatus(quizId, dateFavouredValue, (responseData) => {
                if (responseData) {
                    if (responseData.result === '') {
                        // 'favQuizzesList.filter(favQuiz => favQuiz._id !== quizId)' is used twice below
                        // because of the delay with the useState operation setFavQuizzesList() updating favQuizzesList
                        if (favQuizzesList.filter(favQuiz => favQuiz._id !== quizId).length === 0) {
                            setCurrentMobileTab(CURRENT_MOBILE_TAB.NONFAV)
                            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MOBILE_SORT_BUTTON, payload: true})
                        }

                        // 'date_favoured' has being removed. Remove the Quiz from favQuizzesList.
                        registerGAEvent('Quiz-Dashboard', 'fav-changed', 'removed')
                        setFavQuizzesList(sortFavQuizzes(favQuizzesList.filter(favQuiz => favQuiz._id !== quizId)))

                        // Remove 'date_favoured' from the Quiz property.
                        let quizToUpdate = quizzesList.find(quiz => quiz._id === quizId)
                        let updatedQuizzesList = quizzesList.filter(quiz => quiz._id !== quizId).map(quiz => ({...quiz}))

                        delete quizToUpdate['date_favoured']

                        updatedQuizzesList.push(quizToUpdate)
                        updateQuizzesList(sortForms(updatedQuizzesList, sortFields.DATE_EDITED))

                        if (favQuizzesList.length === numberOfFavCardsToShowInARow) {
                            // Make Favs List collapsed.
                            setViewAllFavs(false);
                        }
                    } else {
                        // 'date_favoured' has being added. Add the Quiz to favQuizzesList.
                        registerGAEvent('Quiz-Dashboard', 'fav-changed', 'added')

                        let quizToUpdate = quizzesList.find(quiz => quiz._id === quizId)
                        let updatedFavQuizzesList = favQuizzesList.map(quiz => ({...quiz}));

                        quizToUpdate.date_favoured = responseData.result
                        updatedFavQuizzesList.push(quizToUpdate)
                        setFavQuizzesList(sortFavQuizzes(updatedFavQuizzesList))
                        setCurrentMobileTab(CURRENT_MOBILE_TAB.FAV)
                    }
                }
            },
            (error) => {
                console.log(error)
            },
        );
    }

    const multipleDeleteCancelButtonClick = () => {
        dispatch({type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY, payload: []})
        dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION, payload: false})
    }

    const multipleDeleteButtonClick = () => {
        dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
    }

    const handleSingleQuizDeleteSuccess = (res) => {
        if (res.result) {
            dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
            isMobileOnly ? dispatch({
                type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL,
                payload: false
            }) : null;
            if (isMobileOnly) {
                const favQuizDeleted = favQuizzesList.find(favQuiz => favQuiz._id === deletableQuizId)

                removeDeletedQuizInQuizzesList(deletableQuizId);

                const favQuizzesLength = quizzesList.filter((quizData) => quizData.date_favoured).length

                if (favQuizDeleted) {
                    if (favQuizzesLength === 0) {
                        // No more Favourite Quizzes are remaining.
                        setCurrentMobileTab(CURRENT_MOBILE_TAB.NONFAV)
                    }
                } else if (favQuizzesLength === quizzesList.length) {
                    // Only Favourite Quizzes are remaining.
                    setCurrentMobileTab(CURRENT_MOBILE_TAB.FAV)
                }
            } else {
                removeDeletedQuizInQuizzesList(deletableQuizId);
            }
        }

        props.refreshStorageTotals()
    }

    const handleMultipleQuizzesDelete = (userId, itemIds, successFunction, failureFunction) => {
        if (itemIds && userId) {
            deleteManyQuizzes(userId, itemIds, (res) => {
                if (res.result) {
                    successFunction(itemIds);
                }
            }, (err) => {
                failureFunction(err)
            });
        }
    }

    const handleMultipleQuizDeleteSuccess = (quizIds) => {
        dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
        dispatch({type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY, payload: []})
        dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION, payload: false})
        removeDeletedQuizInQuizzesList(quizIds);
        props.refreshStorageTotals()

        for (let ctr = 0; ctr < quizIds.length; ctr++) {
            if (favQuizzesList.find(quiz => quiz._id === quizIds[ctr])) {
                // Remove the Quiz from Favs
                setFavQuizzesList(sortFavQuizzes(favQuizzesList.filter(favQuiz => favQuiz._id !== quizIds[ctr])))
            }
            if (favQuizzesList.length === 0) {
                // No more Favourite Quizzes are remaining.
                handleNonFavMobileViewTabClick()
            }
        }
    }

    const sortFavQuizzes = (favQuizzes) => {
        return sortForms(favQuizzes, sortFields.DATE_FAVOURED)
    }

    if (isLoading) return <Loader/>
    if (quizzesList === undefined || quizzesList == null) return <DataUnavailableComponent
        data={t(translationKeys.quiz_server_is_not_available)}/>
    if (quizzesList.length === 0) return <DataUnavailableComponent data={t(translationKeys.no_quiz_available)}/>
    if (!quizzesSectionShouldBeVisible(quizzesList) && !quizzesSectionShouldBeVisible(favQuizzesList, true)) return (
        <DataUnavailableComponent data={t(translationKeys.searched_quiz_not_found)}/>
    )

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0',
        cursor: 'default'
    }
    return (
        <>
            {
                (() => {
                    const favsLength = quizzesList.filter((formData) => formData.date_favoured).length
                    if (isMobileOnly) return (
                        // ************************** Mobile Version ************************** //
                        <>
                            <div className={'forms-container-mobile'}
                                 onTouchStart={(e) => {
                                     if (isMobile) {
                                         startSwipeX = e.changedTouches[0].pageX;
                                         startSwipeY = e.changedTouches[0].pageY;
                                         startTime = new Date().getTime();
                                     }
                                 }} onTouchEnd={e => {
                                if (isMobile && favsLength !== 0) {
                                    distX = e.changedTouches[0].pageX - startSwipeX;
                                    distY = e.changedTouches[0].pageY - startSwipeY;
                                    elapsedTime = new Date().getTime() - startTime;
                                    if (elapsedTime <= allowedTime && distY < 80) {
                                        if (distX < -120) {
                                            setCurrentMobileTab(CURRENT_MOBILE_TAB.NONFAV)
                                        } else if (distX > 120) {
                                            setCurrentMobileTab(CURRENT_MOBILE_TAB.FAV)
                                        }
                                    }
                                }
                            }}
                                 style={isShowingMobileFormControl ? {marginBottom: '0'} : null}>
                                {isShowingMobileFormControl ?
                                    <div className={'dashboard-content-container-mobile'}
                                         style={{padding: '0'}}>
                                        <MobileFormControlContainer
                                            deleteFormInFormList={removeDeletedQuizInQuizzesList}
                                            updateStatus={updateFormStatusInQuizzesList}
                                            showDataEditWarning={props.showDataEditWarning}
                                            favouriteClicked={favouriteClicked}
                                        />
                                    </div> :
                                    <div className='cards-container cards-container-width'
                                         ref={onFavCardsContainerChange}>
                                        <div className='forq-container-mobile-header center'
                                             style={{display: favsLength === 0 ? 'none' : null}}>
                                            {
                                                (() => {

                                                    if (currentMobileTab === CURRENT_MOBILE_TAB.FAV) {
                                                        dispatch({
                                                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SORT_ICON,
                                                            payload: false
                                                        })
                                                    } else {
                                                        dispatch({
                                                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SORT_ICON,
                                                            payload: true
                                                        })
                                                    }
                                                    return (
                                                        <>
                                                            {/* Favourites Tab */}
                                                            <div id="2"
                                                                 className={currentMobileTab === CURRENT_MOBILE_TAB.FAV ? "center header-button-clicked" : "center header-button"}
                                                                 style={{width: '50%'}}
                                                                 onClick={handleFavMobileViewTabClick}>
                                                                    <span
                                                                        className={currentMobileTab === CURRENT_MOBILE_TAB.FAV ? 'mobile-header-tab-row-item mobile-header-tab-row-item-clicked' : 'mobile-header-tab-row-item'}>
                                                                        {t(translationKeys.favourites)}</span>
                                                                <span
                                                                    className={currentMobileTab === CURRENT_MOBILE_TAB.FAV ? 'mobile-header-tab-row-item-badge-clicked' : 'mobile-header-tab-row-item-badge'}> {`${searchText === "" ? favsLength : favQuizzesList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length}`}</span>

                                                            </div>
                                                            {/* Others Tab */}
                                                            <div id="1"
                                                                 className={currentMobileTab === CURRENT_MOBILE_TAB.NONFAV ? "center header-button-clicked" : "center header-button"}
                                                                 style={{width: '50%'}}>
                                                                    <span
                                                                        className={currentMobileTab === CURRENT_MOBILE_TAB.NONFAV ? 'mobile-header-tab-row-item mobile-header-tab-row-item-clicked' : 'mobile-header-tab-row-item'}
                                                                        onClick={handleNonFavMobileViewTabClick}>
                                                                        {t(translationKeys.others)} </span>
                                                                <span
                                                                    className={currentMobileTab === CURRENT_MOBILE_TAB.NONFAV ? 'mobile-header-tab-row-item-badge-clicked' : 'mobile-header-tab-row-item-badge'}> {`${searchText === "" ? quizzesList.length - favsLength : quizzesList.filter((formData) => !formData.date_favoured && formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length}`}</span>
                                                            </div>
                                                        </>
                                                    )
                                                })()
                                            }
                                        </div>
                                        {
                                            (() => {
                                                if (currentMobileTab === CURRENT_MOBILE_TAB.FAV)
                                                    return getFavQuizzes()
                                            })()
                                        }
                                        {
                                            (() => {
                                                if (currentMobileTab === CURRENT_MOBILE_TAB.NONFAV && quizzesList.filter((formData) => !formData.date_favoured && formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length === 0) {
                                                    return <DataUnavailableComponent
                                                        data={t(translationKeys.no_quiz_available)}
                                                        setTemplateType={props.setTemplateType}
                                                        showPage={props.showPage}
                                                    />
                                                } else if (currentMobileTab === CURRENT_MOBILE_TAB.NONFAV)
                                                    return getNonFavQuizzes()
                                            })()
                                        }
                                    </div>
                                }
                            </div>
                        </>
                    )
                    else {
                        return (
                            // ************************** Desktop Version ************************** //
                            <>
                                <div className="forms-container">
                                    {
                                        (() => {
                                                if (quizzesSectionShouldBeVisible(favQuizzesList, true)) return (
                                                    <div className={'dashboard-content-container'}>
                                                        <div className='cards-container cards-container-width'
                                                             ref={onFavCardsContainerChange}>
                                                            <ContentHeaderFavourite
                                                                viewAll_Collapse_Clicked={viewAll_Collapse_Clicked}
                                                                viewAllFavs={viewAllFavs}
                                                                favCount={searchText === "" ? favQuizzesList.length : favQuizzesList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length
                                                                }
                                                                showViewAll_Collapse={searchText === "" ? favQuizzesList.length > numberOfFavCardsToShowInARow : favQuizzesList.filter((formData) => formData.welcome_screen.title.toLowerCase().includes(searchText.toLowerCase())).length > numberOfFavCardsToShowInARow}/>
                                                            {
                                                                getFavQuizzes()
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )()
                                    }
                                    {
                                        (() => {
                                                let quizzes = getNonFavQuizzes()

                                                if (quizzesSectionShouldBeVisible(quizzesList, false)) return (
                                                    <div className={'dashboard-content-container'}
                                                         style={{paddingBottom: showMultipleDeleteOption ? '70px' : null}}>
                                                        <div className='cards-container cards-container-width'>
                                                            <>
                                                                <ContentHeader
                                                                    setSortOrder={setSortOrder}
                                                                    previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                                                    isQuiz={false}
                                                                    formCount={quizzes ? quizzes.length : 0}
                                                                    updateDataList={updateQuizzesList}
                                                                    someForqsAreFavs={quizzesList.filter((quizzesData) => quizzesData.date_favoured).length > 0}
                                                                />
                                                                {
                                                                    quizzes
                                                                }
                                                            </>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        )()
                                    }
                                </div>
                            </>
                        )
                    }
                })()
            }
            {
                multipleQuizzesSelectedArray.length > 0 && !isMobileOnly ?
                    <BottomAlertContainer positiveButtonClick={multipleDeleteButtonClick}
                                          negativeButtonClick={multipleDeleteCancelButtonClick}
                                          negativeButtonText={t(translationKeys.cancel)}
                                          positiveButtonText={t(translationKeys.delete)}
                                          selectedDataType={t(translationKeys.quiz)}
                                          selectedDataArray={multipleQuizzesSelectedArray}/> : null}
            {showDeletePopUp ?
                <ModalDialog
                    imageIcon={isMobileOnly ? deleteIcon : null}
                    header={t(translationKeys.delete) + ' ' + (multipleQuizzesSelectedArray.length > 0 ? (multipleQuizzesSelectedArray.length > 1 ? multipleQuizzesSelectedArray.length + ' ' + t(translationKeys.quizzes) : t(translationKeys.quiz)) : t(translationKeys.quiz))}
                    body={[multipleQuizzesSelectedArray.length > 1 ? t(translationKeys.do_you_want_to_delete_these) + ' ' + multipleQuizzesSelectedArray.length + ' ' + t(translationKeys.quizzes) + '?' : t(translationKeys.do_you_want_to_delete_this_quiz) + '?',
                        showResponseDeleteAlert(multipleQuizzesSelectedArray, quizzesList, deletableQuizId) ? t(translationKeys.all_answer_sheets_will_also_be_unavailable) : null, t(translationKeys.this_action_cannot_be_undone)]}
                    buttons={
                        [
                            {
                                text: t(translationKeys.cancel).toUpperCase(),
                                action: () => dispatch({
                                    type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP,
                                    payload: false
                                })
                            },
                            {
                                text: (props.shared ? t(translationKeys.leave).toUpperCase() : t(translationKeys.delete).toUpperCase()) + (multipleQuizzesSelectedArray.length ? " (" + multipleQuizzesSelectedArray.length + ")" : ""),
                                buttonTextColor: '#E02424',
                                action:
                                    () => {
                                        if (multipleQuizzesSelectedArray.length > 0) {
                                            handleMultipleQuizzesDelete(userId, multipleQuizzesSelectedArray, handleMultipleQuizDeleteSuccess, (err) => {
                                                console.log(err);
                                                alert(t(translationKeys.something_went_wrong));
                                            })
                                        } else if (deletableQuizId) {
                                            deleteQuizService(deletableQuizId, handleSingleQuizDeleteSuccess, e => console.log(e))
                                        }
                                    }
                            }
                        ]
                    }
                /> : null}
            {/*{showQuizQuickSettings ?*/}
            {/*    <QuizQuickSettingsContainer/>*/}
            {/*    : null}*/}
            {/*** share popup ***/}
            {showSharePopUp ?
                <div style={popUpStyle}>
                    <ShareCard isQuiz={true}
                               formId={shareFormId}
                               passcode={quizData?.setting?.passcode}/>
                </div> : null}
            {/*** collaborator popup ***/}
            {showCollaboratePopUp ?
                <div style={popUpStyle}>
                    <CollaborateCard updateCollabIcons={updateCollabIcons}/>
                </div> : null}
            {/*{toast message}*/}
            {builderToastMessage ? <Message width={'250px'} message={builderMessage}/> : null}
        </>
    )
}

export default QuizzesContainer
