import React, {useState} from 'react'
import '../css/notification.css'
import close from "../../../../public/images/close_grey.svg";
import {useDispatch, useSelector} from "react-redux";
import {NOTIFICATION_ACTION_TYPE, NOTIFICATIONS_TYPES} from "../../utils/constants";
import {getDateTimeWithFormat} from "../../../shared/utils/get-date-time-with-format";
import {deleteNotification, getSharedForms} from "../../../shared/utils/sash-v2-api";
import {isMobileOnly} from "react-device-detect";
import {deleteQuizNotification, getSharedQuizzes, QUIZ_BASE_URL} from "../../../shared/utils/quiz-api-services";
import {registerGAEvent} from "../../../shared/utils/analytics";
import ModalDialog from "../../../shared/components/ModalDialog"
import {getBlockedWordsText} from "../../../shared/utils/get-blocked-words-found"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {DATE_FORMATS} from "../../../admin/constants";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";

const NotificationsRowContainer = (props) => {

    const [notificationRowContainer, setNotificationRowContainer] = useState('notification-row-container')
    const notificationData = useSelector(state => state.notificationReducer.notifications)
    const [showUnApprovedFormDialog, setShowUnApprovedFormDialog] = useState(false)
    const formsList = useSelector(state => state.appDashboardReducer.formsList)
    const quizzesList = useSelector(state => state.appDashboardReducer.quizzesList)
    const [unapprovedForq, setUnapprovedForq] = useState(null)
    const dispatch = useDispatch()

    const handleDeleteClick = () => {
        registerGAEvent('Dashboard', 'Notification', 'clicked-delete-notification');
        setNotificationRowContainer('notification-row-container-slide')
        if (props.notification.quiz_id) {
            deleteQuizNotification({notification_ids: [props.notification._id]}, (res) => {
                if (res) {
                    setTimeout(() => {
                        dispatch({type: NOTIFICATION_ACTION_TYPE.REMOVE_NOTIFICATION, payload: props.notification._id})
                        setNotificationRowContainer('notification-row-container')
                    }, 200)
                }
            }, (err) => {
                console.log(err)
            })
        } else {
            deleteNotification({notification_ids: [props.notification._id]}, (res) => {
                if (res) {
                    setTimeout(() => {
                        dispatch({type: NOTIFICATION_ACTION_TYPE.REMOVE_NOTIFICATION, payload: props.notification._id})
                        setNotificationRowContainer('notification-row-container')
                    }, 1000)
                }
            }, (err) => {
                console.log(err)
            })
        }
    }

    const notificationClickHandler = () => {
        registerGAEvent('Dashboard', 'Notification', 'clicked-notification');
        if (isMobileOnly) {
            if (props.notification.type === NOTIFICATIONS_TYPES.COLLAB_ADDED || props.notification.type === NOTIFICATIONS_TYPES.COLLAB_ROLE_CHANGE) {
                if (props.notification.form_id) {
                    getSharedForms(props.notification.user_id, (res) => {
                        if (res.result) {
                            const form = res.result.filter(form => form._id === props.notification.form_id)
                            if (form[0]?._id === props.notification.form_id) {
                                if (form[0]?.is_valid !== undefined && !form[0]?.is_valid){
                                    setUnapprovedForq(form[0])
                                    setShowUnApprovedFormDialog(true)
                                } else {
                                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHARED})
                                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: form[0]})
                                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: true})
                                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                                    handleDeleteClick()
                                }
                            } else {
                                handleDeleteClick()
                            }
                        }
                    }, (err) => {
                        console.log(err)
                    })
                } else if (props.notification.quiz_id) {
                    getSharedQuizzes((res) => {
                        if (res.result) {
                            const form = res.result.filter(form => form._id === props.notification.quiz_id)
                            if (form[0]?._id === props.notification.quiz_id) {
                                if (form[0]?.is_valid !== undefined && !form[0]?.is_valid){
                                    setUnapprovedForq(form[0])
                                    setShowUnApprovedFormDialog(true)
                                } else {
                                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHARED})
                                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: form[0]})
                                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: true})
                                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                                    handleDeleteClick()
                                }
                            } else {
                                handleDeleteClick()
                            }
                        }
                    }, (err) => {
                        console.log(err)
                    })
                }
            } else {
                handleDeleteClick()
            }
        } else {
            if (props.notification.type === NOTIFICATIONS_TYPES.COLLAB_ADDED || props.notification.type === NOTIFICATIONS_TYPES.COLLAB_ROLE_CHANGE) {
                if (formsList?.filter(form => form._id === props.notification.form_id).length > 0) {
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})
                } else if (quizzesList?.filter(quiz => quiz._id === props.notification.quiz_id).length > 0) {
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_QUIZ})
                } else {
                    registerGAEvent('Dashboard', 'Shared', 'view');
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHARED})
                    dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!props.notification.quiz_id})
                }
                handleDeleteClick()
                setTimeout(() => {
                    props.setNotificationInfo(false)
                }, 500)
            } else {
                handleDeleteClick()
                setTimeout(() => {
                    props.setNotificationInfo(false)
                }, 500)
            }
        }
    }

    return <>
        <div id={'notification-row-container'}
            className={props.notification.is_notified ? props.clearAll ? 'notification-row-container-slide' : notificationRowContainer : 'notification-row-container-effect'}>
            <div className={'notification-row-circle'}>
                <div style={{color: 'white', margin: '15px'}}>{notificationData.length - props.index}</div>
            </div>
            <div className={'notification-row-text'} onClick={notificationClickHandler}>
                <div id={'notification-row-text-title'}  style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>{props.notification.title}</div>
                </div>
                <div id={'notification-row-text-description'} style={{fontSize: 'small'}}>{props.notification.description}</div>
                <div id={'notification-row-text-date'} style={{
                    fontSize: 'smaller',
                    color: '#757575',
                    borderBottom: props.index === notificationData.length - 1 ? "none" : 'solid 2px #E5E5E5',
                    paddingBottom: '3%'
                }}>
                    {getDateTimeWithFormat(props.notification.date, DATE_FORMATS.DD_MM_YYYY_TIME)}
                </div>
            </div>
            <img className="delete-notification" src={close} alt="search icon" onClick={handleDeleteClick}/>

            {/*** Unapproved form alert dialog ***/}
            {showUnApprovedFormDialog ?
                <ModalDialog
                    header={unapprovedForq.is_quiz ? t(translationKeys.unapproved_quiz) : t(translationKeys.unapproved_form)}
                    body={[<span
                        style={{fontWeight: 'bold'}}>{'"' + (unapprovedForq?.welcome_screen?.title ? unapprovedForq.welcome_screen.title : '') + '"'}</span>,
                        <br/>, (unapprovedForq.is_quiz ? t(translationKeys.unapproved_quiz_alert)  : t(translationKeys.unapproved_form_alert))]}
                    buttons={
                        [
                            {
                                text: t(translationKeys.close),
                                action: () => setShowUnApprovedFormDialog(false)
                            },
                            {
                                text: t(translationKeys.provide_explanation),
                                action:
                                    () => {
                                        setShowUnApprovedFormDialog(false);
                                        const data = JSON.stringify([{
                                            qid: '5eb4e0c90be7456b687e61fd',
                                            data: unapprovedForq?.welcome_screen?.title ? unapprovedForq.welcome_screen.title : ''
                                        }, {
                                            qid: '5eb3d579c411ae0a298e7a0a',
                                            data: ((unapprovedForq.is_quiz ? (QUIZ_BASE_URL + '/id/') : (location.origin + '/form/')) + (unapprovedForq?._id ? unapprovedForq._id : ''))
                                        }, {
                                            qid: '5eb3d579c411ae0a298e7a0b',
                                            data: props.userId
                                        }, {
                                            qid: '5ec4ec0cc9dff62eb822a839',
                                            data: getBlockedWordsText(unapprovedForq.blocked_words_found)
                                        }]);
                                        const formId = unapprovedForq.is_quiz ? '5f211698b770fe0f8c274122' : '5eb3d579c411ae0a298e7a08';
                                        const domainName = location.href.split('/').slice(0, 3).join('/');
                                        open(encodeURI(domainName + '/form/' + formId + '?prefilleddata=' + data + '#form/0'));
                                    }
                            }
                        ]
                    }
                /> : null}
        </div>
    </>
}

export default NotificationsRowContainer