import React, {useEffect, useRef, useState} from 'react'
import {getSearchedTitlesFromTitlesList} from "../utils/search-forms";
import {getTitles, SearchSuggestionCard} from "./DashboardSearchContainer";
import close from '../../../../public/images/close.png'
import search from '../../../../public/images/search_white.svg'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import surveyHeartIconSmall from "../../../../public/images/surveyheart_icon_small.svg";
import {useTranslation} from "react-i18next";

const MobileSearchBar = (props) => {
    const {t} = useTranslation()
    const formsList = useSelector(state => state.appDashboardReducer.formsList)
    const searchText = useSelector(state => state.appDashboardReducer.searchText)
    const quizzesList = useSelector(state => state.appDashboardReducer.quizzesList)
    const isQuizzesShowing = useSelector(state => state.appDashboardReducer.showQuiz)
    const featureSpotlightIsVisible = useSelector(state => state.featureSpotlightReducer?.featureSpotlightIsVisible)
    const [showSuggestionInfo, setShowSuggestionInfo] = useState(false)
    const [showCloseButton, setShowCloseButton] = useState(false)
    const [searchedFormTitleList, setSearchedFormTitleList] = useState()
    const [searchedText, setSearchedText] = useState()
    const Ref = useRef(null)
    const inputElement = useRef(null)

    const dispatch = useDispatch()

    useEffect(() => {
        if (props.showInputElement) {
            inputElement.current.focus();
            inputElement.current.value = searchText
        } else {
            setShowCloseButton(false);
        }
    }, [props.showInputElement])

    const showSearchedFormList = (searchText) => {
        setSearchedFormTitleList(getSearchedTitlesFromTitlesList(searchText, isQuizzesShowing ? getTitles(quizzesList) : getTitles(formsList)))
        setSearchedText(searchText)
    }

    const showSuggestionContainer = (boolean, formTitle) => {
        setShowSuggestionInfo(boolean)
        setShowCloseButton(true)
        inputElement.current.value = formTitle
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            inputElement.current.blur();
            setShowSuggestionInfo(false);
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: inputElement.current.value})
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP, payload: Date.now()})
            inputElement.current.focus();
        }
    }

        return (
            <div id={'search-icon-container'} className="search-icon-container" ref={Ref}
                 style={props.showInputElement ? showSuggestionInfo ?
                         {width: '100%', justifyContent: 'flex-start'} : {width: '100%'}
                     : null}>
                {props.showInputElement ?
                    <>
                        <img className='product-logo' alt='logo' src={surveyHeartIconSmall}/>
                    <input className='mobile-search-input' placeholder={t(translationKeys.search)} ref={inputElement}
                           onKeyDown={handleKeyDown}
                           autoFocus={true}
                           onChange={(e) => {
                               e.target.value.length > 0 ? setShowSuggestionInfo(true) : setShowSuggestionInfo(false);
                               showSearchedFormList(e.target.value);
                               if (e.target.value === '') {
                                   dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
                                   dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP, payload: Date.now()})
                               }
                               document.onclick = e => {
                                   e.stopPropagation();
                                   if (Ref && !Ref.current.contains(e.target)) {
                                       setShowSuggestionInfo(false);
                                       document.onclick = null;
                                   }
                               }
                           }}
                    />
                    </> : null}
                <div style={{display: 'flex', justifyContent: 'center', zIndex: '1'}}>
                    {showCloseButton || props.showInputElement ?
                        <img className="search-icon" src={close}
                             alt="search"
                             onClick={() => {
                                 props.updateShowSearchInput(false);
                                 setShowCloseButton(false);
                                 setShowSuggestionInfo(false);
                                 dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
                                 dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP, payload: Date.now()})
                                 window.location.hash = '';
                             }}/> :
                        <img className="search-icon" src={search}
                             alt="search icon"
                             style={{display: featureSpotlightIsVisible ? "none" : null}}
                             onClick={() => {
                                 if (!props.showInputElement) {
                                     props.updateShowSearchInput(true);
                                     setShowCloseButton(true);
                                     window.location.hash = 'search';
                                 } else {
                                     showSuggestionContainer(false, inputElement.current.value)
                                     dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: inputElement.current.value})
                                     dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP, payload: Date.now()})
                                 }
                             }}/>
                    }
                </div>
                <div className="search-suggestion-container">
                    {showSuggestionInfo && searchedFormTitleList && searchedFormTitleList.length > 0 ?
                        searchedFormTitleList.map((title, index) => <SearchSuggestionCard
                            sourceText={title}
                            key={index}
                            searchedText={searchedText}
                            updateShowSuggestion={showSuggestionContainer}
                        />)
                        : (showSuggestionInfo && Array.isArray(searchedFormTitleList) && searchedFormTitleList.length === 0) ?
                            <SearchSuggestionCard
                                sourceText={''}
                                key={0}
                                searchedText={searchedText}
                                updateShowSuggestion={showSuggestionContainer}
                            /> : null}
                </div>
            </div>
        )
}

export default MobileSearchBar
