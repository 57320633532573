import React, {useEffect, useState} from "react"
import "../css/storage-management-popup.css"
import close from "../../../../public/images/close.png";
import StorageBandComponent from "./StorageBandComponent";
import quizIconBlue from "../../../../public/images/quiz_blue.svg"
import quizIconGrey from "../../../../public/images/quiz_grey.svg"
import formsIconBlue from "../../../../public/images/summary_blue.svg"
import formsIconGrey from "../../../../public/images/summary_grey.svg"
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {
    deleteMultipleAwsFiles, deleteMultipleAwsFilesByForms, deleteMultipleAwsFilesByQuestions,
    getFormResponsesForResponders, getForms,
    getTotalStorageUsed as getFormTotalStorageUsed
} from "../../../shared/utils/sash-v2-api"
import {
    deleteMultipleAwsFilesQuiz, deleteMultipleAwsFilesQuizByQuestions, deleteMultipleAwsFilesQuizByQuizzes,
    getQuizAnswers, getQuizzesListService,
    getTotalStorageUsed as getQuizTotalStorageUsed
} from "../../../shared/utils/quiz-api-services"
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {formatBytes, PremiumJSON} from "../../../shared/utils/helper";
import {MAX_USER_FILE_STORAGE, QUESTION_TYPE} from "../../../shared/utils/constants";
import {STORAGE_LEVEL, STORAGE_MANAGEMENT_ACTION_TYPE} from "../../reducers/storageManagementReducer";
import StorageManagementDetailsComponent from "./StorageManagementDetailsComponent";
import {getDateTimeWithFormat} from "../../../shared/utils/get-date-time-with-format";
import {DATE_FORMATS} from "../../../admin/constants";
import Loader from "../../../shared/components/Loader";
import ModalDialog from "../../../shared/components/ModalDialog";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {sortFields, sortForms} from "../utils/sort-form-list";

const StorageManagementPopUp = () => {

    const {t} = useTranslation()
    const formsList = useSelector(state => state.appDashboardReducer.formsList)
    const showForms = useSelector(state => state.storageManagementReducer.isForms)
    const quizzesList = useSelector(state => state.appDashboardReducer.quizzesList)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const currentList = useSelector(state => state.storageManagementReducer.currentList)
    const storageLevel = useSelector(state => state.storageManagementReducer.storageLevel)
    const currentForqData = useSelector(state => state.storageManagementReducer.currentForqData)
    const selectedFilesArray = useSelector(state => state.storageManagementReducer.selectedFilesArray)
    const currentResponsesData = useSelector(state => state.storageManagementReducer.currentResponsesData)
    const showStorageDeletePopUp = useSelector(state => state.storageManagementReducer.showStorageDeletePopUp)
    const [headerTitle, setHeaderTitle] = useState(t(translationKeys.forms))
    const [totalFormsStorageUsed, setTotalFormsStorageUsed] = useState()
    const [totalQuizStorageUsed, setTotalQuizStorageUsed] = useState()
    const dispatch = useDispatch()

    useEffect(() => {
        const successFunction = (res, formAPI) => {
            if (res) {
                if (formAPI) {
                    handleFormsClick()
                    setTotalFormsStorageUsed(formatBytes(res.result, res.result > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB"))
                } else {
                    setTotalQuizStorageUsed(formatBytes(res.result, res.result > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB"))
                }
            }
        }
        getFormTotalStorageUsed((res) => successFunction(res, true), (err) => console.log(err))
        getQuizTotalStorageUsed( (res) => successFunction(res, false), (err) => console.log(err))
    }, [])

    const handleCloseClick = () => {
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_MANAGEMENT_POP_UP, payload: false})
    }

    const handleFormsClick = (update) => {
        setHeaderTitle({primary: t(translationKeys.forms)})
        const updatedCurrentList = []
        let formsListForStorage
        if (update) {
            getForms((res) => {
                let resJson = res;
                if (resJson.result.length > 1) {
                    formsListForStorage = sortForms(resJson.result, sortFields.DATE_EDITED);
                } else if (resJson.result) {
                    formsListForStorage = resJson.result;
                }
                dispatch({
                    type: APP_DASHBOARD_ACTION_TYPE.SET_FORMS_LIST,
                    payload: formsListForStorage
                })
                formsListForStorage.map((form) => {
                    if (form.storage_used > 0) {
                        updatedCurrentList.push({
                            id: form._id,
                            title: form.welcome_screen.title,
                            description: formatBytes(form.storage_used, form.storage_used > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB") + ", " + getDateTimeWithFormat(form.date_edited, DATE_FORMATS.DDMMYYYY)
                        })
                    }
                })
            }, (err) => console.log(err))
        } else {
            formsList?.map((form) => {
                if (form.storage_used > 0) {
                    updatedCurrentList.push({
                        id: form._id,
                        title: form.welcome_screen.title,
                        description: formatBytes(form.storage_used, form.storage_used > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB") + ", " + getDateTimeWithFormat(form.date_edited, DATE_FORMATS.DDMMYYYY)
                    })
                }
            })
        }
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL, payload: STORAGE_LEVEL.FORQ})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST, payload: updatedCurrentList})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_IS_FORMS, payload: true})
    }

    const handleQuizzesClick = (update) => {
        setHeaderTitle({primary: t(translationKeys.quizzes)})
        const updatedCurrentList = []
        if (update) {
            getQuizzesListService( (res) => {
                dispatch({
                        type: APP_DASHBOARD_ACTION_TYPE.SET_QUIZZES_LIST,
                        payload: JSON.parse(JSON.stringify(res.result))
                    }
                )
                res.result?.map((quiz) => {
                    if (quiz.storage_used > 0) {
                        updatedCurrentList.push({
                            id: quiz._id,
                            title: quiz.welcome_screen.title,
                            description: formatBytes(quiz.storage_used, quiz.storage_used > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB") + ", " + getDateTimeWithFormat(quiz.date_edited, DATE_FORMATS.DDMMYYYY)
                        })
                    }
                })
            }, (err) => console.log(err))
        } else {
            quizzesList?.map((quiz) => {
                if (quiz.storage_used > 0) {
                    updatedCurrentList.push({
                        id: quiz._id,
                        title: quiz.welcome_screen.title,
                        description: formatBytes(quiz.storage_used, quiz.storage_used > MAX_USER_FILE_STORAGE ? 0 : 5, true, "MB") + ", " + getDateTimeWithFormat(quiz.date_edited, DATE_FORMATS.DDMMYYYY)
                    })
                }
            })
        }
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL, payload: STORAGE_LEVEL.FORQ})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST, payload: updatedCurrentList})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_IS_FORMS, payload: false})
    }

    const handleSelectAllClick = (isChecked) => {
        if (isChecked) {
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: true})
            const updatedSelectedFilesArray = []
            if (storageLevel === STORAGE_LEVEL.FILE) {
                currentResponsesData.map((respondent) => {
                    currentList.map((item) => {
                        if (item.respondentId === respondent._id) {
                            updatedSelectedFilesArray.push({
                                respondent_id: item.respondentId,
                                question_id: respondent.responses[item.questionIndex].question_id
                            })
                        }
                    })
                })
            } else if (storageLevel === STORAGE_LEVEL.QUESTION) {
                currentList.map((item) => {
                    let index = -1
                    currentForqData.pages[0].questions.map((question) => {
                        if (question.type !== QUESTION_TYPE.SECTION.key) {
                            index = index + 1
                        }
                        if (index === item.questionIndex && question.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                            if (showForms) {
                                updatedSelectedFilesArray.push({
                                    form_id: currentForqData._id,
                                    question_id: question._id
                                })
                            } else {
                                updatedSelectedFilesArray.push({
                                    quiz_id: currentForqData._id,
                                    question_id: question._id
                                })
                            }
                        }
                    })
                })
            } else if (storageLevel === STORAGE_LEVEL.FORQ) {
                currentList.map((item) => {
                    updatedSelectedFilesArray.push(item.id)
                })
            }
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: updatedSelectedFilesArray})
        } else {
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
        }
    }

    const handleRowCheckBoxClick = (fileName, questionIndex, isChecked) => {
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: undefined})
        if (storageLevel === STORAGE_LEVEL.FILE) {
            if (isChecked) {
                const updatedSelectedFilesArray = [...selectedFilesArray]
                currentResponsesData.map((respondent) => {
                    if (fileName === (respondent.form_user_data?.user_info_1 ? respondent.form_user_data?.user_info_1 + "_" : "") + respondent.responses[questionIndex]?.text.split('/').pop()) {
                        updatedSelectedFilesArray.push({
                            respondent_id: respondent._id,
                            question_id: respondent.responses[questionIndex].question_id
                        })
                    }
                })
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY,
                    payload: updatedSelectedFilesArray
                })
            } else {
                let updatedSelectedFilesArray = [...selectedFilesArray]
                currentResponsesData.map((respondent) => {
                    if (fileName === (respondent.form_user_data?.user_info_1 ? respondent.form_user_data?.user_info_1 + "_" : "") + respondent.responses[questionIndex]?.text.split('/').pop()) {
                        updatedSelectedFilesArray = updatedSelectedFilesArray.filter((selected) => selected.respondent_id !== respondent._id)
                    }
                })
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY,
                    payload: updatedSelectedFilesArray
                })
            }
        } else if (storageLevel === STORAGE_LEVEL.QUESTION) {
            if (isChecked) {
                let updatedSelectedFilesArray = [...selectedFilesArray]
                let index = -1
                currentForqData.pages[0].questions.map((question) => {
                    if (question.type !== QUESTION_TYPE.SECTION.key) {
                        index = index + 1
                    }
                    if (index === questionIndex && question.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                        if (showForms) {
                            updatedSelectedFilesArray.push({
                                form_id: currentForqData._id,
                                question_id: question._id
                            })
                        } else {
                            updatedSelectedFilesArray.push({
                                quiz_id: currentForqData._id,
                                question_id: question._id
                            })
                        }
                    }
                })
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY,
                    payload: updatedSelectedFilesArray
                })
            } else {
                let updatedSelectedFilesArray = [...selectedFilesArray]
                let index = -1
                currentForqData.pages[0].questions.map((question) => {
                    if (question.type !== QUESTION_TYPE.SECTION.key) {
                        index = index + 1
                    }
                    if (index === questionIndex && question.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                        updatedSelectedFilesArray = updatedSelectedFilesArray.filter((selected) => selected.question_id !== question._id)
                    }
                })
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY,
                    payload: updatedSelectedFilesArray
                })
            }
        } else if (storageLevel === STORAGE_LEVEL.FORQ) {
            if (isChecked) {
                let updatedSelectedFilesArray = [...selectedFilesArray]
                currentList.map((item) => {
                    if (item.title === fileName) {
                        updatedSelectedFilesArray.push(item.id)
                    }
                })
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY,
                    payload: updatedSelectedFilesArray
                })
            } else {
                let updatedSelectedFilesArray = [...selectedFilesArray]
                currentList.map((item) => {
                    if (item.title === fileName) {
                        updatedSelectedFilesArray = updatedSelectedFilesArray.filter((selected) => selected !== item.id)
                    }
                })
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY,
                    payload: updatedSelectedFilesArray
                })
            }
        }
    }

    const handleBackArrowClick = (questionLevel) => {
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
        dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
        if (questionLevel) {
            setHeaderTitle({primary: headerTitle.primary, secondary: "", showBackArrow: true})
            const updatedCurrentList = []
            let questionIndex = -1
            currentForqData.pages[0].questions.map((question) => {
                if (question.type !== QUESTION_TYPE.SECTION.key) {
                    questionIndex = questionIndex + 1
                }
                let storageUsed = 0
                if (question.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                    currentResponsesData.map((respondent) => {
                        if (respondent.responses[questionIndex]?.fileSize) {
                            storageUsed = storageUsed + respondent.responses[questionIndex].fileSize
                        }
                    })
                    if (storageUsed) {
                        updatedCurrentList.push({
                            questionIndex: questionIndex,
                            title: question.title,
                            description: formatBytes(storageUsed, 5, true, "MB")
                        })
                    }
                }
            })
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL, payload: STORAGE_LEVEL.QUESTION})
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST, payload: updatedCurrentList})
        } else {
            if (showForms) {
                handleFormsClick()
            } else {
                handleQuizzesClick()
            }
        }
    }

    const handleRowClick = (id, questionIndex, title) => {
        if (id && storageLevel === STORAGE_LEVEL.FORQ) {
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
            const successFunction = (res) => {
                const updatedCurrentList = []
                let questionIndex = -1
                setHeaderTitle({primary: title, showBackArrow: true})
                res.formData.pages[0].questions.map((question) => {
                    if (question.type !== QUESTION_TYPE.SECTION.key) {
                        questionIndex = questionIndex + 1
                    }
                    let storageUsed = 0
                    if (question.type === QUESTION_TYPE.FILE_UPLOAD.key) {
                        res.formResponses.respondents.map((respondent) => {
                            if (respondent.responses[questionIndex]?.fileSize) {
                                storageUsed = storageUsed + respondent.responses[questionIndex].fileSize
                            }
                        })
                        if (storageUsed) {
                            updatedCurrentList.push({
                                questionIndex: questionIndex,
                                title: question.title,
                                questionId: question._id,
                                description: formatBytes(storageUsed, 5, true, "MB")
                            })
                        }
                    }
                })
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL, payload: STORAGE_LEVEL.QUESTION})
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST, payload: updatedCurrentList})
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_FORQ_DATA, payload: res.formData})
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_RESPONSES_DATA,
                    payload: res.formResponses.respondents
                })
            }
            if (showForms) {
                getFormResponsesForResponders(id, successFunction, (err) => console.log(err))
            } else {
                getQuizAnswers(id, successFunction, (err => console.log(err)))
            }
        } else if (questionIndex && storageLevel === STORAGE_LEVEL.QUESTION) {
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
            const updatedCurrentList = []
            setHeaderTitle({primary: headerTitle.primary, secondary: title, showBackArrow: true})
            currentResponsesData.map((respondent) => {
                if (respondent.responses[questionIndex - 1]?.fileSize) {
                    updatedCurrentList.push({
                        respondentId: respondent._id,
                        questionIndex: questionIndex - 1,
                        title: respondent.form_user_data?.user_info_1 ? respondent.form_user_data?.user_info_1 + "_" + respondent.responses[questionIndex - 1].text.split('/').pop() : respondent.responses[questionIndex - 1].text.split('/').pop(),
                        fileType: respondent.responses[questionIndex - 1].text.split('/').pop().split('.')[1],
                        description: formatBytes(respondent.responses[questionIndex - 1].fileSize, 5, true, "MB") + ", " + getDateTimeWithFormat(respondent.submit_time, DATE_FORMATS.DDMMYYYY)
                    })
                }
            })
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_STORAGE_LEVEL, payload: STORAGE_LEVEL.FILE})
            dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST, payload: updatedCurrentList})
        }
    }

    const handleDelete = () => {
        if (selectedFilesArray.length && storageLevel === STORAGE_LEVEL.FILE) {
            const successFunction = (res) => {
                if (res.files_deleted) {
                    let updatedCurrentList = [...currentList]
                    res.files_deleted.map((deletedFile) => {
                        updatedCurrentList = updatedCurrentList.filter((data) => data.respondentId !== deletedFile.respondent_id)
                    })
                    dispatch({
                        type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST,
                        payload: updatedCurrentList
                    })
                    dispatch({
                        type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_DELETE_POP_UP,
                        payload: false
                    })
                    dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
                    dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
                    if (updatedCurrentList.length === 0) {
                        if (showForms) {
                            handleFormsClick(true)
                        } else {
                            handleQuizzesClick(true)
                        }
                    }
                }
            }
            if (showForms) {
                deleteMultipleAwsFiles(selectedFilesArray, successFunction, (err) => console.log(err))
            } else {
                deleteMultipleAwsFilesQuiz(selectedFilesArray, successFunction, (err) => console.log(err))
            }
        } else if (selectedFilesArray.length && storageLevel === STORAGE_LEVEL.QUESTION) {
            const successFunction = (res) => {
                if (res.files_deleted) {
                    let updatedCurrentList = [...currentList]
                    res.files_deleted.map((deletedFile) => {
                        updatedCurrentList = updatedCurrentList.filter((data) => data.questionId !== deletedFile.question_id)
                    })
                    dispatch({
                        type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST,
                        payload: updatedCurrentList
                    })
                    dispatch({
                        type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_DELETE_POP_UP,
                        payload: false
                    })
                    dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
                    dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
                    if (updatedCurrentList.length === 0) {
                        if (showForms) {
                            handleFormsClick(true)
                        } else {
                            handleQuizzesClick(true)
                        }
                    }
                }
            }
            if (showForms) {
                deleteMultipleAwsFilesByQuestions(selectedFilesArray, successFunction, (err) => console.log(err))
            } else {
                deleteMultipleAwsFilesQuizByQuestions(selectedFilesArray, successFunction, (err) => console.log(err))
            }
        } else if (selectedFilesArray.length && storageLevel === STORAGE_LEVEL.FORQ) {
            const successFunction = (res) => {
                let updatedCurrentList = [...currentList]
                if (res.forms_cleaned) {
                    res.forms_cleaned.map((deletedForm) => {
                        updatedCurrentList = updatedCurrentList.filter((data) => data.id !== deletedForm)
                    })
                } else if (res.quizzes_cleaned) {
                    res.quizzes_cleaned.map((deletedQuiz) => {
                        updatedCurrentList = updatedCurrentList.filter((data) => data.id !== deletedQuiz)
                    })
                }
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_CURRENT_LIST,
                    payload: updatedCurrentList
                })
                dispatch({
                    type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_DELETE_POP_UP,
                    payload: false
                })
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
                if (updatedCurrentList.length === 0) {
                    if (showForms) {
                        handleFormsClick(true)
                    } else {
                        handleQuizzesClick(true)
                    }
                }
            }
            if (showForms) {
                deleteMultipleAwsFilesByForms(selectedFilesArray, successFunction, (err) => console.log(err))
            } else {
                deleteMultipleAwsFilesQuizByQuizzes(selectedFilesArray, successFunction, (err) => console.log(err))
            }
        }
    }

    return (
        <div className={"storage-management"}>
            <div className={"pop-up"}>
                <div className={"pop-up-header"}>
                    <span>{t(translationKeys.manage_storage)}</span>
                    <img className={'localization-close-img'}
                         src={close} alt={close} onClick={handleCloseClick}/>
                </div>
                <div className={"pop-up-body"}>
                    <div className={"pop-up-left-container"}>
                        <div className={"storage-band"}>
                            <StorageBandComponent totalStorage={formatBytes(PremiumJSON[subscriptionData?.subscription_plan]?.storage)}/>
                        </div>
                        <div className={"forq"}
                             style={{background: showForms ? "#F5F5F5" : "#FFFFFF"}}
                             onClick={() => {
                                 dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
                                 dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
                                 handleFormsClick()
                             }}>
                            <img src={showForms ? formsIconBlue : formsIconGrey}
                                 alt={"forms"}/>
                            <span style={{color: showForms ? "#1976D2" : "#5E5E5E"}}>
                                {t(translationKeys.forms)}
                            </span>
                            <p>{totalFormsStorageUsed}</p>
                        </div>
                        <div className={"forq"}
                             style={{background: !showForms ? "#F5F5F5" : "#FFFFFF"}}
                             onClick={() => {
                                 dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECT_ALL, payload: false})
                                 dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SELECTED_FILE_ARRAY, payload: []})
                                 handleQuizzesClick()
                             }}>
                            <img src={!showForms ? quizIconBlue : quizIconGrey}
                                 alt={"quizzes"}/>
                            <span style={{color: !showForms ? "#1976D2" : "#5E5E5E"}}>
                                {t(translationKeys.quizzes)}
                            </span>
                            <p>{totalQuizStorageUsed}</p>
                        </div>
                    </div>
                    {currentList ? <StorageManagementDetailsComponent headerText={headerTitle}
                                                                      handleListRowClick={handleRowClick}
                                                                      handleBackArrowClick={handleBackArrowClick}
                                                                      handleSelectAllClick={handleSelectAllClick}
                                                                      handleRowCheckBoxClick={handleRowCheckBoxClick}/> :
                        <Loader width={"100%"} height={"100%"} left={"0"}/>}
                </div>
            </div>
            {showStorageDeletePopUp ? <ModalDialog
                header={t(translationKeys.delete) + ' ' + (selectedFilesArray.length > 1 ? t(translationKeys.files) : t(translationKeys.file))}
                body={[t(translationKeys.are_you_sure_you_want_to_delete_selected_files), t(translationKeys.please_download_before_deleting)]}
                buttons={[{
                    text: t(translationKeys.cancel).toUpperCase(),
                    action: () => {
                        dispatch({
                            type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_DELETE_POP_UP,
                            payload: false
                        })
                    }
                }, {
                    text: t(translationKeys.delete).toUpperCase() + " (" + selectedFilesArray.length + " " + (selectedFilesArray.length > 1 ? t(translationKeys.files).toUpperCase() : t(translationKeys.file).toUpperCase()) + ")",
                    action: handleDelete
                }]}/> : null}
        </div>
    )
}

export default StorageManagementPopUp
