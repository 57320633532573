import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {getDateWithTime} from "../../../shared/utils/get-date-with-time";

export function getDate(time) {
    let modifiedDate;
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let formEditedDate = new Date(parseInt(time));
    let currentDate = new Date();

//for Modified date preperations
    if ((currentDate.getFullYear() === formEditedDate.getFullYear())
        && (currentDate.getMonth() === formEditedDate.getMonth())
        && (currentDate.getDate() === formEditedDate.getDate())) {
        if (currentDate.getMinutes() === formEditedDate.getMinutes() && currentDate.getHours() === formEditedDate.getHours()) {
            modifiedDate = t(translationKeys.now);
        } else {
            modifiedDate =  t(translationKeys.today) + " " + getDateWithTime(formEditedDate.getTime()).split("-")[1];
        }
    } else {
        modifiedDate = formEditedDate.getDate() + ' ' + month[formEditedDate.getMonth()] + ' ' + formEditedDate.getFullYear();
    }
    return modifiedDate;
}

export function getLastEditedByTime(time, showDate) {
    let lastEditedBy;
    let formEditedDate = new Date(parseInt(time));
    let currentDate = new Date();
    const month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let hours = formEditedDate.getHours() > 12 ? formEditedDate.getHours() - 12 : formEditedDate.getHours()
    let minutes = formEditedDate.getMinutes()
    let amORpm = formEditedDate.getHours() >= 12 ? ' PM' : ' AM';

    if (currentDate.getDate() === formEditedDate.getDate()) {
        lastEditedBy = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + amORpm
    } else {
        if (showDate) {
            lastEditedBy = formEditedDate.getDate() + ' ' + month[formEditedDate.getMonth()] + ' ' + formEditedDate.getFullYear() + ', ' + (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + amORpm
        } else {
            lastEditedBy = (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes + amORpm
        }
    }
    return lastEditedBy
}