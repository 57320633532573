import MenuItem from "./MenuItem"

/**
 * @param {boolean} selected - Selected state
 */
class ChangeContainerMenuItem extends MenuItem{
    constructor(key, title, selected, menuIconInfo) {
        super(key, title, menuIconInfo)
        this.Title = title
        this.Selected = selected
    }

    getSelected() {
        return this.Selected
    }

    setSelected(value) {
        this.Selected = value
    }

    setTitle(newTitle) {
        this.Title = newTitle
    }
}

export default ChangeContainerMenuItem
