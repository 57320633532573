import {getFeatureSpotlightDateKey, saveFeatureSpotlightDateKey} from "../utils/local-storage"

export const FEATURE_SPOTLIGHT_ACTION_TYPE = {
    TOGGLE_VISIBILITY: 'TOGGLE_VISIBILITY',
    UPDATE_THUMBNAIL: 'UPDATE_THUMBNAIL',
    POP_NEW_FEATURES: 'POP_NEW_FEATURES'
}

let initialState = {
    features: null,
    featureSpotlightIsVisible: false
}

const featureSpotlightReducer = (state = initialState, action) => {
    switch (action.type) {
        case FEATURE_SPOTLIGHT_ACTION_TYPE.POP_NEW_FEATURES:
            // This code will be run only once upon loading.
            return {
                ...state,
                features: action.payload,
                featureSpotlightIsVisible: (() => {

                    let existingFeatureSpotlightDateKey = getFeatureSpotlightDateKey()

                    if (existingFeatureSpotlightDateKey) {
                        return existingFeatureSpotlightDateKey !== action.payload[0].date
                    } else {
                        return true
                    }
                })()
            }
        case FEATURE_SPOTLIGHT_ACTION_TYPE.TOGGLE_VISIBILITY:
            if (state.featureSpotlightIsVisible) {
                // Save the FIRST date in the Feature Spotlight JSON file so that it can be used to determine if the Feature Spotlight should be shown when the site is loaded again.
                let existingFeatureSpotlightDateKey = getFeatureSpotlightDateKey()

                if (!existingFeatureSpotlightDateKey || existingFeatureSpotlightDateKey !== state.features[0].date) {
                    saveFeatureSpotlightDateKey(state.features[0].date)
                }
            }

            return {
                ...state,
                featureSpotlightIsVisible: !state.featureSpotlightIsVisible
            }
        case FEATURE_SPOTLIGHT_ACTION_TYPE.UPDATE_THUMBNAIL:
            const updatedFeatures = state.features.map((f, index) => {
                if (index === action.payload.index) {
                    return {...f, thumbnail: action.payload.thumbnail}
                }

                return f
            })

            return {
                ...state,
                features: updatedFeatures
            }
        default:
            return state
    }
}

export default featureSpotlightReducer