export const WEB_TOKEN_SASH_ALIAS = 'sash';
export const WEB_TOKEN_QUIZZORY_ALIAS = 'quizzory';
let WEB_TOKEN_SASH;
let WEB_TOKEN_QUIZZORY;

export function getSASHWebToken() {
    return WEB_TOKEN_SASH ? WEB_TOKEN_SASH : localStorage?.getItem(WEB_TOKEN_SASH_ALIAS);
}

export function getQuizzoryWebToken() {
    return WEB_TOKEN_QUIZZORY ? WEB_TOKEN_QUIZZORY : localStorage?.getItem(WEB_TOKEN_QUIZZORY_ALIAS);
}

export function setSASHWebToken(webToken) {
    WEB_TOKEN_SASH = webToken;
    localStorage.setItem(WEB_TOKEN_SASH_ALIAS, webToken);
}

export function setQuizzoryWebToken(webToken) {
    WEB_TOKEN_QUIZZORY = webToken;
    localStorage.setItem(WEB_TOKEN_QUIZZORY_ALIAS, webToken);
}

export function deleteSASHWebToken() {
    WEB_TOKEN_SASH = undefined;
    localStorage.removeItem(WEB_TOKEN_SASH_ALIAS);
}

export function deleteQuizzoryWebToken() {
    WEB_TOKEN_QUIZZORY = undefined;
    localStorage.removeItem(WEB_TOKEN_QUIZZORY_ALIAS);
}

