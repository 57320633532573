const initialState = {
    selectedOption: '',
    showAbuseWordsPopup: false
}

export const ABUSE_SCAN_ACTION_TYPE = {
    SET_SELECTED_OPTION: 'SET_SELECTED_OPTION',
    SET_SHOW_ABUSE_WORD_POP_UP: 'SET_SHOW_ABUSE_WORD_POP_UP'
}

const abuseScanReducer = (state = initialState, action) => {
    switch (action.type) {
        case (ABUSE_SCAN_ACTION_TYPE.SET_SHOW_ABUSE_WORD_POP_UP):
            return {
                ...state,
                showAbuseWordsPopup: action.payload
            }
        case (ABUSE_SCAN_ACTION_TYPE.SET_SELECTED_OPTION):
            return {
                ...state,
                selectedOption: action.payload
            }
        default:
            return state
    }
}

export default abuseScanReducer