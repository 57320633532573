const initialState = {
    showFreeOwnerPopUp: false,
    showUpgradePlanPopUp: false,
    showPremiumPlansPopUp: false
}

export const PREMIUM_ACTION_TYPE = {
    SET_SHOW_FREE_OWNER_POP_UP: "SET_SHOW_FREE_OWNER_POP_UP",
    SET_SHOW_UPGRADE_PLAN_POP_UP: "SET_SHOW_UPGRADE_PLAN_POP_UP",
    SET_SHOW_PREMIUM_PLANS_POP_UP: "SET_SHOW_PREMIUM_PLANS_POP_UP"
}

export const premiumReducer = (state = initialState, action) => {
    switch (action.type) {
        case (PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP):
            return {
                ...state,
                showPremiumPlansPopUp: action.payload
            }
        case (PREMIUM_ACTION_TYPE.SET_SHOW_FREE_OWNER_POP_UP):
            return {
                ...state,
                showFreeOwnerPopUp: action.payload
            }
        case (PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP):
            return {
                ...state,
                showUpgradePlanPopUp: action.payload
            }
        default:
            return state
    }
}

export default premiumReducer
