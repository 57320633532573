import React from 'react';
import PropTypes from "prop-types";
import MaterialFloatingActionButton from "../components/MaterialFab";

/**
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 * This will use only the material icons for the center Icon.
 * We can't use images directly here. To use Images directly use FabButtonContainerV2
 */
const FabButtonContainer = (props) => {
    return (
        <div style={{position: 'absolute', ...props.containerStyle}}>
            <MaterialFloatingActionButton handleClick={props.handleButtonClick}
                                          iconName={props.iconName}
                                          iconImage={props.iconImage}
                                          iconColor={props.iconColor}
                                          iconSize={props.iconSize}
                                          backgroundColor={props.bgColor}
                                          buttonSize={props.buttonSize}/>
        </div>
    )
}

export default FabButtonContainer

FabButtonContainer.propTypes = {
    bgColor: PropTypes.string,
    containerStyle: PropTypes.object,
    handleButtonClick: PropTypes.func.isRequired,
    iconName: PropTypes.string.isRequired,
    iconSize: PropTypes.string,
    iconColor: PropTypes.string,
    buttonSize: PropTypes.string
}

FabButtonContainer.defaultProps = {
    iconName: 'add',
    handleButtonClick: () => console.log('button clicked')

}