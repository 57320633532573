export const GRID_DETAILS_POPUP_ACTION_TYPE = {
    SET_SHOW_POPUP: 'SET_SHOW_POPUP',
    SET_POPUP_INFO: 'SET_POPUP_INFO'
}

const initialState = {
    answer_text: null,
    grid_answer_col_and_row_indexes: [],
    response_analyze_data: null,
    showPopup: false,
    s_no: -1,
    title: '',
    type: null
}

const gridDetailsPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case (GRID_DETAILS_POPUP_ACTION_TYPE.SET_POPUP_INFO):
            return {
                ...state,
                answer_text: action.payload.answer_text,
                grid_answer_col_and_row_indexes: action.payload.grid_answer_col_and_row_indexes,
                response_analyze_data: action.payload.response_analyze_data,
                s_no: action.payload.s_no,
                title: action.payload.title,
                type: action.payload.type
            }
        case (GRID_DETAILS_POPUP_ACTION_TYPE.SET_SHOW_POPUP):
            return {
                ...state,
                showPopup: action.payload
            }
        default:
            return state
    }
}

export default gridDetailsPopupReducer
