import React from 'react';
import "../css/circular-checkbox.css"

const CircularCheckBox = (props) => {
    return (
        <label className={"checkbox-container"}>
            <input type={"checkbox"} checked={props.isChecked}/>
            <span className="checkmark"></span>
        </label>
    )
}

export default CircularCheckBox