export function getSearchedFormListByTitle(searchText, dataList) {
    if (searchText.length > 0) {
        let includesTextFormArray = [];
        let exactlyMatchedFormsArray = [];
        if (dataList && dataList.length > 0) {
            for (let i = 0; i < dataList.length; i++) {
                let formTitle = dataList[i].welcome_screen.title;
                if (formTitle) {
                    if (formTitle.toLowerCase() === searchText.toLowerCase()) {
                        exactlyMatchedFormsArray.push(dataList[i]);
                    } else if (formTitle.toLowerCase().includes(searchText.toLowerCase())) {
                        includesTextFormArray.push(dataList[i]);
                    }
                }
            }
        }
        return exactlyMatchedFormsArray.concat(includesTextFormArray);
    } else {
        return dataList;
    }
}

export const getSearchedTitlesFromTitlesList = (searchText, titleList) => {
    if (searchText.length > 0) {
        let includesTextArray = [];
        let exactlyMatchedTextArray = [];
        if (titleList && titleList.length > 0) {
            for (let i = 0; i < titleList.length; i++) {
                let title = titleList[i];
                if (title) {
                    if (title.toLowerCase() === searchText.toLowerCase()) {
                        if (exactlyMatchedTextArray.indexOf(title) === -1) {
                            exactlyMatchedTextArray.push(titleList[i]);
                        }
                    } else if (title.toLowerCase().includes(searchText.toLowerCase())) {
                        if (includesTextArray.indexOf(title) === -1) {
                            includesTextArray.push(titleList[i]);
                        }
                    }
                }
            }
        }
        return exactlyMatchedTextArray.concat(includesTextArray);
    } else {
        return [];
    }
}
