import React from 'react'
import closeBlack from '../../../public/images/close_black.svg';
import peoples from '../../../public/images/collaborate.svg'
import SettingsCollaboratorContainer from "../../app/builder/components/SettingsCollaboratorContainer";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {APP_ACTION_TYPE} from "../../app/reducers/appReducer";
import {useTranslation} from "react-i18next";

const CollaborateCard = (props) => {
    const {t} = useTranslation()
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const dispatch = useDispatch()
    return (
        <div id={'collaborate-card'} className='share-card' style={{height: '45%', paddingTop: '1%', paddingLeft: '3%', paddingBottom: '1%'}}>
            <div id={'share-close-button'} className='share-close-button'
                 onClick={() => {
                     if (!showShared) {
                         props.updateCollabIcons()
                     }
                     dispatch({type: APP_ACTION_TYPE.SET_SHOW_COLLABORATE_POP_UP, payload: false})
                 }}><img
                src={closeBlack} alt='close'/></div>
            <div id={'settings-collaboration-card-container'} style={{
                overflowY: "scroll",
                overflowX: 'hidden',
                paddingRight: '0',
                marginRight: '-6.5%',
                marginLeft: '-2.5%',
                height: '-webkit-fill-available'
            }}>
                <SettingsCollaboratorContainer/>
            </div>
        </div>
    );
}


export default CollaborateCard
