import React, {useRef, useState} from "react";
import {sortFields} from "../utils/sort-form-list";
import {handlePreviouslyOpenedSortCard} from "./AccInfoContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {useDispatch, useSelector} from "react-redux";
import CircularCheckBox from "../../../shared/components/CircularCheckBox";
import {useTranslation} from "react-i18next";

export default function MobileSortContainer(props) {
    const {t} = useTranslation()
    const selected = useSelector(state => state.languageReducer.selectedLanguage)
    const [showSortOptions, setShowSortOptions] = useState(false);
    const sortOptions = useRef(null)
    const currentSortOption = useSelector(state => state.appDashboardReducer.mobileSortOrder)
    const dispatch = useDispatch()

    const sortDataList = (sortOrder, event) => {
        event.stopPropagation();
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_MOBILE_SORT_ORDER, payload: sortOrder})
        setShowSortOptions(false);
        document.onmousedown = null;
    }
    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        height: '100%',
        width: '100%',
        zIndex: '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    }

    return (
        <div id={'account-logo-container-mobile'} className="account-logo-container-mobile">
            <div className='acc-logo-mobile' onClick={(e) => {
                e.stopPropagation();
                props.previouslyOpenedDropDownRef.current?.element ? handlePreviouslyOpenedSortCard(props.previouslyOpenedDropDownRef.current) : null;
                setShowSortOptions(prevState => !prevState)
                props.previouslyOpenedDropDownRef.current = {
                    element: sortOptions.current
                };
                document.onmousedown = e => {
                    props.previouslyOpenedDropDownRef.current = null;
                    if (sortOptions.current && !sortOptions.current.contains(e.target)) {
                        setShowSortOptions(false);
                        document.onmousedown = null;
                    }
                }
            }}>
                <img alt='sort' src='images/sort_icon.svg'/>
            </div>
            {showSortOptions ?
                <div style={popUpStyle}>
                    <div id={'form-sort-options-container-mobile'} className="form-sort-options-container-mobile" style={(selected === 'fa' || selected === 'ar') ? {right: '180px'} : null}
                         ref={sortOptions}>
                        <div className="form-sort-header-mobile" style={{border: "none"}}>
                            {t(translationKeys.sort_by)}
                        </div>
                        <div id={'form-sort-Modified-Date'} className="form-sort-each-option-mobile"
                             onClick={e => sortDataList(sortFields.DATE_EDITED, e)}>
                            <CircularCheckBox isChecked={currentSortOption === sortFields.DATE_EDITED}/>
                            {t(translationKeys.modified_date)}
                        </div>
                        <div id={'form-sort-Created-Date'} className="form-sort-each-option-mobile"
                             onClick={e => sortDataList(sortFields.DATE_CREATED, e)}>
                            <CircularCheckBox isChecked={currentSortOption === sortFields.DATE_CREATED}/>
                            {t(translationKeys.created_date)}
                        </div>
                        <div id={'form-sort-Title'} className="form-sort-each-option-mobile"
                             style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}
                             onClick={e => sortDataList(sortFields.TITLE, e)}>
                            <CircularCheckBox isChecked={currentSortOption === sortFields.TITLE}/>
                            {t(translationKeys.title)}
                        </div>
                    </div>
                </div> : null}
        </div>
    )
}