import React from "react"
import whiteTick from "../../../../public/images/done.svg";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import {isMobileOnly} from "react-device-detect";

const ZohoAlreadySubmittedPopUp = (props) => {
    return (
        <div className={"premium-plans-popup"}>
            <div className={"success-pop-up"} style={{width: isMobileOnly ? "80%" : "350px"}}>
                <div className={props.success ? "green-tick" : "yellow-tick"}>
                    <img src={whiteTick} alt={"success"}/>
                </div>
                <h2>{props.title}</h2>
                {props.success ? null :
                    <span style={{background: "#F5F5F5", borderRadius: "20px", padding: "5px 10px"}}>
                        Status: In Progress
                    </span>
                }
                <p>{props.description}</p>
                <MaterialButton data={{
                    title: "CLOSE",
                    buttonType: ButtonTypes.OUTLINED,
                    bg_color: '#1976d2',
                    customButtonContainerStyle: {
                        marginTop: '20px',
                        borderRadius: "50px"
                    }
                }} handleClick={() => {
                    props.handlePopUpClick();
                }}/>
            </div>
        </div>
    )
}

export default ZohoAlreadySubmittedPopUp
