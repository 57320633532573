import {EMAIL_SUGGESTIONS} from "../../shared/utils/constants";

export const EMAIL_ACTION_TYPE = {
    SET_EMAIL_SUGGESTIONS_FROM_LOCAL: 'SET_EMAIL_SUGGESTIONS_FROM_LOCAL',
    CLEAR_SUGGESTIONS: 'CLEAR_SUGGESTIONS',
    GET_KEYWORDS: 'GET_KEYWORDS',
    GET_WORDLIST: 'GET_WORDLIST',
    SEARCH: 'SEARCH',
    SET_SUGGESTIONS: 'SET_SUGGESTIONS',
    SET_CURRENT_EMAIL_VALUES: 'SET_CURRENT_OPTION_VALUES',
    SET_SELECTED_EMAIL_VALUE: 'SET_SELECTED_OPTION_VALUE',
    SUGGESTION_CLICKED: 'SUGGESTION_CLICKED',
    STORE_NEW_EMAIL_IN_LOCAL_STORAGE: 'STORE_NEW_EMAIL_IN_LOCAL_STORAGE'
}

const locallyStoredEmails = window && window.localStorage && window.localStorage?.getItem(EMAIL_SUGGESTIONS.LOCAL_STORAGE_EMAIL_TEXT) ? JSON.parse(window.localStorage?.getItem(EMAIL_SUGGESTIONS.LOCAL_STORAGE_EMAIL_TEXT)) : null;

const initialState = {
    emailSuggestionsFromLocalStorage: locallyStoredEmails,
    suggestions: [],
    matches: [],
    suggestionClicked: false,
    selectedValue: ''
}

const getEmailSuggestions = (localStoreItems) => {
    const allEmailSuggested = []

    if (localStoreItems) {
        localStoreItems.map(localStorageItem => {
                allEmailSuggested.push(localStorageItem.email)
            }
        )
    }

    return allEmailSuggested
}

const collabAutocompleteReducer = (state = initialState, action) => {
    switch (action.type) {
        case EMAIL_ACTION_TYPE.CLEAR_SUGGESTIONS:
            return {
                ...state,
                matches: []
            }
        case EMAIL_ACTION_TYPE.SET_SELECTED_EMAIL_VALUE:
            return {
                ...state,
                selectedValue: action.payload
            }
        case EMAIL_ACTION_TYPE.SET_SUGGESTIONS:
            return {
                ...state,
                suggestions: getEmailSuggestions(state.emailSuggestionsFromLocalStorage)
            }
        case EMAIL_ACTION_TYPE.SEARCH:
            return {
                ...state,
                matches: state.suggestions.filter(f => f.toLowerCase().includes(action.payload.toLowerCase()))
            }
        case EMAIL_ACTION_TYPE.STORE_NEW_EMAIL_IN_LOCAL_STORAGE:
            let localStorageItems = state.emailSuggestionsFromLocalStorage === null ? [] : state.emailSuggestionsFromLocalStorage

            action.payload.map((newmail) => {
                let localStorageItem = {
                    email: newmail
                }

                localStorageItems.push(localStorageItem)
            })

            localStorage.setItem(EMAIL_SUGGESTIONS.LOCAL_STORAGE_EMAIL_TEXT, JSON.stringify(localStorageItems))

            return {
                ...state,
                emailSuggestionsFromLocalStorage: localStorageItems,
                suggestions: getEmailSuggestions(localStorageItems)
            }
        case EMAIL_ACTION_TYPE.SUGGESTION_CLICKED:
            return {
                ...state,
                suggestionClicked: action.payload
            }
        case EMAIL_ACTION_TYPE.SET_EMAIL_SUGGESTIONS_FROM_LOCAL:
            return {
                ...state,
                emailSuggestionsFromLocalStorage: action.payload
            }
        default:
            return state
    }
}

export default collabAutocompleteReducer