import React, {useEffect, useState} from 'react'
import {changeFormStatus, getFormCollaborator} from "../../../shared/utils/sash-v2-api";
import Loader from "../../../shared/components/Loader";
import ShareCard from "../../../shared/components/ShareCard";
import {PAGE_HASH_LOCATION, Pages} from "../../utils/pages";
import {getQuizCollaborator, QUIZ_BASE_URL, updateQuizStatusService} from "../../../shared/utils/quiz-api-services";
import {isMobileOnly} from "react-device-detect";
import {MEDIA_BASE_URL, AWS_FOLDER_NAMES, AWS_BUCKET_MIDDLE_PATH} from "../../builder/utils/aws";
import backWhite from '../../../../public/images/back_white.png';
import favouriteHaloBlue from '../../../../public/images/favourite_halo_blue.svg';
import formStatus from '../../../../public/images/form_status.svg';
import peoples from '../../../../public/images/collaborate.svg'
import shareBlue from '../../../../public/images/share_blue.svg';
import viewResponsesBlue from '../../../../public/images/view_responses_blue.svg';
// import settingsBlue_2 from '../../../../public/images/settings_blue_2.svg';
import editBlue from '../../../../public/images/edit_blue.svg';
import viewFormIcon from '../../../../public/images/view_form_icon.png';
import duplicateFormBlue from '../../../../public/images/duplicate_form_blue.svg';
import deleteSvg from '../../../../public/images/delete.svg';
import leaveCollab from '../../../../public/images/Leave_collab.svg'
import {registerGAEvent} from "../../../shared/utils/analytics";
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";
import {SHARED_ACTION_TYPE} from "../../reducers/sharedReducer";
import {getDateTimeWithFormat} from "../../../shared/utils/get-date-time-with-format";
import {DATE_FORMATS} from "../../../admin/constants";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {RESPONSES_ACTION_TYPE} from "../../reducers/responsesReducer";
import {PremiumJSON} from "../../../shared/utils/helper";


const MobileFormControlContainer = (props) => {
    const {t} = useTranslation()
    const userId = useSelector(state => state.userReducer?.userId)
    const formData = useSelector(state => state.appReducer?.formData)
    const showShared = formData?.user_id !== userId
    const responseCount = useSelector(state => state.responsesReducer.responseCount)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const builderCreationSource = useSelector(state => state.appReducer.builderCreationSource)
    const isShowingMobileFormControl = useSelector(state => state.appDashboardReducer.isShowingMobileFormControl)
    const [role, setRole] = useState();
    const [showFormShare, setShowFormShare] = useState(false);
    const [dateFavoured, setDateFavoured] = useState(formData?.date_favoured);
    const [showNoResponseText, setShowNoResponseText] = useState(null);
    const [showStatusToast, setShowStatusToast] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch()

    const AWS_THUMBNAIL_512_342_WEB_PATH = MEDIA_BASE_URL + AWS_BUCKET_MIDDLE_PATH + AWS_FOLDER_NAMES.THUMBNAIL_512_342_FOLDER;

    useEffect(() => {
        if (responseCount === null) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: formData?.response_count})
        }
        if (showShared && formData?._id) {
            if (!formData.is_quiz) {
                getFormCollaborator(formData?._id, (res) => {
                    if (res?.result?.collaborators) {
                        res.result.collaborators.map(collaborator => {
                            if (collaborator.user_id === userId) {
                                setRole(collaborator.role)
                            }
                        })
                    }
                }, (err) => {
                    console.log(err)
                })
            } else if (formData.is_quiz) {
                getQuizCollaborator(formData?._id, (res) => {
                    if (res?.result?.collaborators) {
                        res.result.collaborators.map(collaborator => {
                            if (collaborator.user_id === userId) {
                                setRole(collaborator.role)
                            }
                        })
                    }
                }, (err) => {
                    console.log(err)
                })
            }
        }
    }, [])

    const showNoResponseTextWithTimeOut = () => {
        setShowNoResponseText(true);
        setTimeout(() => {
            setShowNoResponseText(false);
        }, 2000)
    }

    const showStatusToastWithTimeOut = () => {
        setShowStatusToast(true);
        setTimeout(() => {
            setShowStatusToast(false);
        }, 2000)
    }

    const hideSharePopUp = () => {
        setShowFormShare(false);
    }

    const updateFormStatusInServer = (isActive) => {
        setIsLoading(true);
        if (formData.is_quiz) {
            updateQuizStatusService(formData._id, isActive, res => {
                if (res.result) {
                    props.updateStatus(formData._id, isActive);
                    setIsLoading(false);
                    showStatusToastWithTimeOut()
                    const updatedFormData = {...formData}
                    updatedFormData.setting.is_active = isActive
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                }
            }, res => console.log(res))
        } else {
            changeFormStatus(formData._id, isActive,
                res => {
                    if (res.result) {
                        props.updateStatus(formData._id, isActive);
                        setIsLoading(false);
                        showStatusToastWithTimeOut()
                        const updatedFormData = {...formData}
                        updatedFormData.setting.is_active = isActive
                        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                    }
                },
                res => console.log(res)
            )
        }
    }
    let formTheme;
    const backButtonBackgroundStyle = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "5px 5px 5px 5px",
        position: "absolute",
        top: "10px",
        left: "10px",
        borderRadius: '50%',
        backgroundColor: "rgba(0, 0, 0, 0.2)"
    }

    window.onhashchange = () => {
        if (window.location.hash === '#form-control') {
            showFormShare ? hideSharePopUp() : null
        } else if (window.location.hash === PAGE_HASH_LOCATION.dashboard) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
        }
    }
    isShowingMobileFormControl ? window.location.hash = "form-control" : null
    if (formData?.theme === 'classic_theme') {
        formTheme = AWS_THUMBNAIL_512_342_WEB_PATH + '/classic_new.jpeg'
    } else if (formData?.theme === 'dark_theme') {
        formTheme = AWS_THUMBNAIL_512_342_WEB_PATH + '/dark.jpeg'
    } else if (formData?.theme.startsWith(MEDIA_BASE_URL)) {
        formTheme = formData?.theme;
    } else {
        formTheme = AWS_THUMBNAIL_512_342_WEB_PATH + '/' + formData?.theme;
    }
    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.3)',
        height: '100%',
        width: '100%',
        zIndex: '7',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    };
    const handleShareClick = () => {
        if (isMobileOnly) {
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
            dispatch({type: APP_ACTION_TYPE.SET_IS_ANSWER_SHEET, payload: false})
            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!formData?.is_quiz})
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.share})
        } else {
            setShowFormShare(true)
        }
    }
    const handleViewResponsesClick = () => {
        if (responseCount && responseCount > 0) {
            dispatch({type: RESPONSES_ACTION_TYPE.SET_IS_SHEET_CONNECTED, payload: false})
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!formData?.is_quiz})
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.responses})
            dispatch({type: RESPONSES_ACTION_TYPE.SET_QUESTION_FILTER_OBJECT, payload: []})
        } else showNoResponseTextWithTimeOut()
    }
    const handleEditClick = () => {
        if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_EDIT_PREMIUM_POP_UP, payload: true})
        } else if (responseCount > 0) {
            props.showDataEditWarning(true, formData);
        } else {
            dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.SAVED})
            dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
            dispatch({type: BUILDER_ACTION_TYPE.SET_NEW_QUESTION, payload: false})
            dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
            dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
            dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!formData.is_quiz})
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
        }
    }
    const handleCollaborationClick = () => {
        registerGAEvent('Form-Dashboard', 'Collaboration', 'clicked-collaboration');
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
        dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.SAVED})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!formData?.is_quiz})
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.collaborator})
    }
    const handleDuplicateClick = () => {
        if (PremiumJSON[subscriptionData?.subscription_plan]?.rank < formData.subscription_data?.min_subscription_required?.rank) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DUPLICATE_PREMIUM_POP_UP, payload: true})
            return
        }
        dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.DUPLICATE})
        dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
        dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: !!formData?.is_quiz})
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
    }
    window.onhashchange = () => {
        switch (window.location.hash) {
            case PAGE_HASH_LOCATION.dashboard:
                if (!showShared) {
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
                }
                dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: null})
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_DATA_COLLECTED_WARNING, payload: false})
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                break;
            case PAGE_HASH_LOCATION.form_control:
                if (builderCreationSource === BUILDER_CREATION_SOURCE.DUPLICATE) {
                    dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.NEW})
                    window.location.hash = PAGE_HASH_LOCATION.dashboard
                }
                break;
        }
        dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
        dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
        dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: false})
        dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_QUIZ_QUICK_SETTINGS, payload: false})
    }
    return (
        <div id={'mobile-form-control'} className="mobile-form-control">
            <div id={'mobile-form-control-header'} className='mobile-form-control-header'
                 style={{
                     background: "url(" + formTheme + ")",
                     backgroundSize: '100% auto',
                     backgroundPosition: isMobileOnly ? 'center' : null
                 }}>
                <div id={'form-control-title-container'} className='form-control-title-container'>
                    <p id={'form-control-welcome-title'}
                       className='form-control-form-title'>{formData?.welcome_screen.title}</p>
                    <span id={'form-control-created-form-date'} className='created-date-form-control'>
                           {t(translationKeys.created)}: <span
                        dir={'ltr'}>{getDateTimeWithFormat(formData?.date_created, DATE_FORMATS.DD_MM_YYYYCOMATIME)}</span>
                        </span>
                </div>
                <div id={'form-control-back-button'} style={backButtonBackgroundStyle}
                     onClick={() => {
                         if (!showShared) {
                             dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
                         }
                         dispatch({type: RESPONSES_ACTION_TYPE.SET_RESPONSE_COUNT, payload: null})
                         dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: false})
                         location.hash = 'dashboard'
                     }}>
                    <img className='form-control-back-button' alt='back-button'
                         src={backWhite}/>
                </div>
                {/***favourite tag***/}
                {isMobileOnly && dateFavoured && !showShared ?
                    <div
                        className={'favourite-tag mobile-form-control-favourite-tag-position'}>
                        <img alt='favourite'
                             src={favouriteHaloBlue}/>
                    </div> : null
                }
            </div>
            {!showShared ?
                <div style={{display: 'flex', flexFlow: 'column', height: '70%', width: '100%', overflowY: 'auto'}}>
                    {/*Form status*/}
                    <div id="form-control-status" className="dashboard-form-control-drop-down-card">
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={formStatus}
                            alt="status"/>
                        <p id={'form-status-text'} style={screen.width < 360 ? {
                            width: '250px',
                            textAlign: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "right" : null
                        } : {
                            width: '44%',
                            textAlign: selectedLanguage === 'fa' ? "right" : null
                        }}>{formData?.is_quiz ? t(translationKeys.quiz_status) : t(translationKeys.form_status)}</p>
                        <div id={'mobile-form-control-status'} className='status-text-box'
                             style={formData?.setting.is_active ? {borderColor: 'green'} : {borderColor: 'red'}}>
                                <span className='form-status-text' style={formData?.setting.is_active ? {color: 'green'}
                                    : {color: 'red'}}>{formData?.setting.is_active ? t(translationKeys.active).toUpperCase() : t(translationKeys.inactive).toUpperCase()}</span>
                        </div>
                        <div style={{width: '24%', display: 'flex', justifyContent: 'flex-end'}}>
                            <img style={{width: 'auto'}} alt='status-switch'
                                 src={formData?.setting.is_active ? '../../../images/status_blue.svg' : '../../../images/status_grey.svg'}
                                 onClick={e => {
                                     e.stopPropagation();
                                     formData?.setting.is_active ? updateFormStatusInServer(false)
                                         : updateFormStatusInServer(true);
                                 }}/>
                        </div>
                    </div>
                    {/*Share*/}
                    <div id="form-control-share" className="dashboard-form-control-drop-down-card"
                         onClick={handleShareClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={shareBlue}
                            alt="share"/>
                        <p>{t(translationKeys.share)}</p></div>
                    {/*view Result*/}
                    <div id="form-control-view-result" className="dashboard-form-control-drop-down-card"
                         onClick={handleViewResponsesClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={viewResponsesBlue}
                            alt="view"/>
                        <p id={'form-control-view-result'}>{formData?.is_quiz ? t(translationKeys.view_answers) : t(translationKeys.view_responses)}</p>
                        <div id={'form-control-response-count'} className='form-control-response-count'>
                            {responseCount === formData.response_count ? responseCount : formData.response_count}</div>
                    </div>
                    {/*Edit*/}
                    <div id="form-control-edit" className="dashboard-form-control-drop-down-card"
                         onClick={handleEditClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={editBlue}
                            alt="edit"/>
                        <p>{formData?.is_quiz ? t(translationKeys.edit) + ' ' + t(translationKeys.quiz) : t(translationKeys.edit_form)}</p>
                    </div>
                    {/*Favourite*/}
                    <div id={'form-control-favourite'} className="dashboard-form-control-drop-down-card"
                         onClick={(e) => {
                             e.stopPropagation();
                             setDateFavoured(dateFavoured ? undefined : new Date().getTime().toString())
                             props.favouriteClicked(formData?._id, dateFavoured);
                         }}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            style={isMobileOnly ? {width: '24px'} : null}
                            src={dateFavoured ? 'images/favourite_hollow_blue.svg' : 'images/favourite_blue.svg'}
                            alt="Favourite"/>
                        <p id={'form-control-add-favourite'}>{dateFavoured ? t(translationKeys.remove_favourite) : t(translationKeys.add_favourite)}</p>
                    </div>
                    {/*Collaborate*/}
                    <div id="form-control-collaborate" className="dashboard-form-control-drop-down-card"
                         onClick={handleCollaborationClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={peoples}
                            alt="edit"/>
                        <p>{t(translationKeys.collaboration)}</p>
                    </div>
                    {/*/!*Quiz Quick Settings*!/*/}
                    {/*{formData?.is_quiz ?*/}
                    {/*    <div id="form-control-quick-settings" className="dashboard-form-control-drop-down-card"*/}
                    {/*         onClick={() => {*/}
                    {/*             dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_QUIZ_QUICK_SETTINGS, payload: true})*/}
                    {/*             dispatch({type: QUIZZES_ACTION_TYPE.SET_QUIZ_ID, payload: formData?._id})*/}
                    {/*             dispatch({*/}
                    {/*                 type: QUIZZES_ACTION_TYPE.SET_QUIZ_QUICK_SETTING,*/}
                    {/*                 payload: quizzesList.find(quizzesList => quizzesList._id === formData?._id).setting*/}
                    {/*             })*/}
                    {/*         }}>*/}
                    {/*        <img*/}
                    {/*            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}*/}
                    {/*            src={settingsBlue_2}*/}
                    {/*            alt="quick settings"/>*/}
                    {/*        <p>{t(translationKeys.quick_settings)}</p>*/}
                    {/*    </div>*/}
                    {/*    : null}*/}
                    {/*view*/}
                    <div id="form-control-view" className="dashboard-form-control-drop-down-card"
                         onClick={() => {
                             formData.is_quiz ? open(QUIZ_BASE_URL + '/id/' + formData?._id) :
                                 open("/form/" + formData?._id)
                         }}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={viewFormIcon}
                            alt="view"/>
                        <p>{formData?.is_quiz ? t(translationKeys.view_quiz) : t(translationKeys.view_form)}</p>
                    </div>
                    {/*Duplicate*/}
                    <div id="form-control-duplicate" className="dashboard-form-control-drop-down-card"
                         onClick={handleDuplicateClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={duplicateFormBlue}
                            alt="duplicate"/>
                        <p>{formData?.is_quiz ? t(translationKeys.duplicate_quiz) : t(translationKeys.duplicate_form)}</p>
                    </div>
                    {/*Delete*/}
                    <div id="form-control-delete" className="dashboard-form-control-drop-down-card"
                         style={{borderBottomColor: 'transparent'}}
                         onClick={() => {
                             if (formData.is_quiz) {
                                 dispatch({type: QUIZZES_ACTION_TYPE.SET_DELETABLE_QUIZ_ID, payload: formData?._id})
                                 dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
                             } else {
                                 dispatch({type: FORMS_ACTION_TYPE.SET_DELETABLE_FORM_ID, payload: formData?._id})
                                 dispatch({type: FORMS_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
                             }
                         }}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={deleteSvg}
                            alt="delete"/>
                        <p>{formData?.is_quiz ? t(translationKeys.delete) + ' ' + t(translationKeys.quiz) : t(translationKeys.delete_form)}</p>
                    </div>
                    {isLoading ? <Loader/> : null}
                    {showFormShare ?
                        <div style={popUpStyle}>
                            <ShareCard isQuiz={false}
                                       formId={formData?._id}
                                       isShowSharePopUp={hideSharePopUp}
                                       passcode={formData?.setting?.passcode}
                            />
                        </div> : null}
                </div> :
                <div style={{display: 'flex', flexFlow: 'column', height: '70%', width: '100%', overflowY: 'auto'}}>
                    {/*Form status*/}
                    {role ? <div id="form-control-status" className="dashboard-form-control-drop-down-card">
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={formStatus}
                            alt="status"/>
                        <p style={screen.width < 360 ? {
                            width: '250px',
                            textAlign: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? "right" : null
                        } : {
                            width: '44%',
                            textAlign: selectedLanguage === 'fa' ? "right" : null
                        }}>{formData?.is_quiz ? t(translationKeys.quiz_status) : t(translationKeys.form_status)}</p>
                        <div className='status-text-box'
                             style={formData?.setting.is_active ? {borderColor: 'green'} : {borderColor: 'red'}}>
                                <span className='form-status-text' style={formData?.setting.is_active ? {color: 'green'}
                                    : {color: 'red'}}>{formData?.setting.is_active ? t(translationKeys.active) : t(translationKeys.inactive)}</span>
                        </div>
                        <div style={{width: '24%', display: 'flex', justifyContent: 'flex-end'}}>
                            <img style={{width: 'auto'}} alt='status-switch'
                                 src={formData?.setting.is_active ? '../../../images/status_blue.svg' : '../../../images/status_grey.svg'}
                                 onClick={e => {
                                     e.stopPropagation();
                                     formData?.setting.is_active ? updateFormStatusInServer(false)
                                         : updateFormStatusInServer(true);
                                 }}/>
                        </div>
                    </div> : null}
                    {/*Share*/}
                    <div id="form-control-share" className="dashboard-form-control-drop-down-card"
                         onClick={handleShareClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={shareBlue}
                            alt="share"/>
                        <p>{t(translationKeys.share)}</p></div>
                    {/*view Result*/}
                    <div id="form-control-view-result" className="dashboard-form-control-drop-down-card"
                         onClick={handleViewResponsesClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={viewResponsesBlue}
                            alt="view"/>
                        <p id={'form-control-view-result-responses'}>{(formData?.is_quiz ? t(translationKeys.view_answers) : t(translationKeys.view_responses))}</p>
                        <div className='form-control-response-count'>
                            {responseCount ? responseCount : 0}</div>
                    </div>
                    {/*Edit*/}
                    {role ? <div id="form-control-edit" className="dashboard-form-control-drop-down-card"
                                 onClick={handleEditClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={editBlue}
                            alt="edit"/>
                        <p>{formData?.is_quiz ? t(translationKeys.edit) + " " + t(translationKeys.quiz) : t(translationKeys.edit_form)}</p>
                    </div> : null}
                    {/*Collaborate*/}
                    <div id="form-control-collaborate" className="dashboard-form-control-drop-down-card"
                         onClick={handleCollaborationClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={peoples}
                            alt="edit"/>
                        <p>{t(translationKeys.collaborate)}</p>
                    </div>
                    {/*/!*Quiz Quick Settings*!/*/}
                    {/*{formData?.is_quiz && getRole(formData.collaborators, userId) !== COLLABORATOR_ROLES.VIEWER ?*/}
                    {/*    <div id="form-control-quick-settings" className="dashboard-form-control-drop-down-card"*/}
                    {/*         onClick={() => {*/}
                    {/*             dispatch({type: QUIZZES_ACTION_TYPE.SET_SHOW_QUIZ_QUICK_SETTINGS, payload: true})*/}
                    {/*             dispatch({type: QUIZZES_ACTION_TYPE.SET_QUIZ_ID, payload: formData?._id})*/}
                    {/*             dispatch({*/}
                    {/*                 type: QUIZZES_ACTION_TYPE.SET_QUIZ_QUICK_SETTING,*/}
                    {/*                 payload: quizzesList.find(quizzesList => quizzesList._id === formData?._id).setting*/}
                    {/*             })*/}
                    {/*         }}>*/}
                    {/*        <img*/}
                    {/*            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}*/}
                    {/*            src={settingsBlue_2}*/}
                    {/*            alt="quick settings"/>*/}
                    {/*        <p>{t(translationKeys.quick_settings)}</p>*/}
                    {/*    </div>*/}
                    {/*    : null}*/}
                    {/*view*/}
                    <div id="form-control-view" className="dashboard-form-control-drop-down-card"
                         onClick={() => {
                             formData?.is_quiz ? open(QUIZ_BASE_URL + '/id/' + formData?._id) :
                                 open("/form/" + formData?._id)
                         }}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={viewFormIcon}
                            alt="view"/>
                        <p>{formData?.is_quiz ? t(translationKeys.view_quiz) : t(translationKeys.view_form)}</p>
                    </div>
                    {/*Duplicate*/}
                    <div id="form-control-duplicate" className="dashboard-form-control-drop-down-card"
                         onClick={handleDuplicateClick}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={duplicateFormBlue}
                            alt="duplicate"/>
                        <p>{formData?.is_quiz ? t(translationKeys.duplicate_quiz) : t(translationKeys.duplicate_form)}</p>
                    </div>
                    {/*Delete*/}
                    <div id="form-control-delete" className="dashboard-form-control-drop-down-card"
                         style={{borderBottomColor: 'transparent'}}
                         onClick={() => {
                             registerGAEvent('Form-Dashboard', 'Collaboration', 'clicked-leave-collaboration')
                             dispatch({type: SHARED_ACTION_TYPE.SET_DELETABLE_FORQ_DATA, payload: formData})
                             dispatch({type: SHARED_ACTION_TYPE.SET_SHOW_DELETE_POP_UP, payload: true})
                         }}>
                        <img
                            className={isMobileOnly ? 'form-control-drop-down-card-icon-mobile' : "form-control-drop-down-card-icon"}
                            src={leaveCollab}
                            alt="leave"/>
                        <p>{t(translationKeys.leave_collaboration)}</p>
                    </div>
                    {isLoading ? <Loader/> : null}
                    {showFormShare ?
                        <div style={popUpStyle}>
                            <ShareCard isQuiz={false}
                                       formId={formData?._id}
                                       isShowSharePopUp={hideSharePopUp}
                                       passcode={formData?.setting?.passcode}
                            />
                        </div> : null}
                </div>}
            {showNoResponseText ?
                <div style={{width: '100%', position: 'absolute', bottom: '0'}}><p
                    style={{textAlign: 'center', color: 'red'}}>
                    {formData?.is_quiz ? t(translationKeys.no_answer) : t(translationKeys.no_response)}</p>
                </div> : null}
            {showStatusToast ? <div style={{width: '100%', position: 'absolute', bottom: '0', background: "#4d4d4d"}}>
                <p style={{color: "#FFFFFF", marginLeft: "20px"}}>{formData?.setting.is_active ? "Activated" : "Deactivated"}</p>
            </div> : null}
        </div>
    )
}

export default MobileFormControlContainer
