import {combineReducers, createStore} from "redux"
import userReducer from "../reducers/userReducer"
import notificationReducer from "../reducers/notificationsReducer"
import updatedFormDataReducers from "../reducers/updatedFormDataReducer"
import appReducer from "../reducers/appReducer"
import loginReducer from "../reducers/loginReducer"
import appDashboardReducer from "../reducers/appDashboardReducer"
import {
    getCurrentFormTemplateReducer,
    getCurrentQuizTemplateReducer,
    languageReducer
} from "../../localizations/reducer/localizationReducer"
import formsReducer from "../reducers/formsReducer"
import quizzesReducer from "../reducers/quizzesReducer"
import sharedReducer from "../reducers/sharedReducer"
import templateReducer from "../reducers/templateReducer"
import builderReducer from "../reducers/builderReducer"
import collaboratorsData from "../reducers/collaboratorReducer"
import collabAutocompleteReducer from "../reducers/collabAutocompleteReducer"
import autocompleteReducer from "../reducers/autocompleteReducer"
import optionsReducer from "../reducers/optionsReducer"
import responsesReducer from "../reducers/responsesReducer"
import gridDetailsPopupReducer from "../reducers/gridDetailsPopupReducer"
import featureSpotlightReducer from "../reducers/featureSpotlightReducer"
import importQuestionsReducer from "../reducers/importQuestionsReducer"
import storageManagementReducer from "../reducers/storageManagementReducer";
import premiumReducer from "../reducers/premiumReducer";

const appStore = createStore(combineReducers({
    appReducer,
    userReducer,
    loginReducer,
    formsReducer,
    sharedReducer,
    builderReducer,
    quizzesReducer,
    optionsReducer,
    languageReducer,
    templateReducer,
    responsesReducer,
    collaboratorsData,
    appDashboardReducer,
    notificationReducer,
    autocompleteReducer,
    updatedFormDataReducers,
    storageManagementReducer,
    collabAutocompleteReducer,
    getCurrentQuizTemplateReducer,
    getCurrentFormTemplateReducer,
    gridDetailsPopupReducer,
    featureSpotlightReducer,
    premiumReducer,
    importQuestionsReducer
}), process.env.REACT_APP_ENV_IS_REACT_DEV_TOOLS === "true" ? (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) : undefined)

export default appStore