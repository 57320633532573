import React from "react"
import premiumCrown from "../../../../public/images/premium_crown.svg";
import MaterialButton from "../../../shared/components/MaterialButton";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";
import {useDispatch} from "react-redux";
import {isMobileOnly} from "react-device-detect";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useTranslation} from "react-i18next";

const LimitReachedUI = (props) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()

    return (
        <div style={{
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            margin: isMobileOnly ? "10%" : 0,
            background: props.background ? props.background : "#FFFFFF",
            width: props.width ? props.width : isMobileOnly ? null : "80%",
            marginTop: isMobileOnly ? null : "5%",
            height: props.height ? props.height : isMobileOnly ? "90vh" : "80vh",
            borderRadius: isMobileOnly ? null : "10px"
        }}>
            <img src={premiumCrown} alt={"premium"} style={{width: "75px"}}/>
            <h2>{t(translationKeys.upgrade_your_plan)}!</h2>
            <p style={{marginTop: "0"}}>{props.isQuiz ? t(translationKeys.limit_reached_UI_message_quiz) : t(translationKeys.limit_reached_UI_message)}</p>
            <MaterialButton data={{
                title: t(translationKeys.upgrade_plan),
                customButtonContainerStyle: {
                    borderRadius: '50px',
                    backgroundColor: '#004BA0',
                    width: '200px',
                },
                customButtonTextStyle: {
                    fontFamily: 'Nunito Sans, Sans-serif, serif',
                    fontWeight: "bold",
                    fontSize: '16px',
                }
            }} handleClick={() => {
                if (isMobileOnly) {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.premium_plans})
                } else {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_PREMIUM_PLANS_POP_UP, payload: true})
                }
            }}
            />
        </div>
    )
}

export default LimitReachedUI
