import {isMobileOnly} from "react-device-detect";
import MaterialButton from "./MaterialButton";
import React from "react";
import '../css/bottom-alert.css'
import {useTranslation} from "react-i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";
import {useSelector} from "react-redux";

const BottomAlertContainer = props => {
    const {t} = useTranslation()
    const selectedDataArray = props.selectedDataArray;
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)

    const negativeButtonData = {
        title: props.negativeButtonText,
        bg_color: '#efefef',
        fontSize: isMobileOnly ? '14px' : '16px',
        customButtonContainerStyle: {
            height: isMobileOnly ? '30px' : null,
            padding: isMobileOnly ? '0 6px 0 8px' : null
        },
        customButtonTextStyle: {
            color: '#5c5c5c'
        }
    }
    const positiveButtonData = {
        title: props.positiveButtonText,
        bg_color: '#e75c6e',
        fontSize: isMobileOnly ? '14px' : '16px',
        customButtonContainerStyle: {
            height: isMobileOnly ? '30px' : null,
            padding: isMobileOnly ? '0 6px 0 8px' : null
        }
    }

    return (
        <div id={'delete-button-container'} className={isMobileOnly ? 'delete-button-container-mobile' : 'delete-button-container'} style={props.isFullScreen ? {width: '100%', left: '0'} : null}>
            <div id={'selected-count-container'} className='selected-count-container' style={(selectedLanguage === 'fa' || selectedLanguage === 'ar')? {marginRight: '20px'} : null}>
                    <span ref={props.SelectedCountTextRef ? props.SelectedCountTextRef : null}>
                        {selectedDataArray.length} - {selectedDataArray.length && selectedDataArray.length > 1 ? (props.selectedDataType === t(translationKeys.quiz) ? t(translationKeys.quizzes) : props.selectedDataType === t(translationKeys.form) ? t(translationKeys.forms) : props.selectedDataType === t(translationKeys.answer_s) ? t(translationKeys.answers) : props.selectedDataType === t(translationKeys.response) ? t(translationKeys.responses) : props.selectedDataType) : props.selectedDataType} {t(translationKeys.selected)}</span>
            </div>
            <div  className='buttons-control-container' style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {marginLeft: '20px'} : null}>
                <MaterialButton id={'selected-cancel-button'} data={negativeButtonData}
                                handleClick={props.negativeButtonClick}/>
                <div id={'selected-delete-button'} style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') ? {marginRight: '20px'} : {marginLeft: '20px'}}>
                    <MaterialButton data={positiveButtonData}
                                    handleClick={props.positiveButtonClick}/>
                </div>
            </div>
        </div>
    )
}

export default BottomAlertContainer