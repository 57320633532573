import React, {useState} from 'react'
import HoverControls from "./HoverControls";
import {isMobile, isMobileOnly} from "react-device-detect";
import {Pages} from "../../utils/pages";
import ModalDialog from "../../../shared/components/ModalDialog";
import {getBlockedWordsText} from "../../../shared/utils/get-blocked-words-found";
import FormCardBottomContainer from "./FormCardBottomContainer";
import favouriteImg from '../../../../public/images/favourite_halo_blue.svg'
import multiselectImg from '../../../../public/images/check_box_tick.svg'
import {deleteQuizNotification, QUIZ_BASE_URL} from "../../../shared/utils/quiz-api-services";
import {deleteNotification} from "../../../shared/utils/sash-v2-api";
import {useDispatch, useSelector} from "react-redux";
import {NOTIFICATION_ACTION_TYPE} from "../../utils/constants";
import collabIcon from '../../../../public/images/collab-icon.svg'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";
import {t} from "i18next";
import Message from "../../../shared/containers/ConfirmMessage";
import * as mongoObjectID from "bson-objectid";
import warningIcon from "../../../../public/images/icons/warning_icon.svg";
import {isShowUnavailableFeature} from "../utils/versioning";

export const CARD_TYPE = {
    USER_FORM: 'user-form-card',
    TEMPLATE_FORM: 'template-card',
    DRAFT_FORM: 'draft-card'
};

const FormCard = (props) => {
    const formData = JSON.parse(JSON.stringify(props.formData));
    const [showAcknowledgement, setShowAcknowledgement] = useState(false)
    const [showUnApprovedFormDialog, setShowUnApprovedFormDialog] = useState(false)
    const [showVersioningFormDialog, setShowVersioningFormDialog] = useState(false)
    const userId = useSelector(state => state.userReducer?.userId)
    const showQuiz = useSelector(state => state.appDashboardReducer.showQuiz)
    const showForms = useSelector(state => state.appDashboardReducer.showForms)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    const newNotificationCount = useSelector(state => state.notificationReducer.count)
    const notificationData = useSelector(state => state.notificationReducer.notifications)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const multipleFormsSelectedArray = useSelector(state => state.formsReducer.multipleFormsSelectedArray)
    const multipleQuizzesSelectedArray = useSelector(state => state.quizzesReducer.multipleQuizzesSelectedArray)
    const dispatch = useDispatch()

    const deleteNotificationHandler = () => {
        let notificationId
        let removeNotificationCount = 0
        let formNotificationsIds = []
        let quizNotificationsIds = []
        notificationData.map((notification) => {
            if (notification.form_id === formData._id) {
                notificationId = notification._id
                dispatch({type: NOTIFICATION_ACTION_TYPE.REMOVE_NOTIFICATION, payload: notificationId})
                if (!notification.is_notified) {
                    removeNotificationCount = removeNotificationCount + 1
                    dispatch({
                        type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT,
                        payload: newNotificationCount - removeNotificationCount
                    })
                }
                formNotificationsIds.push(notificationId)
            } else if (notification.quiz_id === formData._id) {
                notificationId = notification._id
                dispatch({type: NOTIFICATION_ACTION_TYPE.REMOVE_NOTIFICATION, payload: notificationId})
                if (!notification.is_notified) {
                    removeNotificationCount = removeNotificationCount + 1
                    dispatch({
                        type: NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT,
                        payload: newNotificationCount - removeNotificationCount
                    })
                }
                quizNotificationsIds.push(notificationId)
            }
        })
        if (formNotificationsIds.length > 0) {
            deleteNotification({notification_ids: formNotificationsIds}, () => {
            }, () => {
            })
        } else if (quizNotificationsIds.length > 0) {
            deleteQuizNotification({notification_ids: quizNotificationsIds}, () => {
            }, () => {
            })
        }
    }

    let formTheme = (formData.theme === 'classic_theme') ? 'classic_new.jpeg' : (formData.theme === 'dark_theme') ? 'dark.jpeg' : formData.theme;
    // Set background CSS value based on whether the Theme is an AWS URL
    let backgroundTheme = formTheme.startsWith('http') ? `url('${formTheme}')` : isMobileOnly ?
        (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ? "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/" + formTheme + "')" :
            "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_512_342/" + formTheme + "')" :
        "url('https://surveyheartmedia.s3.ap-south-1.amazonaws.com/public/images/thumbnail_web/" + formTheme + "')"
    const handleMultipleFormSelect = e => {
        e.stopPropagation();

        if (!formData.is_valid) {
            setShowAcknowledgement(true)
            return
        }

        if (showForms) {
            if (multipleFormsSelectedArray.indexOf(formData._id) !== -1) {
                // Remove the clicked Form
                let multipleForqSelectedArray = [...multipleFormsSelectedArray]

                multipleForqSelectedArray.splice(multipleForqSelectedArray.indexOf(formData._id), 1)
                dispatch({
                    type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY,
                    payload: multipleForqSelectedArray
                })
            } else {
                // Add the clicked Form
                dispatch({
                    type: FORMS_ACTION_TYPE.SET_MULTIPLE_FORMS_SELECTED_ARRAY,
                    payload: [...multipleFormsSelectedArray, formData._id]
                })
            }
            dispatch({
                type: FORMS_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION,
                payload: multipleFormsSelectedArray.length > 0
            })
        } else if (showQuiz) {
            if (multipleQuizzesSelectedArray.indexOf(formData._id) !== -1) {
                // Remove the clicked Quiz
                let multipleForqSelectedArray = [...multipleQuizzesSelectedArray]

                multipleForqSelectedArray.splice(multipleForqSelectedArray.indexOf(formData._id), 1)
                dispatch({
                    type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY,
                    payload: multipleForqSelectedArray
                })
            } else {
                // Add the clicked Quiz
                dispatch({
                    type: QUIZZES_ACTION_TYPE.SET_MULTIPLE_QUIZZES_SELECTED_ARRAY,
                    payload: [...multipleQuizzesSelectedArray, formData._id]
                })
            }
            dispatch({
                type: QUIZZES_ACTION_TYPE.SET_SHOW_MULTIPLE_DELETE_OPTION,
                payload: multipleQuizzesSelectedArray.length > 0
            })
        }
    };

    const templateCardStyleForMobileWidth = {
        backgroundImage: backgroundTheme,
        backgroundSize: '100% 250px'
    };

    const templateCardStyleForDefault = {
        background: backgroundTheme,
        backgroundSize: isMobileOnly ? '100% auto' : 'auto 250px',
        backgroundPosition: 'center'
    };

    const showMultipleDeleteOption = showForms ? multipleFormsSelectedArray?.length > 0 : showQuiz ? multipleQuizzesSelectedArray?.length > 0 : false
    const cardStyle = (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ?
        isMobileOnly ? templateCardStyleForMobileWidth : templateCardStyleForDefault : templateCardStyleForDefault
    const clearLongPressEvent = () => {
        if (isMobile && props.timeOutForLongPress?.current) {
            clearTimeout(props.timeOutForLongPress.current);
            props.timeOutForLongPress.current = undefined;
            return false;
        }
    }

    const setLongPressEvent = (e) => {
        if (isMobile && props.timeOutForLongPress) {
            props.timeOutForLongPress.current = setTimeout(() => {
                handleMultipleFormSelect(e);
            }, 350);
            return false;
        }
    }

    const handleCardClick = (e) => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ""})
        if (showShared) {
            deleteNotificationHandler()
        }
        if (formData.is_valid || props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) {
            if (isMobileOnly) {
                e.stopPropagation();
                if (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) {
                    const updatedFormData = {...formData}
                    updatedFormData.pages[0].questions.map((question) => {
                        if (!question?._id) {
                            question._id = mongoObjectID().toString() + "tempId"
                        }
                    })

                    dispatch({
                        type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                        payload: BUILDER_CREATION_SOURCE.TEMPLATE
                    })
                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: updatedFormData.theme})
                    dispatch({
                        type: APP_ACTION_TYPE.SET_TEMPLATE_QUESTIONS,
                        payload: updatedFormData.pages[0].questions
                    })
                    dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: updatedFormData.is_quiz})
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                    dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
                } else {
                    dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_IS_SHOWING_MOBILE_FORM_CONTROL, payload: true})
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                }
            } else {
                e.stopPropagation();
                if (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) {
                    const updatedFormData = {...formData}
                    updatedFormData.pages[0].questions.map((question) => {
                        if (!question?._id) {
                            question._id = mongoObjectID().toString() + "tempId"
                        }
                    })
                    dispatch({
                        type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                        payload: BUILDER_CREATION_SOURCE.TEMPLATE
                    })
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: updatedFormData})
                }
                if (formData?.pages) {
                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: formData.theme})
                    dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_QUESTIONS, payload: formData?.pages?.[0]?.questions})
                    dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: formData.is_quiz})
                    dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: showShared})
                    dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: formData})
                }
            }
        } else {
            e.stopPropagation();
            setShowUnApprovedFormDialog(true);
        }

        if (props.homePage) {
            window.localStorage.setItem("homeTemplate", JSON.stringify(formData))
            window.location = "/app"
        }
    };

    const handleFavouriteClick = (e) => {
        e.stopPropagation();
        props.favouriteClicked(formData._id, formData.date_favoured);
    }

    return (
        <>
            {(props.formData !== null) ?
                <div className={'form-card form-card-on-hover'}
                     id={'form-card-' + props.cardIndex}
                     onClick={handleCardClick}
                     onTouchStart={setLongPressEvent}
                     onMouseDown={setLongPressEvent}
                     onTouchMove={clearLongPressEvent}
                     onTouchEnd={clearLongPressEvent}
                     onMouseUp={clearLongPressEvent}
                     style={cardStyle}>

                    <FormCardBottomContainer {...props} formData={formData}
                                             favouriteClicked={props.favouriteClicked}

                    />

                    {/***favourite tag***/}
                    {isMobileOnly && props.formData.date_favoured && !showShared ?
                        <div
                            className={'favourite-tag form-card-favourite-tag-position'}
                            style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {right: "90%"} : null}
                            onClick={handleFavouriteClick}>
                            <img alt='favourite'
                                 src={favouriteImg}/>
                        </div> : null
                    }

                    {/***top left and right tags***/}
                    <div style={isShowUnavailableFeature(formData) ? {display: "none"} : null}>
                        {(props.CARD_TYPE === CARD_TYPE.USER_FORM) ?
                            <>
                                <FormCardTopLeftTagContainer formData={formData}/>

                                {/*** Multiple Selection Tag ***/}
                                {showForms && multipleFormsSelectedArray?.length > 0 && formData.is_valid ?
                                    <div
                                        className={multipleFormsSelectedArray.indexOf(formData._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                                        style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {right: "90%"} : null}
                                        onClick={handleMultipleFormSelect}>
                                        {multipleFormsSelectedArray.indexOf(formData._id) !== -1 ?
                                            <img style={{width: '120%', height: '120%'}} alt='select'
                                                 src={multiselectImg}/> : null}
                                    </div>
                                    : null}
                                {showQuiz && multipleQuizzesSelectedArray?.length > 0 && formData.is_valid ?
                                    <div
                                        className={multipleQuizzesSelectedArray.indexOf(formData._id) !== -1 ? 'multiple-select-tag-for-delete selected-for-delete' : 'multiple-select-tag-for-delete'}
                                        style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {right: "90%"} : null}
                                        onClick={handleMultipleFormSelect}>
                                        {multipleQuizzesSelectedArray.indexOf(formData._id) !== -1 ?
                                            <img style={{width: '120%', height: '120%'}} alt='select'
                                                 src={multiselectImg}/> : null}
                                    </div>
                                    : null}
                            </>
                            : (props.CARD_TYPE === CARD_TYPE.TEMPLATE_FORM) ?
                                <div className="form-card-top-right-tag"
                                     style={isMobileOnly ? {fontSize: '12px'} : null}>
                                    <span>{formData.pages[0].questions.length ? formData.pages[0].questions.length : 0 + ''} </span>
                                    <span>{t(translationKeys.questions)}</span>
                                </div> : null}
                    </div>

                    {/***on hover layer***/}
                    {(props.CARD_TYPE === CARD_TYPE.USER_FORM && !isMobileOnly && !showMultipleDeleteOption && formData.is_valid && !isShowUnavailableFeature(formData)) ?
                        <HoverControls {...props} formData={formData}
                                       deleteNotificationHandler={deleteNotificationHandler}
                                       removeCollaborator={props.removeCollaborator}
                                       cardIndex={props.cardIndex}
                                       favouriteClicked={props.favouriteClicked}
                                       showEdit={props.showEdit}
                                       previouslyOpenedDropDownRef={props.previouslyOpenedDropDownRef}
                                       showMultipleDeleteOption={showMultipleDeleteOption}
                                       handleMultipleFormSelect={handleMultipleFormSelect}
                                       showDataEditWarning={props.showDataEditWarning}/> : null}

                    {/*** Invalid form layer ***/}
                    {(props.CARD_TYPE === CARD_TYPE.USER_FORM) && !formData.is_valid ?
                        <div className='inValid-form-layer'
                             onClick={e => {
                                 e.stopPropagation();
                                 setShowUnApprovedFormDialog(true);
                             }}>
                        </div> : null}

                    {/*** Versioning form layer ***/}
                    {(props.CARD_TYPE === CARD_TYPE.USER_FORM) && isShowUnavailableFeature(formData) ?
                        <div className={'versioning-form-layer'}
                             onClick={e => {
                                 e.stopPropagation();
                                 setShowVersioningFormDialog(true);
                             }}>
                            <div className="form-card-top-left-tag-container"
                                 style={selectedLanguage === 'fa' && isMobileOnly ? {
                                     left: 0,
                                     right: "10px"
                                 } : {background: "#1976d2", borderRadius: "50px", padding: "5px 10px"}}>
                                <img src={warningIcon} alt={"unavailable"} style={{width: "14px"}}/>
                                <span style={{
                                    paddingLeft: "2.5px",
                                    fontSize: "14px",
                                    color: "#FFFFFF"
                                }}>Unavailable</span>
                            </div>
                        </div> : null}

                    {/*** Multiple forms selection layer ***/}
                    {showMultipleDeleteOption ? <div className='multiple-select-layer'
                                                     onClick={handleMultipleFormSelect}/> : null}
                </div> : null}

            {/*** Toast message ***/}
            {showAcknowledgement ? <Message width={'250px'}
                                            message={formData.is_quiz ? t(translationKeys.invalidated_quiz_cannot_be_selected) : t(translationKeys.invalidated_form_cannot_be_selected)}
                                            close={() => setShowAcknowledgement(false)}
                                            setShowAcknowledgement={setShowAcknowledgement}/> : null}

            {/*** Unapproved form alert dialog ***/}
            {showUnApprovedFormDialog ?
                <ModalDialog
                    header={`${formData.is_quiz ? t(translationKeys.unapproved_quiz) : t(translationKeys.unapproved_form)}`}
                    body={[<span
                        style={{fontWeight: 'bold'}}>{'"' + (formData?.welcome_screen?.title ? formData.welcome_screen.title : '') + '"'}</span>,
                        <br/>, (formData.is_quiz ? t(translationKeys.unapproved_quiz_alert) : t(translationKeys.unapproved_form_alert))]}
                    buttons={
                        [
                            {
                                text: t(translationKeys.close),
                                action: () => setShowUnApprovedFormDialog(false)
                            },
                            {
                                text: t(translationKeys.provide_explanation),
                                action:
                                    () => {
                                        setShowUnApprovedFormDialog(false);
                                        const data = JSON.stringify([{
                                            qid: '5eb4e0c90be7456b687e61fd',
                                            data: formData?.welcome_screen?.title ? formData.welcome_screen.title : ''
                                        }, {
                                            qid: '5eb3d579c411ae0a298e7a0a',
                                            data: ((formData.is_quiz ? (QUIZ_BASE_URL + '/id/') : (location.origin + '/form/')) + (formData?._id ? formData._id : ''))
                                        }, {
                                            qid: '5eb3d579c411ae0a298e7a0b',
                                            data: userId
                                        }, {
                                            qid: '5ec4ec0cc9dff62eb822a839',
                                            data: getBlockedWordsText(formData.blocked_words_found)
                                        }]);
                                        const formId = formData.is_quiz ? '5f211698b770fe0f8c274122' : '5eb3d579c411ae0a298e7a08';
                                        const domainName = location.href.split('/').slice(0, 3).join('/');
                                        open(encodeURI(domainName + '/form/' + formId + '?prefilleddata=' + data + '#form/0'));
                                    }
                            }
                        ]
                    }
                /> : null}

            {/*** Versioning form alert dialog ***/}
            {showVersioningFormDialog ?
                <ModalDialog
                    header={t(translationKeys.unavailable)}
                    imageIcon={warningIcon}
                    body={formData.is_quiz ? [t(translationKeys.unavailable_quiz_features_description)] : [t(translationKeys.unavailable_features_description)]}
                    buttons={
                        [
                            {
                                text: t(translationKeys.ok),
                                action: () => setShowVersioningFormDialog(false)
                            }
                        ]
                    }
                /> : null}

        </>
    )
}

export default FormCard;

const FormCardTopLeftTagContainer = props => {
    const formData = props.formData;
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const showShared = useSelector(state => state.appDashboardReducer.showShared)
    return <div className="form-card-top-left-tag-container"
                style={(selectedLanguage === 'fa' || selectedLanguage === 'ar') && isMobileOnly ? {left: 0, right: "10px"} : null}>
        {formData.collaborators?.length > 0 && !showShared ?
            <img src={collabIcon} alt={'collab_icon'} style={{width: '25px', margin: '0 5px 0 0'}}/> : null}
        {formData.response_count && formData.is_quizzory_v2 ?
            <div className="form-card-top-left-tag"
                 style={selectedLanguage === 'ta' || selectedLanguage === 'in' ? {padding: '4px'} : null}>
                <img alt='publish'
                     style={{margin: '0 4px'}}
                     src={formData.is_publish ? '/images/cloud_success.svg' : '/images/cloud_error.svg'}
                />
                <span
                    style={{whiteSpace: "nowrap"}}>{formData.is_publish ? t(translationKeys.published) : t(translationKeys.unpublished)}</span>
            </div> : null}
        {formData.setting.is_active ? null :
            <div className="form-card-top-left-tag" style={selectedLanguage === 'ta' ? {padding: '4px'} : null}>
                <span style={{color: 'red'}}>{t(translationKeys.inactive)}</span>
            </div>}
    </div>
}
