import React, {useEffect, useRef, useState} from "react";
import {
    getSearchedTitlesFromTitlesList,
} from "../utils/search-forms";
import close from '../../../../public/images/close_circle.svg'
import search from '../../../../public/images/search_white.svg'
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {useDispatch, useSelector} from "react-redux";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {t} from "i18next";
import {useTranslation} from "react-i18next";

const DashboardSearchContainer = () => {

    const {t} = useTranslation()
    const formsList = useSelector(state => state.appDashboardReducer.formsList)
    const quizzesList = useSelector(state => state.appDashboardReducer.quizzesList)
    const sharesList = useSelector(state => state.appDashboardReducer.sharesList)
    const showShare = useSelector(state => state.appDashboardReducer.showShared)
    const isQuizzesShowing = useSelector(state => state.appDashboardReducer.showQuiz)
    const searchText = useSelector(state => state.appDashboardReducer.searchText)
    const [showSuggestionInfo, setShowSuggestionInfo] = useState(true)
    const [searchedFormTitleList, setSearchedFormTitleList] = useState()
    const [searchedText, setSearchedText] = useState(searchText)
    const Ref = useRef()
    const inputElement = useRef()
    const dispatch = useDispatch()

    useEffect(() => {
        setSearchedText(searchText)
    }, [searchText])

    const showSearchedFormList = (searchText) => {
        setSearchedFormTitleList(getSearchedTitlesFromTitlesList(searchText, isQuizzesShowing ? getTitles(quizzesList) : showShare ? getTitles(sharesList) : getTitles(formsList)), )
        setSearchedText(searchText)
    }

    const showSuggestionContainer = (boolean, formTitle) => {
        setShowSuggestionInfo(boolean)
        inputElement.current.value = formTitle;
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: formTitle})
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setShowSuggestionInfo(false)
        }
    }

        return (
            <div id={'search-container'} className="search-container">
                <div id={'search-bar'} className="search-bar" ref={Ref}>
                    <label>
                        <img className="search-icon" src={search}
                             onClick={() => {
                                 setShowSuggestionInfo(false);
                                 dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP, payload: Date.now()})
                             }}
                             alt="Search"/>
                        <input ref={inputElement} placeholder={t(translationKeys.search)}
                               onKeyDown={handleKeyDown}
                               value={searchedText}
                               onChange={(e) => {
                                   dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: e.target.value})

                                   if (e.target.value.length > 0) {
                                       setShowSuggestionInfo(true)
                                       showSearchedFormList(e.target.value);
                                       document.onmousedown = e => {
                                           if (Ref && !Ref.current.contains(e.target)) {
                                               setShowSuggestionInfo(false);
                                               document.onmousedown = null;
                                           }
                                       }
                                   } else {
                                       setShowSuggestionInfo(false);
                                       dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP, payload: Date.now()})
                                   }
                               }}/>
                    </label>
                    {inputElement.current && inputElement.current.value.length > 0 && searchText !== '' ?
                        <img alt='close' className='search-bar-close-button' src={close}
                             onClick={() => {
                                 dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
                                 dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_RESULT_TIME_STAMP, payload: Date.now()})
                                 inputElement.current.value = '';
                                 setShowSuggestionInfo(false);
                             }}/> : null}
                    <div className="search-suggestion-container" onScroll={e => e.stopPropagation()}>
                        {showSuggestionInfo && searchedFormTitleList && searchedFormTitleList.length > 0 ?
                            searchedFormTitleList.map((title, index) => <SearchSuggestionCard
                                sourceText={title}
                                key={index}
                                searchedText={searchedText}
                                updateShowSuggestion={showSuggestionContainer}
                            />)

                            : (showSuggestionInfo && Array.isArray(searchedFormTitleList) && searchedFormTitleList.length === 0) ?
                                <SearchSuggestionCard
                                    sourceText={''}
                                    key={0}
                                    searchedText={searchedText}
                                    updateShowSuggestion={showSuggestionContainer}
                                /> : null}
                    </div>
                </div>
            </div>
        )
}

export default DashboardSearchContainer

export function SearchSuggestionCard(props) {
    const searchedElementText = props.searchedText.toLowerCase();
    const sourceText = props.sourceText;
    const comparableSourceText = sourceText.toLowerCase();
    const index = comparableSourceText.indexOf(searchedElementText);
    const searchedTextLength = searchedElementText.length;
    const dispatch = useDispatch()

    let firstText, searchedText, remainingText;
    if (index !== 0 && index !== -1) {
        firstText = sourceText.substr(0, index);
        searchedText = sourceText.substr(index, searchedTextLength);
        remainingText = sourceText.substr((index + searchedTextLength), (sourceText.length - (index + searchedTextLength)));
    } else if (index === 0) {
        searchedText = sourceText.substr(0, searchedTextLength);
        remainingText = sourceText.substr(searchedTextLength, (sourceText.length - searchedTextLength));
    } else if (index === -1) {
        firstText = t(translationKeys.no_matches_found);
    }

    return (
        <div className={index === -1 ? null : 'search-suggestion-card'} style={{padding: '10px', color: '#363636'}}
             onClick={() => {
                 if (index === -1) {
                     props.updateShowSuggestion(false, sourceText);
                     dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
                 } else {
                     props.updateShowSuggestion(false, sourceText);
                     dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: sourceText})
                 }
             }}>
            <span style={index === -1 ? {color: '#bdbdbd'} : null}>{firstText ? firstText : null}</span>
            <span style={{fontWeight: 'bold'}}>{searchedText ? searchedText : null}</span>
            <span>{remainingText ? remainingText : null}</span>
        </div>
    )
}

export const getTitles = (dataList) => {
    const titlesList = [];
    for (let i = 0; i < dataList.length; i++) {
        titlesList.push(dataList[i].welcome_screen.title)
    }
    return titlesList;
}
