let LAST_SAVED_PREVIEW_PREFERENCE_KEY = "LAST_SAVED_PREVIEW_PREFERENCE_KEY";
let FEATURE_SPOTLIGHT_DATE_KEY = "FEATURE_SPOTLIGHT_DATE_KEY";

export function savePreviewPreference(previewPreference) {
    localStorage.setItem(LAST_SAVED_PREVIEW_PREFERENCE_KEY, previewPreference);
}

export function getPreviewPreference(previewPreference) {
    return localStorage?.getItem(LAST_SAVED_PREVIEW_PREFERENCE_KEY);
}

export function saveFeatureSpotlightDateKey(featureSpotlightDateKey) {
    localStorage.setItem(FEATURE_SPOTLIGHT_DATE_KEY, featureSpotlightDateKey);
}

export function getFeatureSpotlightDateKey() {
    return localStorage?.getItem(FEATURE_SPOTLIGHT_DATE_KEY);
}
