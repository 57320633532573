import optionKeywordsAndWordLists from "../../../public/json/options.json"

export const OPTIONS_ACTION_TYPE = {
    CLEAR_SELECTED_VALUE_AND_WORDLIST: 'CLEAR_SELECTED_VALUE_AND_WORDLIST',
    CLEAR_SUGGESTIONS: 'CLEAR_SUGGESTIONS',
    GET_KEYWORDS: 'GET_KEYWORDS',
    GET_WORDLIST: 'GET_WORDLIST',
    SET_CURRENT_OPTION_VALUES: 'SET_CURRENT_OPTION_VALUES',
    SET_SELECTED_OPTION_VALUE: 'SET_SELECTED_OPTION_VALUE',
    SUGGESTION_CLICKED: 'SUGGESTION_CLICKED'
}

let initialState = {
    currentOptionIndex: -1,
    currentQuestionIndex: -1,
    keyWords: optionKeywordsAndWordLists.map(o => o.keywords),
    matches: [],
    selectedValue: '',
    wordList: []
}

const optionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPTIONS_ACTION_TYPE.CLEAR_SUGGESTIONS:
            return {
                ...state,
                matches: []
            }
        case OPTIONS_ACTION_TYPE.GET_KEYWORDS:
            return {
                ...state,
                matches: state.keyWords.filter(f => f.toLowerCase().startsWith(action.payload.toLowerCase()))
            }
        case OPTIONS_ACTION_TYPE.GET_WORDLIST:
            return {
                ...state,
                wordList: optionKeywordsAndWordLists.filter(o => o.keywords === action.payload)[0] ? optionKeywordsAndWordLists.filter(o => o.keywords === action.payload)[0].wordList : []
            }
        case OPTIONS_ACTION_TYPE.SET_CURRENT_OPTION_VALUES:
            return {
                ...state,
                currentOptionIndex: action.payload.optionIndex,
                currentQuestionIndex: action.payload.questionIndex
            }
        case OPTIONS_ACTION_TYPE.CLEAR_SELECTED_VALUE_AND_WORDLIST:
            return {
                ...state,
                selectedValue: '',
                wordList: []
            }
        case OPTIONS_ACTION_TYPE.SET_SELECTED_OPTION_VALUE:
            return {
                ...state,
                selectedValue: action.payload
            }
        case OPTIONS_ACTION_TYPE.SUGGESTION_CLICKED:
            return {
                ...state,
                suggestionClicked: action.payload
            }
        default:
            return state
    }
}

export default optionsReducer