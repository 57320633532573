import React, {useEffect, useState} from "react"
import "../css/carousel-component.css"
import {isMobileOnly} from "react-device-detect";

const StoriesCarouselComponent = () => {

    const [currentIndex, setCurrentIndex] = useState(0);

    const slides = [
        {
            stars: 5,
            name: "ADOJA GODWIN",
            review: "Exceptionally fantastic App, ad-free, and the perfect researcher's companion. 5-star experience! Already shared with over 15 friends. Couldn't ask for more! 🌟🌟🌟🌟🌟"
        },
        {
            stars: 5,
            name: "MADROS TECHNOLOGIES",
            review: "SurveyHeart is an easy-to-use form app with great new features like file upload and collaboration. Thanks for making tech easier, and for resolving our complaint quickly. JAH bless you!"
        },
        {
            stars: 5,
            name: "LEONARDO CHOLA",
            review: "This is a very wonderful app. I've been using it for 2 years now for my organization to collect membership forms. It's very easy to use and to customize."
        },
        {
            stars: 5,
            name: "SAFFIE DRAMMEH",
            review: "SurveyHeart is very helpful. I love it a lot. It helps me conduct my surveys, and it's very simple to use. I highly recommend it 100%."
        },
        {
            stars: 5,
            name: "TATIANA VALENCIA",
            review: "It is the best app to carry out your surveys and work without fear of losing data. 100% recommended!"
        },
        {
            stars: 5,
            name: "OFORI RICHARD OSEI",
            review: "I'm enjoying the betterment of it, It's really good!"
        },
        {
            stars: 5,
            name: "ASAD CHOUDHARY",
            review: "This is a good Application to invite people for our business and get reviews"
        },
        {
            stars: 5,
            name: "PROF RAMESH DAVADA",
            review: "Very happy to inform you that this application is very useful for inquiries and surveys."
        },
        {
            stars: 5,
            name: "SHANKAR DAMOR",
            review: "I found this app to be honest and trustworthy."
        },
        {
            stars: 5,
            name: "ALI AL NAHI",
            review: "The program is something imaginative that saves you from Google Forms, its language, and its tediousness."
        },
        {
            stars: 5,
            name: "BALA R",
            review: "Good app—very useful for my studies and my friends' studies. Thank you, sir!"
        },
        {
            stars: 5,
            name: "ALEENA SHAH",
            review: "Amazing app very helpful for those person who wants to advertise their business, Something else!! Just like woow!!"
        },
    ]

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentIndex((prevIndex) => (prevIndex === slides.length - (isMobileOnly ? 1 : 3) ? 0 : prevIndex + (isMobileOnly ? 1 : 3)));
        }, 5000)
        return () => clearTimeout(timer)
    }, [currentIndex])

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex === 0 ? slides.length - (isMobileOnly ? 1 : 3) : prevIndex - (isMobileOnly ? 1 : 3)));
    };

    const goToNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex === slides.length - (isMobileOnly ? 1 : 3) ? 0 : prevIndex + (isMobileOnly ? 1 : 3)));
    };

    return (
        <div className={"carousel"} style={{width: "95%", margin: isMobileOnly ? null : "50px auto"}}>
            <button onClick={goToPrevious} className={"carousel-button"} style={{left: 0}}>
                &#10094;
            </button>
            <div className="carousel-content"
                 style={{
                     transform: `translateX(${(isMobileOnly ? -100.1 : -99.75) * currentIndex}%)`,
                     transition: currentIndex ? "transform 0.5s ease-in-out" : null
                 }}>
                {slides.map((slide, index) => {
                    if (isMobileOnly) {
                        return (
                            <div key={index}
                                 style={{minWidth: "90%", height: "400px", background: "#F5F5F5", margin: "20px"}}
                                 className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}>
                                <div
                                    style={{textAlign: "center", alignSelf: "end", margin: "auto 30px", width: "100%"}}>
                                    <img style={{width: "70px"}} src={"../../images/user.svg"} alt={"user"}/>
                                    <h2 style={{margin: "5px 0", fontSize: "30px"}}>{slide.name}</h2>
                                    <img
                                        src={`../../../images/Star_${slide.stars}.svg`}
                                        alt="Star Rating" className="star-line-img"
                                        style={{width: '300px'}}/>
                                    <h3 style={{
                                        fontSize: "18px",
                                        fontWeight: "normal",
                                        margin: "0",
                                        paddingTop: "0"
                                    }}>{slide.review}</h3>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <>
                                <div key={index}
                                     style={{minWidth: "30.5%", height: "450px", background: "#F5F5F5", margin: "20px"}}
                                     className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            alignSelf: "end",
                                            margin: "auto 30px",
                                            width: "100%"
                                        }}>
                                        <img style={{width: "70px"}} src={"../../images/user.svg"} alt={"user"}/>
                                        <h2 style={{margin: "5px 0", fontSize: "30px"}}>{slide.name}</h2>
                                        <img
                                            src={`../../../images/Star_${slide.stars}.svg`}
                                            alt="Star Rating" className="star-line-img"
                                            style={{width: '300px'}}/>
                                        <h3 style={{
                                            fontSize: "20px",
                                            fontWeight: "normal",
                                            margin: "0"
                                        }}>{slide.review}</h3>
                                    </div>
                                </div>
                                <div key={index + 1}
                                     style={{minWidth: "30.5%", height: "450px", background: "#F5F5F5", margin: "20px"}}
                                     className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            alignSelf: "end",
                                            margin: "auto 30px",
                                            width: "100%"
                                        }}>
                                        <img style={{width: "70px"}} src={"../../images/user.svg"} alt={"user"}/>
                                        <h2 style={{
                                            margin: "5px 0",
                                            fontSize: "30px"
                                        }}>{slides[index + 1 < slides.length ? index + 1 : 0].name}</h2>
                                        <img
                                            src={`../../../images/Star_${slides[index + 1 < slides.length ? index + 1 : 0].stars}.svg`}
                                            alt="Star Rating" className="star-line-img"
                                            style={{width: '300px'}}/>
                                        <h3 style={{
                                            fontSize: "20px",
                                            fontWeight: "normal",
                                            margin: "0"
                                        }}>{slides[index + 1 < slides.length ? index + 1 : 0].review}</h3>
                                    </div>
                                </div>
                                <div key={index + 2}
                                     style={{minWidth: "30.5%", height: "450px", background: "#F5F5F5", margin: "20px"}}
                                     className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            alignSelf: "end",
                                            margin: "auto 30px",
                                            width: "100%"
                                        }}>
                                        <img style={{width: "70px"}} src={"../../images/user.svg"} alt={"user"}/>
                                        <h2 style={{
                                            margin: "5px 0",
                                            fontSize: "30px"
                                        }}>{slides[index + 2 < slides.length ? index + 2 : 1].name}</h2>
                                        <img
                                            src={`../../../images/Star_${slides[index + 2 < slides.length ? index + 2 : 1].stars}.svg`}
                                            alt="Star Rating" className="star-line-img"
                                            style={{width: '300px'}}/>
                                        <h3 style={{
                                            fontSize: "20px",
                                            fontWeight: "normal",
                                            margin: "0"
                                        }}>{slides[index + 2 < slides.length ? index + 2 : 1].review}</h3>
                                    </div>
                                </div>
                            </>
                        )
                    }
                })}
            </div>
            <button onClick={goToNext} className={"carousel-button"} style={{right: 0}}>
                &#10095;
            </button>
        </div>
    )
}

export default StoriesCarouselComponent
