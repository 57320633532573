export const initializeSignUpErrorState = () => {
    return {
        isPasswordMismatch: false,
        isPasswordPatternIssue: false,
        isEmptyData: false,
        inValidEmailFormat: false,
        isExistingUser: false
    }
};

export const initializeSignInErrorState = () => {
    return {
        isEmptyData: false,
        inValidEmailFormat: false,
        wrongPasswordIssue: false,
        isExistingUser: true,
        isGoogleUserIssue: false,
        isPasswordNotPresentIssue: false,
        isPasswordWrongIssue: false,
        isEmailVerified: false
    }
};

export const LOGIN_METHOD = {
    web_password: 'web.password',
    web_google: 'web.google'
}

export const LOGIN_CONTAINER_SECTION = {
    SIGN_IN: 'signIn',
    SIGN_UP: 'signUp',
    EMAIL_VERIFICATION_INFO: 'emailVerification',
    FORGET_PASSWORD_INFO: 'forgetPassword',
    CREATE_PASSWORD_INFO: 'createPassword'
}

export const COLLABORATOR_ACTION_TYPE = {
    ADD_COLLABORATOR: 'add_collaborator',
    REMOVE_COLLABORATOR: 'remove_collaborator',
    UPDATE_COLLABORATOR_ROLE: 'update_collaborator_role',
    UPDATE_COLLABORATOR_DATA: 'update_collaborator_data',
}

export const UPDATE_FORM_DATA = 'updated_form_data'


export const NOTIFICATION_ACTION_TYPE = {
    UPDATE_NOTIFICATION_STATUS: 'update_notification_status',
    REMOVE_NOTIFICATION: 'remove_notification',
    UPDATE_NOTIFICATION_DATA: 'update_notification_data',
    UPDATE_NOTIFICATION_COUNT: 'update_notification_count'
}

export const APP_VERSION = "App Version - v2.0.2"

export const NOTIFICATIONS_TYPES = {
    COLLAB_ADDED: "collab_added",
    COLLAB_ROLE_CHANGE: "collab_role_changed",
    COLLAB_REMOVED: 'collab_removed'
}