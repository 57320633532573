const initialState = {
    userId: null,
    subscriptionData: null
}

export const USER_ACTION_TYPE = {
    SET_USER_ID: 'SET_USER_ID',
    SET_SUBSCRIPTION_DATA: 'SET_SUBSCRIPTION_DATA'
}

const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case (USER_ACTION_TYPE.SET_USER_ID):
            return {
                ...state,
                userId: action.payload
            }
        case (USER_ACTION_TYPE.SET_SUBSCRIPTION_DATA):
            return {
                ...state,
                subscriptionData: action.payload
            }
        default:
            return state
    }
}

export default userReducer
