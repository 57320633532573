import React from 'react'
import UploadImageContainer from "../../app/builder/components/UploadImageContainer";

export default function PopUpContainer(props) {
    return (
        <div id={'popup-container'}>
            <div style = {{
                backgroundColor: props.backgroundColor ? props.backgroundColor : props.showSummary ? 'white' : 'rgba(0, 0, 0, 0.3)',
                height: '100%',
                width: '100%',
                zIndex: props.zIndex ? props.zIndex : '7',
                top: '0',
                left: '0',
                position: 'fixed',
                display: props.showSummary ? 'initial' : 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0',
                overflow: props.showSummary ? 'auto' : null,
                padding: '0'
            }}
            className={'popup-container'}>
                {
                    (() => {
                            if (props.isImageUploader) return (<UploadImageContainer fileUpload={true} {...props}/>)
                            if (props.children) return props.children
                        }
                    )()
                }
            </div>
        </div>
    )
}