import MenuItem from "./MenuItem"

/**
 * @param {MenuIconInfo} menuIconInfo - Instance of the MenuIconInfo class
 * @param {string} newLocation - The target URL
 */
class NavigationMenuItem extends MenuItem {
    constructor(key, title, menuIconInfo, newLocation) {
        super(key, title, menuIconInfo)
        this.NewLocation = newLocation
    }

    getNewLocation() {
        return this.NewLocation
    }
}

export default NavigationMenuItem