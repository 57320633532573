import React, {useEffect, useRef, useState} from "react"
import "../css/mail-support-popup.css"
import {isMobileOnly} from "react-device-detect";
import {MDCSelect} from "@material/select/index";
import close from "../../../../public/images/close_white.svg";
import backArrow from "../../../../public/images/back_white.svg";
import closeBlack from "../../../../public/images/close_black.svg";
import {useDispatch, useSelector} from "react-redux";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import attach from "../../../../public/images/icons/builder/attach_file_icon.svg"
import submitTick from "../../../../public/images/icons/builder/submit_white_tick.svg"
import MaterialTextField, {TEXT_FIELD_TYPES} from "../../../shared/components/MaterialTextField";
import {getPlanBasedOnRank, PremiumJSON} from "../../../shared/utils/helper";
import MaterialSelectOptionsContainer from "../../../shared/components/MaterialSelectOptionsContainer";
import MaterialButton, {ButtonTypes} from "../../../shared/components/MaterialButton";
import countryCodes from "../../../shared/utils/country-codes.json"
import MaterialTextFieldOutlinedContainer from "../../../admin/containers/MaterialTextFieldOutlinedContainer";
import SelectedFileComponent from "../../../form/components/SelectedFileComponent";
import {createZohoDeskTicket} from "../../../shared/utils/sash-v2-api";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {Pages} from "../../utils/pages";

const MailSupportPopUp = () => {
    const [message, setMessage] = useState("")
    const [category, setCategory] = useState("Forms")
    const [phoneNumber, setPhoneNumber] = useState(null)
    const [selectedFile, setSelectedFile] = useState(null)
    const [showFileSizeWarning, setShowFileSizeWarning] = useState(false)
    const [filteredCountryCodes, setFilteredCountryCodes] = useState(countryCodes)
    const [showCodePopUp, setShowCodePopUp] = useState(false)
    const [selectedCountryCode, setSelectedCountryCode] = useState({
        name: "India",
        dial_code: "+91",
        emoji: "🇮🇳",
        code: "IN"
    })
    const userId = useSelector(state => state.userReducer?.userId)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showCallSupportPopUp = useSelector(state => state.appDashboardReducer.showCallSupportPopUp)
    const dispatch = useDispatch()
    const fileInput = useRef()

    const handleMessageChange = (value) => {
        setMessage(value)
    }

    const handlePhoneChange = (value) => {
        setPhoneNumber(value)
    }

    const handleCloseClick = () => {
        if (isMobileOnly) {
            dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
        }
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MAIL_SUPPORT_POPUP, payload: false})
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_CALL_SUPPORT_POPUP, payload: false})
    }

    const handleCategorySelection = (value) => {
        setCategory(value)
    }

    const handleCodeClick = () => {
        setShowCodePopUp(true)
    }

    const handleAttachmentClick = () => {
        fileInput.current.click();
    }

    const handleSubmitClick = () => {
        const successFunction = (res) => {
            if (res?.result) {
                if (showCallSupportPopUp) {
                    dispatch({type: APP_ACTION_TYPE.SET_ZOHO_CALL_TICKET, payload: res?.ticket_data})
                } else {
                    dispatch({type: APP_ACTION_TYPE.SET_ZOHO_MAIL_TICKET, payload: res?.ticket_data})
                }
                if (isMobileOnly) {
                    dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.dashboard})
                }
                dispatch({
                    type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SUBMITTED_ZOHO_TICKET,
                    payload: res?.ticket_data?.ticketNumber
                })
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_CALL_SUPPORT_POPUP, payload: false})
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MAIL_SUPPORT_POPUP, payload: false})
            }
        }
        const failureFunction = (err) => {
            console.log(err)
        }
        const subject = category + " " + getPlanBasedOnRank(PremiumJSON[subscriptionData.subscription_plan].rank).split(" ")[0] + " " + "WEBAPP"
        if ((showCallSupportPopUp && selectedFile && validatePhoneNumber(phoneNumber) && message.length) || (!showCallSupportPopUp && message.length && selectedFile)) {
            createZohoDeskTicket(subject, showCallSupportPopUp, message, category, selectedFile, phoneNumber, successFunction, failureFunction)
        } else {
            window.alert("All fields are required")
        }
    }

    const handleSearchChange = (value) => {
        setFilteredCountryCodes(countryCodes.filter((code) => code.name.toLowerCase().includes(value)))
    }

    const validatePhoneNumber = (value) => {
        const checkNumber = /(?:[-+() ]*\d){10,13}/g;
        return value?.match(checkNumber)
    }

    return (
        <div className={"mail-support-popup"}>
            <div className={"mail-support"}>
                <div className={"pop-up-header"} style={{justifyContent: isMobileOnly ? "initial" : null}}>
                    {isMobileOnly ? <img className={'localization-close-img'} style={{width: "20px"}}
                                         src={backArrow} alt={"back"} onClick={handleCloseClick}/> : null}
                    <span>SurveyHeart {showCallSupportPopUp ? "Call" : "Mail"} Support</span>
                    {isMobileOnly ? null : <img className={'localization-close-img'}
                                                src={close} alt={"close"} onClick={handleCloseClick}/>}
                </div>
                <div className={"mail-support-body"}>
                    <MaterialTextField
                        data={{
                            hint: "Subscription",
                            inputType: 'text',
                            defaultValue: getPlanBasedOnRank(PremiumJSON[subscriptionData.subscription_plan].rank).split(" ")[0],
                            isReadOnly: true
                        }}
                        textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                        customStyleObject={{width: '100%', marginTop: '25px'}}
                    />
                    <MaterialTextField
                        data={{
                            hint: "Email ID",
                            inputType: 'text',
                            defaultValue: userId,
                            isReadOnly: true
                        }}
                        textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                        customStyleObject={{width: '100%', marginTop: '25px'}}
                    />
                    {showCallSupportPopUp ? <div className={"phone-number"}>
                        <div style={{width: "35%", margin: "0 5px"}} onClick={handleCodeClick}>
                            <MaterialTextField
                                data={{
                                    hint: "Code",
                                    inputType: 'drop-down',
                                    defaultValue: `${selectedCountryCode.emoji + " " + selectedCountryCode.dial_code} ▼`,
                                    isReadOnly: true
                                }}
                                textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                                customStyleObject={{marginTop: '25px'}}
                            />
                        </div>
                        <div style={{width: "100%"}}>
                            <MaterialTextFieldOutlinedContainer placeHolder={'Phone Number'}
                                                                onChange={handlePhoneChange}
                                                                validationFunction={validatePhoneNumber}
                                                                errorText={'Invalid Phone Number'}
                                                                autoFocus={true}/>
                        </div>
                    </div> : null}
                    <div className={"can-help"}>
                        <MaterialTextFieldOutlinedContainer placeHolder={"How can we help?"} maxLength={500}
                                                            onChange={handleMessageChange}
                                                            validationFunction={(value) => value?.length > 0}
                                                            errorText={'Message is Required'}
                                                            customStyleObject={{
                                                                height: isMobileOnly ? "150px" : "80px"
                                                            }}
                                                            autoFocus={true}
                                                            textArea={true}
                        />
                    </div>
                    <div>
                        <span>Choose the category of this issue</span>
                    </div>
                    <div>
                        <DropDown
                            selectedValues={["Forms", "Quiz", "Form Responses", "Quiz Answers", "Payment", "Billing", "Account", "Storage", "Others"]}
                            handleSelection={handleCategorySelection}/>
                    </div>
                    <div>
                        <p style={{marginBottom: 0}}>
                            Attach a screenshot or other attachments to help troubleshoot your issue
                        </p>
                    </div>
                    <input id={"choose-file-input"} type={"file"}
                           style={{display: 'none'}}
                           ref={fileInput}
                           onChange={e => {
                               const file = e.target.files[0];
                               const fileSizeLimit = 20 * 1024 * 1024;

                               if (file && file.size <= fileSizeLimit) {
                                   setShowFileSizeWarning(false)
                                   setSelectedFile(file);
                               } else {
                                   setShowFileSizeWarning(true)
                               }
                           }}/>
                    {selectedFile === null ?
                        <div>
                            <MaterialButton handleClick={handleAttachmentClick}
                                            data={{
                                                title: "Attachment",
                                                buttonType: ButtonTypes.OUTLINED,
                                                base64ImageData: attach,
                                                customButtonContainerStyle: {
                                                    height: "40px",
                                                    margin: "12px",
                                                    borderRadius: "50px"
                                                }
                                            }}/>
                            <br/>
                            {showFileSizeWarning ? <span style={{fontSize: "14px", color: "red",}}>
                                * Maximum allowed file upload size is 20MB
                            </span> : null}
                        </div> :
                        <SelectedFileComponent text={selectedFile.name} size={selectedFile.size}
                                               showClose={true}
                                               clickHandler={() => {
                                                   setSelectedFile(null)
                                               }}/>
                    }
                    <div className={"note"}>
                        <span>Note: </span>
                        <p>Support is available only in English.<br/>We will respond you within 4 hrs.</p>
                    </div>
                    <div className={"submit-btn"}>
                        <MaterialButton handleClick={handleSubmitClick}
                                        data={{
                                            title: "SUBMIT",
                                            buttonType: ButtonTypes.CONTAINED,
                                            base64ImageData: submitTick,
                                            customButtonContainerStyle: {
                                                height: "40px",
                                                margin: "12px",
                                                borderRadius: "50px"
                                            }
                                        }}/>
                    </div>
                </div>
            </div>
            {showCodePopUp ? <div className={"mail-support-popup"}>
                <div className={"country-code"}>
                    <div className={"pop-up-header"} style={{backgroundColor: "#FFFFFF"}}>
                        <span style={{color: "#000000"}}>Select Country</span>
                        <img className={'localization-close-img'}
                             src={closeBlack} alt={"close"} onClick={() => setShowCodePopUp(false)}/>
                    </div>
                    <div className={"search"}>
                        <MaterialTextField
                            data={{
                                hint: "Search",
                                inputType: 'search',
                            }}
                            onChange={handleSearchChange}
                            textFieldType={TEXT_FIELD_TYPES.OUTLINED}
                            customStyleObject={{width: '100%'}}
                        />
                    </div>
                    <div className={"codes"}>
                        {filteredCountryCodes.map((code) => {
                            return (
                                <div className={"code-row"} onClick={() => {
                                    setSelectedCountryCode(code)
                                    setShowCodePopUp(false)
                                }}>
                                    <p><span>{code.emoji}</span> {code.name} ({code.dial_code})</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div> : null}
        </div>
    )
}

const DropDown = (props) => {

    const mdcSelectMenuRef = useRef(null);
    useEffect(() => {
        const select = new MDCSelect(mdcSelectMenuRef.current);
        if (props.initialValueToSelect !== undefined) {
            select.value = props.initialValueToSelect
        }
    }, [])

    const selectorStyle = {
        '--mdc-theme-primary': '#212121',
        fontSize: '14px',
        width: '100%'
    }

    const selectTextStyle = {
        fontSize: '18px'
    }

    const selectValues = props.selectedValues

    return (
        <div className={'mdc-select mdc-select--outlined'}
             style={{color: '#212121', fontSize: '21px', background: 'transparent', width: "-webkit-fill-available"}}
             ref={mdcSelectMenuRef}>
            <div className="mdc-select__anchor"
                 aria-labelledby="outlined-select-label"
                 style={{
                     width: selectorStyle.width ? selectorStyle.width : null,
                     background: '#F5F5F5',
                     borderRadius: '5px',
                     height: '40px',
                     marginRight: '5px',
                 }}>
                <span className="mdc-select__selected-text-container">
                    <span className="mdc-select__selected-text" style={selectTextStyle}/>
                </span>
                <span className="mdc-select__dropdown-icon" style={{margin: isMobileOnly ? '0' : null}}>
                   <svg
                       className="mdc-select__dropdown-icon-graphic"
                       viewBox="7 10 10 5" focusable="false">
                     <polygon
                         className="mdc-select__dropdown-icon-inactive"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 10 12 15 17 10">
                     </polygon>
                     <polygon
                         className="mdc-select__dropdown-icon-active"
                         stroke="none"
                         fillRule="evenodd"
                         points="7 15 12 10 17 15">
                     </polygon>
                   </svg>
                </span>
            </div>
            <MaterialSelectOptionsContainer selectValues={selectValues}
                                            selectTextStyle={selectTextStyle}
                                            handleSelection={props.handleSelection}
                                            initialValueToSelect={"Forms"}/>
        </div>
    )
}

export default MailSupportPopUp
