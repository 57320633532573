import React, {useEffect, useRef, useState} from 'react'
import '../css/dashboard.css'
import DashBoardHeader from "./DashboardHeader";
import LeftNavigator from "../../../shared/components/LeftNavigator";
import ContentContainer from "./ContentContainer";
import ChangeContainerMenuItem from "../../../shared/classes/ChangeContainerMenuItem";
import MenuIconInfo from "../../../shared/classes/MenuIconInfo";
import PopupMenuItem from "../../../shared/classes/PopupMenuItem";
import {PAGE_HASH_LOCATION, Pages} from "../../utils/pages";
import PremiumIcon from "../../../../public/images/premium_crown.svg"
import CreateNewBanner from "./CreateNewBannerMobile";
import {isMobileOnly} from "react-device-detect";
import BottomBar from "../../../shared/components/BottomBar";
import {BlockedUI} from "./BlockedUI";
import createBlankForm from "../../../../public/images/create_blank_form.svg"
import inProgressIcon from "../../../../public/images/in-progress.svg"
import {registerGAEvent} from "../../../shared/utils/analytics";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";
import {APP_DASHBOARD_ACTION_TYPE} from "../../reducers/appDashboardReducer";
import {TEMPLATE_ACTION_TYPE} from "../../reducers/templateReducer";
import StorageManagementPopUp from "./StorageManagementPopUp";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import PremiumPlansPopUp from "./PremiumPlansPopUp";
import DashboardLimitReachedUI from "./DashboardLimitReachedUI";
import {PremiumJSON} from "../../../shared/utils/helper";
import DasboardHoldPaymentUI from "./DasboardHoldPaymentUI";
import MailSupportPopUp from "./MailSupportPopUp";
import ZohoAlreadySubmittedPopUp from "./ZohoAlreadySubmittedPopUp";
import {STORAGE_MANAGEMENT_ACTION_TYPE} from "../../reducers/storageManagementReducer";
import {PREMIUM_ACTION_TYPE} from "../../reducers/premiumReducer";
import {FEATURE_SPOTLIGHT_ACTION_TYPE} from "../../reducers/featureSpotlightReducer";

export const templateTypes = {
    FORM: t(translationKeys.form),
    QUIZ: t(translationKeys.quiz)
}

const DashBoardContainer = (props) => {

    const awsThemes = useSelector(state => state.appReducer?.awsThemes)
    const formImages = useSelector(state => state.appReducer?.formImages)
    const showQuiz = useSelector(state => state.appDashboardReducer.showQuiz)
    const showMore = useSelector(state => state.appDashboardReducer.showMore)
    const showForms = useSelector(state => state.appDashboardReducer.showForms)
    const zohoMailTicket = useSelector(state => state.appReducer.zohoMailTicket)
    const zohoCallTicket = useSelector(state => state.appReducer.zohoCallTicket)
    const showShare = useSelector(state => state.appDashboardReducer.showShared)
    const totalStorageUsed = useSelector(state => state.appReducer.totalStorageUsed)
    const templateType = useSelector(state => state.appDashboardReducer.templateType)
    const subscriptionData = useSelector(state => state.userReducer?.subscriptionData)
    const showTemplates = useSelector(state => state.appDashboardReducer.showTemplates)
    const showMorePopUp = useSelector(state => state.appDashboardReducer.showMorePopUp)
    const isBlockedUser = useSelector(state => state.appDashboardReducer.isBlockedUser)
    const totalUserResponseCount = useSelector(state => state.appReducer.totalUserResponseCount)
    const showMailSupportPopUp = useSelector(state => state.appDashboardReducer.showMailSupportPopUp)
    const showCallSupportPopUp = useSelector(state => state.appDashboardReducer.showCallSupportPopUp)
    const isShowingMobileFormControl = useSelector(state => state.appDashboardReducer.isShowingMobileFormControl)
    const showStorageManagementPopUp = useSelector(state => state.storageManagementReducer.showStorageManagementPopUp)
    const showAlreadySubmittedZohoTicket = useSelector(state => state.appDashboardReducer.showAlreadySubmittedZohoTicket)
    const showSubmittedZohoTicket = useSelector(state => state.appDashboardReducer.showSubmittedZohoTicket)
    const showPremiumPlansPopUp = useSelector(state => state.premiumReducer.showPremiumPlansPopUp)
    const [showCreateNewPopUp, setShowCreateNewPopUp] = useState(false);
    const [screenHeight, setScreenHeight] = useState(window.innerHeight)
    const previouslyOpenedDropDownRef = useRef(null);
    const bodyContainerElement = useRef(null);

    const dispatch = useDispatch()

    useEffect(() => {
        if (showForms) {
            dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})
        }
        dispatch({type: BUILDER_ACTION_TYPE.SET_IS_MANUALLY_CHANGED_FORM_USER_INFO, payload: false})
    }, [])

    const showPage = (pageName, quiz) => {
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SEARCH_TEXT, payload: ''})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: quiz})
        let pageNameToCheck;
        if (pageName instanceof ChangeContainerMenuItem) {
            pageNameToCheck = pageName.getTitle();
        } else {
            pageNameToCheck = pageName
        }
        switch (pageNameToCheck) {
            case t(translationKeys.forms):
                registerGAEvent('Dashboard', 'Forms', 'view');
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})
                break;
            case t(translationKeys.quizzes):
                registerGAEvent('Dashboard', 'Quizzes', 'view');
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_QUIZ})
                break;
            case t(translationKeys.shared):
                registerGAEvent('Dashboard', 'Shared', 'view');
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHARED})
                break;
            case t(translationKeys.templates):
                registerGAEvent('Dashboard', 'Templates', 'view');
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_TEMPLATES})
                dispatch({type: TEMPLATE_ACTION_TYPE.SET_IS_SHOWING_FORMS, payload: true})
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.FORM})
                break;
            case t(translationKeys.more):
                registerGAEvent('Dashboard', 'Templates', 'more');
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MORE})
                break;
        }
    }

    const popUpStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        height: '100%',
        width: '100%',
        zIndex: '10',
        top: '0',
        left: '0',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0',
        padding: '0'
    };
    location.hash = PAGE_HASH_LOCATION.dashboard;
    if (isMobileOnly) {
        document.body.onresize = () => {
            if (window.innerHeight < 400) {
                //for not shrink the height while keyboard opened.
                setScreenHeight(document.documentElement.clientHeight)
            } else {
                //to handle the chrome url tab shown/hidden screen adjustment in Mobile view.
                setScreenHeight(window.innerHeight)
            }
        }
    }
    let keyCtr = 0;
    const AppMenuItem = new ChangeContainerMenuItem(keyCtr++, "", false, new MenuIconInfo("survey-heart-icon", "surveyheart"));
    const FormsMenuItem = new ChangeContainerMenuItem(keyCtr++, t(translationKeys.forms), showForms, new MenuIconInfo("Forms", "summary"));
    const QuizzesMenuItem = new ChangeContainerMenuItem(keyCtr++, t(translationKeys.quizzes), showQuiz, new MenuIconInfo("Quizzes", "quiz"));
    const SharedMenuItem = new ChangeContainerMenuItem(keyCtr++, t(translationKeys.shared), showShare, new MenuIconInfo("Shared", "peoples"));
    const TemplateMenuItem = new ChangeContainerMenuItem(keyCtr++, t(translationKeys.templates), showTemplates, new MenuIconInfo("Templates", "template"));
    const MoreMobileMenuItem = new ChangeContainerMenuItem(keyCtr++, t(translationKeys.more), showMore, new MenuIconInfo("More", "more"));

    const MoreMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.more), new MenuIconInfo("More", showMorePopUp ? "drop_down_arrow_grey.svg" : "arrow_right.svg"));
    const ManageStorageMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.manage_storage), new MenuIconInfo("Storage", "storage.svg"));
    const EmailSupportMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.email_support), new MenuIconInfo("Email", "mail_support.svg"), null, zohoMailTicket ? inProgressIcon : PremiumJSON[subscriptionData?.subscription_plan]?.email_support_rank ? null : PremiumIcon);
    const CallSupportMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.call_support), new MenuIconInfo("Call", "call_support.svg"), null, zohoCallTicket ? inProgressIcon : PremiumJSON[subscriptionData?.subscription_plan]?.on_call_support ? null : PremiumIcon);
    const FeatureSpotlightMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.feature_spotlight), new MenuIconInfo("Spotlight", "feature_spotlight_grey.svg"));
    const KeyboardShortCutsMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.keyboard_shortcuts), new MenuIconInfo("Shortcuts", "keyboard.svg"));
    const FeedbackMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.feedback), new MenuIconInfo("Feedback", "feedback_grey.svg"));
    const PrivacyMenuItem = new PopupMenuItem(keyCtr++, t(translationKeys.privacy_policy), new MenuIconInfo("Privacy", "policy_grey.svg"));
    let dashboardPageMenuItems = [AppMenuItem, FormsMenuItem, QuizzesMenuItem, SharedMenuItem, TemplateMenuItem, MoreMenuItem];

    if (showMorePopUp) {
        dashboardPageMenuItems = [AppMenuItem, FormsMenuItem, QuizzesMenuItem, SharedMenuItem, TemplateMenuItem, MoreMenuItem, ManageStorageMenuItem, EmailSupportMenuItem, CallSupportMenuItem, FeatureSpotlightMenuItem, KeyboardShortCutsMenuItem, FeedbackMenuItem, PrivacyMenuItem];
    }

    const doPopupAction = (menuItem) => {
        switch (menuItem.Key) {
            case (6):
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MORE_POPUP, payload: !showMorePopUp})
                break
            case (7):
                dispatch({type: STORAGE_MANAGEMENT_ACTION_TYPE.SET_SHOW_STORAGE_MANAGEMENT_POP_UP, payload: true})
                break
            case (8):
                if (PremiumJSON[subscriptionData?.subscription_plan]?.email_support_rank) {
                    if (zohoMailTicket) {
                        dispatch({
                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET,
                            payload: zohoMailTicket.ticketNumber
                        })
                    } else {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_MAIL_SUPPORT_POPUP, payload: true})
                    }
                } else {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
                break
            case (9):
                if (PremiumJSON[subscriptionData?.subscription_plan]?.on_call_support) {
                    if (zohoCallTicket) {
                        dispatch({
                            type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET,
                            payload: zohoCallTicket.ticketNumber
                        })
                    } else {
                        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_CALL_SUPPORT_POPUP, payload: true})
                    }
                } else {
                    dispatch({type: PREMIUM_ACTION_TYPE.SET_SHOW_UPGRADE_PLAN_POP_UP, payload: true})
                }
                break
            case (10):
                dispatch({type: FEATURE_SPOTLIGHT_ACTION_TYPE.TOGGLE_VISIBILITY})
                break
            case (11):
                dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SHORTCUTS, payload: true})
                break
            case (12):
                window.open('/form/5e2ad7279ddceb28451312d4')
                break
            case (13):
                window.open('/privacypolicy')
                break
        }
    }

    const handleFormsCardClick = (e) => {
        dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.NEW})
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_FORMS})
        dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: null})
        dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: false})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: false})
        setShowCreateNewPopUp(false)
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_TEMPLATES})
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.FORM})
        e.stopPropagation()
    }

    const handleQuizzesCardClick = (e) => {
        dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.NEW})
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_QUIZ})
        dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: null})
        dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: false})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: true})
        setShowCreateNewPopUp(false)
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_TEMPLATES})
        dispatch({type: APP_DASHBOARD_ACTION_TYPE.SET_TEMPLATE_TYPE, payload: templateTypes.QUIZ})
        e.stopPropagation()
    }

    const isLimitReachedToEighty = () => {
        const themesPercentage = (awsThemes?.length / PremiumJSON[subscriptionData?.subscription_plan]?.theme_attachments) * 100
        const imagesPercentage = (formImages?.length / PremiumJSON[subscriptionData?.subscription_plan]?.question_attachments) * 100
        const responsesPercentage = (totalUserResponseCount / PremiumJSON[subscriptionData?.subscription_plan]?.response_limit.access) * 100
        const storagePercentage = (totalStorageUsed / PremiumJSON[subscriptionData?.subscription_plan]?.storage) * 100
        return themesPercentage >= 80 || imagesPercentage >= 80 || responsesPercentage >= 80 || storagePercentage >= 80;
    }

    return (
        navigator.onLine ?
            !isBlockedUser ?
                <div id='dashboard_container' className='app-container'
                     style={isMobileOnly && screenHeight ? {height: screenHeight} : null}>
                    {isMobileOnly ? null :
                        <LeftNavigator LeftNavigatorMenuItems={dashboardPageMenuItems}
                                       updateContainer={showPage}
                                       doPopupAction={doPopupAction}
                        />
                    }
                    {showStorageManagementPopUp && !isMobileOnly ? <StorageManagementPopUp/> : null}
                    {showPremiumPlansPopUp && !isMobileOnly ? <PremiumPlansPopUp/> : null}
                    {showMailSupportPopUp || showCallSupportPopUp ? <MailSupportPopUp/> : null}
                    {showAlreadySubmittedZohoTicket ? <ZohoAlreadySubmittedPopUp success={false}
                                                                                 title={"Already Submitted!"}
                                                                                 description={`The ticket number for your support request is ${showAlreadySubmittedZohoTicket}. We appreciate your patience while we process your request. `}
                                                                                 handlePopUpClick={() => {
                                                                                     dispatch({
                                                                                         type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_ALREADY_SUBMITTED_ZOHO_TICKET,
                                                                                         payload: false
                                                                                     })
                                                                                 }}/> : null}
                    {showSubmittedZohoTicket ? <ZohoAlreadySubmittedPopUp success={true}
                                                                          title={"Submitted Successfully!"}
                                                                          description={`Thanks for contacting the SurveyHeart Support team. The ticket number for your support request is ${showSubmittedZohoTicket}.  We appreciate your patience while we process your request `}
                                                                          handlePopUpClick={() => {
                                                                              dispatch({
                                                                                  type: APP_DASHBOARD_ACTION_TYPE.SET_SHOW_SUBMITTED_ZOHO_TICKET,
                                                                                  payload: false
                                                                              })
                                                                          }}/> : null}
                    <div id='dashboard_body_container'
                         className={isMobileOnly ? 'body-container-mobile' : 'body-container'}
                         ref={bodyContainerElement}>
                        {!isMobileOnly ?
                            <div style={{backgroundColor: '#1976d2', width: '100%', height: '33%'}}/> : null}
                        <div className='dashboard-body-container'
                             style={(isMobileOnly && (showForms || showQuiz || showShare || showTemplates || showMore)) ?
                                 isShowingMobileFormControl ? {height: '100%'} : {margin: showTemplates && isMobileOnly ? '0' : '50px 0 48px 0'}
                                 : null}>
                            {!isShowingMobileFormControl ?
                                <DashBoardHeader previouslyOpenedDropDownRef={previouslyOpenedDropDownRef}/> : null}
                            {isLimitReachedToEighty() && subscriptionData?.subscription_plan !== "premium-on-hold" && (showForms || showQuiz) && !isShowingMobileFormControl ?
                                <DashboardLimitReachedUI/> : null}
                            {subscriptionData?.subscription_plan === "premium-on-hold" && (showForms || showQuiz) && !isShowingMobileFormControl ?
                                <DasboardHoldPaymentUI/> : null}
                            <ContentContainer previouslyOpenedDropDownRef={previouslyOpenedDropDownRef}
                                              bodyContainerElementRef={bodyContainerElement}
                                              refreshStorageTotals={props.refreshStorageTotals}
                            />
                        </div>
                    </div>
                    {isMobileOnly && !isShowingMobileFormControl && !showTemplates ?
                        (() => {
                            const BottomBarMenuItems = [FormsMenuItem, QuizzesMenuItem, SharedMenuItem, MoreMobileMenuItem];
                            return <BottomBar BottomBarMenuItems={BottomBarMenuItems}
                                              doPopupAction={doPopupAction}
                                              isCenterFABPresent={true}
                                              updateContainer={showPage}
                                              centerFABClickFunction={() => {
                                                  setShowCreateNewPopUp(true)
                                              }}
                            />
                        })() : null}
                    {isMobileOnly && showTemplates ?
                        <div className='bottom-bar-container' style={{
                            backgroundColor: '#004ba0',
                            color: 'white',
                            fontSize: '20px',
                            justifyContent: 'center'
                        }}
                             onClick={() => {
                                 dispatch({
                                     type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE,
                                     payload: BUILDER_CREATION_SOURCE.NEW
                                 })
                                 dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
                                 dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
                                 dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: null})
                             }}>
                            <img src={createBlankForm} alt={'create blank form'} style={{margin: '5px'}}/>
                            <span>{templateType === templateTypes.FORM ? t(translationKeys.create_blank_form) : t(translationKeys.create_blank_quiz)}</span>
                        </div> : null}
                    {showCreateNewPopUp ?
                        <div style={popUpStyle} onClick={() => {
                            setShowCreateNewPopUp(false)
                        }}>
                            <CreateNewBanner setShowCreateNewPopUp={setShowCreateNewPopUp}
                                             formsDesc={t(translationKeys.general_form_type_description)}
                                             quizzesDesc={t(translationKeys.quiz_type_description)}
                                             handleFormsCardClick={handleFormsCardClick}
                                             handleQuizzesCardClick={handleQuizzesCardClick}/>
                        </div> : null}
                </div> : <BlockedUI/> : <NoInternetUI/>)
}

export default DashBoardContainer

const NoInternetUI = () => {
    return (
        <div className='app-container'>
            <div style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexFlow: 'column'
            }}>
                <p style={{textAlign: 'center'}}>{t(translationKeys.no_connection)}</p><span
                style={{textAlign: 'center'}}>{t(translationKeys.check_connection)}</span>
            </div>
        </div>)
}
