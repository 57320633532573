import {PAGE_HASH_LOCATION, Pages} from "../utils/pages";
import {BUILDER_CREATION_SOURCE} from "../../shared/utils/constants";

const getCurrentPageFromHashLocation = () => {
    switch (window.location.hash) {
        case PAGE_HASH_LOCATION.email_verified:
            return Pages.email_verified
        case PAGE_HASH_LOCATION.reset_password:
            return Pages.reset_password
        default:
            return Pages.login
    }
}

const initialState = {
    shared: null,
    isQuiz: false,
    questionIndexToFocus: -1,
    formData: null,
    previewData: null,
    templateTheme: null,
    isAnswerSheet: false,
    showSharePopUp: false,
    totalStorageUsed: null,
    templateQuestions: null,
    showRefreshPreview: false,
    showCollaboratePopUp: false,
    totalUserResponseCount: null,
    awsThemes: null,
    formImages: null,
    zohoMailTicket: null,
    zohoCallTicket: null,
    showGridQtChangeNotAllowedPopUp: false,
    showColumnOrRowLimitReachedPopUp: false,
    currentPage: getCurrentPageFromHashLocation(),
    builderCreationSource: BUILDER_CREATION_SOURCE.NEW
}

export const APP_ACTION_TYPE = {
    RESET_ALL: 'RESET_ALL',
    SET_SHARED: 'SET_SHARED',
    SET_IS_QUIZ: 'SET_IS_QUIZ',
    SET_FORM_DATA: 'SET_FORM_DATA',
    SET_AWS_THEMES: "SET_AWS_THEMES",
    SET_FORM_IMAGES: "SET_FORM_IMAGES",
    SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
    SET_TEMPLATE_THEME: 'SET_TEMPLATE_THEME',
    UPDATE_PREVIEW_DATA: 'UPDATE_PREVIEW_DATA',
    SET_IS_ANSWER_SHEET: 'SET_IS_ANSWER_SHEET',
    SET_ZOHO_MAIL_TICKET: "SET_ZOHO_MAIL_TICKET",
    SET_ZOHO_CALL_TICKET: "SET_ZOHO_CALL_TICKET",
    SET_SHOW_SHARE_POP_UP: 'SET_SHOW_SHARE_POP_UP',
    SET_TEMPLATE_QUESTIONS: 'SET_TEMPLATE_QUESTIONS',
    SET_TOTAL_STORAGE_USED: 'SET_TOTAL_STORAGE_USED',
    SET_SHOW_REFRESH_PREVIEW: 'SET_SHOW_REFRESH_PREVIEW',
    SET_SHOW_COLLABORATE_POP_UP: 'SET_SHOW_COLLABORATE_POP_UP',
    SET_TOTAL_USER_RESPONSE_COUNT: 'SET_TOTAL_USER_RESPONSE_COUNT',
    SET_SHOW_GRID_QT_CHANGE_NOT_ALLOWED_POP_UP: 'SET_SHOW_GRID_QT_CHANGE_NOT_ALLOWED_POP_UP',
    SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP: 'SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP',
    SET_BUILDER_CREATION_SOURCE: 'SET_BUILDER_CREATION_SOURCE',
    SET_QUESTION_INDEX_TO_FOCUS: 'SET_QUESTION_INDEX_TO_FOCUS'
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case (APP_ACTION_TYPE.SET_SHOW_SHARE_POP_UP):
            return {
                ...state,
                showSharePopUp: action.payload
            }
        case (APP_ACTION_TYPE.SET_SHOW_COLLABORATE_POP_UP):
            return {
                ...state,
                showCollaboratePopUp: action.payload
            }
        case (APP_ACTION_TYPE.SET_SHOW_GRID_QT_CHANGE_NOT_ALLOWED_POP_UP):
            return {
                ...state,
                showGridQtChangeNotAllowedPopUp: action.payload
            }
        case (APP_ACTION_TYPE.SET_AWS_THEMES):
            return {
                ...state,
                awsThemes: action.payload
            }
        case (APP_ACTION_TYPE.SET_FORM_IMAGES):
            return {
                ...state,
                formImages: action.payload
            }
        case (APP_ACTION_TYPE.SET_ZOHO_MAIL_TICKET):
            return {
                ...state,
                zohoMailTicket: action.payload
            }
        case (APP_ACTION_TYPE.SET_ZOHO_CALL_TICKET):
            return {
                ...state,
                zohoCallTicket: action.payload
            }
        case (APP_ACTION_TYPE.SET_TOTAL_USER_RESPONSE_COUNT):
            return {
                ...state,
                totalUserResponseCount: action.payload
            }
        case (APP_ACTION_TYPE.SET_SHOW_REFRESH_PREVIEW):
            return {
                ...state,
                showRefreshPreview: action.payload
            }
        case (APP_ACTION_TYPE.UPDATE_PREVIEW_DATA):
            return {
                ...state,
                previewData: action.payload
            }
        case (APP_ACTION_TYPE.SET_SHOW_COLUMN_OR_ROW_LIMIT_REACHED_POP_UP):
            return {
                ...state,
                showColumnOrRowLimitReachedPopUp: action.payload
            }
        case (APP_ACTION_TYPE.SET_TEMPLATE_QUESTIONS):
            return {
                ...state,
                templateQuestions: action.payload
            }
        case (APP_ACTION_TYPE.SET_IS_ANSWER_SHEET):
            return {
                ...state,
                isAnswerSheet: action.payload
            }
        case (APP_ACTION_TYPE.SET_TEMPLATE_THEME):
            return {
                ...state,
                templateTheme: action.payload
            }
        case (APP_ACTION_TYPE.SET_SHARED):
            return {
                ...state,
                shared: action.payload
            }
        case (APP_ACTION_TYPE.SET_FORM_DATA):
            return {
                ...state,
                formData: action.payload
            }
        case (APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE):
            return {
                ...state,
                builderCreationSource: action.payload
            }
        case (APP_ACTION_TYPE.SET_TOTAL_STORAGE_USED):
            return {
                ...state,
                totalStorageUsed: action.payload
            }
        case (APP_ACTION_TYPE.SET_IS_QUIZ):
            return {
                ...state,
                isQuiz: action.payload
            }
        case (APP_ACTION_TYPE.SET_CURRENT_PAGE):
            return {
                ...state,
                currentPage: action.payload
            }
        case (APP_ACTION_TYPE.SET_QUESTION_INDEX_TO_FOCUS):
            return {
                ...state,
                questionIndexToFocus: action.payload
            }
        case (APP_ACTION_TYPE.RESET_ALL):
            state = initialState
            return {
                ...state
            }
        default:
            return state
    }
}

export default appReducer
