import React, {useEffect, useRef, useState} from 'react';
import AvatarEditor from 'react-avatar-editor';
import {uploadImageFile} from "../utils/upload-file-to-aws"
import {AWS_UPLOAD_TYPE} from "../utils/aws"
import {useDispatch, useSelector} from "react-redux";
import '../css/image-crop.css';
import close from "../../../../public/images/close_white.svg";
import tick from "../../../../public/images/icons/tick_white_mark.svg";
import cropIcon from '../../../../public/images/icons/crop_icon.svg';
import cropIconActive from '../../../../public/images/icons/crop_icon_blue.svg';
import rotateIcon from '../../../../public/images/icons/rotate_icon.svg';
import rotateIconActive from '../../../../public/images/icons/rotate_icon_blue.svg';
import scaleIcon from '../../../../public/images/icons/scale_zoom_icon.svg';
import scaleIconActive from '../../../../public/images/icons/scale_zoom_icon_blue.svg';
import crossIcon from '../../../../public/images/icons/admin/cross_masked_icon.svg';
import rotate90Icon from '../../../../public/images/icons/admin/rotate_90_icon.svg';
import {BUILDER_ACTION_TYPE} from "../../reducers/builderReducer";
import {Slider} from "@mui/material";

const ImagePreviewContainer = (props) => {

    const [image, setImage] = useState(props.imageToPreview);
    const editor = useRef(null)
    const [scale, setScale] = useState(1);
    const [scaleLine, setScaleLine] = useState(50);

    const [rotate, setRotate] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [activeButton, setActiveButton] = useState('crop');
    const [width, setWidth] = useState(250);
    const [aspectRatio, setAspectRatio] = useState(1);
    const reduxDispatch = useDispatch()


    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    const uploadType = props.uploadType;
    const userId = useSelector(state => state.userReducer?.userId)

    const aspectRatioOptions = [
        { label: '1:2', value: 0.5 },
        { label: 'Original', value: 1 },
        { label: '3:4', value: 0.75 },
        { label: '5:3', value: 1.6667 },
        { label: '16:9', value: 1.7778 },
        { label: '1:1', value: 1 },
    ];

    useEffect(() => {
        setImage(props.imageToPreview)
        if(props.imageToPreview !== '') {
            props.hideCancelButton()
            reduxDispatch({type: BUILDER_ACTION_TYPE.SET_IS_DRAG_AND_DROP_DISABLED, payload: true})
        }

    }, [props.imageToPreview]);


    const rotateImage = (event, value) => {
        const newRotation = parseInt(value, 10);
        setRotate(newRotation);
    }

    const uploadImage = () => {
        if (editor?.current) {
            const canvas = editor.current.getImageScaledToCanvas();
            const dataUrl = canvas.toDataURL(props.imageFile.type);

            if (!isUploading) {
                if (uploadType === AWS_UPLOAD_TYPE.NONE) {
                    setIsUploading(false);
                    props.previewCompleted(image, image.size)
                    props.closePopUp()
                    return
                }
                setIsUploading(true);
                if(dataUrl) {
                    function dataURLtoFile(dataurl, filename) {
                        let arr = dataurl.split(','),
                            mime = arr[0].match(/:(.*?);/)[1],
                            bstr = atob(arr[arr.length - 1]),
                            n = bstr.length,
                            u8arr = new Uint8Array(n);
                        while(n--){
                            u8arr[n] = bstr.charCodeAt(n);
                        }
                        return new File([u8arr], filename, {type:mime});
                    }

                    const newCropImage = dataURLtoFile(dataUrl, props.imageFile.name)

                    uploadImageFile(uploadType, newCropImage, (uploadType, userJson, addedImage) => {
                        setIsUploading(false);
                        if (uploadType === AWS_UPLOAD_TYPE.LOGO) {
                            props.updateLogo(addedImage)
                        } else if (uploadType === AWS_UPLOAD_TYPE.FORM) {
                            props.addImage(addedImage)
                        } else if (uploadType === AWS_UPLOAD_TYPE.THEME) {
                            props.updateThemesList(userJson.theme_images, addedImage)
                        } else if (uploadType === AWS_UPLOAD_TYPE.ADMIN_CAREER) {
                            props.careersIcon(addedImage)
                        }
                        props.closePopUp()
                    }, () => {
                        alert('Something went wrong while uploading!')
                    }, userId)

                }
            }
        }
    };

    const handleAspectRatioChange = (newAspectRatio) => {
        setAspectRatio(newAspectRatio);
        setWidth(250);
    };

    const scaleChange = (event, newValue) => {
        if (typeof newValue === 'number' && newValue > 0) {
            setScale((newValue/5) * 0.1);
            setScaleLine(newValue);
        }
    };
    return (
        <div className={`image-crop-upload-container ${image ? 'image-crop-preview-upload': ''}`}>
            {image && (
                <>
                    <div className={'image-crop-header-container'}>
                        <div className={'image-crop-header-inner-container'}>
                            <a onClick={props.closePopUp}>
                                <img className={'image-crop-header-close-icon'} src={close} alt={'close icon'}/>
                            </a>
                            <h2>Crop Image</h2>
                        </div>
                        <a className={'image-crop-header-upload-container'} onClick={uploadImage}>
                            <img src={tick} alt={'tick mark'} />
                        </a>
                    </div>
                    <div className="image-avatar-editor">
                        <AvatarEditor
                            ref={editor}
                            image={image}
                            width={width}
                            height={width / aspectRatio}
                            border={5}
                            scale={scale}
                            rotate={rotate}
                            borderRadius={0}
                            onLoadFailure={(error) => console.error('Error loading image:', error)}
                            color= {[255, 255, 255, 1]}
                        />

                    </div>
                    <div className={'image-crop-scroll-container'}>
                        {activeButton === 'crop' && <div className={'image-crop-scroll-aspect-ratio-container'}>
                            {aspectRatioOptions.map((option) => (
                                <>
                                    <input
                                        type="radio"
                                        name="aspectRatio"
                                        value={option.label}
                                        id={option.label}
                                        onChange={() => handleAspectRatioChange(option.value)}
                                        // defaultChecked={option.label === 'Original'}
                                    />
                                    <label key={option.label} htmlFor={option.label}>
                                        {option.label}
                                    </label>
                                </>
                            ))}
                        </div> }
                        {activeButton === 'rotate' && <>
                            <img src={crossIcon} className={'image-preview-rotate-line-icons'} alt={'cross icon'} onClick={()=>{ setRotate(0)}}/>

                            <Slider
                                track={false}
                                value={rotate || 0}
                                valueLabelFormat={(rotate) => `${rotate}°`}
                                min={-180}
                                step={1}
                                max={180}
                                size="small"
                                sx={{
                                    width: '80%',
                                    color: '#004BA0',
                                    '& .MuiSlider-track': {
                                        border: 'none',
                                    },
                                    '& .MuiSlider-thumb': {
                                        width: '16px',
                                        height: '16px',
                                        backgroundColor: '#004BA0',
                                        border: '2px solid #fff',
                                    },
                                }}
                                onChange={rotateImage}
                                valueLabelDisplay="auto"
                            />

                            <img src={rotate90Icon} className={'image-preview-rotate-line-icons'} alt={'rotate 90 deg'} onClick={()=>{ setRotate(rotate + 90 > 180 ? 180 - (rotate + 90) : rotate + 90)}}/>

                            <div className={"material-slider-indicator-container"}>
                                <div>-180°</div>
                                <div>0°</div>
                                <div>180°</div>
                            </div>
                        </>}
                        {activeButton === 'scale' && <>
                            <Slider
                                track={false}
                                value={scaleLine || 0}
                                valueLabelFormat={(scaleLine) => `${scaleLine}%`}
                                min={0}
                                step={1}
                                max={100}
                                size="small"
                                sx={{
                                    width: '80%',
                                    color: '#004BA0',
                                    left: '10%',
                                    '& .MuiSlider-thumb': {
                                        width: '16px',
                                        height: '16px',
                                        backgroundColor: '#004BA0',
                                        border: '2px solid #fff',
                                    },
                                }}
                                onChange={scaleChange}
                                valueLabelDisplay="auto"
                            />
                            <div className={"material-slider-indicator-container"}>
                                <div>0%</div>
                                <div>50%</div>
                                <div>100%</div>
                            </div>
                        </> }
                    </div>
                    <div className={'image-crop-options-container'}>
                        <a>
                            {activeButton === 'crop' ?
                                <img className={'image-preview-icon-image'} src={cropIconActive} alt={'crop icon active'}
                                 onClick={() => handleButtonClick('crop')}/> :
                                <img className={'image-preview-icon-image'} src={cropIcon} alt={'crop icon'}
                                     onClick={() => handleButtonClick('crop')}/>}
                            {activeButton === 'crop' && <p className={'crop-icon-active'}>Crop</p> }
                        </a>
                        <a>
                            {activeButton === 'rotate' ?
                            <img className={'image-preview-icon-image'} src={rotateIconActive} alt={'rotate icon'}
                                 onClick={() => handleButtonClick('rotate')}
                            /> :
                                <img className={'image-preview-icon-image'} src={rotateIcon} alt={'rotate icon'}
                                     onClick={() => handleButtonClick('rotate')}
                                /> }
                            {activeButton === 'rotate' && <p className={'crop-icon-active'}>Rotate</p>}
                        </a>
                        <a>
                            {
                                activeButton === 'scale' ?
                                <img className={'image-preview-icon-image'} src={scaleIconActive} alt={'scale icon'}
                                 onClick={() => handleButtonClick('scale')}
                                /> :
                                <img className={'image-preview-icon-image'} src={scaleIcon} alt={'scale icon'}
                                      onClick={() => handleButtonClick('scale')}
                                />
                            }
                            {activeButton === 'scale' && <p className={'crop-icon-active'}>Scale</p>}
                        </a>
                    </div>
                </>
            )}
        </div>
    );
};

export default ImagePreviewContainer;
