import React, {useState, useEffect, useRef} from 'react'
import {sortFields} from "../utils/sort-form-list";
import {handlePreviouslyOpenedSortCard} from "./AccInfoContainer";
import {translationKeys} from "../../../localizations/translationKeys-localization";
import {t} from "i18next";
import {useDispatch, useSelector} from "react-redux";
import {FORMS_ACTION_TYPE} from "../../reducers/formsReducer";
import {QUIZZES_ACTION_TYPE} from "../../reducers/quizzesReducer";

const SORTED_BY_TEXT_TO_DISPLAY = {
    MODIFIED_DATE: t(translationKeys.modified_date),
    CREATED_DATE: t(translationKeys.created_date),
    TITLE: t(translationKeys.title)
}

const ContentHeader = (props) => {
    // const [label, setLabel] = useState(`${props.someForqsAreFavs ? 'Others' : 'All'}`);
    const showQuiz = useSelector(state => state.appDashboardReducer.showQuiz)
    const showForms = useSelector(state => state.appDashboardReducer.showForms)
    const searchText = useSelector(state => state.appDashboardReducer.searchText)
    const lastFormsSortedBy = useSelector(state => state.formsReducer.lastFormsSortedBy)
    const lastQuizzesSortedBy = useSelector(state => state.quizzesReducer.lastQuizzesSortedBy)
    const [showSortOptions, setShowSortOptions] = useState(false);
    const [sortByText, setSortByText] = useState(null)
    const Ref = useRef(null);
    const dispatch = useDispatch()

    const sortDataList = (sortOrder) => {
        props.setSortOrder(sortOrder)
        setShowSortOptions(false);
        if (showForms) {
            dispatch({type: FORMS_ACTION_TYPE.SET_LAST_FORMS_SORTED_BY, payload: sortOrder})
        } else if (showQuiz) {
            dispatch({type: QUIZZES_ACTION_TYPE.SET_LAST_QUIZZES_SORTED_BY, payload: sortOrder})
        }
    }

    useEffect(() => {
        if (!sortByText) {
            if (showForms) {
                setSortByText((lastFormsSortedBy === sortFields.DATE_EDITED) ? SORTED_BY_TEXT_TO_DISPLAY.MODIFIED_DATE
                    : (lastFormsSortedBy === sortFields.DATE_CREATED) ? SORTED_BY_TEXT_TO_DISPLAY.CREATED_DATE :
                        (lastFormsSortedBy === sortFields.TITLE) ? SORTED_BY_TEXT_TO_DISPLAY.TITLE : SORTED_BY_TEXT_TO_DISPLAY.MODIFIED_DATE)
            } else if (showQuiz) {
                setSortByText((lastQuizzesSortedBy === sortFields.DATE_EDITED) ? SORTED_BY_TEXT_TO_DISPLAY.MODIFIED_DATE
                    : (lastQuizzesSortedBy === sortFields.DATE_CREATED) ? SORTED_BY_TEXT_TO_DISPLAY.CREATED_DATE :
                        (lastQuizzesSortedBy === sortFields.TITLE) ? SORTED_BY_TEXT_TO_DISPLAY.TITLE : SORTED_BY_TEXT_TO_DISPLAY.MODIFIED_DATE)
            }
        }
    })

    useEffect(() => {
        if (showSortOptions) {
            !showSortOptions ? document.onmousedown = null : null
        }
        if (!showSortOptions) {
            showSortOptions ? props.previouslyOpenedDropDownRef.current = {
                element: Ref.current,
            } : null
        }

    }, [searchText, props.someForqsAreFavs]);


    return (
        <div id={'content-header'} className="content-header">
            <div id={'form-sort-container'} className="form-sort-container">
                <div id={'form-sort-container-text'}
                     className="sort-forms-fav">{props.someForqsAreFavs ? t(translationKeys.others) : t(translationKeys.all)}
                    <span style={{marginLeft: '4px', width: 'auto'}}>{` (${props.formCount})`}</span></div>
                <div ref={Ref}>
                    {props.formCount ? <div id={'sort-forms-fav'} className="sort-forms"
                                            style={showSortOptions ? {
                                                backgroundColor: '#f5f5f5',
                                                color: '#004BA3',
                                                borderBottomLeftRadius: 0,
                                                borderBottomRightRadius: 0
                                            } : null}
                                            onClick={e => {
                                                e.stopPropagation();
                                                props.previouslyOpenedDropDownRef?.current?.element ? handlePreviouslyOpenedSortCard(props.previouslyOpenedDropDownRef.current) : null;
                                                showSortOptions ? setShowSortOptions(false) : setShowSortOptions(true);
                                                props.previouslyOpenedDropDownRef.current = {
                                                    element: Ref.current,
                                                };
                                                document.onmousedown = e => {
                                                    props.previouslyOpenedDropDownRef.current = null;
                                                    if (Ref && !Ref.current.contains(e.target)) {
                                                        setShowSortOptions(false);
                                                        document.onmousedown = null;
                                                    }
                                                }
                                            }}>
                        <span style={{marginRight: '5px'}}>{sortByText}</span>
                        <img style={{width: '24px', height: '24px'}}
                             src={showSortOptions ? "../../../images/sort_minimize.svg" : "../../../images/sort_more.svg"}
                             alt="more-icon"/>
                    </div> : null}
                    {showSortOptions ?
                        <div id={'form-sort-options-container'} className="form-sort-options-container">
                            <div id={'form_sort_modified_date'} className="form-sort-each-option"
                                 onClick={() => {
                                     sortDataList(sortFields.DATE_EDITED)
                                     setSortByText(SORTED_BY_TEXT_TO_DISPLAY.MODIFIED_DATE)
                                 }}>{t(translationKeys.modified_date)}
                            </div>
                            <div id={'form_sort_created_date'} className="form-sort-each-option"
                                 onClick={() => {
                                     sortDataList(sortFields.DATE_CREATED)
                                     setSortByText(SORTED_BY_TEXT_TO_DISPLAY.CREATED_DATE)
                                 }}>{t(translationKeys.created_date)}
                            </div>
                            <div id={'form_sort_title'} className="form-sort-each-option"
                                 style={{borderBottomLeftRadius: '5px', borderBottomRightRadius: '5px'}}
                                 onClick={() => {
                                     sortDataList(sortFields.TITLE);
                                     setSortByText(SORTED_BY_TEXT_TO_DISPLAY.TITLE)
                                 }}>{t(translationKeys.title)}
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>
    )
}


export default ContentHeader;