import React from 'react';
import PropTypes from "prop-types";
import MaterialButton, {ButtonTypes} from "../components/MaterialButton";

const ActionButtonContainer = props => {
    const containerStyle = {
        margin: '0 10px',
        width: props.containerWidth
    }

    return (
        <div style={containerStyle}>
            <MaterialButton data={{
                buttonType: ButtonTypes.CONTAINED,
                title: props.buttonText,
                customButtonContainerStyle: {
                    borderRadius: '35px',
                    backgroundColor: props.bgColor,
                    width: props.buttonWidth,
                    height: props.height
                },
                base64ImageData: props.base64ImageData,
                customImageStyle: {
                    width: '14px',
                    height: '14px',
                    margin: '8px 8px 8px 0'
                },
                customButtonTextStyle: {
                    fontFamily: 'Nunito Sans, Sans-serif, serif',
                    fontSize: props.fontSize,
                    color: props.color,
                    fontWeight: props.fontWeight
                }
            }} handleClick={props.handleClick}
             id={props.id}
            />
        </div>
    )
}

export default ActionButtonContainer

ActionButtonContainer.propTypes = {
    bgColor: PropTypes.string,
    buttonText: PropTypes.string.isRequired,
    buttonWidth: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
    imageName: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    height: PropTypes.string,
    fontWeight: PropTypes.string
}

ActionButtonContainer.defaultProps = {
    bgColor: '#1976d2',
    buttonText: 'Button',
    color: '#fff',
    fontSize: '12px',
    height: '45px',
    fontWeight: 'initial',
    handleClick: () => console.log('Clicked')
}
