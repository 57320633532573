import React, {useEffect, useRef} from 'react';
import {MDCRadio} from "@material/radio/component";
import '../css/material-multiple-choice.css'

export const MaterialRadioSelection = (props) => {
    const mdcRadioButton = useRef(undefined)
    useEffect(() => {
        if (mdcRadioButton.current) {
            const radioButton = new MDCRadio(mdcRadioButton.current);
            props.isClearRadioSelection ? radioButton.checked = false : undefined
            if (props.isChecked !== undefined) {
                radioButton.checked = props.isChecked
            }
        }
    })
    return <div className="mdc-form-field" style={{width: props.width ? props.width : null, background: props.background ? props.background : null}}>
        <div className='mdc-radio' ref={mdcRadioButton}
             style={{
                 margin: props.customStyle && props.customStyle.radioButtonMargin ? props.customStyle.radioButtonMargin : null,
                 padding: props.customStyle && props.customStyle.radioButtonPadding ? props.customStyle.radioButtonPadding : null,
                 "--mdc-theme-secondary": (props.customStyle && props.customStyle.radioButtonLabelColor)
                     ? props.customStyle.radioButtonLabelColor : '#212121'
             }}>
            <input field-type="MULTIPLE_CHOICE"
                   className="mdc-radio__native-control"
                   type="radio"
                   onFocus={props.onFocus}
                   id={props.id}
                   name={props.name}
                   value={props.id}
                   onChange={props.onChange}
                   defaultChecked={props.isChecked ? props.isChecked : false}
            />
            <div className="mdc-radio__background">
                <div className="mdc-radio__outer-circle"
                     style={{borderColor: props.customStyle.radioButtonColor}}/>
                <div className="mdc-radio__inner-circle"
                     style={{borderColor: props.customStyle.radioButtonColor}}/>
            </div>
            <div id={props.id} className="mdc-radio__ripple"/>
        </div>
        {props.label ? <label
            style={{
                color: (props.customStyle && props.customStyle.radioButtonLabelColor)
                    ? props.customStyle.radioButtonLabelColor : '#212121',
                fontSize: props.customStyle?.fontSize ? props.customStyle?.fontSize : '18px'
            }}>
            {props.label}</label> : null}
    </div>;
}
