import {NOTIFICATION_ACTION_TYPE} from "../utils/constants";

const initialState = {
    notifications: []
}

const notificationReducer = (state = initialState, action) => {
    const data = {...state}

    switch (action.type) {
        case NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_COUNT:
            data.count = action.payload
            return data
        case NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_DATA:
            data.notifications = action.payload
            data.notifications.sort(function(a, b) {
                return (b.date - a.date);
            })
            return data
        case NOTIFICATION_ACTION_TYPE.REMOVE_NOTIFICATION:
            data.notifications = data.notifications.filter(notifications => notifications._id !== action.payload)
            return data
        case NOTIFICATION_ACTION_TYPE.UPDATE_NOTIFICATION_STATUS:
            data.notifications.map(notifications => notifications.is_notified = action.payload)
            return data
        default:
            return state;
    }
}

export default notificationReducer