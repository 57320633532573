import React, {useEffect} from 'react';
import '../css/ConfirmMessage.css'
import PropTypes from "prop-types";
import {SCREEN_NAME_ACTION_TYPE} from "../../admin/reducers/screenNameReducer";
import {useDispatch} from "react-redux";
import {BUILDER_ACTION_TYPE} from "../../app/reducers/builderReducer";

const Message = (props) => {
    const dispatch = useDispatch()
    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch({type: SCREEN_NAME_ACTION_TYPE.SET_SHOW_ACKNOWLEDGEMENT, payload: false})
            dispatch({type: BUILDER_ACTION_TYPE.SET_BUILDER_TOAST_MESSAGE, payload: false})

            props.setShowAcknowledgement ? props.setShowAcknowledgement(false) : null
        }, 2000)
        return () => clearTimeout(timer)
    }, [])
    return(
        <div id={'message'} className={'message'} style={{width: props.width ? props.width : null, zIndex: '15'}}>
            <h1 style={{wordBreak: "break-word"}}>{props.message}</h1>
        </div>
    )
}

export default Message

Message.prototype = {
    message: PropTypes.string.isRequired
}
Message.defaultProps = {
    message: 'DEFAULT!'
}