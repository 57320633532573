import React from "react"
import "../css/privacy-policy.css"

const PrivacyPolicy = (props) => {
    const isQuiz = props.isQuiz
    return (
        <div className={"privacy-policy"}>
            <h2 style={{textAlign: "center", color: "#004ba0"}}>PRIVACY POLICY</h2>


            <h1>1. INTRODUCTION</h1>

            <p>This Privacy Policy applies to all the products, services, websites and apps offered
                by {isQuiz ? "quizzory.com" : "surveyheart.com"} and their affiliates (collectively
                "{isQuiz ? "QUIZZORY" : "SURVEYHEART"}"). We refer to those products, services, websites and apps
                collectively as the "services" in this policy.</p>

            <p>References to "data" in this Privacy Policy will refer to whatever data you use our services to collect,
                whether it be survey responses, data collected in a form, or data inserted on a site hosted by us – it’s
                all
                your data! Reference to personal information or just information, means information about you personally
                that we collect or for which we act as custodian.</p>

            <p>If you want to identify your data controller please see the "Who is my data controller" section
                below.</p>

            <h1>2. INFORMATION WE COLLECT</h1>

            <h2>2.1 Who are "you"?</h2>
            <p>We refer to "you" a lot in this Privacy Policy. To better understand what information is most relevant to
                you, see the following useful definitions.</p>

            <p>CREATORS - You hold an account within a {isQuiz ? "QUIZZORY" : "SURVEYHEART"} service and you either
                directly create surveys,
                forms,
                applications, or questionnaires or you are collaborating on, commenting on, or reviewing surveys, forms,
                applications, or questionnaires within an account.</p>

            <p>RESPONDENTS - You have received a survey, form, application, or questionnaire powered by
                a {isQuiz ? "QUIZZORY" : "SURVEYHEART"}
                service.</p>

            <p>WEBSITE VISITORS - You are just visiting one of our websites because you are Curious!</p>

            <h2>2.2 Information we collect about you.</h2>

            <h2>Contact Information - an email address.</h2>
            <p>You might provide us with your contact information, whether through use of our services, a form on our
                website, an interaction with our sales or customer support team, or a response to one
                of {isQuiz ? "QUIZZORY" : "SURVEYHEART"}’s
                own
                surveys.</p>

            <h2>Usage information.</h2>
            <p>We collect usage information about you whenever you interact with our websites and services. This
                includes
                which webpages you visit, what you click on, when you perform those actions, what language preference
                you
                have, what you buy and so on.</p>

            <h2>Device and browser data.</h2>
            <p>We collect information from the device and application you use to access our services. Device data mainly
                means your operating system version, device type, system and performance information, and browser type.
                If
                you are on a mobile device we also collect the FCMID for that device.</p>

            <h2>Information from page tags.</h2>
            <p>We use first party and third party cookies and tracking services that employ cookies and page tags (also
                known as web beacons) to collect data about visitors to our websites. This data includes usage and user
                statistics. Emails sent by {isQuiz ? "QUIZZORY" : "SURVEYHEART"} or by users through our services also
                include page tags that
                allow
                the sender to collect information about who opened those emails and clicked on links in them.</p>

            <h2>Log Data.</h2>
            <p>Like most websites today, our web servers keep log files that record data each time a device accesses
                those
                servers. The log files contain data about the nature of each access, internet service providers, the
                files
                viewed on our site (e.g., HTML pages, graphics, etc.), operating system versions, device type and
                timestamps.</p>
            <h2>Referral information.</h2>
            <p>If you arrive at a {isQuiz ? "QUIZZORY" : "SURVEYHEART"} website from an external source (such as a link
                on another website or in
                an
                email), we record information about the source that referred you to us.</p>
            <p>If you are a Creator we will also collect: Account Information</p>


            <h2>Registration information.</h2>
            <p>You need a {isQuiz ? "QUIZZORY" : "SURVEYHEART"} account before you can
                use {isQuiz ? "QUIZZORY" : "SURVEYHEART"} services. When you register for an account,
                we
                collect your email address.</p>

            <h2>Billing information.</h2>
            <p>If you make a payment to {isQuiz ? "QUIZZORY" : "SURVEYHEART"}, we require you to provide your billing
                details, a name, address,
                email
                address and financial information corresponding to your selected method of payment (e.g. a credit card
                number and expiration date or a bank account number). If you provide a billing address, we will regard
                that
                as the location of the account holder to determine which {isQuiz ? "QUIZZORY" : "SURVEYHEART"} entity
                with whom you contract and
                the
                sales tax, if applicable, to be applied to your purchase.</p>

            <h2>Account settings.</h2>
            <p>You can set various preferences and personal details on pages like your account settings page. For
                example,
                your default language, time zone and communication preferences (e.g. opting in or out of receiving
                marketing
                communications from {isQuiz ? "QUIZZORY" : "SURVEYHEART"}).</p>


            <h2>Use of some of our services will also result in us collecting the following data on your behalf:</h2>

            <h2>Address book information.</h2>
            <p>We may allow you to import email addresses and other contact information into an Address Book so you can
                easily invite people to take your surveys or fill in your form via our collectors. We don’t use this
                data
                for our own purposes or contact anyone, except at your direction.</p>

            <h2>Survey/form/application data.</h2>
            <p>We store your survey/form/application data (questions and responses) for you and provide analysis tools
                for
                you to use with respect to this data.</p>

            <h2>Profile information.</h2>
            <p>When you sign up for our services you are asked to provide us with information about yourself and to give
                us
                more detailed insights into who you are.</p>

            <h1>3. HOW WE USE THE INFORMATION WE COLLECT</h1>

            <h2>3.1 Creator</h2>

            <p>We process personal data about you either with your consent or in order to:Fulfill our contractual
                responsibility to deliver the services to you;</p>

            <p>To pursue {isQuiz ? "QUIZZORY" : "SURVEYHEART"}’s legitimate interests of: improving service experience;
                and developing new
                products
                and service features. In each of the instances where we describe how we use your data in this privacy
                policy, we have identified which of these grounds for processing we are relying upon.</p>

            <h2>Contact Information.</h2>

            <p>We use contact information to respond to your inquiries, send you information as part of the services,
                and
                send you marketing information (for as long as you do not opt-out).</p>

            <p>More details: We use your email address to send you marketing (newsletters), unless you indicate a
                preference
                to opt-out and for general updates on your account. You can opt out of marketing communications at any
                time
                by clicking on the "unsubscribe" link in them and changing the relevant setting on your Profile
                page.</p>


            <h2>How you use our services.</h2>

            <p>We use information about how you use our services to improve our services for you and all users.</p>

            <p>More details: We collect information about the types of surveys/forms/applications you create (e.g. HR
                surveys), the types of plans you purchase and your account transactional behavior to build a profile
                about
                you so as to help direct you and your organization to other relevant features and services we offer and
                help
                you in using our services, for example by making recommendations for you to optimize use of our
                services.</p>

            <h2>Device and browser data.</h2>

            <p>We use device data both to troubleshoot problems with our service and to make improvements to it.</p>

            <p>More details: We collect this to help us improve your service experience through a specific
                device/browser by
                optimizing how the website looks in a particular browser, how your screen brightness affects your
                experience
                and to ensure the service operates optimally and as it should on different devices and browsers.</p>

            <h2>Log data.</h2>

            <p>We use log data for many different business purposes to include:</p>

            <p>To monitor abuse and troubleshoot.</p>

            <p>To create new services, features, content or make recommendations.</p>

            <p>To track behavior at the aggregate/anonymous level to identify and understand trends in the various
                interactions with</p>
            <p>our services.</p>

            <p>To fix bugs and troubleshoot product functionality.</p>

            <h2>Referral information.</h2>
            <p>We use referral information to track the success of our integrations and referral processes.</p>

            <p>To personalize our services for you; and</p>

            <p>Ensure you can use our service in conjunction with other services.</p>
            <p>Service and Marketing uses.</p>
            <p>Profiling. We combine information about you from third party sources with information we hold about you
                to
                create a user profile, which will help us to make our sales and marketing efforts more relevant to you
                and
                to personalize and improve your service experience.</p>

            <p>To manage our services we will also internally use your information and data, for the following limited
                purposes:</p>

            <p>To enforce our agreements where applicable.</p>

            <p>To prevent potentially illegal activities.</p>

            <p>To screen for and prevent undesirable or abusive activity. For example, we have automated systems that
                screen
                content for phishing activities, spam, and fraud.</p>

            <h2>Legal uses.</h2>
            <p>To respond to legal requests or prevent fraud, we may need to use and disclose information or data we
                hold
                about you. If we receive a subpoena or other legal request, we may need to inspect the data we hold to
                determine how to respond.</p>

            <p>We collect and use the following on the basis that we have to use this information in order to fulfill
                our
                contract with you:</p>

            <h2>Your Account Information.</h2>
            <p>We need to use your account information to run your account, provide you with services, bill you for our
                services, provide you with customer support, and contact you about your service or account. We
                occasionally
                send you communications of a transactional nature (e.g. service-related announcements, billing-related
                matters, changes to our services or policies, a welcome email when you first register). You cannot opt
                out
                of these communications since they are required to provide our services to you.</p>

            <h2>Your Profile.</h2>
            <p>We process other aspects of your account information (like the personal information you provide about
                your
                job, your job title, and your marketing preferences) as well as information obtained from public
                sources,
                for legitimate interests like providing you with a personalized experience and relevant and useful
                marketing
                information as well as to make other product, feature and service recommendations to you and your
                organization to optimize use of the services we offer.</p>

            <p>You can object to us using your information as described above but in some cases, our ability to fully
                and
                properly provide our services to you may be impacted if you do not want us to collect or use the above
                data.</p>


            <h2>In relation to Survey Data</h2>

            <h2>Your Data.</h2>

            <p>We also use survey questions and responses on an aggregated and anonymized basis as described in this
                Privacy
                Policy. We will never sell individual response data or identify / contact individual respondents except
                on
                your request or where required by law.</p>


            <h2>3.2 Respondent</h2>
            <p>We process your personal information in the following categories of data for legitimate interests pursued
                by
                us, which are described in detail in this Privacy Policy. We have undertaken to ensure that we place
                clear
                limitations on each of these uses so that your privacy is respected and only the information necessary
                to
                achieve these legitimate aims is used. Our primary goal is to improve upon and make sure our services
                and
                messaging are relevant for all our users, while also ensuring that personal information of all users is
                respected and protected.</p>

            <h2>Contact Information.</h2>
            <p>We only use contact information to respond to an inquiry which you, as a Respondent, submit to us.</p>

            <h2>Examples</h2>
            <p>Our customer support team use your email address to communicate with you if you have contacted us about a
                survey, form, application or questionnaire you received, but we will not send marketing to you unless
                you
                have otherwise opted-in to marketing.</p>

            <p>How you use our services (applicable to survey Respondents only).</p>

            <p>We use information about how you use our services to improve our services for you and all users.</p>

            <h2>Examples</h2>
            <p>We collect information about the types of questions you answer. This data will be aggregated and
                anonymized
                so we can examine patterns in terms of respondent preferences when submitting responses. We collect and
                use
                all this data for our legitimate interests like helping us improve the experience for respondents (so
                that
                questions are easier to answer), to understand industry trends in and to help improve the completion
                rates
                on surveys/forms.</p>

            <p>We will also use usage information such as the type of survey, form, questionnaire or application that
                you
                answered to personalize products we show you on completion of a survey when you are redirected to our
                website.</p>

            <h2>Device and browser data.</h2>
            <p>We use device data both to troubleshoot problems with our service and to make improvements to it.</p>

            <h2>Log data.</h2>
            <p>We use log data for many different business purposes to include:</p>

            <p>To monitor abuse and troubleshoot.</p>
            <p>To create new services, features, content or make recommendations.</p>
            <p>To track behavior at the aggregate/anonymous level to identify and understand trends in the various
                interactions with our services.</p>
            <p>To fix bugs and troubleshoot product functionality.</p>

            <h2>Examples</h2>

            <p>We collect log data to collate aggregated data and metrics on activity at a non-identifying level and so
                that
                we can identify trends in survey taking over time.
            </p>

            <p>To manage our services we will also internally use your information and data, for the following
                limited
                purposes:</p>

            <p>To enforce our agreements where applicable.</p>
            <p>To prevent potentially illegal activities.</p>
            <p>To screen for and prevent undesirable or abusive activity. For example, we have automated systems
                that
                screen content for phishing activities, spam, and fraud.</p>

            <h2>Legal uses.</h2>

            <p>To respond to legal requests or prevent fraud, we may need to disclose any information or data we
                hold
                about you. If we receive a subpoena or other legal request, we may need to inspect the data we hold
                to
                determine how to respond.</p>

            <p>Use of survey responses ({isQuiz ? "QUIZZORY" : "SURVEYHEART"} surveys)</p>

            <p>In general survey responses to {isQuiz ? "QUIZZORY" : "SURVEYHEART"} surveys are controlled and managed
                by the Creator (the
                person
                who sent or deployed that survey). In those instances {isQuiz ? "QUIZZORY" : "SURVEYHEART"} is only
                processing those responses
                on
                behalf of the Creator.</p>

            <p>Creator and Respondent trust is paramount to everything we do and so when we do use data about
                Respondents, we put</p>
            <p>Creators and Respondents first. When we do analysis of response data we only do so once we have
                ensured
                the anonymity of individual respondents (by aggregating and anonymizing the data).</p>

            <p>Our goal is to improve the user experience across {isQuiz ? "QUIZZORY" : "SURVEYHEART"} survey services
                while maintaining the
                confidentiality and privacy of responses.</p>

            <p>We go into more detail below on how {isQuiz ? "QUIZZORY" : "SURVEYHEART"} uses survey data.</p>

            <p>{isQuiz ? "QUIZZORY" : "SURVEYHEART"} uses data in the ways described below, for our legitimate interests
                as described in this
                section:</p>

            <p>The data impacted by this section includes:</p>

            <p>Survey type, question type and responses (at an aggregated and anonymized level only)</p>


            <h2>Device data</h2>

            <h2>Log data</h2>
            <p>{isQuiz ? "QUIZZORY" : "SURVEYHEART"} will use automated processes, to analyze survey responses, which in
                turn helps us to:</p>

            <p>Aggregate response data and activity: We will aggregate responses, activity and behavior of
                Respondents
                so that we can identify trends, build product features that optimize responses, make product
                recommendations and provide guidance on which products and services work best in different
                scenarios.
                These product features also provide feedback and recommendations to increase response rates.</p>


            <h2>3.3 Visitor</h2>

            <p>We process personal data about you where: You have consented or; {isQuiz ? "QUIZZORY" : "SURVEYHEART"}’s
                legitimate interests
                of:
                improving service experience; and developing new products and service features.</p>

            <p>In each of the instances where we describe how we use your data in this Privacy Policy, we have
                identified which of these grounds for processing we are relying upon.</p>

            <p>When you have consented we collect and use the following information about you:</p>

            <h2>Contact Information.</h2>

            <p>We use contact information to respond to your inquiries, send you information as part of the
                services,
                and send you marketing information (as long as you have agreed to this at the point of providing
                your
                information and for as long as you do not opt-out).</p>

            <h2>Examples</h2>

            <p>We provide your email address to a member of our sales team who will contact you if you submitted an
                inquiry through one of the online forms on our site. You will also receive marketing communications
                from
                us if you have consented to this at the point where you provided your information. We will always
                provide you with the means to opt-out of this marketing at any time.</p>

            <h2>Profiling.</h2>

            <p>We combine information about you from third party sources with information we hold about you to
                create a
                user profile, which will help us to make our sales and marketing efforts more relevant to you and to
                personalize and improve our marketing campaigns and website experience.</p>

            <h2>Device data.</h2>

            <p>We use device data both to troubleshoot problems with our service and to make improvements to it.
                Some
                examples relevant to you:</p>

            <h2>Examples</h2>

            <p>We collect device and browser information from you to troubleshoot website functionality issues and
                to
                fix bugs.</p>


            <h2>How you use our services.</h2>

            <p>We use information about how you have interacted with our websites to improve our website services
                for
                you and all users. Some examples relevant to you:</p>

            <h2>Examples</h2>

            <p>We collect information about the webpages you have visited and your activity on our sites at an
                aggregate
                level. We collect this information so that we can track the most visited and most useful parts of
                our
                website to identify what are our most popular services.</p>

            <h2>Log data.</h2>

            <p>We use log data for many different business purposes to include:</p>

            <p>To monitor abuse and troubleshoot.</p>
            <p>To track your preferences and create new services, features, content or make recommendations
                personalized
                for you.</p>
            <p>To track behavior at the aggregate/anonymous level to identify and understand trends in the various
                interactions with our services.</p>
            <p>To fix bugs and troubleshoot product functionality.</p>

            <h2>Examples</h2>
            <p>Your browser type to determine how we can present our website best within that browser
                environment.</p>

            <h2>Referral information.</h2>
            <p>We use referral information to track the success of our integrations and referral processes.</p>

            <h2>Examples</h2>
            <p>If you clicked on an advertisement for {isQuiz ? "QUIZZORY" : "SURVEYHEART"}, presented by one of our
                partners on the web,
                which
                brought you to
                one of our websites, we will record this information to help us track the success of advertising
                campaigns</p>

            <p>To manage our services we will also internally use your information and data, for the following
                limited
                purposes:</p>

            <p>To enforce our agreements where applicable.</p>
            <p>To prevent potentially illegal activities.</p>
            <p>To screen for and prevent undesirable or abusive activity. For example, we have automated systems
                that
                screen content
                for phishing activities, spam, and fraud.</p>


            <h2>Legal uses.</h2>

            <p>To respond to legal requests or prevent fraud, we may need to disclose any information or data we
                hold
                about you. If
                we
                receive a subpoena or other legal request, we may need to inspect the data we hold to determine how
                to
                respond</p>


            <h1>4. INFORMATION YOU SHARE</h1>
            <p>Many of our services let you share information with others. Remember that when you share information
                publicly, it can
                be
                indexable by search engines. Our services provide you with different options on sharing and deleting
                your content
                but we
                cannot delete content from search engines so you need to be careful about information you make
                public.</p>

            <h1>5. INFORMATION WE SHARE</h1>

            <p>We do not share your information or data with third parties
                outside {isQuiz ? "QUIZZORY" : "SURVEYHEART"} except in the
                following
                limited
                circumstances:</p>

            <p>If you are a Creator that is part of a team plan or Enterprise plan
                using {isQuiz ? "QUIZZORY" : "SURVEYHEART"}, your account
                information and
                data
                will be shared with the primary administrator(s) and your survey data may also be visible to other
                members in your
                team
                with whom you share your surveys or with whom you collaborate. Your administrator(s) will be able to
                view your
                account
                data, suspend, transfer or terminate your account or restrict your settings. Please refer to your
                organization’s
                internal policies if you have questions about this.</p>

            <p>If your organization has purchased an account and you are using an email address on a domain owned by
                your employer
                or
                organization linked to your individual account, you may be asked to migrate to that purchased
                Account
                and your email
                address, name and account data will subsequently be visible to the primary administrator for that
                account once you
                have
                been migrated. You may be notified in advance of this migration and given an opportunity to change
                the
                email address
                linked to your account if you are not using your account for business purposes.</p>

            <p>To help us provide certain aspects of our services we use our affiliates and trusted key partners –
                in
                particular, we
                engage third parties to:</p>

            <p>facilitate our email collectors for sending surveys by email to Respondents.
                facilitate customers in making credit card payments.
                deliver and help us track our marketing and advertising content.
                help us track website conversion success metrics.
                manage our sales and customer support services to you.
                We enter into confidentiality and data processing terms with partners to ensure they comply with
                high
                levels of
                confidentiality and best practice in privacy and security standards and we regularly review these
                standards and
                practices.</p>

            <p>We also may have to share information or data in order to:</p>

            <p>meet any applicable law, regulation, legal process or enforceable governmental request.
                enforce applicable policies, including investigation of potential violations.
                detect, prevent, or otherwise address fraud, security or technical issues.
                protect against harm to the rights, property or safety of our users, the public or
                to {isQuiz ? "QUIZZORY" : "SURVEYHEART"}
                and/or
                as required
                or
                permitted by law.
                facilitate a sale, merger or change in control of all or any part of our company or business or in
                preparation for
                any
                of these events.</p>

            <h2>Google Analytics.</h2>
            <p>In addition to the above, we have implemented on our websites and other services certain Google
                Analytics
                features
                that
                support Display Advertising, including re-targeting. Visitors to our websites may opt out of certain
                types of Google
                Analytics tracking, customize the Google Display Network ads by using the Google Ad Preferences
                Manager
                and learn
                more
                about how Google serves ads by viewing its Customer Ads Help Center. If you do not wish to
                participate
                in Google
                Analytics, you may also download the Google Analytics opt-out browser add-on.
                You can choose to remove or disable cookies via your browser settings.</p>

            <h1>6. DATA RETENTION</h1>

            <p>If you hold an account with {isQuiz ? "QUIZZORY" : "SURVEYHEART"} we do not delete the data in your
                account – you are
                responsible
                for and
                control
                the time periods for which you retain this data. There are controls in your account where you can
                delete
                data at the
                account level (all data in your account) and at the response level. If you are a Respondent, you
                will
                need to ask
                the
                Creator how long your responses will be stored in {isQuiz ? "QUIZZORY" : "SURVEYHEART"} services.</p>


            <h1>7. SAFETY OF MINORS</h1>

            <p>Our services are not intended for and may not be used by minors. "Minors" are individuals under the
                age
                of 13 (or
                under
                a higher age if permitted by the laws of their residence). {isQuiz ? "QUIZZORY" : "SURVEYHEART"} does
                not knowingly collect
                personal data from
                Minors or allow them to register. If it comes to our attention that we have collected personal data
                from
                a Minor, we
                may
                delete this information without notice. If you have reason to believe that this has occurred, please
                contact
                customer
                support via heartfullapps@gmail.com</p>

            <p>Please contact {isQuiz ? "QUIZZORY" : "SURVEYHEART"} as described in Section 14 below if you have any
                concerns or complaints of
                any
                nature. If
                you
                have an unresolved privacy or data use concern that we have not addressed satisfactorily, please
                contact
                us (free of
                charge) at heartfullapps@gmail.com</p>

            <h1>8. CHANGES TO OUR PRIVACY POLICY</h1>

            <p>We can make changes to this Privacy Policy from time to time. We will identify the changes we have
                made
                on this page.
                In
                circumstances where a change will materially change the way in which we collect or use your personal
                information or
                data, we will send a notice of this change to all of our account holders.</p>

            <h1>9. PERSONALIZED MARKETING</h1>

            <p>You can opt-out from direct marketing in your account and we provide opt-out options in all direct
                marketing emails.
                Finally, if you do not wish to see personalized marketing content on the web related to our service
                you
                can clear
                the
                cookies in your browser settings.</p>

            <h1>10. WHO IS MY DATA CONTROLLER?</h1>

            <p>As mentioned above – all response data at an individual level is controlled by the Creator.
                {isQuiz ? "QUIZZORY" : "SURVEYHEART"}
                can be a data
                controller of data about Respondents only in the very limited ways described in the section here
                called
                "How we use
                the
                information we collect - Respondent". To the extent that is the case we have identified the correct
                controller
                below.</p>


            <h1>CONTACT US:</h1>

            <p>SurveyHeart LLP,
                <br/>
                Awfis Space Solutions, 2nd Floor, Survey No 34,
                <br/>
                Kothaguda Junction, Kondapur,<br/>
                Hyderabad, Telangana,
                <br/>
                India - 500084.
                <br/>
                Website: <a
                    href={isQuiz ? "https://www.quizzory.com" : "https://www.surveyheart.com"}>{isQuiz ? "https://www.quizzory.com" : "https://www.surveyheart.com"}</a>
            </p>
        </div>
    )
}

export default PrivacyPolicy
