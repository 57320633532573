import {COLLABORATOR_ACTION_TYPE} from "../utils/constants";
import {COLLABORATOR_ROLES} from "../builder/enums/collaboratorsRoles";
import {t} from "i18next";
import {translationKeys} from "../../localizations/translationKeys-localization";

const COLLABORATOR_ROLES_TEXT = {
    ADMINISTRATOR: t(translationKeys.admin),
    EDITOR: t(translationKeys.editor),
    VIEWER: t(translationKeys.viewer)
}

const initialState = {
    collaborators: []
}

const collaboratorsData = (state = initialState, action) => {
    const data = {...state}

    switch (action.type) {
        case COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_DATA:
            data.collaborators = action.payload
            return data
        case COLLABORATOR_ACTION_TYPE.ADD_COLLABORATOR:
            if (data.collaborators === undefined) {
                data.collaborators = []
            }
            data.collaborators.push({
                _id: action.payload.collaboratorId,
                user_id: action.payload.user_id,
                role: action.payload.role === COLLABORATOR_ROLES_TEXT.VIEWER ? COLLABORATOR_ROLES.VIEWER : action.payload.role === COLLABORATOR_ROLES_TEXT.EDITOR ? COLLABORATOR_ROLES.EDITOR : COLLABORATOR_ROLES.ADMINISTRATOR
            })
            return data
        case COLLABORATOR_ACTION_TYPE.REMOVE_COLLABORATOR:
            data.collaborators = data.collaborators.filter(collaborator => collaborator.user_id !== action.payload.user_id)
            return data
        case COLLABORATOR_ACTION_TYPE.UPDATE_COLLABORATOR_ROLE:
            data.collaborators[data.collaborators.findIndex(c => c._id === action.payload.collaborator_id)].role = action.payload.role === COLLABORATOR_ROLES_TEXT.VIEWER ? COLLABORATOR_ROLES.VIEWER : action.payload.role === COLLABORATOR_ROLES_TEXT.EDITOR ? COLLABORATOR_ROLES.EDITOR : COLLABORATOR_ROLES.ADMINISTRATOR
            return data
        default:
            return state;
    }
}

export default collaboratorsData