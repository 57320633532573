/* This is the Abstract Base Class */
/**
 * @param {number} key - Unique Key
 * @param {string} title - The Title to be displayed
 * @param {MenuIconInfo} menuIconInfo - Instance of the MenuIconInfo class
 */
class MenuItem {
    constructor(key, title, menuIconInfo, lastImg) {
        if (new.target === MenuItem) {
            throw new TypeError("Cannot construct MenuItem instances directly.");
        }

        this.Key = key
        this.Title = title
        this.LastImg = lastImg
        this.MenuIconInfo = menuIconInfo
    }

    getKey() {
        return this.Key
    }

    getTitle() {
        return this.Title
    }

    getLastImg() {
        return this.LastImg
    }

    getIconInfo() {
        return this.MenuIconInfo
    }
}

export default MenuItem