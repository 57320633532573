import React, {useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {Pages} from "../../utils/pages";
import MaterialFloatingActionButton from "../../../shared/components/MaterialFab";
import {APP_ACTION_TYPE} from "../../reducers/appReducer";
import {useDispatch, useSelector} from "react-redux";
import {BUILDER_CREATION_SOURCE} from "../../../shared/utils/constants";
import {templateTypes} from "./DashBoardContainer";

export const DashBoardFABButton = (props) => {
    const [stickCreateNewFAB, setStickCreateNewFAB] = useState(false);
    const showQuiz = useSelector(state => state.appDashboardReducer.showQuiz)
    const templateType = useSelector(state => state.appDashboardReducer.templateType)
    const selectedLanguage = useSelector(state => state.languageReducer.selectedLanguage)
    const isQuiz = (showQuiz || (templateType === templateTypes.QUIZ))
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.bodyContainerElementRef.current) {
            props.bodyContainerElementRef.current.addEventListener('scroll', (e) => {
                e.target.scrollTop > 120 ? setStickCreateNewFAB(true) : setStickCreateNewFAB(false)
                if (isMobile && props.timeOutForLongPress.current) {
                    clearTimeout(props.timeOutForLongPress.current)
                    props.timeOutForLongPress.current = undefined
                }
            })
        }
    }, [props.bodyContainerElementRef.current]);

    const createNewFloatButtonClickHandler = () => {
        dispatch({type: APP_ACTION_TYPE.SET_BUILDER_CREATION_SOURCE, payload: BUILDER_CREATION_SOURCE.NEW})
        dispatch({type: APP_ACTION_TYPE.SET_TEMPLATE_THEME, payload: null})
        dispatch({type: APP_ACTION_TYPE.SET_SHARED, payload: false})
        dispatch({type: APP_ACTION_TYPE.SET_FORM_DATA, payload: null})
        dispatch({type: APP_ACTION_TYPE.SET_IS_QUIZ, payload: isQuiz})
        dispatch({type: APP_ACTION_TYPE.SET_CURRENT_PAGE, payload: Pages.builder})
    }

    return (
        <div id={'create-new-FAB'} className={stickCreateNewFAB ? 'create-new-FAB stick-to-bottom-active' : 'create-new-FAB'}
             style={stickCreateNewFAB ? (props.multipleSelectedArrayRef && Array.isArray(props.multipleSelectedArrayRef.current) && props.multipleSelectedArrayRef.current.length > 0) ?
                 {transform: 'translateY(-150px)', right: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '93%' : null} : {right: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '93%' : null} : {right: (selectedLanguage === 'fa' || selectedLanguage === 'ar') ? '93%' : null}}>
            <MaterialFloatingActionButton iconName={'add'} handleClick={createNewFloatButtonClickHandler}/>
        </div>
    )
}