/**
 * @param {string} altText - The alternate text for the Image
 * @param {string} imgBaseFile - The 'base' of the file name (should not include "_blue.png" or "_grey.png").
 * @param {string} defaultPath - The default path. This allows sub-classes to specify other default paths.  Default is '../../../images/'.
 * @param {boolean} useDefaultImagePath - Should the file be prefixed with the default path. Default is true.
 */
class MenuIconInfo {
    constructor(altText, imgBaseFile, defaultPath, useDefaultImagePath) {
        this.altText = altText;
        this.imgBaseFile = imgBaseFile;
        this.defaultPath = (defaultPath === undefined ? "../../../images/" : defaultPath)
        this.useDefaultImagePath = (useDefaultImagePath === undefined ? true : useDefaultImagePath)
    }

    getImgSrcPath() {
        if(this.useDefaultImagePath === true) {
            return this.imgBaseFile = `${this.defaultPath}${this.imgBaseFile}`;
        } else {
            return this.imgBaseFile;
        }
    }

    getAltText() {
        return this.altText;
    }
}

export default MenuIconInfo