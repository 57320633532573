export const SOURCE_TYPE = {
    ANDROID: 'android',
    WEB_APP: 'web-app',
    IOS: 'ios-app'
}
export const PER_PAGE_LIMIT = 50
export const ADMIN_SERVICE_TYPE = {
    FORM: 'Form',
    QUIZ: 'Quiz'
}
export const ADMIN_LOCAL_STORAGE_CONSTANTS = {
    USER_ID: 'userId',
    SERVICE: 'service',
    REMEMBER: 'remember',
    LAST_ADMIN_SCREEN: 'lastAdminScreen'
}
export const ADMIN_FORM_IDS = {
    REPORT_ABUSE_FORMS: "5c6cdd029f25685f413430ef",
    REPORT_ABUSE_QUIZZES: "5f323faf7693da10a21c84c6",
    CONTACT_US: "5dc50aa5b678b61dbec474a4",
    INVALIDATED_FORM_APPLICATION: "5eb3d579c411ae0a298e7a08",
    FAGF_FEEDBACK: "5e47c519bfec405494314f4d",
    SASH_FASH_FEEDBACK: "5e2ad7279ddceb28451312d4",
    QUIZ_REPORT_ABUSE: "5f323faf7693da10a21c84c6",
    BLOCK_APPEAL_FORM: "5f2144e489672f100af71a75",
    INVALIDATED_QUIZ_APPLICATION: "5f211698b770fe0f8c274122"
}
export const WORD_TYPE = {
    ABUSE: 'Abuse',
    BLOCKED: 'Blocked'
}

export const DATE_FORMATS = {
    DDMMYYYY: 'DD/MM/YYYY',
    MMDDYYYY: 'MM/DD/YYYY',
    YYYYMMDD: 'YYYY/MM/DD',
    DD_MM_YYYY_TIME: 'DD MM YYYY time',
    DD_MM_YYYYCOMATIME: 'DD MM YYYY, time',
    TIME_DDMMYYYY: 'time - DD/MM/YYYY'
}

export const reportAbuseChoices = [
    {
        "_id": "5c6cdd029f25685f413430fb",
        "label": "Violence"
    },
    {
        "_id": "5c6cdd029f25685f413430fa",
        "label": "Terrorist content"
    },
    {
        "_id": "5c6cdd029f25685f413430f9",
        "label": "Spam, malware and phishing"
    },
    {
        "_id": "5c6cdd029f25685f413430f8",
        "label": "Sexually explicit material"
    },
    {
        "_id": "5c6cdd029f25685f413430f7",
        "label": "Child exploitation"
    },
    {
        "_id": "5c6cdd029f25685f413430f6",
        "label": "Copyright infringement"
    },
    {
        "_id": "5c6cdd029f25685f413430f5",
        "label": "Content use and submission"
    },
    {
        "_id": "5c6cdd029f25685f413430f4",
        "label": "Illegal activities"
    },
    {
        "_id": "5c6cdd029f25685f413430f3",
        "label": "Harassment, bullying and threats"
    },
    {
        "_id": "5c6cdd029f25685f413430f2",
        "label": "Hate speech"
    }
]