import MenuItem from "./MenuItem"

/**
 * @param {string} action - The Action to be performed
 */
class PopupMenuItem extends MenuItem {
    constructor(key, title, menuIconInfo, action, lastImg) {
        super(key, title, menuIconInfo, lastImg)
        this.Action = action
    }

    getAction() {
        return this.Action()
    }
}

export default PopupMenuItem