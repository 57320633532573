import React from 'react'

export const showResponseDeleteAlert = (multipleSelectedDataArray, DataListArray, deletableDataId) => {
    let deletableId;
    if (!multipleSelectedDataArray || multipleSelectedDataArray.length === 0) {
        deletableId = deletableDataId;
    } else if (multipleSelectedDataArray?.length === 1) {
        deletableId = multipleSelectedDataArray[0];
    } else if (Array.isArray(multipleSelectedDataArray) && multipleSelectedDataArray?.length > 1) {
        return true
    }
    if (deletableId) {
        for (let i = 0; i < DataListArray.length; i++) {
            if ((DataListArray[i]._id === deletableId) && (DataListArray[i].response_count > 0)) {
                return true
            }
        }
    }
    return false;
}