const AWS = require('aws-sdk/global');
let s3 = require('aws-sdk/clients/s3');
const albumBucketName = "surveyheartmedia";
const bucketRegion = "ap-south-1";
const IdentityPoolId = "ap-south-1:08291bb8-da50-4343-913f-05159a951c0c";

export const MEDIA_BASE_URL = 'https://surveyheartmedia.s3.ap-south-1.amazonaws.com';
const AWS_IMAGE_PATH = process.env.REACT_APP_ENV_AWS_IMAGE_PATH || "images";
const AWS_LOGOS_PATH = process.env.REACT_APP_ENV_AWS_LOGOS_PATH || "logos";
const AWS_THEME_PATH = process.env.REACT_APP_ENV_AWS_THEME_PATH || "themes";
const AWS_FILES_PATH = process.env.REACT_APP_ENV_AWS_FILES_PATH || "files";
const AWS_USERS_JSON_PATH = process.env.REACT_APP_ENV_AWS_USERS_JSON_PATH || "users-json";
const AWS_ADMIN_PATH = process.env.REACT_APP_ENV_AWS_ADMIN_PATH || "admin";

const AWS_MOBILE_PATH = "mobile";
const AWS_PC_PATH = "pc";
const AWS_THUMBNAIL_PATH = "thumbnail";
const AWS_THUMBNAIL_512_342_PATH = "thumbnail_512_342";
const AWS_THUMBNAILWEB_PATH = "thumbnail_web";

export const AWS_BUCKET_MIDDLE_PATH = "/public/images/"

export const AWS_FOLDER_NAMES = {
    NONE: "none",
    IMAGES_FOLDER: AWS_IMAGE_PATH,
    LOGOS_FOLDER: AWS_LOGOS_PATH,
    FILES_FOLDER: AWS_FILES_PATH,
    THEMES_FOLDER: AWS_THEME_PATH,
    USERS_JSON_FOLDER: AWS_USERS_JSON_PATH,

    MOBILE_FOLDER: AWS_MOBILE_PATH,
    PC_FOLDER: AWS_PC_PATH,
    THUMBNAIL_FOLDER: AWS_THUMBNAIL_PATH,
    THUMBNAIL_512_342_FOLDER: AWS_THUMBNAIL_512_342_PATH,
    THUMBNAILWEB_FOLDER: AWS_THUMBNAILWEB_PATH,
    ADMIN_FOLDER: AWS_ADMIN_PATH
};

export const AWS_UPLOAD_TYPE = {
    FORM: "form",
    LOGO: "logo",
    THEME: "theme",
    NONE: "none",
    FILE: "file",
    ADMIN_CAREER: "admin-career"
}

let folder = AWS_FOLDER_NAMES.NONE;

AWS.config.update({
    region: bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: IdentityPoolId
    })
});

s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: {Bucket: albumBucketName}
});

export function uploadFile(file, uploadType, userId, successFunction, failureFunction) {
    let fileName;

    try {
        if (uploadType === AWS_UPLOAD_TYPE.LOGO) {
            fileName = setFileName(file, `${userId}_logo`);
        } else if (uploadType === AWS_UPLOAD_TYPE.ADMIN_CAREER) {
            fileName = setFileName(file);
        } else {
            fileName = setFileName(file);
        }

        const albumPhotosKey = folder + "/";
        const photoKey = albumPhotosKey + fileName;
        // To upload file to AWS server
        const upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: albumBucketName,
                Key: photoKey,
                Body: file,
                ACL: "public-read"
            }
        });
        const promise = upload.promise();
        promise.then(
            function (data) {
                successFunction(data);
                // alert("Successfully uploaded photo.");
            },
            function (err) {
                failureFunction();
                console.log(err);
            }
        );
    } catch (e) {
        console.log(e)
    }
}

export async function uploadFilesFromAdmin(files, userId, successFunction, failureFunction) {
    try {
        for (const file of files) {
            let fileName = setFileName(file);

            const albumPhotosKey = folder + "/";
            const photoKey = albumPhotosKey + fileName;

            // To upload file to AWS server
            const upload = new AWS.S3.ManagedUpload({
                params: {
                    Bucket: albumBucketName,
                    Key: photoKey,
                    Body: file,
                    ACL: "public-read"
                }
            });

            const promise = upload.promise();
            await promise.then(
                function (data) {
                    successFunction(data);
                },
                function (err) {
                    failureFunction();
                    console.log(err);
                }
            );
        }
    } catch (e) {
        console.log(e);
    }
}

export function uploadTemplateCategories(file, uploadType, successFunction, failureFunction) {

    try {
        let templateKey = "public/templates_categories.json"
        // To upload file to AWS server
        const upload = new AWS.S3.ManagedUpload({
            params: {
                Bucket: albumBucketName,
                Key: templateKey,
                Body: JSON.stringify(file),
                ACL: "public-read",
                ContentType: 'application/json'
            }
        });
        const promise = upload.promise();
        promise.then(
            function (data) {
                successFunction(data);
            },
            function (err) {
                failureFunction();
                console.error(err);
            }
        );
    } catch (e) {
        console.error(e)
    }
}

export function deletePhoto(folder, imageName, successFunction) {
    const imageKey = folder + '/' + imageName;

    //To delete object from AWS server.
    s3.deleteObject({Key: imageKey}, (err) => {
        if (err) {
            return alert("There was an error deleting your photo: ", err.message);
        }
        successFunction();
    });
}

export const deleteFileAws = async (fileKey, successFunction) => {
    //To delete object from AWS server.
    await s3.deleteObject({Key: fileKey}, (err) => {
        if (err) {
            console.log(`There was an error deleting the file: ${err.message}`);
            return
        }
        successFunction();
    });
}

export function setAwsFolderName(folderName) {
    folder = folderName;
}

function setFileName(file, targetFileName = null) {
    // If targetFileName is NOT null, a Logo is being saved.
    // We don't rename the Logo file. Example format "rjeyakumar92@gmail.com_logo.png".
    const millies = new Date().getTime();
    let newName = targetFileName === null ? "sh_" + millies : targetFileName;

    switch (file.type) {
        case 'application/json':
            newName = file.name;
            break;
        case 'image/png':
            newName = newName + '.png';
            break;
        case 'image/jpg':
            newName = newName + '.jpg';
            break;
        case 'image/jpeg':
            newName = newName + '.jpg';
            break;
        default:
            // This is a non-image File So use the extension that came with the File.
            newName = newName + file.name.substr(file.name.lastIndexOf('.'))
    }

    return newName;
}

export async function updateCareersFile(updatedFile, successFunction, failureFunction) {

    try {
        const update = await s3.putObject({
            Bucket: albumBucketName,
            Key: AWS_ADMIN_PATH + "/career.json",
            Body: JSON.stringify(updatedFile),
            ContentType: "application/json"
        });
        const promise = update.promise();
        promise.then(
            function (data) {
                successFunction(data);
            },
            function (err) {
                failureFunction();
                console.log(err);
            }
        );
    } catch (e) {
        console.log(e)
    }
}

export const getCareersJson = async (successFunction) => {
    try {
        const update = await s3.getObject({
            Bucket: albumBucketName,
            Key: AWS_ADMIN_PATH + "/career.json"
        });
        const promise = update.promise();
        promise.then(
            function (data) {
                successFunction(data);
            },
            function (err) {
                console.log(err);
            }
        );
    } catch (e) {
        console.log(e)
    }
}
