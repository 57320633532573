import React, {useState} from "react"
import "../css/top-nav-bar.css"
import MaterialButton, {ButtonTypes} from "../../shared/components/MaterialButton";

const TopNavBar = (props) => {

    const [showMobileMenu, setShowMobileMenu] = useState(false)

    const navigateToUrl = (url) => {
        window.open(url, "_blank")
    }

    return (
        <>
            <div className={"top-navigator-bar"}
                 style={{boxShadow: props.color === "#000000" ? "0px 6px 12px 0px #00000029" : null}}>
                <div className="title-banner">
                    <img id={"app-logo"} src="images/surveyheart1.png"/>
                    <div className={"mobile-container"}>
                        <MaterialButton data={{
                            title: "Sign in",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#1976D2',
                            fontSize: '18px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '2px',
                                padding: '15px 35px',
                                width: '50px',
                            }
                        }} handleClick={() => navigateToUrl("/app?u=1")}/>
                        <i onClick={() => setShowMobileMenu(true)}
                           style={{color: props.burgerColor ? props.burgerColor : null}}
                           className="material-icons">menu</i>
                    </div>
                </div>
                <div className={"navigators"}>
                    <span style={{color: props.color ? props.color : null}}
                          onClick={() => window.location = "/"}>Home</span>
                    <span id={"templates"} style={{color: props.color ? props.color : null}}
                          onClick={() => window.location = "/#templates"}>Templates</span>
                    <span id={"pricing"} style={{color: props.color ? props.color : null}}
                          onClick={() => navigateToUrl("/#pricing")}>Pricing</span>
                    <span id={"careers"} style={{color: props.color ? props.color : null}}
                          onClick={() => navigateToUrl("/#careers")}>Careers</span>
                    <span id={"contact-us"} style={{color: props.color ? props.color : null}}
                          onClick={() => navigateToUrl('https://www.surveyheart.com/form/5dc50aa5b678b61dbec474a4#welcome')}>Contact</span>
                    {props.showDemo ? <span id={"demo"} style={{color: props.color ? props.color : null}}
                                            onClick={() => props.setShowDemoPopUp(true)}>Request Demo</span> : null}
                    <div className={"login-container"}>
                        <MaterialButton data={{
                            title: "Sign in",
                            buttonType: ButtonTypes.OUTLINED,
                            fontSize: '18px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '20px',
                                borderColor: "#FFFFFF",
                                padding: '15px 35px',
                                width: '100px',
                                visibility: props.color === "#000000" ? "hidden" : null
                            }
                        }} handleClick={() => navigateToUrl("/app?u=1")}/>
                        <MaterialButton data={{
                            title: "Sign up",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#1976D2',
                            fontSize: '18px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '20px',
                                padding: '15px 35px',
                                width: '100px',
                            }
                        }} handleClick={() => navigateToUrl("/app?u=0")}/>
                    </div>
                </div>
                {showMobileMenu ? <div className={"mobile-navigation-menu"} id={"mobile-navigation-menu"}>
                <span style={{color: props.color ? props.color : null}}
                      onClick={() => window.location = "/"}>Home</span>
                    <span id={"templates"} style={{color: props.color ? props.color : null}}
                          onClick={() => window.location = "/#templates"}>Templates</span>
                    <span id={"pricing"} style={{color: props.color ? props.color : null}}
                          onClick={() => navigateToUrl("/#pricing")}>Pricing</span>
                    <span id={"careers"} style={{color: props.color ? props.color : null}}
                          onClick={() => navigateToUrl("/#careers")}>Careers</span>
                    <span id={"contact-us"} style={{color: props.color ? props.color : null}}
                          onClick={() => navigateToUrl('https://www.surveyheart.com/form/5dc50aa5b678b61dbec474a4#welcome')}>Contact</span>
                    {props.showDemo ? <span id={"demo"} style={{color: props.color ? props.color : null}}
                                            onClick={() => props.setShowDemoPopUp(true)}>Request Demo</span> : null}
                    <div className={"login-container"}>
                        <MaterialButton data={{
                            title: "Sign in",
                            buttonType: ButtonTypes.OUTLINED,
                            fontSize: '18px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '20px',
                                borderColor: "#FFFFFF",
                                padding: '15px 35px',
                                width: '100px',
                                visibility: props.color === "#000000" ? "hidden" : null
                            }
                        }} handleClick={() => navigateToUrl("/app?u=1")}/>
                        <MaterialButton data={{
                            title: "Sign up",
                            buttonType: ButtonTypes.CONTAINED,
                            bg_color: '#1976D2',
                            fontSize: '18px',
                            customButtonContainerStyle: {
                                margin: '10px',
                                borderRadius: '20px',
                                padding: '15px 35px',
                                width: '100px',
                            }
                        }} handleClick={() => navigateToUrl("/app?u=0")}/>
                    </div>
                    <i onClick={() => setShowMobileMenu(false)} className="material-icons">cancel</i>
                </div> : null}
            </div>
        </>
    )
}

export default TopNavBar
